import React, { FunctionComponent, useState } from "react";

import { Paper, Box, Typography, Grid, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { Bottle } from "../../../services/types/bottle";
import { showNotification } from "../../notification/notificationSlice";
import { updateBottle } from "./bottleSlice";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputColor from "../../../components/form/InputColor";
import InputSelect from "../../../components/form/InputSelect";
import { bottleCategories } from "../../constants";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import InputSwitch from "../../../components/form/InputSwitch";

interface EditBottleFormProps {
  bottle: Bottle;
  bar: Bar | null;
}

const EditBottleForm: FunctionComponent<EditBottleFormProps> = ({
  bottle,
  bar,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: bottle,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Bottle) => {
    setDisabled(true);
    bottle = {
      ...bottle,
      name: data.name,
      category: data.category,
      color: data.color,
      subCategory: data.subCategory || "",
      disabled: data.disabled,
      visible: data.visible,
      price: data.price || 0,
      alcoholPerc: data.alcoholPerc || 0,
    };
    console.log(bottle);
    dispatch(
      updateBottle(
        bottle,
        bar,
        () => {
          dispatch(showNotification("Bottle Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!bottle) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Bottle
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputSelect
                label="Category"
                name="category"
                //defaultValue={bottle.category}
                control={control}
                required
                fullWidth
              >
                {bottleCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item md={6}>
              <InputText
                name="subCategory"
                label="Subcategory"
                register={register}
                fullWidth
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                Ingredient Info
              </Typography>
              {/* <InputSwitch
                label="Disabled ingredient?"
                name="disabled"
                type="checkbox"
                control={control}
              />
              <br />

              <InputSwitch
                label="Visible?"
                type="checkbox"
                name="visible"
                control={control}
              />
            </Grid>
            <Grid item md={6}>
              <InputNumber
                name="price"
                label="Price"
                register={register}
                fullWidth
              /> */}
              <InputNumber
                name="alcoholPerc"
                label="Alcoholic Percentage"
                register={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>

        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditBottleForm;
