import React, { FunctionComponent, useState } from "react";

import { Box, FormControl, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showNotification } from "../../notification/notificationSlice";
import { manualCommandIce } from "./dispenserIceSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import { DispenserIce } from "../../../services/types/dispenserIce";

interface ManualCommandIceProps {
  dispenserIce: DispenserIce;
}

const ManualCommandIce: FunctionComponent<ManualCommandIceProps> = ({
  dispenserIce,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      dispenserIce,
      quantity: 1,
      dispensingTime: 200,
    },
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  register('dispenserIce');

  const onSubmit = (data: any) => {
    setDisabled(true);
    console.log(data);

    dispatch(
      manualCommandIce(
        data.dispenserIce,
        data.quantity,
        data.dispensingTime,
        () => {
          dispatch(showNotification("Manual command sent!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl margin="normal" fullWidth>
              <InputNumber
                name="quantity"
                label="Quantity"
                register={register}
                required
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl margin="normal" fullWidth>
              <InputNumber
                name="dispensingTime"
                label="Dispensing Time"
                register={register}
                required
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>
        <MainSpacer />
      </Box>
      <InputButton disabled={disabled}>Test</InputButton>
    </form>
  );
};

export default ManualCommandIce;
