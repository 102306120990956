import { createReducer, createAction, PrepareAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  Bar,
  CupSettings,
  RoboticSystem,
  ThemeType,
} from '../../services/types/bar';
import { Robot } from '../../services/types/robot';
import { updateDispenserBottleSuccess } from '../positionScreen/bottle/dispenserBottleSlice';
import { restoreCupErrorSuccess } from '../activityScreen/activitySlice';

// STATE INTERFACE
interface InstanceBarState {
  current: Bar | null;
  robots: Robot[];
  available: Bar[];
}

// INITIAL STATE
const initialState: InstanceBarState = {
  current: null,
  robots: [],
  available: [],
};

// ACTIONS
export const impersonateBar = createAction<Bar>('instanceBar/impersonateBar');
export const getAvailableBars = createAction<PrepareAction<undefined>>(
  'instanceBar/getAvailableBars',
  () => ({
    payload: undefined,
    meta: {
      fetch: 'bar',
      resource: 'getAvailableBars',
    },
  })
);
export const getAvailableBarsSuccess = createAction<Bar[]>(
  'instanceBar/getAvailableBars_SUCCESS'
);

export const getCurrentBar = createAction<PrepareAction<Bar>>(
  'instanceBar/getCurrentBar',
  (bar: Bar, onSuccess, onFailure) => ({
    payload: bar,
    meta: {
      fetch: 'bar',
      resource: 'getCurrentBar',
      onSuccess,
      onFailure,
    },
  })
);
export const getCurrentBarSuccess = createAction<Bar>(
  'instanceBar/getCurrentBar_SUCCESS'
);
export const getCurrentBarFailure = createAction<Bar>(
  'instanceBar/getCurrentBar_FAILURE'
);

export const toggleDanceBar = createAction<
  PrepareAction<Partial<RoboticSystem> & { barId: string }>
>(
  'activity/toggleDanceBar',
  (bar: Bar, value: boolean, onSuccess, onFailure) => ({
    payload: { danceMode: value, barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'updateRoboticSystemBar',
      onSuccess,
      onFailure,
    },
  })
);
export const toggleDanceBarSuccess = createAction<Bar>(
  'activity/toggleDanceBar_SUCCESS'
);

export const toggleGhostBar = createAction<
  PrepareAction<Partial<RoboticSystem> & { barId: string }>
>(
  'activity/toggleGhostBar',
  (bar: Bar, value: boolean, onSuccess, onFailure) => ({
    payload: { ghostMode: value, barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'updateRoboticSystemBar',
      onSuccess,
      onFailure,
    },
  })
);
export const toggleGhostBarSuccess = createAction<Bar>(
  'activity/toggleGhostBar_SUCCESS'
);
export const toggleMaintenanceBar = createAction<
  PrepareAction<Partial<RoboticSystem> & { barId: string }>
>(
  'activity/toggleMaintenanceBar',
  (bar: Bar, value: boolean, onSuccess, onFailure) => ({
    payload: { maintenanceMode: value, barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'updateRoboticSystemBar',
      onSuccess,
      onFailure,
    },
  })
);
export const toggleMaintenanceBarSuccess = createAction<Bar>(
  'activity/toggleMaintenanceBar_SUCCESS'
);

export const toggleAutomaticConveyorBar = createAction<
  PrepareAction<Partial<RoboticSystem> & { barId: string }>
>(
  'activity/toggleAutomaticConveyorBar',
  (bar: Bar, value: boolean, onSuccess, onFailure) => ({
    payload: { automaticConveyor: value, barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'updateRoboticSystemBar',
      onSuccess,
      onFailure,
    },
  })
);
export const toggleAutomaticConveyorBarSuccess = createAction<Bar>(
  'activity/toggleAutomaticConveyorBar_SUCCESS'
);

export const toggleBarStateBar = createAction<
  PrepareAction<{ open: boolean; id: string }>
>(
  'activity/toggleBarStateBar',
  (bar: Bar, value: boolean, onSuccess, onFailure) => ({
    payload: { open: value, id: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'toggleBarStateBar',
      onSuccess,
      onFailure,
    },
  })
);
export const toggleBarStateBarSuccess = createAction<Bar>(
  'activity/toggleBarStateBar_SUCCESS'
);

export const barInstanceUpdatedRemotely = createAction<Bar>(
  'activity/barInstanceUpdatedRemotely'
);
export const robotInstanceUpdatedRemotely = createAction<Robot>(
  'activity/robotInstanceUpdatedRemotely'
);

export const setVizTheme = createAction<
  PrepareAction<Partial<{ id: string; theme: ThemeType }>>
>(
  'activity/setVizTheme',
  (bar: Bar, theme: ThemeType, onSuccess, onFailure) => ({
    payload: { id: bar.id, theme },
    meta: {
      fetch: 'bar',
      resource: 'setVizTheme',
      onSuccess,
      onFailure,
    },
  })
);
export const setVizThemeSuccess = createAction<Bar>(
  'activity/setVizTheme_SUCCESS'
);

export const toggleRobotEnabled = createAction(
  'activity/toggleRobotEnabled',
  (bar: Bar, robot: Robot, onSuccess, onFailure) => ({
    payload: { id: robot.id, enabled: !robot.enabled, barId: bar.id, command: 'toggleRobot' },
    meta: {
      fetch: 'robot',
      resource: 'updateRobot',
      onSuccess,
      onFailure,
    },
  })
);

export const toggleRobotEnabledSuccess = createAction<Robot>(
  'activity/toggleRobotEnabled_SUCCESS'
);

// REDUCERS
const instanceBarsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(impersonateBar, (state: InstanceBarState, { payload }) => {
      state.current = payload;
      state.robots = payload.robots;
    })
    .addCase(
      getAvailableBarsSuccess,
      (state: InstanceBarState, { payload }) => {
        state.available = payload;
      }
    )
    .addCase(
      toggleDanceBarSuccess,
      (state: InstanceBarState, { payload: { roboticSystem } }) => {
        (state.current as Bar).roboticSystem = roboticSystem;
      }
    )
    .addCase(
      toggleGhostBarSuccess,
      (state: InstanceBarState, { payload: { roboticSystem } }) => {
        (state.current as Bar).roboticSystem = roboticSystem;
      }
    )
    .addCase(
      toggleMaintenanceBarSuccess,
      (state: InstanceBarState, { payload: { roboticSystem } }) => {
        (state.current as Bar).roboticSystem = roboticSystem;
      }
    )
    .addCase(
      toggleAutomaticConveyorBarSuccess,
      (state: InstanceBarState, { payload: { roboticSystem } }) => {
        (state.current as Bar).roboticSystem = roboticSystem;
      }
    )
    .addCase(
      toggleBarStateBarSuccess,
      (state: InstanceBarState, { payload }) => {
        (state.current as Bar).open = payload.open;
      }
    )
    .addCase(setVizThemeSuccess, (state: InstanceBarState, { payload }) => {
      (state.current as Bar).theme = payload.theme;
    })
    .addCase(
      barInstanceUpdatedRemotely,
      (state: InstanceBarState, { payload }) => {
        if (state.current?.id === payload.id) {
          state.current = payload;
        }
      }
    )
    .addCase(restoreCupErrorSuccess, (state: InstanceBarState, { payload }) => {
      if (state.current?.id === payload.id) {
        state.current = payload;
      }
    })
    .addCase(
      toggleRobotEnabledSuccess,
      (state: InstanceBarState, { payload }) => {
        state.robots = state.robots.map((robot) => {
          if (robot.id === payload.id) {
            return payload;
          }
          return robot;
        });
      }
    )
    .addCase(
      robotInstanceUpdatedRemotely,
      (state: InstanceBarState, { payload }) => {
        state.robots = state.robots.map((robot) => {
          if (robot.id === payload.id) {
            return payload;
          }
          return robot;
        });
      }
    )
    .addCase(
      updateDispenserBottleSuccess,
      (state: InstanceBarState, { payload: dispenser }) => {
        const bar = state.current as Bar;
        if (
          dispenser.enabled &&
          dispenser.actualLevel <= dispenser.alarmLevel
        ) {
          bar.bottleAlarm = (bar?.bottleAlarm || []).filter(
            (el) => el.dispenserId !== dispenser.id
          );
          bar?.bottleAlarm.push({
            dispenserId: dispenser.id,
            ingredientId: dispenser.ingredientId,
          });
          bar.bottleWarning = bar?.bottleWarning.filter(
            (el) => el.dispenserId !== dispenser.id
          );
        } else if (
          dispenser.enabled &&
          dispenser.actualLevel < dispenser.dispenserCapacity * 0.3
        ) {
          bar.bottleWarning = (bar?.bottleWarning || []).filter(
            (el) => el.dispenserId !== dispenser.id
          );
          bar?.bottleWarning.push({
            dispenserId: dispenser.id,
            ingredientId: dispenser.ingredientId,
          });
        } else {
          bar.bottleAlarm = (bar?.bottleAlarm || []).filter(
            (el) => el.dispenserId !== dispenser.id
          );
          bar.bottleWarning = bar?.bottleWarning.filter(
            (el) => el.dispenserId !== dispenser.id
          );
        }
        state.current = bar;
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentInstanceBar = (state: RootState) =>
  state.instanceBar.current;
export const selectCurrentInstanceRobots = (state: RootState) =>
  state.instanceBar.robots;
export const selectCurrentInstanceCupsCode = (state: RootState) => {
  return (state.instanceBar.current?.roboticSystem.cups || []).map(
    (c: CupSettings) => ({
      code: c.code,
      name: c.name,
    })
  );
};
export const selectAvailableBars = (state: RootState) =>
  state.instanceBar.available;

export default instanceBarsReducer;
