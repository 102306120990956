import React, { FunctionComponent } from "react"
import { useSelector } from 'react-redux';

import {
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { selectIsAuthenticated } from "../auth/authSlice";


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PublicRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
      }
    />
  );
}

export default PublicRoute;