import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FunctionComponent } from "react";
import { RegisterOptions } from "react-hook-form";
import InputSwitch from "../../../components/form/InputSwitch";
import { Bar } from "../../../services/types/bar";

interface BarFeaturesProps {
  register: (arg:RegisterOptions) => any;
  errors: any;
  control: any;
  bar: Bar;
  setValue: (...arg: any) => any;
  className: any;
}

const ComponentBarFeatures: FunctionComponent<BarFeaturesProps> = ({
  register,
  control,
  errors,
  bar,
  setValue,
  ...props
}) => {
  return (
    <div {...props}>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Features that will be available by admin portal
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <InputSwitch
              name="features.bottles"
              label="Bottles"
              type="checkbox"
              control={control}
            />
            <br />  
            <InputSwitch
              name="features.bibs"
              label="Bibs"
              type="checkbox"
              control={control}
            />
            <br />  
            <InputSwitch
              name="features.granulars"
              label="Granulars"
              type="checkbox"
              control={control}
            />
            <br />  
            <InputSwitch
              name="features.garnish"
              label="Garnish"
              type="checkbox"
              control={control}
            />
            <br />  
            <InputSwitch
              name="features.leaves"
              label="Leaves"
              type="checkbox"
              control={control}
            />
            <br />  
            <InputSwitch
              name="features.ice"
              label="Ice"
              type="checkbox"
              control={control}
            />
            <br />  
            <InputSwitch
              name="features.coffee"
              label="Coffee"
              type="checkbox"
              control={control}
            />
            <br />  
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ComponentBarFeatures;
