import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
  ListItemText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createMilk, findGlobalMilkByName } from "./milkSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Beverage } from "../../../services/types/beverage";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useFieldArray, useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { milkCategories } from "../../constants";
import { Delete } from "@material-ui/icons";
import { findFlavourByName } from "../flavour/flavourSlice";
import FlavourLookup from "../FlavourLookup";
import InputSwitch from "../../../components/form/InputSwitch";
import InputText from "../../../components/form/InputText";
interface CreateMilkFormProps {
  bar: Bar | null;
  onCreateSuccess: (beverage: Beverage) => any;
}

const CreateMilkForm: FunctionComponent<CreateMilkFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      category: milkCategories[0],
      isVegan: false,
      isHot: true,
      color: "#E1E1E1",
      price: 0,
      alcoholPerc: 0,
      flavours: [""],
      foamAirSpeed: 0,
      pumpSpeed: 0,
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "flavours",
    keyName: "keyId",
  });

  const onSubmit = (data: Beverage) => {
    setDisabled(true);
    dispatch(
      createMilk(
        data,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Milk Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Milk
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            label="Name"
            name="name"
            register={register}
            // onChange={(data: any) => {
            //   if (data) {
            //     console.log(data);
            //     data.color && setValue("color", data.color);
            //     data.alcoholPerc && setValue("alcoholPerc", data.alcoholPerc);
            //     data.category && setValue("category", data.category);
            //     data.isVegan && setValue("isVegan", data.isVegan);
            //     data.flavours &&
            //       setValue(
            //         "flavours",
            //         data.flavours.map((f: any) => {
            //           f.globalId = f.id;
            //           return f;
            //         })
            //       );
            //     setValue("price", 0);
            //   }
            // }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {milkCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
          <Grid item xs={12}>
            <InputSwitch
              name="isVegan"
              label="Is Vegan?"
              type="checkbox"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <InputSwitch
              name="isHot"
              label="Hot milk?"
              type="checkbox"
              control={control}
            />
          </Grid>
      </Grid>
      <br />
      <Typography variant="h5" component="h2" gutterBottom>
        Selected Flavours: {fields.length}/3
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List>
            {fields.map((field, idx) => {
              register({
                name: `flavours[${idx}].id`,
                defaultValue: field.id,
                value: field.id,
              });
              register({
                name: `flavours[${idx}].globalId`,
                defaultValue: field.globalId,
                value: field.globalId,
              });
              return (
                <div key={field.keyId}>
                  <ListItem>
                    <FlavourLookup
                      label={`Flavour ${idx + 1}`}
                      name={`flavours[${idx}].name`}
                      control={control}
                      setError={setError}
                      bar={bar}
                      findIngredient={findFlavourByName}
                      //required
                      onChange={(data: any) => {
                        console.log({ DATA: data });
                        setValue(`flavours[${idx}].id`, data.id);
                      }}
                      defaultValue={field.name}
                      fullWidth
                      disableAdd
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        color="primary"
                        onClick={() => remove(idx)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <InputNumber
                        label="Dispensing Time"
                        name={`flavours[${idx}].dispensingTime`}
                        defaultValue={field.dispensingTime}
                        register={register}
                      />
                    </ListItemText>
                  </ListItem>
                </div>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (fields.length < 3) append({ name: "", dispensingTime: 0 });
              }}
            >
              Add Flavour
            </Button>
          </List>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="foamAirSpeed"
            label="Foam Air Speed"
            helperText="0-400"
            rules={{min: 0, max: 400}}
            register={register}
            fullWidth
          />
          <InputNumber
            name="pumpSpeed"
            label="Pump Speed"
            helperText="0-400"
            rules={{min: 0, max: 400}}
            register={register}
            fullWidth
          />
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Milk!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateMilkForm;
