/* eslint-disable no-mixed-operators */
import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { pink } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { selectCurrentInstanceBar } from './instanceBarSlice';
import BottleIcon from '../../components/icons/BottleIcon';
import CupIcon from '../../components/icons/CupIcon';
import CupIconOff from '../../components/icons/CupIconOff';
import Badge from '../../components/layout/Badge';
import Button from '../../components/layout/Button';
import { Bar } from '../../services/types/bar';
import { restoreFences, generalStart, restoreCupError } from '../activityScreen/activitySlice';
import { showNotification } from '../notification/notificationSlice';
import { useHistory } from 'react-router-dom';
import { subMinutes } from 'date-fns';
import { Typography } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  robotsControls: {
    flexGrow: 0.2,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: pink[50],
    color: theme.palette.text.primary,
  },
  verticalSpacer: {
    display: "inline-block",
    width: "1px",
    height: "30px",
    backgroundColor: theme.palette.divider
  }
}));

const HeaderBarControls: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const bar = useSelector(selectCurrentInstanceBar);

  const handleRestoreCupError = () => {
    dispatch(
      restoreCupError(
        bar as Bar,
        ({ payload }: any) =>
          dispatch(showNotification("Cup Error restored!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Cup Error restore failure!", "error"));
        }
      )
    );
  };
  
  const handleRestoreFences = () => {
    dispatch(
      restoreFences(
        bar as Bar,
        ({ payload }: any) =>
          dispatch(showNotification("Fences restored!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Fences restore failure!", "error"));
        }
      )
    );
  };

  const handleGeneralStart = () => {
    dispatch(
      generalStart(
        bar as Bar,
        ({ payload }: any) =>
          dispatch(showNotification("Started!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("General Start failure!", "error"));
        }
      )
    );
  };

  const handleGoToBottles = () => {
    history.push("/dispensers/bottles")
  }

  if (!bar) {
    return null;
  }
  return (
    <>
      <div className={classes.robotsControls}>
        {(new Date(bar.liveCheck)).getTime() >= (subMinutes(new Date(), 3)).getTime() && (
          <>
            <Button variant="contained" color="default" onClick={handleRestoreFences}>
              Fences
            </Button>
            <Button variant="contained" buttonColor="secondary" onClick={handleGeneralStart}>
              General Start
            </Button>
            <span className={classes.verticalSpacer}/>
            <Button variant="contained" buttonColor={bar.roboticSystem?.cupError ? "error" : "secondary"} onClick={handleRestoreCupError}>
              {(!bar.roboticSystem?.cupError) && <>Cups Ok <CupIcon/></>}
              {(bar.roboticSystem?.cupError) && <>Cups Alarm <CupIconOff/></>}
            </Button> 
          </>
        ) || (
          <Typography color="error">Bar is OFFLINE</Typography>
        )}
        <span className={classes.verticalSpacer}/>
        <Button variant="contained" buttonColor="warning" disabled={bar.bottleWarning.length === 0} onClick={handleGoToBottles}>
          <Badge badgeContent={bar.bottleWarning.length.toString()} badgeColor="white">
            Less than 30% <BottleIcon/>
          </Badge>
        </Button>
        <Button variant="contained" buttonColor="error" disabled={bar.bottleAlarm.length === 0} onClick={handleGoToBottles}>
          <Badge badgeContent={bar.bottleAlarm.length.toString()} badgeColor="white">
            Empty <BottleIcon/>
          </Badge>
        </Button>
        
      </div>
  
    </>
  )
}

export default HeaderBarControls
