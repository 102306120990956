import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import { Coffee } from "../../../services/types/coffee";

import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import {
  getAllCoffees,
  selectCoffee,
  selectCoffeeCount,
} from "./coffeeSlice";

const columns: Column<any>[] = [
  /*{
    Header: "Category",
    accessor: "category",
    Filter: withOptions(ingredientCategories, SelectFilter),
  },*/
  { Header: "Name", accessor: "name" },
  { Header: "Infusion Time", accessor: "infusionTime" },
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
  // { Header: 'Actions', Cell: BarRowActions, disableFilters: true, disableSortBy: true },
];

interface CoffeeTableProps {
  onCoffeeSelected: (coffee: Coffee) => any;
}

const CoffeeTable: FunctionComponent<CoffeeTableProps> = ({
  onCoffeeSelected,
}) => {
  const dispatch = useDispatch();
  const coffees = useSelector(selectCoffee);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectCoffeeCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllCoffees(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (coffeee: Coffee) => {
      onCoffeeSelected(coffeee);
    },
    [onCoffeeSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={coffees}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default CoffeeTable;
