import axios, { AxiosResponse } from "axios";
import { Drink } from "./types/drink";
import { PaginationResponse } from "./types/pagination";
import {
  getAccessTokenRequestForAxiosOptions,
  transformID,
  transformIDElement,
} from "./utils";

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllActive = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Drink>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/drink/active`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const getAllByStatus = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/drink/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const updateDrinkPriority = async (
  payload: Drink,
  accessToken: string
): Promise<Drink> => {
  console.log(payload);
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Drink> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/drink/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const updateDrinkErrorAck = async (
  payload: Drink,
  accessToken: string
): Promise<Drink> => {
  console.log(payload);
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Drink> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/drink/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const deleteDrink = async (
  payload: Drink,
  accessToken: string
): Promise<Drink> => {
  console.log(payload);
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Drink> = await axios.delete(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/drink/${payload.id}`,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const api = {
  getAllActive,
  getAllByStatus,
  updateDrinkPriority,
  deleteDrink,
  updateDrinkErrorAck,
};

export default api;
