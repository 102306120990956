import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "../../../components/dialog/Dialog";
import D3JsBottleDispensers from "../../../components/dispenserBottle/D3JsBottleDispensers";
import { Bar } from "../../../services/types/bar";
import { DispenserBottle } from "../../../services/types/dispenserBottle";
import { useLoading } from "../../loading/hooks";
import DispenserBottleEditForm from "./DispenserBottleEditForm";
import {
  getAllDispenserBottles,
  selectDispenserBottles,
} from "./dispenserBottleSlice";

interface DispenserBottleListProps {
  bar: Bar | null;
}
const DispenserBottleList: FunctionComponent<DispenserBottleListProps> = ({
  bar,
}) => {
  const dispatch = useDispatch();
  const bottleDispensers = useSelector(selectDispenserBottles);
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    dispenser: DispenserBottle | null;
  }>({ open: false, dispenser: null });
  const isLoading = useLoading();

  useEffect(() => {
    if (bar?.id) {
      dispatch(getAllDispenserBottles(bar));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, bar?.id]);

  const handleClickOnHex = useCallback((dispenser: DispenserBottle) => {
    if (!dispenser.fake) {
      setDialogState({
        open: true,
        dispenser,
      });
    }
  }, []);
  const handleCloseDialog = () => {
    setDialogState({
      open: false,
      dispenser: null,
    });
  };

  return (
    <>
      <D3JsBottleDispensers
        dispensers={bottleDispensers}
        onClick={handleClickOnHex}
        isLoading={isLoading}
      />
      {/* <div>
        This is a dispenserList
        <JSONTree data={bottleDispensers} />
      </div> */}
      <Dialog
        title="Edit Dispenser"
        open={dialogState.open}
        onAbort={handleCloseDialog}
      >
        {dialogState.dispenser && (
          <DispenserBottleEditForm
            dispenser={dialogState.dispenser}
            onSuccess={handleCloseDialog}
          />
        )}
      </Dialog>
    </>
  );
};

export default DispenserBottleList;
