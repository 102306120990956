import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import SelectFilter, {
  withOptions,
} from "../../../components/table/filters/SelectFilter";
import { Flavour } from "../../../services/types/flavour";
import { useLoading } from "../../loading/hooks";
import { ingredientCategories } from "../../constants";
import {
  getAllGlobalFlavours,
  selectGlobalFlavours,
  selectGlobalFlavoursCount,
} from "./globalFlavourSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  {
    Header: "Category",
    accessor: "category",
    Filter: withOptions(ingredientCategories, SelectFilter),
  },
  // { Header: 'Actions', Cell: BarRowActions, disableFilters: true, disableSortBy: true },
];

interface FlavourTableProps {
  onFlavourSelected: (flavour: Flavour) => any;
}

const GlobalFlavourTable: FunctionComponent<FlavourTableProps> = ({
  onFlavourSelected,
}) => {
  const dispatch = useDispatch();
  const flavours = useSelector(selectGlobalFlavours);
  const isLoading = useLoading();

  const totalCount = useSelector(selectGlobalFlavoursCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      dispatch(
        getAllGlobalFlavours({
          page: pageIndex,
          perPage: pageSize,
          sort: sortBy,
          search: filters,
        })
      );
    },
    [dispatch]
  );

  const handleRowClick = useCallback(
    (flavour: Flavour) => {
      onFlavourSelected(flavour);
    },
    [onFlavourSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={flavours}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default GlobalFlavourTable;
