import { Button, Tooltip } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../notification/notificationSlice';
import { barShift4GetAccessToken } from '../barSlice';

interface Shift4LoginButtonProps {
  setValue: (value: string) => void;
  value: string;
}
const Shift4LoginButton: FunctionComponent<Shift4LoginButtonProps> = ({ setValue, value }) => {
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(
      barShift4GetAccessToken(
        { authToken: value },
        ({ payload }: {payload: {credential: { accessToken: string }}}) => {
          setValue(payload.credential.accessToken);
        },
        (e: any) => {
          dispatch(showNotification("Get Access Token Failed!", "error"));
          console.error(e);
        }
      )
    );
  }

  return (
    <>
      <Button 
          style={{ top: "20px" }}
          onClick={handleLogin}
          variant="outlined"
          color="primary"
          fullWidth
        >Shift4 GetAccessToken</Button>
    </>
  )
}

export default Shift4LoginButton
