import React, { FunctionComponent, useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../../../components/dialog/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditBibForm from "./EditBibForm";
import { DispenserBib } from "../../../services/types/dispenserBib";
import { Bar } from "../../../services/types/bar";
import { useDispatch } from "react-redux";
import { getAllDispenserBib } from "./bibSlice";
import { Box, Tab, Tabs } from "@material-ui/core";
import ManualCommandValve from "./ManualCommandValve";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
      height: 545,
      margin: 15,
      marginTop: 25,
      boxSizing: "border-box",
      backgroundColor: "#F1F1F1",
      "&::-webkit-scrollbar": {
        width: "0.4em",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(235,0,139,.3)",
        borderRadius: "10px",
      },
    },
  })
);

interface BibCardProps {
  bar: Bar | null;
  position: number;
  dispenser: DispenserBib;
}

const BibCard: FunctionComponent<BibCardProps> = ({
  bar,
  position,
  dispenser,
}) => {
  const classes = useStyles();
  const [valve, setValve] = useState(0);
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    view: string;
  }>({ open: false, view: "edit" });

  const dispatch = useDispatch();

  useEffect(() => {
    if (bar) {
      dispatch(getAllDispenserBib(bar));
    }
  }, [dispatch, bar]);

  const handleCloseDialog = () => {
    setDialogState({
      ...dialogState,
      open: false,
    });
    if (bar) {
      dispatch(getAllDispenserBib(bar));
    }
  };

  const handleClick = (position: number) => {
    setDialogState({
      ...dialogState,
      open: true,
    });
    setValve(position);
  };

  return (
    <>
      <Card className={classes.root}>
        {/* <div className={classes.overlay}> */}
        <CardHeader
          title={`${dispenser.beverageType} Dispenser - ${
            dispenser.graphicCode || dispenser.robots[0].position
          }`}
        />
        <CardContent>
          <List component="nav" aria-label="mailbox folders">
            {dispenser.valveIds.map((el, i) => {
              return (
                <ListItem divider key={i}>
                  <ListItemText
                    primary={
                      el.flavourId
                        ? `${el.graphicCode} - ${el.flavourName}`
                        : null
                    }
                    secondary={
                      !el.flavourId ? `${el.graphicCode} - UNASSIGNED` : null
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="settings"
                      onClick={() => handleClick(i)}
                    >
                      {el.flavourId ? (
                        <EditOutlinedIcon color="primary" />
                      ) : (
                        <AddIcon color="primary" />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
      <Dialog
        title={`Edit Valve - ${dispenser.valveIds[valve]?.graphicCode}`}
        open={dialogState.open}
        onAbort={handleCloseDialog}
      >
        <Tabs
          value={dialogState.view}
          onChange={(_event, value) => {
            setDialogState({
            ...dialogState,
            view: value,
          });}}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Edit Valve" value={"edit"} />
          <Tab label="Test Valve" value={"test"} />
        </Tabs>
        {dispenser && (dialogState.view === "edit") && (
          <Box>
            <EditBibForm
              onSuccess={handleCloseDialog}
              valve={dispenser.valveIds[valve]}
            />
          </Box>
        )}
        {dispenser && (dialogState.view === "test") && (
          <ManualCommandValve valve={dispenser.valveIds[valve]}></ManualCommandValve>
        )}
      </Dialog>
    </>
  );
};

export default BibCard;
