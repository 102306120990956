import React, { FunctionComponent, MouseEvent } from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Theme, 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import { UserType } from "../../../features/auth/authSlice";

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    background: theme.palette.secondary.dark
  }
}));

interface UserAccountProps {
  user?: UserType;
  role?: string;
  handleLogout?: (event: MouseEvent) => void;
}
const UserAccount: FunctionComponent<UserAccountProps> = ({ user, role, handleLogout }) => {
  const classes = useStyles();
  return (
    <List>
      { user && (
      <ListItem button>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={user.email}
          primaryTypographyProps={{
            noWrap: true
          }}
          secondary={role || "Guest"}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      )}
    </List>
  );
}


export default UserAccount;
