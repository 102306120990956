import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Leaf } from "../../../services/types/leaf";
import { MapLike } from "typescript";

// STATE INTERFACE
interface LeafState {
  data: Leaf[];
  map: MapLike<Leaf>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: LeafState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalLeaves = createAction<
  PrepareAction<PaginationPayload>
>("leaves/getAllGlobalLeaves", ({ page, perPage, sort, search }) => ({
  payload: {
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "globalIngredients",
    resource: "getAllGlobalLeaves",
  },
}));

export const getAllGlobalLeavesSuccess = createAction<PaginationResponse<Leaf>>(
  "leaves/getAllGlobalLeaves_SUCCESS"
);

export const getGlobalLeafById = createAction<PrepareAction<{ id: string }>>(
  "leaves/getGlobalLeafById",
  ({ id, onFailure }) => ({
    payload: {
      id,
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getGlobalLeafById",
      onFailure,
    },
  })
);
export const getGlobalLeafByIdSuccess = createAction<Leaf>(
  "leaves/getGlobalLeafById_SUCCESS"
);

export const updateGlobalLeaf = createAction<PrepareAction<Leaf>>(
  "leaves/updateGlobalLeaves",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalLeaf",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalLeafSuccess = createAction<Leaf>(
  "leaves/updateGlobalLeaf_SUCCESS"
); /* 
export const findGlobalLeafByName = createAction<
  PrepareAction<{ name: string }>
>("leaves/findGlobalLeafByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalLeafByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalLeaf = createAction<PrepareAction<Leaf>>(
  "leaves/createGlobalLeaves",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalLeaf",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalLeafSuccess = createAction<Leaf>(
  "leaves/createGlobalLeaf_SUCCESS"
);

// REDUCERS
const globalLeavesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalLeavesSuccess,
      (state: LeafState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Leaf) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getGlobalLeafByIdSuccess, (state: LeafState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(updateGlobalLeafSuccess, (state: LeafState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalLeaves = (state: RootState) =>
  state.globalIngredients.leaf.data;
export const selectGlobalLeavesCount = (state: RootState) =>
  state.globalIngredients.leaf.count;
export const selectGlobalLeafById = (id: string) => (state: RootState) =>
  state.globalIngredients.leaf.map[id];

export default globalLeavesReducer;
