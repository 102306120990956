import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import { PaginationResponse } from "../../../services/types/pagination";
import { DispenserIce } from "../../../services/types/dispenserIce";
import { Bar } from "../../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface DispenserIceState {
  data: DispenserIce[];
  map: MapLike<DispenserIce>;
  count: number;
}

// INITIAL STATE
const initialState: DispenserIceState = {
  data: [],
  map: {},
  count: 0,
};

// ACTIONS
export const getAllDispenserIce = createAction<
  PrepareAction<{ barId: string }>
>("dispenserIce/getAllDispenserIce", (bar: Bar) => ({
  payload: {
    barId: bar.id,
  },
  meta: {
    fetch: "dispensers",
    resource: "getAllDispenserIce",
  },
}));

export const getAllDispenserIceSuccess = createAction<
  PaginationResponse<DispenserIce>
>("dispenserIce/getAllDispenserIce_SUCCESS");

export const updateDispenserIce = createAction<PrepareAction<DispenserIce>>(
  "dispenserIce/updateDispenserIce",
  (dispenser: DispenserIce, onSuccess, onFailure) => ({
    payload: {
      ...dispenser,
    },
    meta: {
      fetch: "dispensers",
      resource: "updateDispenserIce",
      onSuccess,
      onFailure,
    },
  })
);

export const updateDispenserIceSuccess = createAction<DispenserIce>(
  "dispenserIce/updateDispenserIce_SUCCESS"
);


export const manualCommandIce = createAction<PrepareAction<{id: string, quantity: number, dispensingTime: number}>>(
  "dispenserIce/manualCommandIce",
  (dispenser: DispenserIce, quantity, dispensingTime, onSuccess, onFailure) => ({
    payload: {
      id: dispenser.id,
      barId: dispenser.barId,
      quantity,
      dispensingTime
    },
    meta: {
      fetch: "dispensers",
      resource: "manualCommandIce",
      onSuccess,
      onFailure,
    },
  })
);


// REDUCERS
const dispenserIceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllDispenserIceSuccess,
      (state: DispenserIceState, { payload: { data, count } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: DispenserIce) => el.id);
        state.count = count;
        // state.page = page;
        // state.perPage = perPage;
      }
    )
    .addCase(
      updateDispenserIceSuccess,
      (state: DispenserIceState, { payload }) => {
        state.map[payload.id] = payload;
        state.data = state.data.map((el) => {
          if (el.id === payload.id) {
            return payload;
          }
          return el;
        });
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectDispenserIce = (state: RootState) =>
  state.dispensers.ice.data;

export default dispenserIceReducer;
