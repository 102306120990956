/* eslint-disable no-mixed-operators */
import React, { FunctionComponent, useEffect } from "react";

import JSONTree from "react-json-tree";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  LinearProgress,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";

import PageTitle from "../../components/layout/PageTitle";
import { useLoading } from "../loading/hooks";
import { getAppOrderById, selectAppOrderById } from "./appOrderSlice";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
      padding: "3rem 3rem 2rem 3rem",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      paddingBottom: "1rem",
    },
    grey: {
      color: "#A0A0A0",
      paddingRight: "1rem",
    },
  })
);

const AppOrderShowScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const AppOrder = useSelector(selectAppOrderById(id));

  const classes = useStyles();

  useEffect(() => {
    dispatch(
      getAppOrderById(
        {
          id,
          onFailure: () => history.push("/app-orders"),
        },
        bar
      )
    );
  }, [dispatch, id, history, bar]);

  return (
    <Box>
      <PageTitle
        pageTitle={"AppOrder Detail"}
        breadcrumb={[
          bar?.name || "Bar",
          { label: "App Orders", location: "/app-orders" },
          AppOrder?.nickname,
        ]}
      />
      <Paper>
        {isLoading && <LinearProgress /> || (
        <div className={classes.root}>
          <div className={classes.row}>
            <Typography className={classes.grey} variant="h5" component="h2">
              Nickname:
            </Typography>
            <Typography variant="h5" component="h2">
              {AppOrder.nickname}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography variant="h5" component="h2" className={classes.grey}>
              Type:
            </Typography>
            <Typography variant="h5" component="h2">
              {AppOrder.type}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography variant="h5" component="h2" className={classes.grey}>
              Payment Method:
            </Typography>
            <Typography variant="h5" component="h2">
              {AppOrder.paymentMethod}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography variant="h5" component="h2" className={classes.grey}>
              Status:
            </Typography>
            <Typography variant="h5" component="h2">
              {AppOrder.status}
            </Typography>
          </div>

          <div className={classes.row}>
            <Typography variant="h5" component="h2" className={classes.grey}>
              Price:
            </Typography>
            <Typography variant="h5" component="h2">
              {AppOrder.price} {AppOrder.currency}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography variant="h5" component="h2" className={classes.grey}>
              Created At:
            </Typography>
            <Typography variant="h5" component="h2">
              {format(
                parseISO(AppOrder.createdAt.toString()),
                "HH:mm , d MMM yyyy"
              )}
            </Typography>
          </div>
        </div>
        )}
        <code>
          <JSONTree data={AppOrder} />
        </code>
      </Paper>
    </Box>
  );
};

export default AppOrderShowScreen;
