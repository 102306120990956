import { FormControl } from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import React from "react";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";

interface InputTimeProps {
  name: string;
  label: string;
  control: any;
  error?: boolean;
  required?: boolean;
  fullWidth?: boolean;
}

/**
 * @todo error message and custom validation rules
 */
const InputTime: FunctionComponent<InputTimeProps> = ({
  name,
  label,
  control,
  required,
  error,
  fullWidth,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      label={label}
      control={control}
      required={required}
      render={({ value, onChange, onBlur }) => {
        const date = value
          ? new Date(1970, 1, 1, value.split(":")[0], value.split(":")[1])
          : null;
        return (
          <FormControl margin="normal" fullWidth={fullWidth}>
            <TimePicker
              label={label}
              onBlur={onBlur}
              required={required}
              onChange={(newDate) =>
                onChange(
                  newDate instanceof Date ? format(newDate, "HH:mm") : null
                )
              }
              error={error}
              value={date}
              format="HH:mm"
              {...rest}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default InputTime;
