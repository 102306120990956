import React from "react";
import Button from "@material-ui/core/Button";
import { FunctionComponent } from "react";

interface PrimaryButtonProps {
  children: any;
  disabled?: any;
}

const InputButton: FunctionComponent<PrimaryButtonProps> = ({
  children,
  disabled,
}) => (
  <Button
    type="submit"
    fullWidth
    variant="contained"
    color="primary"
    size="large"
    disabled={disabled ? disabled : false}
  >
    {children}
  </Button>
);

export default InputButton;
