import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createFlavour, findGlobalFlavourByName } from "./flavourSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Flavour } from "../../../services/types/flavour";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputSelect from "../../../components/form/InputSelect";
import { flavourCategories } from "../../constants";

interface CreateFlavourFormProps {
  bar: Bar | null;
  onCreateSuccess: (flavour: Flavour) => any;
}

const CreateFlavourForm: FunctionComponent<CreateFlavourFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      globalId: "",
      category: flavourCategories[0],
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (flavour: Flavour) => {
    dispatch(
      createFlavour(
        flavour,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Flavour Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Flavour
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/*           <InputGlobalLookup
            name="name"
            label="Name"
            control={control}
            inputRef={register}
            fullWidth
          /> */}

          <input
            name="globalId"
            type="hidden"
            ref={register()}
            //defaultValue={} // make sure to set up defaultValue
          />

          <GlobalIngredientLookup
            label="Name"
            name="name"
            control={control}
            setError={setError}
            onChange={(data: any) => {
              if (data) {
                setValue("globalId", data._id);
              }
            }}
            findGlobalIngredient={findGlobalFlavourByName}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {flavourCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Flavour!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateFlavourForm;
