/* eslint-disable no-mixed-operators */
import React, { FunctionComponent } from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  LinearProgress,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  GridSize,
} from "@material-ui/core";
import {
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";
import {
  PlayArrow as PlayArrowIcon,
  Refresh as RefreshIcon,
  Stop as StopIcon,
} from "@material-ui/icons";
import PageTitle from "../../components/layout/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../loading/hooks";
import {
  restoreEmergency,
  restoreFences,
  restoreCupError,
  abortRobot,
  stopRobot,
  startRobot,
  restartViz,
  generalStart,
  moveConveyor,
} from "./activitySlice";
import {
  selectCurrentInstanceBar,
  selectCurrentInstanceRobots,
  toggleDanceBar,
  toggleGhostBar,
  toggleBarStateBar,
  toggleMaintenanceBar,
  setVizTheme,
  toggleRobotEnabled,
  toggleAutomaticConveyorBar,
} from "../instanceBar/instanceBarSlice";
import { showNotification } from "../notification/notificationSlice";
import { Robot } from "../../services/types/robot";
import MainSpacer from "../../components/layout/MainSpacer";
import CupIcon from "../../components/icons/CupIcon";
import CupIconOff from "../../components/icons/CupIconOff";
import ColorButton from "../../components/layout/Button";
import { Bar, ThemeType } from "../../services/types/bar";
import PushButtonBoxStandard from "./PushButtonBoxStandard";
import PushButtonBoxVeloce from "./PushButtonBoxVeloce";
import { subMinutes } from "date-fns";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  mt20: {
    marginTop: 20,
  }
}));

const ActivityScreen: FunctionComponent = () => {
  const classes = useStyles();
  const isLoading = useLoading();
  const dispatch = useDispatch();

  const bar = useSelector(selectCurrentInstanceBar);
  const robots = useSelector(selectCurrentInstanceRobots);

  const handleStartRobot = (robot: Robot) => {
    dispatch(
      startRobot(
        robot,
        ({ payload }: any) =>
          dispatch(showNotification("Robot started!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Robot start failure!", "error"));
        }
      )
    );
  };
  const handleStopRobot = (robot: Robot) => {
    dispatch(
      stopRobot(
        robot,
        ({ payload }: any) =>
          dispatch(showNotification("Robot stopped!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Robot stop failure!", "error"));
        }
      )
    );
  };

  const handleGeneralStop = () => {
    robots.forEach((robot) => handleStopRobot(robot));
  };

  const handleAbortRobot = (robot: Robot) => {
    dispatch(
      abortRobot(
        robot,
        ({ payload }: any) =>
          dispatch(showNotification("Robot aborted!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Robot abort failure!", "error"));
        }
      )
    );
  };

  const handleAbort = () => {
    robots.forEach((robot) => handleAbortRobot(robot));
  };

  const handleRestoreEmergency = () => {
    dispatch(
      restoreEmergency(
        bar as Bar,
        ({ payload }: any) =>
          dispatch(showNotification("Emergency restored!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Emergency restore failure!", "error"));
        }
      )
    );
  };
  const handleRestoreFences = () => {
    dispatch(
      restoreFences(
        bar as Bar,
        ({ payload }: any) =>
          dispatch(showNotification("Fences restored!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Fences restore failure!", "error"));
        }
      )
    );
  };

  const handleGeneralStart = () => {
    dispatch(
      generalStart(
        bar as Bar,
        ({ payload }: any) => dispatch(showNotification("Started!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("General Start failure!", "error"));
        }
      )
    );
  };

  const handleRestoreCupError = () => {
    dispatch(
      restoreCupError(
        bar as Bar,
        ({ payload }: any) =>
          dispatch(showNotification("Cup Error restored!", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Cup Error restore failure!", "error"));
        }
      )
    );
  };
  const handleDanceToggle = (value: boolean) => {
    dispatch(
      toggleDanceBar(
        bar,
        value,
        ({ payload }: any) =>
          dispatch(
            showNotification(
              `Dance mode ${value ? "enabled" : "disabled"}!`,
              "success"
            )
          ),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Dance mode failure!", "error"));
        }
      )
    );
  };
  const handleGhostToggle = (value: boolean) => {
    dispatch(
      toggleGhostBar(
        bar,
        value,
        ({ payload }: any) =>
          dispatch(
            showNotification(
              `Ghost mode ${value ? "enabled" : "disabled"}!`,
              "success"
            )
          ),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Ghost mode failure!", "error"));
        }
      )
    );
  };

  const handleMaintenanceToggle = (value: boolean) => {
    dispatch(
      toggleMaintenanceBar(
        bar,
        value,
        ({ payload }: any) =>
          dispatch(
            showNotification(
              `Maintenance mode ${value ? "enabled" : "disabled"}!`,
              "success"
            )
          ),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Maintenance mode failure!", "error"));
        }
      )
    );
  };

  const handleAutomaticConveyorToggle = (value: boolean) => {
    dispatch(
      toggleAutomaticConveyorBar(
        bar,
        value,
        ({ payload }: any) =>
          dispatch(
            showNotification(
              `Automatic Conveyor mode ${value ? "enabled" : "disabled"}!`,
              "success"
            )
          ),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Automatic Conveyor mode failure!", "error"));
        }
      )
    );
  };

  const handleBarStateToggle = (value: boolean) => {
    dispatch(
      toggleBarStateBar(
        bar,
        value,
        ({ payload }: any) =>
          dispatch(
            showNotification(
              `Bar is now ${value ? "open to accept orders" : "closed"}!`,
              "success"
            )
          ),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("BarState mode failure!", "error"));
        }
      )
    );
  };



  const handleRobotEnabledToggle = (robot: Robot) => {
    dispatch(
      toggleRobotEnabled(
        bar as Bar,
        robot,
        ({ payload }: { payload: Robot }) => {
          dispatch(
            showNotification(
              `Robot is now ${payload.enabled ? "enabled" : "disabled"}!`,
              "success"
            )
          )
        },
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Robot enable mode failure!", "error"));
        }
      )
    );
  };

  const handleConveyorMove = (number: number, position: string) => {
    dispatch(
      moveConveyor(
        bar as Bar,
        number,
        position,
        ({ payload }: { payload: Robot }) => {
          dispatch(
            showNotification(
              `Conveyor command sent!`,
              "success"
            )
          )
        },
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Conveyor command failure!", "error"));
        }
      )
    );
  };



  const dispatchSetVizTheme = (theme: ThemeType) => {
    dispatch(
      setVizTheme(
        bar as Bar, theme,
        () => dispatch(showNotification(`Viz theme changed`, "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Failed to update Viz theme", "error"));
        }
      )
    );
  };

  const dispatchRestartViz = () => {
    dispatch(
      restartViz(
        bar as Bar,
        () => dispatch(showNotification(`Viz Restarted`, "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Viz Failed to Restart", "error"));
        }
      )
    );
  };

  if (!bar) {
    return (
      <Box className={classes.root}>
        <Typography>Please select a bar to start.</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <PageTitle
        pageTitle={bar?.name || "Activity"}
        breadcrumb={[bar?.name || "Bar", "Activity"]}
      />

      <Paper>
        <Box p={3}>
          <Typography variant="h5" component="h2">
            Bar
          </Typography>
          {(new Date(bar.liveCheck).getTime() >=
            subMinutes(new Date(), 3).getTime() && (
              <Typography variant="subtitle1" gutterBottom>
                Status: {bar?.status}
              </Typography>
            )) || (
              <Typography variant="subtitle1" color="error" gutterBottom>
                Status: OFFLINE - previous status: {bar?.status}
              </Typography>
            )}
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              {bar.version === 'veloce' && (
                <PushButtonBoxVeloce
                  handleAbortR1={() => handleAbortRobot(robots[0])}
                  handleStopR1={() => handleStopRobot(robots[0])}
                  handleStartR1={() => handleStartRobot(robots[0])}
                  handleAbortR2={() => handleAbortRobot(robots[1])}
                  handleStopR2={() => handleStopRobot(robots[1])}
                  handleStartR2={() => handleStartRobot(robots[1])}
                  handleRestoreEmergency={handleRestoreEmergency}
                  handleRestoreFences={handleRestoreFences}
                />
              ) || (
                  <PushButtonBoxStandard
                    handleAbort={handleAbort}
                    handleGeneralStop={handleGeneralStop}
                    handleGeneralStart={handleGeneralStart}
                    handleRestoreEmergency={handleRestoreEmergency}
                    handleRestoreFences={handleRestoreFences}
                  />
                )}
            </Grid>
            <Grid item sm={12} md={6}>
              <ColorButton
                variant="contained"
                buttonColor="error"
                disabled={!bar.roboticSystem.cupError}
                onClick={handleRestoreCupError}
              >
                {!bar.roboticSystem.cupError && (
                  <>
                    Cups Ok <CupIcon />
                  </>
                )}
                {bar.roboticSystem.cupError && (
                  <>
                    Cups Alarm <CupIconOff />
                  </>
                )}
              </ColorButton>
              <br />
              {bar.version !== 'ms1' && (
              <>
              <br />
              <FormControlLabel
                label="Dance"
                checked={bar?.roboticSystem?.danceMode || false}
                control={
                  <Switch
                    value={true}
                    onChange={(evt, value) => {
                      handleDanceToggle(value);
                    }}
                  />
                }
              />
              <br />
              <FormControlLabel
                label="Ghost"
                checked={bar?.roboticSystem?.ghostMode || false}
                control={
                  <Switch
                    value={true}
                    onChange={(evt, value) => {
                      handleGhostToggle(value);
                    }}
                  />
                }
              />
              </>
              )}
              {bar.version === 'ms1' && (
                <>
                <br />
                <FormControlLabel
                label="Automatic Conveyor"
                checked={bar?.roboticSystem?.automaticConveyor || false}
                control={
                  <Switch
                  value={true}
                  onChange={(evt, value) => {
                    handleAutomaticConveyorToggle(value);
                  }}
                  />
                }
                />
                </>
              )}
              <br />
              <FormControlLabel
                label="Maintenance"
                checked={bar?.roboticSystem?.maintenanceMode || false}
                control={
                  <Switch
                    value={true}
                    onChange={(evt, value) => {
                      handleMaintenanceToggle(value);
                    }}
                  />
                }
              />

              <br />
              <h2>Bar state</h2>
              <p>
                A closed bar prevents new orders to be processed from every
                sales channel
              </p>
              <FormControlLabel
                label={`Bar ${bar.open ? "open" : "closed"}`}
                checked={bar?.open || false}
                control={
                  <Switch
                    value={true}
                    onChange={(evt, value) => {
                      handleBarStateToggle(value);
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <MainSpacer />

      <Paper>
        {isLoading && <LinearProgress />}
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom paragraph>
            Robots
          </Typography>

          <Grid container spacing={3}>
            {robots.map((robot, robotIdx) => {
              const wide = Math.round(12 / robots.length) as GridSize;
              return (
                <Grid key={robotIdx} item xs={12} md={wide}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        Robot {robot.displayName}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="h3"
                        gutterBottom
                      >
                        Status {robot.status}
                      </Typography>
                      <br />
                      <Grid container>
                        <Grid item xs={6}>
                          <ButtonGroup
                            size="small"
                            variant="contained"
                            color="primary"
                          >
                            <Button
                              onClick={() => handleAbortRobot(robot)}
                              color="default"
                            >
                              <RefreshIcon />
                            </Button>
                            <Button onClick={() => handleStopRobot(robot)}>
                              <StopIcon />
                            </Button>
                            <Button
                              onClick={() => handleStartRobot(robot)}
                              color="secondary"
                            >
                              <PlayArrowIcon />
                            </Button>
                          </ButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                          {bar.version === 'ms1' && (
                            <FormControlLabel
                              label={`Robot ${robot.enabled ? "enabled" : "DISABLED"}`}
                              checked={robot?.enabled || false}
                              control={
                                <Switch
                                  value={true}
                                  onChange={(evt, value) => handleRobotEnabledToggle(robot)}
                                />
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>

                  {bar.version === 'ms1' && (
                    <Card className={classes.mt20}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Both robots must be in STOP to manually move the conveyors</Typography>
                          </Grid>
                          {((robotIdx === 0) ? _.dropRight : _.drop)(bar.conveyors, 3).map((conveyor) => (
                            <Grid item xs={4}>
                              <Typography variant="subtitle1" style={{paddingBottom: 20}}>Conveyor #{conveyor.number}</Typography>
                              <Button 
                                onClick={() => handleConveyorMove(conveyor.number, "back")}
                                variant="contained"
                                >
                                Go Back
                              </Button>
                              <div>
                                <Typography style={{paddingTop: 20, paddingBottom: 20}}></Typography>
                                {/* <Typography style={{paddingTop: 20, paddingBottom: 20}}><b>Position:</b> {conveyor.position.toUpperCase()}</Typography> */}
                              </div>
                              <Button 
                                onClick={() => handleConveyorMove(conveyor.number, "front")}
                                variant="contained"
                                >
                                Go Front
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Paper>
      <MainSpacer />
      <Paper>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom paragraph>
            Viz
          </Typography>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <Typography component="h5">Theme color</Typography>
              <ToggleButtonGroup value={bar.theme} size="small" onChange={(_, value) => dispatchSetVizTheme(value)} exclusive>
                <ToggleButton color="secondary" value="auto">Auto</ToggleButton>
                <ToggleButton color="primary" value="dark">Dark</ToggleButton>
                <ToggleButton color="primary" value="light">Light</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item>
              <Typography component="h5">Viz commands</Typography>
              <ButtonGroup color="primary" variant="contained">
                <Button onClick={dispatchRestartViz}>
                  Restart Viz
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default ActivityScreen;
