import React, { FunctionComponent } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ingredientTypesArray } from "../constants";
import { Controller } from "react-hook-form";

interface SelectProps {
  onChange: (...args: any) => void;
  disabled: boolean;
  name: string;
  label: string;
  control: any;
  defaultValue?: any;
}

const IngredientTypeSelect: FunctionComponent<SelectProps> = ({
  label,
  defaultValue,
  name,
  control,
  disabled,
  onChange: onParentChange,
}) => {
  return (
    <Controller
      name={name}
      label={label}
      defaultValue={defaultValue}
      control={control}
      render={({ value, onChange, onBlur, name }) => (
        <FormControl fullWidth>
          <InputLabel shrink={true}>{label}</InputLabel>
          <Select
            disabled={disabled}
            onChange={(evt: any, newValue: any) => {
              newValue && onChange(newValue.props.value);
              onParentChange && onParentChange(evt);
              onBlur();
            }}
            value={value}
            name={name}
            fullWidth
            required
          >
            {ingredientTypesArray.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default IngredientTypeSelect;
