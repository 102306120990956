import { Button, Tooltip } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../notification/notificationSlice';
import { barSatispayGetKeyId } from '../barSlice';

interface SatispayLoginButtonProps {
  setKeyId: (value: string) => void;
  publicKey: string;
  privateKey: string;
  token: string;
}

const SatispayLoginButton: FunctionComponent<SatispayLoginButtonProps> = ({ setKeyId, publicKey, privateKey, token }) => {
  const dispatch = useDispatch();

  const handleGenerateKeyId = () => {
    dispatch(
      barSatispayGetKeyId(
        { publicKey: publicKey, privateKey: privateKey, token: token },
        ({ payload }: {payload: {key_id: string}}) => {
          setKeyId(payload.key_id);
        },
        (e: any) => {
          dispatch(showNotification("Get keyId Failed!", "error"));
          console.error(e);
        }
      )
    );
  }

  return (
    <>
      <Button 
          style={{ top: "20px" }}
          onClick={handleGenerateKeyId}
          variant="outlined"
          color="primary"
          fullWidth
        >Satispay Generate KeyId</Button>
    </>
  )
}

export default SatispayLoginButton
