import axios, { AxiosResponse } from "axios";
import { Bottle } from "./types/bottle";
import { Beverage } from "./types/beverage";
import { Garnish } from "./types/garnish";
import { Granular } from "./types/granular";
import { Leaf } from "./types/leaf";
import { PaginationResponse } from "./types/pagination";
import {
  getAccessTokenRequestForAxiosOptions,
  transformID,
  transformIDElement,
} from "./utils";
import { Flavour } from "./types/flavour";
import { Coffee } from "./types/coffee";

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllBottles = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Bottle>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/bottles/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const getBottleById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Bottle> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/bottles/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const updateBottle = async (
  payload: Bottle,
  accessToken: string
): Promise<Bottle> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/bottles/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const createBottle = async (
  payload: Bottle,
  accessToken: string
): Promise<Bottle> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/bottles/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const findGlobalBottleByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Bottle[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/bottles/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const findBottleByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Bottle[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/bottles/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const getAllFlavours = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Flavour>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/flavours`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const getFlavourById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Flavour> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/flavours/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const updateFlavour = async (
  payload: Flavour,
  accessToken: string
): Promise<Flavour> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/flavours/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const createFlavour = async (
  payload: Flavour,
  accessToken: string
): Promise<Flavour> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/flavours/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const findGlobalFlavourByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Flavour[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/flavours/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const findFlavourByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Flavour[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/flavours/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const getAllGarnishes = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Garnish>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/garnishes`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const getGarnishById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Garnish> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/garnishes/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const updateGarnish = async (
  payload: Garnish,
  accessToken: string
): Promise<Garnish> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/garnishes/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const createGarnish = async (
  payload: Garnish,
  accessToken: string
): Promise<Garnish> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/garnishes/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const findGlobalGarnishByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Garnish[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/garnishes/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const findGarnishByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Garnish[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/garnishes/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const getAllGranulars = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Granular>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/granulars`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const getGranularById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Granular> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/granulars/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const updateGranular = async (
  payload: Granular,
  accessToken: string
): Promise<Granular> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/granulars/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const createGranular = async (
  payload: Granular,
  accessToken: string
): Promise<Granular> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/granulars/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const findGlobalGranularByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Granular[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/granulars/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const findGranularByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Granular[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/granulars/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const getAllLeaves = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Leaf>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/leaves`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const getLeafById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Leaf> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/leaves/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const updateLeaf = async (
  payload: Leaf,
  accessToken: string
): Promise<Leaf> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/leaves/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const createLeaf = async (
  payload: Leaf,
  accessToken: string
): Promise<Leaf> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/leaves/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const findGlobalLeafByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Leaf[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/leaves/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const findLeafByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Leaf[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/leaves/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getAllBeverages = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Beverage>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/beverages/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getBeverageById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/beverages/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getBeverageByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/beverages/byName`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const updateBeverage = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/beverages/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const createBeverage = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/beverages/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const findGlobalBeverageByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Beverage[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/beverages/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const findBeverageByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Beverage[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/beverages/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const getAllCoffees = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Coffee>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/coffee`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const getCoffeeById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Coffee> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/coffee/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const updateCoffee = async (
  payload: Coffee,
  accessToken: string
): Promise<Coffee> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/coffee/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const createCoffee = async (
  payload: Coffee,
  accessToken: string
): Promise<Coffee> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/coffee/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const findGlobalCoffeeByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Coffee[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee/byName`,
    optionDecorator({ params: payload })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const findCoffeeByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Coffee[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/coffee/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getAllSolubles = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Beverage>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/solubles/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getSolubleById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/solubles/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getSolubleByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/solubles/byName`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const updateSoluble = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/solubles/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const createSoluble = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/solubles/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const findGlobalSolubleByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Beverage[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/solubles/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const findSolubleByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Beverage[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/solubles/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getAllMilks = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Beverage>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/milk/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getMilkById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/milk/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getMilkByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/milk/byName`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const updateMilk = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/milk/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const createMilk = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/milk/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const findMilkByName = async (
  payload: { name: string; barId: string },
  accessToken: string
): Promise<Beverage[]> => {
  const { name, barId } = payload;
  const params = { name, barId };
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uingredient/milk/byName`,
    optionDecorator({ params }, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};


const api = {
  getAllBottles,
  getBottleById,
  updateBottle,
  createBottle,
  findGlobalBottleByName,
  findBottleByName,
  getAllGarnishes,
  getGarnishById,
  updateGarnish,
  createGarnish,
  findGlobalGarnishByName,
  findGarnishByName,
  getAllGranulars,
  getGranularById,
  updateGranular,
  createGranular,
  findGlobalGranularByName,
  findGranularByName,
  getAllLeaves,
  getLeafById,
  updateLeaf,
  createLeaf,
  findGlobalLeafByName,
  findLeafByName,
  getAllBeverages,
  getBeverageById,
  getBeverageByName,
  updateBeverage,
  createBeverage,
  findGlobalBeverageByName,
  findBeverageByName,
  getAllFlavours,
  getFlavourById,
  updateFlavour,
  createFlavour,
  findGlobalFlavourByName,
  findFlavourByName,
  getAllCoffees,
  getCoffeeById,
  updateCoffee,
  createCoffee,
  findGlobalCoffeeByName,
  findCoffeeByName,
  getAllSolubles,
  getSolubleById,
  getSolubleByName,
  updateSoluble,
  findSolubleByName,
  createSoluble,
  getAllMilks,
  getMilkById,
  getMilkByName,
  updateMilk,
  findMilkByName,
  createMilk,
};

export default api;
