import { SvgIcon } from '@material-ui/core'
import React from 'react'
import { FunctionComponent } from 'react'

const CupIcon: FunctionComponent = (props) => {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M3 2L5 20.23C5.13 21.23 5.97 22 7 22H17C18 22 18.87 21.23 19 20.23L21 2H3M5.22 4H18.78L17 20H7L5.22 4Z" />
    </SvgIcon>
  )
}

export default CupIcon
