import { Tooltip } from '@material-ui/core';
import React, { FunctionComponent } from 'react'
import { CellProps } from 'react-table';

interface DateTimeCellProps {
  value: string;
  cell: CellProps<any, string>;
}
const DateTimeCell: FunctionComponent<DateTimeCellProps> = ({ value }) => {
  const datetime = new Date(value).toLocaleString();

  ///TODOO
  function timeElapsed(past: Date) {
    const now = new Date();
    let elapsed = (now.getTime() - past.getTime()) / (1000 * 60 * 60);
    if (elapsed > 24) {
      elapsed = Math.round(elapsed / 24);
      return `${elapsed} days ago`;
    } else if (elapsed < 1) {
      elapsed = Math.round(elapsed * 60);
      return `${elapsed} minutes ago`;
    } else {
      elapsed = Math.round(elapsed);
      return `${elapsed} hours ago`;
    }
  }

  return (
    <Tooltip title={datetime}>

      <span>{timeElapsed(new Date(value))}</span>
    </Tooltip>

  )
}

export default DateTimeCell
