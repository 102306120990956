import React, { FunctionComponent } from "react";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";
import { ReactComponent as EmptyState } from "../../assets/svg/emptyState.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "1rem 0rem 1rem 2rem",
      backgroundColor: "#F6F6F6",
      borderRadius: "10px",
      boxShadow: "0px 8px 10px -10px rgba(0,0,0,1);",
    },
    title: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
    },
    container: {
      marginLeft: "2rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    legend: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "space-around",
    },
    containerSvg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100%",
      paddingBottom: "2rem",
    },
    svg: {
      margin: "2rem 0 0 2rem",
      height: "13rem",
    },
    dot: {
      backgroundColor: "red",
      height: "1rem",
      width: "1rem",
      marginRight: "1rem",
      borderRadius: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      padding: "0.5rem",
    },
    tooltip: {
      borderRadius: "0.25rem",
      background: "rgba(0, 0, 0, 0.7)",
      color: "#fff",
      padding: "0.3rem 1rem 0.3rem 1rem",
      textAlign: "center",
    },
  })
);

type PieChartProps = {
  data: any[] | undefined;
};

const PieChartComponent: FunctionComponent<PieChartProps> = ({ data }) => {
  const classes = useStyles();
  const COLORS = ["#EB008B", "#1AEDA3", "#ED5F3E", "#40F74F"];

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" component="h2">
        Top Drink Categories Today
      </Typography>
      {data?.length === 0 ? (
        <div className={classes.containerSvg}>
          <EmptyState className={classes.svg} />
          <Typography component="h2" variant="h5">
            No data found
          </Typography>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.legend}>
            {data?.map((el, idx) => {
              return (
                <div className={classes.row} key={idx}>
                  <div
                    className={classes.dot}
                    style={{ backgroundColor: COLORS[idx % COLORS.length] }}
                  />
                  <Typography component="h3" variant="h6">
                    {el.name}
                  </Typography>
                </div>
              );
            })}
          </div>
          <ResponsiveContainer width="65%" height={300}>
            <PieChart width={100} height={300}>
              <Pie
                dataKey="value"
                isAnimationActive={false}
                data={data}
                cx={120}
                cy={150}
                innerRadius={75}
                outerRadius={100}
                paddingAngle={3}
                fill="#8884d8"
              >
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = ({
  active,
  payload,
}) => {
  const classes = useStyles();

  if (active) {
    return (
      <div className={classes.tooltip}>
        <h4>
          {payload && payload[0].name}:{payload && payload[0].value}
        </h4>
      </div>
    );
  }
  return null;
};

export default PieChartComponent;
