import { all } from "redux-saga/effects";

import fetchSaga from "./fetchSaga";
import authSaga from "./authSaga";
import instanceBarSaga from "./instanceBarSaga";
import websocketSaga from "./websocketSaga";


function* rootSaga() {
  yield all([
    fetchSaga(),
    instanceBarSaga(),
    authSaga(),
    websocketSaga(),
  ]);
}

export default rootSaga;