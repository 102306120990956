import React, { FunctionComponent, useEffect } from "react";
import { Bar } from "../../../services/types/bar";
import Grid from "@material-ui/core/Grid";
import LeafCard from "./LeafCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDispenserLeaves,
  selectDispenserLeaves,
} from "./dispenserLeafSlice";

interface LeafDispenserProps {
  bar: Bar | null;
}

const LeafDispenserList: FunctionComponent<LeafDispenserProps> = ({ bar }) => {
  const select = useSelector(selectDispenserLeaves);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bar) {
      dispatch(getAllDispenserLeaves(bar));
    }
  }, [dispatch, bar]);

  return (
    <>
      <Grid container spacing={1}>
        {select.map((el, i) => (
          <Grid item xs={6} key={i}>
            <LeafCard bar={bar} position={i} dispenser={el} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default LeafDispenserList;
