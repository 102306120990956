import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import {
  getGlobalCoffeeById,
  selectGlobalCoffeeById,
} from "./globalCoffeeSlice";
import EditGlobalCoffeeForm from "./EditGlobalCoffeeForm";

const GlobalCoffeeShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const coffee = useSelector(selectGlobalCoffeeById(id));

  useEffect(() => {
    dispatch(
      getGlobalCoffeeById(
        {
          id,
          onFailure: () => history.push("/global-ingredients"),
        }
      )
    );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={"Super Admin"}
        breadcrumb={[{label: "Global Ingredients", location: "/global-ingredients/bottles"}, {label: "Coffee", location: "/global-ingredients/coffee"}, coffee?.name ]}
      />
      {coffee && <EditGlobalCoffeeForm coffee={coffee} />}

      <Paper>
        {isLoading && <LinearProgress />}
        <code>
          <JSONTree data={coffee} />
        </code>
      </Paper>
    </Box>
  );
};

export default GlobalCoffeeShowScreen;
