import React, { FunctionComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ReactComponent as EmptyState } from "../../assets/svg/emptyState.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "1rem 2rem 1rem 0rem",
      backgroundColor: "#F6F6F6",
      borderRadius: "10px",
      boxShadow: "0px 8px 10px -10px rgba(0,0,0,1);",
    },
    title: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
    },
    tooltip: {
      borderRadius: "0.25rem",
      background: "rgba(0, 0, 0, 0.7)",
      color: "#fff",
      padding: "0.3rem 1rem 0.3rem 1rem",
      textAlign: "center",
    },
    containerSvg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100%",
      paddingBottom: "2rem",
    },
    svg: {
      margin: "2rem 0 0 2rem",
      height: "13rem",
    },
  })
);

type BarChartProps = {
  data: any[] | undefined;
};

const BarChartComponent: FunctionComponent<BarChartProps> = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" component="h2">
        Top Ingredients Today
      </Typography>
      {data?.length === 0 ? (
        <div className={classes.containerSvg}>
          <EmptyState className={classes.svg} />
          <Typography component="h2" variant="h5">
            No data found
          </Typography>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 50,
              left: 20,
              bottom: 20,
            }}
            layout="vertical"
          >
            <defs>
              <linearGradient id="color1" x1="1" y1="0" x2="0" y2="0">
                <stop offset="0%" stopColor="#EB008B" stopOpacity={0.4} />
                <stop offset="75%" stopColor="#EB008B" stopOpacity={0.2} />
              </linearGradient>
              <linearGradient id="color2" x1="1" y1="0" x2="0" y2="0">
                <stop offset="0%" stopColor="#EB008B" stopOpacity={1} />
                <stop offset="90%" stopColor="#EB008B" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tickCount={4}
            />
            <YAxis
              axisLine={true}
              tickLine={false}
              dataKey="name"
              type="category"
              scale="band"
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="value"
              barSize={20}
              fill="url(#color1)"
              stroke="url(#color2)"
              radius={[0, 10, 10, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = ({
  active,
  payload,
}) => {
  const classes = useStyles();
  if (active) {
    return (
      <div className={classes.tooltip}>
        <h4>
          {payload && payload[0].payload.name}:{"  "}
          {payload && payload[0].value}
        </h4>
      </div>
    );
  }
  return null;
};

export default BarChartComponent;
