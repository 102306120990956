/* eslint-disable no-mixed-operators */
import React, {
  FunctionComponent,
  useEffect,
  useState,
  MouseEvent,
} from "react";
import {
  Paper,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  LinearProgress,
  Typography,
  IconButton,
  Button,
  TableHead,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
  Cancel as CancelIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable, SortableEvent } from "react-sortablejs";
import arrayMove from "array-move";

import Dialog from "../../components/dialog/Dialog";
import PageTitle from "../../components/layout/PageTitle";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { Recipe } from "../../services/types/recipe";
import { useLoading } from "../loading/hooks";
import {
  selectRecipeCategoryById,
  getRecipeCategoryById,
} from "../recipeCategoryScreen/recipeCategorySlice";
import {
  selectRecipes,
  getAllRecipes,
  updateSortingRecipe,
} from "./recipeSlice";
import { useParams } from "react-router-dom";
import CreateRecipeForm from "./CreateRecipeForm";
import UpdateRecipeForm from "./UpdateRecipeForm";
import DeleteRecipeDialog from "./DeleteRecipeDialog";
import OrderRecipeForm from "./OrderRecipeForm";
import { usePriceFormatter } from "../instanceBar/hooks";
import FloatingActionButton from "../../components/layout/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  numberCircle: {
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    padding: "8px",

    background: "#fff",
    border: "2px solid #666",
    color: "#666",
    textAlign: "center",
  },
}));

const RecipeScreen: FunctionComponent = (props) => {
  const classes = useStyles();
  const isLoading = useLoading();
  const { categoryId } = useParams<{ categoryId: string }>();
  const bar = useSelector(selectCurrentInstanceBar);
  const recipes = useSelector(selectRecipes);
  const category = useSelector(selectRecipeCategoryById(categoryId));
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialog, setUpdateDialog] = useState<{
    recipe: Recipe | null;
    open: boolean;
  }>({ recipe: null, open: false });

  const [deleteDialog, setDeleteDialog] = useState<{
    recipe: Recipe | null;
    open: boolean;
  }>({ recipe: null, open: false });

  const [orderDialog, setOrderDialog] = useState<{
    recipe: Recipe | null;
    open: boolean;
  }>({ recipe: null, open: false });

  const dispatch = useDispatch();
  const priceFormatter = usePriceFormatter();

  useEffect(() => {
    dispatch(getAllRecipes(categoryId, bar));
    dispatch(getRecipeCategoryById(categoryId, bar));
  }, [dispatch, bar, categoryId]);

  const handleSort = (evt: SortableEvent) => {
    const toBeUpdated = arrayMove(
      recipes,
      evt.oldIndex as number,
      evt.newIndex as number
    )
      .filter((el, idx) => {
        if (
          (idx >= (evt.oldIndex as number) &&
            idx <= (evt.newIndex as number)) ||
          (idx <= (evt.oldIndex as number) && idx >= (evt.newIndex as number))
        ) {
          return true;
        }
        return false;
      })
      .map((el, idx) => ({
        ...el,
        order: idx + Math.min(evt.oldIndex as number, evt.newIndex as number),
      }));
    dispatch(updateSortingRecipe(toBeUpdated, bar));
  };

  if (!category) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <PageTitle
        pageTitle={bar?.name || "Recipe Categories"}
        breadcrumb={[
          bar?.name || "Bar",
          { label: "Recipe Categories", location: "/drinks" },
          category?.name || "Recipes",
        ]}
      />

      {recipes.length === 0 && !isLoading && (
        <Typography variant="subtitle2" align="center">
          Click on the "Add" button below to add your first recipe
        </Typography>
      )}
      <Paper>
        {isLoading && <LinearProgress />}
        {recipes.length > 0 && (
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Ingredients Avaliability</TableCell>
                  <TableCell>Ingredients List</TableCell>
                  {category.isCoffee ? (
                    <TableCell>Coffee Category</TableCell>
                  ) : null}
                  <TableCell>Cup Type</TableCell>
                  <TableCell>Parts</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <ReactSortable
                tag="tbody"
                handle=".drag-handle"
                list={recipes}
                setList={() => {}} // BUG IN THE LIBRARY
                onEnd={handleSort}
              >
                {recipes.map((row: Recipe) => (
                  <TableRow
                    hover
                    key={row.id}
                    onClick={(evt: MouseEvent) => {
                      evt.stopPropagation();
                      setUpdateDialog({
                        recipe: row,
                        open: true,
                      });
                    }}
                  >
                    {/* <TableCell><ColorCell value={row.color}/></TableCell> */}
                    <TableCell>{row.name}</TableCell>
                    <TableCell>
                      {priceFormatter(
                        row.price != null ? row.price : category.price
                      )}
                    </TableCell>
                    <TableCell>
                      {(row.valid && <CheckIcon color="secondary" />) || (
                        <CancelIcon htmlColor="red" />
                      )}
                    </TableCell>
                    <TableCell>
                      {row.ingredients.map(
                        (el, i) => ` [ ${el.ingredientName}: ${el.quantity} ] `
                      )}
                    </TableCell>
                    {category.isCoffee ? (
                      <TableCell>{row.coffeeType}</TableCell>
                    ) : null}
                    <TableCell>{row.cup}</TableCell>
                    <TableCell>
                      {row.ingredients
                        .filter(
                          (el) =>
                            el.ingredientType === "Bottle" ||
                            el.ingredientType === "Beverage" ||
                            el.ingredientType === "Ice" ||
                            el.ingredientType === "Coffee"
                        )
                        .map((el) => el.quantity)
                        .reduce((acc, el) => acc + el, 0)}
                      /14
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(evt: MouseEvent) => {
                          evt.stopPropagation();
                          setOrderDialog({
                            recipe: row,
                            open: true,
                          });
                        }}
                      >
                        Order it!
                      </Button>
                      <IconButton
                        onClick={(evt: MouseEvent) => {
                          evt.stopPropagation();
                          setDeleteDialog({
                            recipe: row,
                            open: true,
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <span
                        className="drag-handle"
                        onClick={(evt: MouseEvent) => {
                          evt.stopPropagation();
                          console.log("Display a popup for ordering");
                        }}
                      >
                        <IconButton>
                          <DragHandleIcon />
                        </IconButton>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </ReactSortable>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <FloatingActionButton onClick={() => setCreateDialogOpen(true)} />
      <Dialog
        title="Create New Recipe"
        open={createDialogOpen}
        onAbort={() => setCreateDialogOpen(false)}
      >
        <CreateRecipeForm
          bar={bar}
          category={category}
          onSuccess={() => setCreateDialogOpen(false)}
        />
      </Dialog>
      <Dialog
        title="Update Recipe"
        open={updateDialog.open}
        onAbort={() =>
          setUpdateDialog({
            ...updateDialog,
            open: false,
          })
        }
      >
        <UpdateRecipeForm
          recipe={updateDialog.recipe as Recipe}
          bar={bar}
          category={category}
          onSuccess={() =>
            setUpdateDialog({
              recipe: null,
              open: false,
            })
          }
        />
      </Dialog>
      <Dialog
        title="Delete Recipe"
        open={deleteDialog.open}
        onAbort={() =>
          setDeleteDialog({
            ...deleteDialog,
            open: false,
          })
        }
      >
        <DeleteRecipeDialog
          recipe={deleteDialog.recipe as Recipe}
          bar={bar}
          onSubmit={() =>
            setDeleteDialog({
              recipe: null,
              open: false,
            })
          }
        />
      </Dialog>
      <Dialog
        title="Order Drink"
        open={orderDialog.open}
        onAbort={() =>
          setOrderDialog({
            ...orderDialog,
            open: false,
          })
        }
      >
        <OrderRecipeForm
          recipe={orderDialog.recipe as Recipe}
          bar={bar}
          onFormSubmit={() =>
            setOrderDialog({
              recipe: null,
              open: false,
            })
          }
        />
      </Dialog>
    </Box>
  );
};

export default RecipeScreen;
