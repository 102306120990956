  
import { createAction, PrepareAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export const FETCH_START = 'FETCH_START';
export const fetchStart = createAction(FETCH_START);


export const FETCH_END = 'FETCH_END';
export const fetchEnd = createAction(FETCH_END);


export const FETCH_ERROR = 'FETCH_ERROR';
export const fetchError = createAction<PrepareAction<AxiosError>>(FETCH_ERROR, (payload:AxiosError) => ({ payload, error: true }));

export const FETCH_CANCEL = 'FETCH_CANCEL';
export const fetchCancel = createAction(FETCH_CANCEL);
