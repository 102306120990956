import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import SelectFilter, {
  withOptions,
} from "../../../components/table/filters/SelectFilter";
import { Beverage } from "../../../services/types/beverage";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import { milkCategories } from "../../constants";
import {
  getAllMilks,
  selectMilks,
  selectMilkCount,
} from "./milkSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  {
    Header: "Category",
    accessor: "category",
    Filter: withOptions(milkCategories, SelectFilter),
  },
  /*   {
    Header: "Type",
    accessor: "type",
    Filter: withOptions(milkCategories, SelectFilter),
  }, */
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
];

interface MilkTableProps {
  onMilkSelected: (beverage: Beverage) => any;
}

const MilkTable: FunctionComponent<MilkTableProps> = ({
  onMilkSelected,
}) => {
  const dispatch = useDispatch();
  const milks = useSelector(selectMilks);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectMilkCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllMilks(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (beverage: Beverage) => {
      onMilkSelected(beverage);
    },
    [onMilkSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={milks}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default MilkTable;
