import React, { FunctionComponent } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { useLoading } from "../loading/hooks";
import { ErrorOption, useController } from "react-hook-form";
import { Ingredient } from "../../services/types/ingredient";

interface IngredientDispenserLookupProps {
  name: string;
  label: string;
  barId: string;
  fullWidth?: boolean;
  required?: boolean;
  control: any;
  onChange?: (valueObject: any) => void;
  setError: (name: any, error: ErrorOption) => void;
  findIngredient: any;
  limit?: boolean;
}

const IngredientDispenserLookup: FunctionComponent<IngredientDispenserLookupProps> = (
  props
) => {
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  const {
    name,
    label,
    barId,
    fullWidth,
    control,
    setError,
    onChange: onParentChange,
    findIngredient,
    required,
    limit,
  } = props;

  const {
    field: { value, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });
  const [inputValue, setInputValue] = React.useState(value ? value.name : "");
  const [options, setOptions] = React.useState<Ingredient[]>([]);
  const isLoading = useLoading();

  const fetch = React.useMemo(
    () =>
      debounce(
        (inputValue) =>
          dispatch(
            findIngredient(
              inputValue,
              barId,
              (result: { payload: Ingredient[] }) => {
                setOptions(result.payload);
              },
              (e: any) => {
                console.error(e);
                setError(
                  name,
                  e?.payload?.data?.message ||
                    e?.error ||
                    "General Error - Dispenser Department"
                );
              }
            )
          ),
        300,
        { maxWait: 1000 }
      ),
    [barId, dispatch, findIngredient, name, setError]
  );

  React.useEffect(() => {
    if (!limit || inputValue.length >= 3) {
      fetch(inputValue);
    }
  }, [inputValue, fetch, limit]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option?.name || option}
      options={options}
      freeSolo
      autoComplete
      fullWidth={fullWidth}
      value={value}
      includeInputInList
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      filterSelectedOptions
      loading={isLoading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event: any, newValue) => {
        if (newValue) {
          onChange(newValue); //valore dato da autocomplete
        } else {
          onChange(null);
        }
        onParentChange && onParentChange(newValue);
        onBlur();
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default IngredientDispenserLookup;
