import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import { PaginationResponse } from "../../../services/types/pagination";
import { DispenserCoffee } from "../../../services/types/dispenserCoffee";
import { Bar } from "../../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface DispenserCoffeeState {
  data: DispenserCoffee[];
  map: MapLike<DispenserCoffee>;
  count: number;
}

// INITIAL STATE
const initialState: DispenserCoffeeState = {
  data: [],
  map: {},
  count: 0,
};

// ACTIONS
export const getAllDispenserCoffee = createAction<
  PrepareAction<{ barId: string }>
>("dispenserCoffee/getAllDispenserCoffee", (bar: Bar) => ({
  payload: {
    barId: bar.id,
  },
  meta: {
    fetch: "dispensers",
    resource: "getAllDispenserCoffee",
  },
}));

export const getAllDispenserCoffeeSuccess = createAction<
  PaginationResponse<DispenserCoffee>
>("dispenserCoffee/getAllDispenserCoffee_SUCCESS");

export const updateDispenserCoffee = createAction<PrepareAction<DispenserCoffee>>(
  "dispenserCoffee/updateDispenserCoffee",
  (dispenser: DispenserCoffee, onSuccess, onFailure) => ({
    payload: {
      ...dispenser,
    },
    meta: {
      fetch: "dispensers",
      resource: "updateDispenserCoffee",
      onSuccess,
      onFailure,
    },
  })
);

export const updateDispenserCoffeeSuccess = createAction<DispenserCoffee>(
  "dispenserCoffee/updateDispenserCoffee_SUCCESS"
);

export const findCoffeeByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "dispenserCoffee/findCoffeeByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findCoffeeByName",
      onSuccess,
      onFailure,
    },
  })
);

// REDUCERS
const dispenserCoffeeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllDispenserCoffeeSuccess,
      (state: DispenserCoffeeState, { payload: { data, count } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: DispenserCoffee) => el.id);
        state.count = count;
        // state.page = page;
        // state.perPage = perPage;
      }
    )
    .addCase(
      updateDispenserCoffeeSuccess,
      (state: DispenserCoffeeState, { payload }) => {
        state.map[payload.id] = payload;
        state.data = state.data.map((el) => {
          if (el.id === payload.id) {
            return payload;
          }
          return el;
        });
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectDispenserCoffee = (state: RootState) =>
  state.dispensers.coffee.data;

export default dispenserCoffeeReducer;
