import axios, { AxiosResponse } from "axios";
import { Bar, RoboticSystem, ThemeType } from "./types/bar";
import { PaginationResponse } from "./types/pagination";
import {
  getAccessTokenRequestForAxiosOptions,
  transformID,
  transformIDElement,
} from "./utils";

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllBars = async (
  payload: {} | undefined,
  accessToken: string
): Promise<PaginationResponse<Bar>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/`,
    optionDecorator({ params: payload })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAvailableBars = async (
  payload: {} | undefined,
  accessToken: string
): Promise<Bar[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar[]> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/avail`,
    optionDecorator({ params: payload })
  );
  const body = response.data;
  return transformID(body);
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const createBar = async (payload: Bar, accessToken: string): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/create`,
    payload,
    optionDecorator()
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload {id: string, formData: FormData}
 * @param accessToken
 */
const updateBar = async (
  payload: { id: string; formData: FormData },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.put(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/${payload.id}`,
    payload.formData,
    optionDecorator()
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload {id: string, formData: FormData}
 * @param accessToken
 */
const barJwtGenerate = async (
  payload: { id: string },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<string> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/auth/bar-jwt/${payload.id}`,
    {},
    optionDecorator()
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload {id: string, formData: FormData}
 * @param accessToken
 */
const shift4GetAccessToken = async (
  payload: { authToken: string },
  accessToken: string
): Promise<{ credential: { accessToken: string } }> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<{ credential: { accessToken: string } }> =
    await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/shift4/getAccessToken`,
      payload,
      optionDecorator()
    );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const getBarById = async (
  payload: { id: string },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/${payload.id}`,
    optionDecorator()
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload {name: string}
 * @param accessToken
 */
const findBarByName = async (
  payload: { name: string },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/byName`,
    optionDecorator({ params: payload })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const getCurrentBar = async (
  payload: { id: string },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/current`,
    optionDecorator({}, { "x-bar": payload.id })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const orderRecipe = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/orderDrink`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const restoreFences = async (
  payload: { barId: string },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/restoreFences`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const restoreEmergency = async (
  payload: { barId: string },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/restoreEmergency`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const generalStart = async (
  payload: { barId: string },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/generalStart`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const restoreCupError = async (
  payload: { barId: string },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/restoreCupError`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const moveConveyor = async (
  payload: { barId: string; number: number; position: string },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/moveConveyor`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};
/**
 *
 * @param payload Bar
 * @param accessToken
 */
const unlockConveyorBar = async (
  payload: { barId: string; number: number },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/unlockConveyor`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const restartViz = async (
  payload: {
    barId: string;
  },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<any> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubender/zoidberg/restartViz`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Bar
 * @param accessToken
 */
const setVizTheme = async (
  payload: {
    id: string;
    theme: ThemeType;
  },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/${payload.id}/updateFromBarManager`,
    payload,
    optionDecorator({}, { "x-bar": payload.id })
  );
  return response.data;
};

/**
 *
 * @param payload RoboticSystem
 * @param accessToken
 */
const updateRoboticSystemBar = async (
  payload: RoboticSystem & { barId: string },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/${payload.barId}/roboticSettings`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};
/**
 *
 * @param payload {open: boolean, barId: string }
 * @param accessToken
 */
const toggleBarStateBar = async (
  payload: { open: boolean; id: string },
  accessToken: string
): Promise<Bar> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bar> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/ubar/bars/${payload.id}/updateFromBarManager`,
    payload,
    optionDecorator({}, { "x-bar": payload?.id })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload {id: string, formData: FormData}
 * @param accessToken
 */
const satispayGetKeyId = async (
  payload: {
    publicKey: string;
    privateKey: string;
    token: string;
  },
  accessToken: string
): Promise<any> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<any> =
    await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/satispay/satispayKeyId`,
      payload,
      optionDecorator()
      );
  console.log(response)
  return response.data;
};

const api = {
  getAllBars,
  getAvailableBars,
  createBar,
  updateBar,
  getBarById,
  findBarByName,
  getCurrentBar,
  orderRecipe,
  restoreFences,
  restoreEmergency,
  generalStart,
  restoreCupError,
  moveConveyor,
  unlockConveyorBar,
  updateRoboticSystemBar,
  toggleBarStateBar,
  restartViz,
  setVizTheme,
  barJwtGenerate,
  shift4GetAccessToken,
  satispayGetKeyId,
};
export default api;
