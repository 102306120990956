import { Box, Divider, Grid, MenuItem, Typography } from "@material-ui/core";
import React from "react";
import { FunctionComponent } from "react";
import { RegisterOptions } from "react-hook-form";
import InputCheckbox from "../../../components/form/InputCheckbox";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import InputText from "../../../components/form/InputText";
import { Bar } from "../../../services/types/bar";
import GenerateAndCopyButton from "./GenerateAndCopyButton";

interface SecurityProps {
  bar: Bar;
  register: (arg:RegisterOptions) => any;
  control: any;
  classes: any;
  errors: any;
  className: any;
  watch: any;
  setValue: any;
}

const ComponentSecurity: FunctionComponent<SecurityProps> = ({
  bar,
  register,
  control,
  errors,
  classes,
  watch,
  setValue,
  ...props
}) => {
  return (
  <div {...props}>
    <Box p={3} >
      <Typography variant="h5" component="h2" gutterBottom>
        Security
      </Typography>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <InputNumber
            name="security.minAgeForDrink"
            label="Minimum Drinking Age"
            register={register}
            error={errors?.security?.minAgeForDrink?.type}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <InputCheckbox
            name="security.maxAllowedAlcoholPartsEnabled"
            label="Restrict number of alcoholic parts in drinks"
            control={control}
            classes={classes.gutterTopLabel}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputNumber
            name="security.maxAllowedAlcoholParts"
            label="Max part number"
            register={register}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <InputCheckbox
            name="security.maxAllowedAlcoholPercEnabled"
            label="Restrict percentage of alcohol in drinks"
            control={control}
            classes={classes.gutterTopLabel}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <InputNumber
            name="security.maxAllowedAlcoholPerc"
            label="Max part number"
            register={register}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
    <Divider />
    <Box p={3}>
      <Typography variant="h5" component="h2" gutterBottom>
        External Integration API
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <InputText
              name="security.udoorJwtToken"
              label="JWT Token"
              register={register}
              fullWidth
            />
        </Grid>
        <Grid item xs={3}>
          <GenerateAndCopyButton barId={bar.id} setValue={(value) => setValue('security.udoorJwtToken', value)} value={watch("security.udoorJwtToken")} />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputSelect
            name="security.serviceNameIntegration"
            label="External Service Name"
            control={control}
            fullWidth
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="udoor">U-Door</MenuItem>
            <MenuItem value="uwcreek">U-WindCreek</MenuItem>
          </InputSelect>
        </Grid>
      </Grid>
    </Box>
  </div>

  );
};

export default ComponentSecurity;
