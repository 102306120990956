import { Grid, Typography } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import Dropzone from "react-dropzone";

interface FileDropzoneProps {
  name: string;
  label: string;
  styles: any;
  onChange: () => any;
  onBlur: () => any;
}

const InputFile: FunctionComponent<FileDropzoneProps> = ({
  name,
  label,
  styles,
  onChange,
  onBlur,
}) => {
  return (
    /*     <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ onChange, onBlur, value }) => (
        <> */
    <Dropzone onDrop={onChange} accept="image/*" multiple={false} >
      {({ getRootProps, getInputProps }) => (
        <Grid item xs={12} className={styles.root} {...getRootProps()}>
          <div className={styles.zone}>
            <CloudUpload className={styles.icon} />
            <Typography component="h2" variant="h5">
              {label}
            </Typography>
            <input {...getInputProps()} name={name} onBlur={onBlur} />
            <p>Drag 'n' drop your image here, or click to select it</p>
          </div>
        </Grid>
      )}
    </Dropzone>
    /*         </>
      )}
    ></Controller> */
  );
};

export default InputFile;
