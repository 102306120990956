

import { createReducer, createAction, PrepareAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../store';
import { PaginationPayload, PaginationResponse } from '../../services/types/pagination';
import { Voucher } from '../../services/types/voucher';
import { MapLike } from 'typescript';
import { Bar } from '../../services/types/bar';

// STATE INTERFACE
interface VoucherState {
  data: Voucher[];
  map: {
    [key: string]: Voucher
  };
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: VoucherState = {
  data: [],
  map: {},
  count: 0,
  page: 0,
  perPage: 10,
};

// ACTIONS
export const getAllVouchers = createAction<PrepareAction<PaginationPayload>>('voucher/getAllVouchers', ({page, perPage, sort, search}, bar: Bar) => ({
  payload: {
    barId: bar.id,
    page,
    perPage,
    sort: (sort || []).reduce((acc: MapLike<number>, sorter: {id: string, desc: boolean}) => { 
      acc[sorter.id] = (sorter.desc) ? -1 : 1;
      return acc;
    }, {}), 
    search: (search || []).reduce((acc: MapLike<string>, searcher: {id: string, value: string}) => { 
      acc[searcher.id] = searcher.value ;
      return acc;
    }, {}),
  },
  meta: {
    fetch: "voucher",
    resource: "getAllVouchers",
  }
}));
export const getVoucherById = createAction<PrepareAction<{ id: string }>>('voucher/getVoucherById', ({id, onFailure}, bar: Bar) => ({
  payload: { id, barId: bar.id },
  meta: {
    fetch: "voucher",
    resource: "getVoucherById",
    onFailure
  }
}));
export const updateVoucher = createAction<PrepareAction<Voucher>>('voucher/updateVoucher', (payload, onSuccess, onFailure) => ({
  payload,
  meta: {
    fetch: "voucher",
    resource: "updateVoucher",
    onSuccess,
    onFailure
  }}));
export const createVoucher = createAction<PrepareAction<Voucher>>('voucher/createVoucher', (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "voucher",
      resource: "createVoucher",
      onSuccess,
      onFailure
    }}));
export const updateVoucherSuccess = createAction<Voucher>('voucher/updateVoucher_SUCCESS')
export const createVoucherSuccess = createAction<Voucher>('voucher/createVoucher_SUCCESS')
export const getVoucherByIdSuccess = createAction<Voucher>('voucher/getVoucherById_SUCCESS')
export const getAllVouchersSuccess = createAction<PaginationResponse<Voucher>>('voucher/getAllVouchers_SUCCESS')

  
// REDUCERS
const VoucherReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getVoucherByIdSuccess, (state: VoucherState, { payload }) => { 
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(getAllVouchersSuccess, (state: VoucherState, { payload: { data, count, page, perPage } }) => { 
      state.data = data;
      state.map = _.keyBy(data, (el: Voucher) => el.id);
      state.count = count;
      state.page = page;
      state.perPage = perPage;
    })
    .addCase(updateVoucherSuccess, (state: VoucherState, { payload }) => { 
      state.map[payload.id] = payload;
    })
    .addCase(createVoucherSuccess, (state: VoucherState, { payload }) => { 
      state.map[payload.id] = payload;
      state.data.push(payload);
    })
    ;
});


// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectVouchers = (state: RootState) => state.vouchers.data;
export const selectVouchersCount = (state: RootState) => state.vouchers.count;
export const selectVoucherById = (id: string) => ((state: RootState) => state.vouchers.map[id]);
export const selectVouchersPagination = (state: RootState) => ({ page: state.vouchers.page, perPage: state.vouchers.perPage });

export default VoucherReducer;
