import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { hideNotification, selectNotifications } from "./notificationSlice";


let displayed: any[] = [];

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: any) => {
      displayed = [...displayed, id];
    };

    const removeDisplayed = (id: any) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({ key,  message, type, onClose, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }
            
            // Do nothing if snackbar is already displayed
            if (displayed.includes(key)) { 
              return;
            }

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                variant: type,
                onClose: (event, reason, myKey) => {
                    if (onClose) {
                        onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(hideNotification(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
