import React, { FunctionComponent, useState } from "react";

import {
  Paper,
  Box,
  Typography,
  Grid,
  MenuItem,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
  Button,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { Beverage } from "../../../services/types/beverage";
import { showNotification } from "../../notification/notificationSlice";
import { updateGlobalBeverage } from "./globalBeverageSlice";
import { beverageCategories } from "../../constants";
import InputColor from "../../../components/form/InputColor";
import { useFieldArray, useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import { findGlobalFlavourByName } from "../../ingredientsScreen/flavour/flavourSlice";
import GlobalIngredientLookup from "../../ingredientsScreen/GlobalIngredientLookup";

interface EditBeverageFormProps {
  beverage: Beverage;
}

const EditGlobalBeverageForm: FunctionComponent<EditBeverageFormProps> = ({
  beverage,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: beverage,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "flavours",
    keyName: "keyId",
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   beverage?.flavours.forEach(() => append({}));
  // }, [append, beverage?.flavours]);

  const onSubmit = (data: Beverage) => {
    setDisabled(true);
    beverage = {
      ...beverage,
      name: data.name,
      category: data.category,
      color: data.color,
      alcoholPerc: data.alcoholPerc || 0,
      flavours: data.flavours || [],
    };

    console.log(beverage);

    dispatch(
      updateGlobalBeverage(
        beverage,
        () => {
          dispatch(showNotification("Beverage Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Global Beverage
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                control={control}
                required
                fullWidth
              >
                {beverageCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Selected Flavours: {fields.length}/3
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <List>
                {fields.map((field, idx) => {
                  register({
                    name: `flavours[${idx}].id`,
                    defaultValue: field.id,
                    value: field.id,
                  });
                  return (
                    <div key={field.keyId}>
                      <ListItem>
                        {/* <FieldArraySelect
                          label={`Flavour ${idx + 1}`}
                          name={`flavours[${idx}].name`}
                          control={control}
                          defaultValue={field.name}
                          onChangeProp={(evt) => {
                            setValue(
                              `flavours[${idx}].id`,
                              flavours.find((f) => f.name === evt.target.value)
                                ?.id
                            );
                            setValue(
                              `flavours[${idx}].name`,
                              flavours.find((f) => f.name === evt.target.value)
                                ?.name
                            );
                          }}
                        >
                          {flavours.map((sub, jdx) => (
                            <MenuItem key={jdx} value={sub.name}>
                              {sub.name}
                            </MenuItem>
                          ))}
                        </FieldArraySelect> */}

                        <GlobalIngredientLookup
                          label={`Flavour ${idx + 1}`}
                          name={`flavours[${idx}].name`}
                          control={control}
                          setError={setError}
                          findGlobalIngredient={findGlobalFlavourByName}
                          required
                          onChange={(data: any) => {
                            console.log({ DATA: data });
                            if (data) {
                              setValue(`flavours[${idx}].id`, data?._id);
                            }
                          }}
                          fullWidth
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              setValue(`flavours[${idx}]`, "");
                              remove(idx);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          <InputNumber
                            label="Dispensing Time"
                            name={`flavours[${idx}].dispensingTime`}
                            defaultValue={field.dispensingTime}
                            register={register}
                          />
                        </ListItemText>
                      </ListItem>
                    </div>
                  );
                })}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (fields.length < 3)
                      append({ name: "", dispensingTime: 0 });
                  }}
                >
                  Add Flavour
                </Button>
              </List>
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Global Ingredient Info
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputNumber
                name="alcoholPerc"
                label="Alcoholic Percentage"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                register={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditGlobalBeverageForm;
