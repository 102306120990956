import React, { FunctionComponent, useState } from "react";

import { Paper, Box, Typography, Grid, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { Flavour } from "../../../services/types/flavour";
import { showNotification } from "../../notification/notificationSlice";
import { updateFlavour } from "./flavourSlice";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import { flavourCategories } from "../../constants";
import InputButton from "../../../components/form/InputButton";

interface EditFlavourFormProps {
  flavour: Flavour;
  bar: Bar | null;
}

const EditFlavourForm: FunctionComponent<EditFlavourFormProps> = ({
  flavour,
  bar,
}) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: flavour,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Flavour) => {
    setDisabled(true);
    flavour = {
      ...flavour,
      name: data.name,
      category: data.category,
    };
    console.log(flavour);
    dispatch(
      updateFlavour(
        flavour,
        bar,
        () => {
          dispatch(showNotification("Flavour Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!flavour) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Flavour
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                //defaultValue={flavour.category}
                control={control}
                required
                fullWidth
              >
                {flavourCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
          </Grid>
        </Box>

        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditFlavourForm;
