
import { createReducer, createAction, PrepareAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../store';
import { PaginationPayload, PaginationResponse } from '../../services/types/pagination';
import { Robot } from '../../services/types/robot';
import { MapLike } from 'typescript';


// STATE INTERFACE
interface RobotState {
  data: Robot[];
  map: {
    [key: string]: Robot
  };
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: RobotState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllRobots = createAction<PrepareAction<PaginationPayload>>('robot/getAllRobots', ({page, perPage, sort, search}) => ({
  payload: {
    page,
    perPage,
    sort: (sort || []).reduce((acc: MapLike<number>, sorter: {id: string, desc: boolean}) => { 
      acc[sorter.id] = (sorter.desc) ? -1 : 1;
      return acc;
    }, {}), 
    search: (search || []).reduce((acc: MapLike<string>, searcher: {id: string, value: string}) => { 
      acc[searcher.id] = searcher.value ;
      return acc;
    }, {}),
  },
  meta: {
    fetch: "robot",
    resource: "getAllRobots",
  }
}));
export const getRobotById = createAction<PrepareAction<{ id: string }>>('robot/getRobotById', ({ id, onFailure }) => ({
  payload: { id },
  meta: {
    fetch: "robot",
    resource: "getRobotById",
    onFailure
  }
}));
export const createRobot = createAction<PrepareAction<Robot>>('robot/createRobot', (payload, onSuccess, onFailure) => ({
  payload,
  meta: {
    fetch: "robot",
    resource: "createRobot",
    onSuccess,
    onFailure
  }
}));
export const getRobotByIdSuccess = createAction<Robot>('robot/getRobotById_SUCCESS')
export const getAllRobotsSuccess = createAction<PaginationResponse<Robot>>('robot/getAllRobots_SUCCESS')

// REDUCERS
const robotsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRobotByIdSuccess, (state: RobotState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(getAllRobotsSuccess, (state: RobotState, { payload: { data, count, page, perPage } }) => {
      state.data = data;
      state.map = _.keyBy(data, (el: Robot) => el.id);
      state.count = count;
      state.page = page;
      state.perPage = perPage;
    })
    ;
});


// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRobots = (state: RootState) => state.robots.data;
export const selectRobotsCount = (state: RootState) => state.robots.count;
export const selectRobotById = (id: string) => ((state: RootState) => state.robots.map[id]);
export const selectRobotsPagination = (state: RootState) => ({ page: state.robots.page, perPage: state.robots.perPage });

export default robotsReducer;
