import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import { Leaf } from "../../../services/types/leaf";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import { getAllLeaves, selectLeaves, selectLeafCount } from "./leafSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
];

interface LeafTableProps {
  onLeafSelected: (leaf: Leaf) => any;
}

const LeafTable: FunctionComponent<LeafTableProps> = ({ onLeafSelected }) => {
  const dispatch = useDispatch();
  const leaves = useSelector(selectLeaves);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectLeafCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllLeaves(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (leaf: Leaf) => {
      onLeafSelected(leaf);
    },
    [onLeafSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={leaves}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default LeafTable;
