import React, { FunctionComponent } from 'react'

import { Theme, useTheme } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import { ColumnInstance } from 'react-table';
import FilterLayout from './FilterLayout';

function getSelectedStyles(value: string, selected: string, theme: Theme) {
  return {
    fontWeight:
      // selected.indexOf(value) === -1
      selected === value
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface SelectFilterProps {
  column: ColumnInstance;
  options: string[]; 
  setFilter: (columnId: string, value: string | null) => any
}

export const withOptions = <P extends object> (
  options: string[], 
  Component: React.ComponentType<P>
): React.FC<P & SelectFilterProps> => ({
  ...props
}: SelectFilterProps) => <Component options={options} {...props as P} />;

const SelectFilter: FunctionComponent<SelectFilterProps> = ({column, options, setFilter, ...props}) => {
  
  const theme = useTheme();

  const handleSearchChange = (event: React.ChangeEvent<{value: unknown}>) => {
    setFilter(column.id as string, event.target.value as string);
  };
  
  return (
    <FilterLayout onClose={() => setFilter(column.id as string, null)}>
      <Select
        value={column.filterValue || '' }
        onChange={handleSearchChange}
        placeholder="Search in column..."
        fullWidth
        // MenuProps={MenuProps}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name} style={getSelectedStyles(name, (column.filter as unknown as string || ''), theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FilterLayout>
  )
}

export default SelectFilter
