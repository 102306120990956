import React, { FunctionComponent, useEffect } from "react";
import { Bar } from "../../../services/types/bar";
import BibCard from "./BibCard";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllDispenserBib, selectDispenserBib } from "./bibSlice";

interface BibDispenserProps {
  bar: Bar | null;
}

const BibDispenserList: FunctionComponent<BibDispenserProps> = ({ bar }) => {
  const select = useSelector(selectDispenserBib);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bar) {
      dispatch(getAllDispenserBib(bar));
    }
  }, [dispatch, bar]);

  return (
    <>
      <Grid container spacing={3}>
        {select.map((el, i) => (
          <Grid item lg={4} md={6} key={i}>
            <BibCard bar={bar} position={i} dispenser={el} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default BibDispenserList;
