import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import { PaginationResponse } from "../../../services/types/pagination";
import { DispenserLeaf } from "../../../services/types/dispenserLeaf";
import { Bar } from "../../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface DispenserLeafState {
  data: DispenserLeaf[];
  map: MapLike<DispenserLeaf>;
  count: number;
  // page: number;
  // perPage: number;
}

// INITIAL STATE
const initialState: DispenserLeafState = {
  data: [],
  map: {},
  count: 0,
  // page: 1,
  // perPage: 2,
};

// ACTIONS
export const getAllDispenserLeaves = createAction<
  PrepareAction<{ barId: string }>
>("dispenserLeaf/getAllDispenserLeaves", (bar: Bar) => ({
  payload: {
    barId: bar.id,
  },
  meta: {
    fetch: "dispensers",
    resource: "getAllDispenserLeaves",
  },
}));

export const getAllDispenserLeavesSuccess = createAction<
  PaginationResponse<DispenserLeaf>
>("dispenserLeaf/getAllDispenserLeaves_SUCCESS");

export const updateDispenserLeaf = createAction<PrepareAction<DispenserLeaf>>(
  "dispenserLeaf/updateDispenserLeaf",
  (dispenser: DispenserLeaf, onSuccess, onFailure) => ({
    payload: {
      ...dispenser,
    },
    meta: {
      fetch: "dispensers",
      resource: "updateDispenserLeaf",
      onSuccess,
      onFailure,
    },
  })
);

export const updateDispenserLeafSuccess = createAction<DispenserLeaf>(
  "dispenserLeaf/updateDispenserLeaf_SUCCESS"
);

export const findLeafByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "dispenserLeaf/findLeafByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findLeafByName",
      onSuccess,
      onFailure,
    },
  })
);

// REDUCERS
const dispenserLeavesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllDispenserLeavesSuccess,
      (
        state: DispenserLeafState,
        { payload: { data, count, page, perPage } }
      ) => {
        state.data = data;
        state.map = _.keyBy(data, (el: DispenserLeaf) => el.id);
        state.count = count;
        // state.page = page;
        // state.perPage = perPage;
      }
    )
    .addCase(
      updateDispenserLeafSuccess,
      (state: DispenserLeafState, { payload }) => {
        state.map[payload.id] = payload;
        state.data = state.data.map((el) => {
          if (el.id === payload.id) {
            return payload;
          }
          return el;
        });
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectDispenserLeaves = (state: RootState) =>
  state.dispensers.leaf.data;

export default dispenserLeavesReducer;
