import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FunctionComponent } from "react";

type InputPasswordProps = TextFieldProps & {
  name: string;
  label: string;
  InputProps?: any;
  defaultValue?: any;
  inputRef: any;
  fullWidth?: boolean;
};

const InputPassword: FunctionComponent<InputPasswordProps> = ({
  inputRef,
  defaultValue,
  ...props
}) => {
  return (
    <TextField
      margin="normal"
      type="password"
      inputRef={inputRef}
      defaultValue={defaultValue}
      {...props}
    />
  );
};

export default InputPassword;
