import { Button } from "@material-ui/core";
import { AxiosResponse } from "axios";
import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-table";
import { ExportRequest } from "../../services/types/exportRequest";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { getExportRequestDownloadById } from "./exportRequestSlice";

export const DownloadExportCell: FunctionComponent<{ row: Row<ExportRequest> }> = ({
  row,
  ...rest
}) => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);

  const handleDownloadClick = () => {
    dispatch(getExportRequestDownloadById(row.original.id, bar?.id, ({payload}: {payload: AxiosResponse}) => { 
      console.log(payload.headers);
      const blob = new Blob([payload.data], {type: payload.headers['content-type']});
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = row.original.filepath as string;
      a.click();
      window.URL.revokeObjectURL(url);
    }));
  }
  return row.original?.filepath ? (
    <Button
      variant="outlined"
      color="primary"
      // endIcon={<Download />}
      component="a"
      onClick={handleDownloadClick}
    >
      Download now
    </Button>
  ) : (
    <span>
      Export processing.
    </span>
  );
}