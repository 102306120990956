import React from "react";
import { RecipeCategory } from "../../services/types/recipeCategory";
import { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import MainSpacer from "../../components/layout/MainSpacer";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { deleteRecipeCategory } from "./recipeCategorySlice";
import { Bar } from "../../services/types/bar";
import { showNotification } from "../notification/notificationSlice";

interface DeleteRecipeCategoryProps {
  recipeCategory: RecipeCategory;
  onSubmit: (...args: any) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

const DeleteRecipeCategory: FunctionComponent<DeleteRecipeCategoryProps> = ({
  recipeCategory,
  onSubmit,
}) => {
  const classes = useStyles();
  const bar: Bar | null = useSelector(selectCurrentInstanceBar);
  const dispatch = useDispatch();

  const handleDelete = (id: String) => {
    dispatch(deleteRecipeCategory(id, bar, ({ payload }: any) => {
        dispatch(showNotification("Category deleted!", "success"));
        onSubmit();
      },
      (e: any) => {
        console.error(e);
        dispatch(showNotification("Error in Category delete!", "error"));
      })
    );
  };
  
  return (
    <>
      <Typography variant="h5" component="h2">
        Are you sure you want to delete {recipeCategory?.name}?
      </Typography>
      <MainSpacer />
      <div className={classes.navigation}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubmit}
        >
          NO
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDelete(recipeCategory.id)}
        >
          DELETE
        </Button>
      </div>
    </>
  );
};

export default DeleteRecipeCategory;
