import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import { getGlobalLeafById, selectGlobalLeafById } from "./globalLeafSlice";
import EditGlobalLeafForm from "./EditGlobalLeafForm";

const GlobalLeafShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const leaf = useSelector(selectGlobalLeafById(id));

  useEffect(() => {
    dispatch(
      getGlobalLeafById(
        {
          id,
          onFailure: () => history.push("/global-ingredients"),
        },
      )
    );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={"Super Admin"}
        breadcrumb={[{label: "Global Ingredients", location: "/global-ingredients/bottles"}, {label: "Leaves", location: "/global-ingredients/leaves"}, leaf?.name ]}
      />
      {leaf && <EditGlobalLeafForm leaf={leaf} />}

      <Paper>
        {isLoading && <LinearProgress />}
        <code>
          <JSONTree data={leaf} />
        </code>
      </Paper>
    </Box>
  );
};

export default GlobalLeafShowScreen;
