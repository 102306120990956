import React, { FunctionComponent } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  colorSecondary: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  colorError: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  colorSuccess: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.success.main,
      },
    },
  },
  colorWarning: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.warning.main,
      },
    },
  },
}));


interface ButtonProp extends MuiButtonProps {
  buttonColor?: "primary" | "secondary" | 'success' | 'warning' | 'error',
}
  
const Button: FunctionComponent<ButtonProp> = ({color, buttonColor, children, ...props}) => {
  const classes = useStyles();
  return (
    <MuiButton classes={{ root: clsx({
      [classes.colorPrimary]: buttonColor === 'primary',
      [classes.colorSecondary]: buttonColor === 'secondary',
      [classes.colorWarning]: buttonColor === 'warning',
      [classes.colorSuccess]: buttonColor === 'success',
      [classes.colorError]: buttonColor === 'error',
    }) }}
      {...props}
      >
      {children}
    </MuiButton>
  );
}

export default Button