import React, { FunctionComponent, useState } from "react";

import { Box, FormControl, MenuItem, Grid, Button, InputLabel, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import MainSpacer from "../../../components/layout/MainSpacer";
import { DispenserBottle } from "../../../services/types/dispenserBottle";
import { showNotification } from "../../notification/notificationSlice";
import {
  findBottleByName,
  updateDispenserBottle,
} from "./dispenserBottleSlice";
import { dispenserCapacities } from "../../constants";
import { useForm } from "react-hook-form";
import InputSwitch from "../../../components/form/InputSwitch";
import InputSelect from "../../../components/form/InputSelect";
import InputNumber from "../../../components/form/InputNumber";
import InputButton from "../../../components/form/InputButton";
import InputSlider from "../../../components/form/InputSlider";
import IngredientDispenserLookup from "../IngredientDispenserLookup";

interface DispenserBottleEditFormProps {
  dispenser: DispenserBottle;
  onSuccess: () => any;
}
const DispenserBottleEditForm: FunctionComponent<DispenserBottleEditFormProps> = ({
  dispenser,
  onSuccess,
}) => {
  const { register, control, handleSubmit, setValue, setError } = useForm({
    defaultValues: dispenser,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [maxValue, setMaxValue] = useState(dispenser.dispenserCapacity);

  const handleLevel = (param: number | undefined = 0) => {
    if (param && param <= maxValue) {
      setValue("actualLevel", param);
    }
  };

  const onSubmit = (data: DispenserBottle) => {
    setDisabled(true);
    dispenser = {
      ...dispenser,
      enabled: data.enabled,
      dispenserCapacity: data.dispenserCapacity,
      dispenserCapacityDescription: dispenserCapacities.find(
        (cap) => cap.capacity === data.dispenserCapacity
      )?.description,
      dispenserCapacityId: dispenserCapacities.find(
        (cap) => cap.capacity === data.dispenserCapacity
      )?._id,
      alarmLevel: data.alarmLevel,
      doserType: data.doserType,
      estimatedLevel: data.estimatedLevel,
      actualLevel: data.actualLevel,
      ingredient: data.ingredient,
      ingredientId: data.ingredient?.id || "",
    };
    console.log(dispenser);
    dispatch(
      updateDispenserBottle(
        dispenser,
        () => {
          setDisabled(false);
          dispatch(showNotification("Dispenser Saved!", "success"));
          onSuccess();
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <IngredientDispenserLookup
              name="ingredient"
              label="Ingredient"
              barId={dispenser.barId}
              control={control}
              fullWidth
              findIngredient={findBottleByName}
              setError={setError}
              limit
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl margin="normal" fullWidth>
              <InputSwitch
                name="enabled"
                label="Enabled"
                type="checkbox"
                control={control}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <InputSelect
              name="dispenserCapacity"
              label="Bottle Size"
              control={control}
              fullWidth
              required
              onChange={(e: any) => {
                setMaxValue(e.target.value);
                handleLevel(e.target.value);
              }}
            >
              {dispenserCapacities.map((cap, idx) => (
                <MenuItem key={idx} value={cap.capacity}>
                  {cap.description}
                </MenuItem>
              ))}
            </InputSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputSelect
              name="doserType"
              label="Doser Type"
              control={control}
              fullWidth
              required
            >
              <MenuItem value="SPIRITS">Spirits bottle doser</MenuItem>
              <MenuItem value="SYRUP">Syrup bottle doser</MenuItem>
            </InputSelect>
          </Grid>
        </Grid>
        <MainSpacer />
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <FormControl margin="normal" fullWidth>
              <InputLabel shrink={true}>Bottle Level</InputLabel>

              <Button
                onClick={() => {
                  handleLevel();
                  setValue("actualLevel", maxValue);
                }}
                style={{ top: "20px", border: "2px solid" }}
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                disabled={disabled || isNaN(maxValue)}
              >
                Set Full
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputSlider
              name="actualLevel"
              label=""
              control={control}
              maxValue={maxValue}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputNumber
              name="alarmLevel"
              label="Alerts if less then"
              register={register}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">Estimated level: {dispenser.estimatedLevel}</Typography>
          </Grid>
        </Grid>
      </Box>
      <InputButton disabled={disabled}>Save!</InputButton>
    </form>
  );
};

export default DispenserBottleEditForm;
