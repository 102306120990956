import React, { FunctionComponent } from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useController } from 'react-hook-form';


type InputTextMultiBadgeProps = {
  name: string;
  label: string;
  control: any;
  fullWidth?: boolean;
  required?: boolean;
  onChange?: (valueObject: any) => void;
};

const InputTextMultiBadge: FunctionComponent<InputTextMultiBadgeProps> = ({
  label,
  name,
  control,
  fullWidth,
  required,
  onChange: onParentChange,
  ...rest
}) => {
  const {
    field: { value, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={[]}
      defaultValue={value}
      freeSolo
      fullWidth={fullWidth}
      onChange={(event: any, newValue) => {
        if (newValue) {
          onChange(newValue); //valore dato
        } else {
          onChange(null);
        }
        onParentChange && onParentChange(newValue);
        onBlur();
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField 
        {...params}
        margin="normal"
        fullWidth={fullWidth}
        label={label}
        required={required} 
        placeholder="PosID" />
      )}
    />
  );
}

export default InputTextMultiBadge;