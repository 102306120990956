import React, { FunctionComponent } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";
import { useLoading } from "../loading/hooks";
import { ErrorOption, useController } from "react-hook-form";
import { Bar } from "../../services/types/bar";
import { findBarByName } from "./userManagementSlice";

interface BarNameLookupProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  required?: boolean;
  control: any;
  defaultValue: any;
  onChange?: (valueObject: any) => void;
  setError: (name: any, error: ErrorOption) => void;
}

const BarNameLookup: FunctionComponent<BarNameLookupProps> = ({
  name,
  label,
  fullWidth,
  control,
  defaultValue,
  setError,
  onChange: onParentChange,
  required,
}) => {
  const dispatch = useDispatch();

  const {
    field: { value, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue
  });

  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<Bar[]>([]);
  const isLoading = useLoading();

  const fetch = React.useMemo(
    () =>
      debounce(
        (inputValue) =>
          dispatch(
            findBarByName(
              inputValue,
              (result: { payload: Bar[] }) => {
                setOptions(result.payload);
              },
              (e: any) => {
                console.error(e);
                setError(
                  name,
                  e?.payload?.data?.message ||
                    e?.error ||
                    "General Error - Bar lookup"
                );
              }
            )
          ),
        300,
        { maxWait: 1000 }
      ),
    [dispatch, name, setError]
  );

  React.useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={(option) => option?.name || option}
      options={options}
      freeSolo
      autoComplete
      fullWidth={fullWidth}
      filterOptions={(x) => x}
      filterSelectedOptions
      value={value}
      includeInputInList
      loading={isLoading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event: any, newValue) => {
        if (newValue) {
          onChange(newValue.name);
        } else {
          onChange(null);
        }
        onParentChange && onParentChange(newValue);
        onBlur();
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          label={label}
          required={required}
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default BarNameLookup
