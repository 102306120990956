import React, { FunctionComponent, useState } from "react";

import { Box, FormControl, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showNotification } from "../../notification/notificationSlice";
import { updateDispenserIce } from "./dispenserIceSlice";
import { DispenserIce } from "../../../services/types/dispenserIce";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";

interface EditIceFormProps {
  onSuccess: () => any;
  dispenser: DispenserIce;
}

const EditIceForm: FunctionComponent<EditIceFormProps> = ({
  onSuccess,
  dispenser,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: dispenser,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: DispenserIce) => {
    setDisabled(true);
    dispenser = {
      ...dispenser,
      dispensingTime: data.dispensingTime,
    };
    console.log(dispenser);
    dispatch(
      updateDispenserIce(
        dispenser,
        () => {
          dispatch(showNotification("Dispenser Saved!", "success"));
          onSuccess();
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl margin="normal" fullWidth>
              <InputNumber
                name="dispensingTime"
                label="Dispensing Time"
                register={register}
                required
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>
        <MainSpacer />
      </Box>
      <InputButton disabled={disabled}>Save!</InputButton>
    </form>
  );
};

export default EditIceForm;
