import React, { FunctionComponent, useEffect } from "react";

import JSONTree from "react-json-tree";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, LinearProgress, Paper } from "@material-ui/core";

import PageTitle from "../../components/layout/PageTitle";
import { useLoading } from "../loading/hooks";
import { getBarById, selectBarById } from "./barSlice";
import EditBarForm from "./EditBarForm";

const BarShowScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const bar = useSelector(selectBarById(id));

  useEffect(() => {
    dispatch(
      getBarById({
        id,
        onFailure: () => history.push("/bars"),
      })
    );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={bar?.name || "Bar Detail"}
        breadcrumb={["Operations", {label: "Bars", location: "/bars"}]}
      />
      {bar && <EditBarForm bar={bar} />}

      <Paper>
        {isLoading && <LinearProgress />}
        <code>
          <JSONTree data={bar} />
        </code>
      </Paper>
    </Box>
  );
};

export default BarShowScreen;
