import auth from "./auth";
import bar from "./bar";
import robot from "./robot";
import user from "./user";
import ingredients from "./ingredients";
import dispensers from "./dispensers";
import recipes from "./recipes";
import globalIngredients from "./globalIngredients";
import drinks from "./drinks";
import appOrder from "./appOrder";
import voucher from "./voucher";
import dashboard from "./dashboard";
import exportRequest from "./exportRequest";

export type ServiceType = {
  [key: string]: (...args: any) => any;
};
export function getService(serviceName: string): ServiceType {
  switch (serviceName) {
    case "auth":
      return auth;
    // break;
    case "bar":
      return bar;
    // break;
    case "robot":
      return robot;
    // break;
    case "user":
      return user;
    // break;
    case "ingredients":
      return ingredients;
    // break;
    case "dispensers":
      return dispensers;
    // break;
    case "recipes":
      return recipes;
    // break;
    case "globalIngredients":
      return globalIngredients;
    case "drinks":
      return drinks;
    case "dashboard":
      return dashboard;
    // break;
    case "appOrder":
      return appOrder;
    // break;
    case "voucher":
      return voucher;
    case "exportRequest":
      return exportRequest;
    // break;
    default:
      throw new Error(`Undefined service ${serviceName}`);
    // break;
  }
}
