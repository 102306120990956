import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import { Box, Paper, Tab, Tabs, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Bar } from "../../services/types/bar";
import { useDispatch } from "react-redux";
import ComponentBarInfo from "./edit-bar-form-components/ComponentBarInfo";
import { useForm } from "react-hook-form";
import InputButton from "../../components/form/InputButton";
import ComponentBarState from "./edit-bar-form-components/ComponentBarState";
import ComponentRoboticSettings from "./edit-bar-form-components/ComponentRoboticSettings";
import ComponentPayment from "./edit-bar-form-components/ComponentPayment";
import ComponentSecurity from "./edit-bar-form-components/ComponentSecurity";
import { showNotification } from "../notification/notificationSlice";
import { updateBar } from "./barSlice";
import MainSpacer from "../../components/layout/MainSpacer";
import ComponentBarFeatures from "./edit-bar-form-components/ComponentBarFeatures";

const useStyles = makeStyles((theme: Theme) => ({

  inactive: {
    display: "none",
  },
}));

interface EditBarFormProps {
  bar: Bar;
}

const EditBarForm: FunctionComponent<EditBarFormProps> = ({ bar }) => {
  const [tabActive, setTabActive] = useState<string>("bar-info");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, control, setValue, watch, errors } = useForm({
    defaultValues: bar,
    shouldUnregister: false
  });

  // validation tab selected effect
  useEffect(() => {
    const tabMap: {[key: string]: string} = {
      "name": "bar-info",
      "geoPosition": "bar-info",
      "openTime": "bar-state",
      "closeTime": "bar-state",
      "instanceInfos": "bar-info",
      "roboticSettings": "robotic-settings",
      "paymentSettings": "payment",
      "security": "security",
    };
    let errorTab = '';
    const errorFieldList = Object.keys(errors) as (keyof {[key: string]: string})[];
    if (errorFieldList.length > 0) {
      console.log({validation: errors});
      if (errorFieldList[0] === 'instanceInfos') {
        errorTab = tabMap[Object.keys((errors.instanceInfos || {}))[0] as (keyof {[key: string]: string})] || tabMap[errorFieldList[0]];
      } else {
        errorTab = tabMap[errorFieldList[0]];
      }
      if (errorTab) {
        setTabActive(errorTab);
      }
    };
  }, [errors]);

  const onSubmit = (data: Bar) => {
    setDisabled(true);
    console.log({data});
    const formData = new FormData();
    formData.append("body", JSON.stringify(data));
    if (data.instanceInfos.coverImage instanceof Array && data.instanceInfos.coverImage.length > 0) {
      formData.append("coverImage", data.instanceInfos.coverImage[0]);
    }
    if (data.instanceInfos.logo instanceof Array && data.instanceInfos.logo.length > 0) {
      formData.append("logo", data.instanceInfos.logo[0]);
    }
    if (data.instanceInfos.logoBW instanceof Array && data.instanceInfos.logoBW.length > 0) {
      formData.append("logoBW", data.instanceInfos.logoBW[0]);
    }

    dispatch(
      updateBar(
        {id: bar.id, formData},
        () => {
          setDisabled(false);
          dispatch(showNotification("Bar Saved!", "success"));
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Tabs
          value={tabActive}
          onChange={(event: ChangeEvent<{}>, value) => setTabActive(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Bar Info" value="bar-info" />
          <Tab label="Bar Features" value="bar-features" />
          <Tab label="Bar State" value="bar-state" />
          <Tab label="Robotic Settings" value="robotic-settings" />
          <Tab label="Payment" value="payment" />
          <Tab label="Security" value="security" />
        </Tabs>
        <ComponentBarInfo
          bar={bar}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          className={tabActive !== "bar-info" ? classes.inactive : ""}
        />
        <ComponentBarFeatures
          bar={bar}
          register={register}
          errors={errors}
          control={control}
          setValue={setValue}
          className={tabActive !== "bar-features" ? classes.inactive : ""}
        />
        <ComponentBarState
          bar={bar}
          control={control}
          errors={errors}
          className={tabActive !== "bar-state" ? classes.inactive : ""}
        />
        <ComponentRoboticSettings
          bar={bar}
          control={control}
          register={register}
          errors={errors}
          className={tabActive !== "robotic-settings" ? classes.inactive : ""}
        />
        <ComponentPayment
          bar={bar}
          register={register}
          control={control}
          errors={errors}
          className={tabActive !== "payment" ? classes.inactive : ""}
          classes={classes}
          watch={watch}
          setValue={setValue}
        />
        <ComponentSecurity
          bar={bar}
          register={register}
          errors={errors}
          control={control}
          classes={classes}
          className={tabActive !== "security" ? classes.inactive : ""}
          watch={watch}
          setValue={setValue}
        />
        <Box p={3}>
          <MainSpacer />
          <InputButton disabled={disabled}>Save!</InputButton>
        </Box>
      </form>
    </Paper>
  );
};

export default EditBarForm;
