import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Granular } from "../../../services/types/granular";

interface GranularState {
    data: Granular[];
    map: MapLike<Granular>;
    count: number;
    page: number;
    perPage: number;
}

const initialState: GranularState = {
    data: [],
    map: {},
    count: 0,
    page: 1,
    perPage:2,
};


//Actions
export const getAllGranulars = createAction<
PrepareAction<PaginationPayloadByBar>
>("granulars/getAllGranulars", ({ page, perPage, sort, search }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
        fetch: "ingredients",
        resource: "getAllGranulars",
    },
}));

export const getAllGranularsSuccess = createAction<PaginationResponse<Granular>>(
    "granulars/getAllGranulars_SUCCESS"
);

export const getGranularById = createAction<PrepareAction<{ id: string }>>(
  "granulars/getGranularById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getGranularById",
      onFailure,
    },
  })
);

export const getGranularByIdSuccess = createAction<Granular>(
  "granulars/getGranularById_SUCCESS"
);

export const updateGranular = createAction<PrepareAction<Granular>>(
  "granulars/updateGranulars",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateGranular",
      onSuccess,
      onFailure,
    },
  })
);

export const updateGranularSuccess = createAction<Granular>(
  "granulars/updateGranular_SUCCESS"
);

export const findGlobalGranularByName = createAction<PrepareAction<{ name: string }>>(
  "granulars/findGlobalGranularByName",
  (payload, onSuccess, onFailure) => ({
    payload: {
      name: payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "findGlobalGranularByName",
      onSuccess,
      onFailure,
    },
  })
);

export const createGranular = createAction<PrepareAction<Granular>>(
  "granulars/createGranular",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createGranular",
      onSuccess,
      onFailure,
    },
  })
);

export const createGranularSuccess = createAction<Granular>(
  "granulars/createGranular_SUCCESS"
);

//Reducers
const granularsReducer = createReducer(initialState, (builder) => {
    builder
      .addCase(
        getAllGranularsSuccess,
        (state: GranularState, { payload: { data, count, page, perPage } }) => {
          state.data = data;
          state.map = _.keyBy(data, (el: Granular) => el.id);
          state.count = count;
          state.page = page;
          state.perPage = perPage;
        }
      )
      .addCase(getGranularByIdSuccess, (state: GranularState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      })
      .addCase(updateGranularSuccess, (state: GranularState, { payload }) => {
        state.map[payload.id] = payload;
      });
});

//Selector
export const selectGranulars = (state: RootState) => 
  state.ingredients.granular.data;
export const selectGranularCount = (state: RootState) =>
  state.ingredients.granular.count;
export const selectGranularById = (id: string) => (state: RootState) =>
  state.ingredients.granular.map[id];

export default granularsReducer;  

