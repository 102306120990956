import { useSelector } from "react-redux";

import { selectRole } from "./authSlice";


export const ROLEMAP = [
  "SUPERADMIN",
  "MSADMIN",
  "MSMAINT",
  "ADMIN",
  "MAINT",
  "BARMAN",
]


/**
 * Hook for checking role of the current user, i.e. a boolean indicating if the selected role is permitted
 *
 *
 * @example
 *
 * import { useHasPermission } from './feature/auth/hooks'
 *
 * const MyComponent = () => {
 *      const hasAdminPermission = useHasPermission("ADMIN");
 *      return hasAdminPermission ? <SecuredComponent /> : <PublicAltComponent />;
 * }
 */
export const useHasPermission = (roleCheck: string): boolean => {
  const role = useSelector(selectRole);
  const indexRole = ROLEMAP.indexOf(role);
  const indexCheck = ROLEMAP.indexOf(roleCheck);

  return (indexRole >= 0 && indexCheck >= 0 && indexCheck >= indexRole);
};
