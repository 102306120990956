import axios, { AxiosResponse } from "axios";
import { Robot } from "./types/robot";
import { PaginationResponse } from "./types/pagination";
import { getAccessTokenRequestForAxiosOptions, transformID, transformIDElement } from "./utils";

/**
 * Is it right to change the _id in id here? 
 * Don't know, but works neat now.
 * 
 * @param payload 
 * @param accessToken 
 */
const getAllRobots = async (payload: {} | undefined, accessToken: string) : Promise<PaginationResponse<Robot>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>>  = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/`,
        optionDecorator({ params: payload }),
    );
    const body = response.data;
    body.data = transformID(body.data);
    return body;
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const createRobot = async (payload: Robot, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/create`,
        payload,
        optionDecorator(),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const getRobotById = async (payload: { id: string}, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/${payload.id}`,
        optionDecorator(),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const toggleMaintenanceRobot = async (payload: {id: string, barId: string}, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/${payload.id}/toggleMaintenance`,
        null,
        optionDecorator({}, { "x-bar": payload?.barId }),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const startRobot = async (payload: {id: string, barId: string}, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/${payload.id}/start`,
        null,
        optionDecorator({}, { "x-bar": payload?.barId }),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const stopRobot = async (payload: {id: string, barId: string}, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/${payload.id}/stop`,
        null,
        optionDecorator({}, { "x-bar": payload?.barId }),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const abortRobot = async (payload: {id: string, barId: string}, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/${payload.id}/abort`,
        null,
        optionDecorator({}, { "x-bar": payload?.barId }),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Robot
 * @param accessToken 
 */
const updateRobot = async (payload: {id: string, barId: string}, accessToken: string) : Promise<Robot> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Robot> = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uBar/robots/${payload.id}`,
        { ...payload },
        optionDecorator({}, { "x-bar": payload?.barId }),
    );
    return transformIDElement(response.data);
};

const api = {
    getAllRobots,
    createRobot,
    getRobotById,
    toggleMaintenanceRobot,
    startRobot,
    stopRobot,
    abortRobot,
    updateRobot,
};
export default api;
