import React, { FunctionComponent } from 'react'
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import { 
  Search as SearchIcon,
  Add as AddIcon,
} from '@material-ui/icons';


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "inline",
    float: "right",
  },
  containerOpen: {
    position: "relative",
  },
  expand: {
    color: theme.palette.text.hint
  },
  expandOpen: {
    color: theme.palette.primary.dark,
    transform: "rotate(45deg)",
    position: "absolute",
    right: 0,
    top: '-27px',
    zIndex: theme.zIndex.drawer - 1
  },
}));


const FilterLayout: FunctionComponent<{onClose: () => any}> = ({onClose, children}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const handleExpandClick = () => {
    expanded && onClose();
    setExpanded(!expanded);
  }

  return (
    <div className={clsx({
      [classes.container]: !expanded,
      [classes.containerOpen]: expanded,
    })}>
      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: expanded,
        })}
        size={"small"}
        onClick={(handleExpandClick)}
        aria-expanded={expanded}
        aria-label="Show Filter"
      >
        {expanded ? <AddIcon /> : <SearchIcon />}
      </IconButton>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  )
}

export default FilterLayout
