import React, { FunctionComponent } from "react";
import { Button } from "@material-ui/core";
import { Row } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from "@material-ui/icons/Refresh";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { orderRecipeForBar } from "../recipeScreen/recipeSlice";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { showNotification } from "../notification/notificationSlice";
import { updateDrinkErrorAck } from "./productionSlice";

export const TableActions: FunctionComponent<{ row: Row<any> }> = ({
  row,
  ...rest
}) => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);

  const handleAckClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      status: "error_ack",
      id: row?.original?.id,
    };
    dispatch(
      updateDrinkErrorAck(
        payload,
        bar,
        () =>
          dispatch(
            showNotification("Error Acknowledged Successfully", "success")
          ),
        (e: any) => {
          console.error(e);
        }
      )
    );
  }

  const handleRemakeClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
    const payload = {
      ...row.original.recipe,
      orderName: row?.original?.name,
    };
    dispatch(
      orderRecipeForBar(
        payload,
        bar?.id,
        row?.original?.appOrderId,
        () =>
          dispatch(showNotification("Drink Ordered Successfully", "success")),
        (e: any) => {
          console.error(e);
        }
      )
    );
  }

  return row.original.status === "error" ? (
    <Button
      variant="outlined"
      color="primary"
      endIcon={<VisibilityIcon />}
      component="a"
      onClick={handleAckClick}
    >
      Acknowledge
    </Button>
  ) : (
    <Button
      variant="outlined"
      color="primary"
      endIcon={<RefreshIcon />}
      component="a"
      onClick={handleRemakeClick}
    >
      Remake
    </Button>
  );
};
