  
import React, { FunctionComponent } from 'react';
import ContentLoader from 'react-content-loader';
import TableCell from "@material-ui/core/TableCell";
import { TableRow } from '@material-ui/core';

const TableLoader: FunctionComponent<{
  columns: number;
  bgColor?: string;
  fgColor?: string;
  height?: string;
  width?: string;
}> = ({ columns, bgColor, fgColor, width, height}) => (
  <TableRow>
    <TableCell colSpan={columns}>
      <ContentLoader
          speed={2}
          width={width || "100%"}
          height={height || "100%"}
          backgroundColor={bgColor}
          foregroundColor={fgColor}
        >
          <rect x="0" y="8" rx="3" ry="3" width="100%" height="28" />
          <rect x="0" y="44" rx="3" ry="3" width="100%" height="28" />
          <rect x="0" y="80" rx="3" ry="3" width="100%" height="28" />
          <rect x="0" y="116" rx="3" ry="3" width="100%" height="28" />
          <rect x="0" y="152" rx="3" ry="3" width="100%" height="28" />
      </ContentLoader>
    </TableCell>
  </TableRow>
);


export default TableLoader;