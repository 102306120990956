

import { createReducer, createAction, PrepareAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../store';
import { PaginationPayload, PaginationResponse } from '../../services/types/pagination';
import { AppOrder } from '../../services/types/appOrder';
import { MapLike } from 'typescript';
import { Bar } from '../../services/types/bar';

// STATE INTERFACE
interface AppOrderState {
  data: AppOrder[];
  map: {
    [key: string]: AppOrder
  };
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: AppOrderState = {
  data: [],
  map: {},
  count: 0,
  page: 0,
  perPage: 10,
};

// ACTIONS
const getAllAppOrderAction: PrepareAction<PaginationPayload> = ({page, perPage, sort, search, dates}, bar: Bar, onSuccess, onFailure) => ({
  payload: {
    barId: bar.id,
    page,
    perPage,
    sort: (sort || []).reduce((acc: MapLike<number>, sorter: {id: string, desc: boolean}) => {
      acc[sorter.id] = (sorter.desc) ? -1 : 1;
      return acc;
    }, {}),
    search: (search || []).reduce((acc: MapLike<string>, searcher: {id: string, value: string}) => {
      acc[searcher.id] = searcher.value ;
      return acc;
    }, {}),
  },
  meta: {
    fetch: "appOrder",
    resource: "getAllAppOrders",
    onSuccess,
    onFailure,
  }
});
export const getAllAppOrders = createAction<PrepareAction<PaginationPayload>>('appOrder/getAllAppOrders', getAllAppOrderAction);
export const getAllAppOrdersRaw = createAction<PrepareAction<PaginationPayload>>('appOrder/getAllAppOrdersRaw', getAllAppOrderAction);

export const getAppOrderById = createAction<PrepareAction<{ id: string }>>('appOrder/getAppOrderById', ({id, onFailure}, bar: Bar) => ({
  payload: { id, barId: bar.id },
  meta: {
    fetch: "appOrder",
    resource: "getAppOrderById",
    onFailure
  }
}));
export const getAppOrderByIdSuccess = createAction<AppOrder>('appOrder/getAppOrderById_SUCCESS')
export const getAllAppOrdersSuccess = createAction<PaginationResponse<AppOrder>>('appOrder/getAllAppOrders_SUCCESS')
export const refundAppOrder = createAction<
  PrepareAction<{ id: string; barId: string }>
>("appOrder/refundAppOrder", (id, bar: Bar, onSuccess, onFailure) => ({
  payload: {
    id,
    barId: bar.id,
  },
  meta: {
    fetch: "appOrder",
    resource: "refundAppOrder",
    onSuccess,
    onFailure
  },
}));

export const refundAppOrderSuccess = createAction<AppOrder>(
  "appOrder/refundAppOrder_SUCCESS"
);


// REDUCERS
const appOrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAppOrderByIdSuccess, (state: AppOrderState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(getAllAppOrdersSuccess, (state: AppOrderState, { payload: { data, count, page, perPage }, ...other }) => {
      state.data = data;
      state.map = _.keyBy(data, (el: AppOrder) => el.id);
      state.count = count;
      state.page = page;
      state.perPage = perPage;
    })
    .addCase(refundAppOrderSuccess,
      (state: AppOrderState, { payload }) => {
        state.data = state.data.filter((el) => el.id !== payload.id);
        delete state.map[payload.id];
      })
    ;
});


// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAppOrders = (state: RootState) => state.appOrders.data;
export const selectAppOrdersCount = (state: RootState) => state.appOrders.count;
export const selectAppOrderById = (id: string) => ((state: RootState) => state.appOrders.map[id]);
export const selectAppOrdersPagination = (state: RootState) => ({ page: state.appOrders.page, perPage: state.appOrders.perPage });

export default appOrderReducer;
