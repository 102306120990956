import React, { FunctionComponent, useState } from "react";

import { Paper, Box, Typography, Grid, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Coffee } from "../../../services/types/coffee";
import { showNotification } from "../../notification/notificationSlice";
import { updateGlobalCoffee } from "./globalCoffeeSlice";
import { coffeeCategories } from "../../constants";
import InputColor from "../../../components/form/InputColor";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";

interface EditCoffeeFormProps {
  coffee: Coffee;
}

const EditGlobalCoffeeForm: FunctionComponent<EditCoffeeFormProps> = ({
  coffee,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: coffee,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Partial<Coffee>) => {
    setDisabled(true);
    dispatch(
      updateGlobalCoffee(
        { ...coffee, ...data },
        () => {
          dispatch(showNotification("Coffee Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!coffee) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Global Coffee
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                control={control}
                required
                fullWidth
              >
                {coffeeCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
              <InputNumber
                name="infusionTime"
                label="Infusion Time"
                register={register}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditGlobalCoffeeForm;
