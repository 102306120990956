import React from "react";
import {
  FormControl,
  InputLabel,
  Slider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FunctionComponent } from "react";
import { useController } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    paddingTop: theme.spacing(4),
    // width: "100%",
  },
}));

type InputSwitchrops = {
  name: string;
  label: string;
  control: any;
  maxValue: number;
  fullWidth?: boolean;
};

const InputSlider: FunctionComponent<InputSwitchrops> = ({
  label,
  name,
  control,
  maxValue,
  fullWidth,
}) => {
  const classes = useStyles();
  const {
    field: { value, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });
  return (
    <FormControl fullWidth={fullWidth} margin="normal">
      <InputLabel shrink={true}>{label}</InputLabel>
      <div className={classes.sliderContainer}>
        <Slider
          name={name}
          max={maxValue}
          value={value}
          onChange={(evt, value) => {
            onChange(value);
            onBlur();
          }}
          valueLabelDisplay="on"
        />
      </div>
    </FormControl>
  );
};

export default InputSlider;
