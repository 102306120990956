import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ReactComponent as EmptyState } from "../../assets/svg/emptyState.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F6F6F6",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "center",
      height: "94%",
      padding: "2rem",
      margin: "2rem 2rem 2rem 0rem",
      borderRadius: "10px",
      boxShadow: "0px 8px 10px -10px rgba(0,0,0,1);",
    },
    number: {
      color: theme.palette.primary.main,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      justifyContent: "flexStart",
      height: "100%",
    },
    containerSvg: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      height: "100%",
    },
    drink: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      padding: "1rem 0 1rem 0.5rem",
    },
    drinkItem: {
      paddingRight: "1.5rem",
    },
    drinkItemValue: {
      color: theme.palette.primary.main,
    },
    svg: {
      margin: "5rem 0 0 2rem",
      height: "13rem",
    },
  })
);

type SquareCounterProps = {
  data: any[] | undefined;
};

const SquareCounter: FunctionComponent<SquareCounterProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h4">
        Top Drinks Today
      </Typography>
      {data?.length === 0 ? (
        <div className={classes.containerSvg}>
          <EmptyState className={classes.svg} />
          <Typography component="h2" variant="h5">
            No data found
          </Typography>
        </div>
      ) : (
        <div className={classes.container}>
          {data?.map((el, idx) => {
            return (
              <div key={idx} className={classes.drink}>
                <Typography
                  className={classes.drinkItem}
                  component="h2"
                  variant="h5"
                >
                  {idx + 1})
                </Typography>
                <Typography
                  className={classes.drinkItem}
                  component="h2"
                  variant="h5"
                >
                  {el.name}:
                </Typography>
                <Typography
                  className={classes.drinkItemValue}
                  component="h2"
                  variant="h5"
                >
                  {el.value}
                </Typography>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SquareCounter;
