import axios, { AxiosResponse } from "axios";
import { AppOrder } from "./types/appOrder";
import { PaginationResponse } from "./types/pagination";
import { getAccessTokenRequestForAxiosOptions, transformID, transformIDElement } from "./utils";

/**
 * Is it right to change the _id in id here? 
 * Don't know, but works neat now.
 * 
 * @param payload 
 * @param accessToken 
 */
const getAllAppOrders = async (payload: {barId: string} | undefined, accessToken: string) : Promise<PaginationResponse<AppOrder>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>>  = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/apporders/`,
        optionDecorator({ params: payload }, { "x-bar": payload?.barId })
    );
    const body = response.data;
    body.data = transformID(body.data);
    return body;
};

/**
 * 
 * @param payload AppOrder
 * @param accessToken 
 */
const getAppOrderById = async (payload: { id: string, barId?: string }, accessToken: string) : Promise<AppOrder> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<AppOrder> = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/apporders/${payload.id}`,
        optionDecorator({}, { "x-bar": payload?.barId })
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload AppOrder
 * @param accessToken 
 */
const refundAppOrder = async (payload: { id: string, barId?: string }, accessToken: string) : Promise<AppOrder> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<AppOrder> = await axios.delete(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/apporders/${payload.id}/refund`,
        optionDecorator({}, { "x-bar": payload?.barId })
    );
    return transformIDElement(response.data);
};


const api = {
    getAllAppOrders,
    getAppOrderById,
    refundAppOrder,
};
export default api;
