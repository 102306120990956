import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  MenuItem,
  DialogActions,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Garnish } from "../../../services/types/garnish";
import { showNotification } from "../../notification/notificationSlice";
import { createGlobalGarnish } from "./globalGarnishSlice";
import { garnishCategories } from "../../constants";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputColor from "../../../components/form/InputColor";
import InputButton from "../../../components/form/InputButton";

interface CreateGlobalGarnishFormProps {
  onCreateSuccess: (garnish: Garnish) => any;
}

const CreateGlobalGarnishForm: FunctionComponent<CreateGlobalGarnishFormProps> = ({
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      name: "",
      color: "",
      price: null,
      category: garnishCategories[0],
    },
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Garnish) => {
    setDisabled(true);
    const garnish = {
      name: data.name,
      category: data.category,
      color: data.color,
    };
    console.log(garnish);
    dispatch(
      createGlobalGarnish(
        garnish,
        ({ payload }: any) => {
          dispatch(showNotification("Global Garnish Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Global Garnish
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            name="name"
            label="Name"
            register={register}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {garnishCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
      </Grid>
      <MainSpacer />
      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Garnish!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateGlobalGarnishForm;
