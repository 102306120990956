import axios, { AxiosResponse } from "axios";
import { Voucher } from "./types/voucher";
import { PaginationResponse } from "./types/pagination";
import { getAccessTokenRequestForAxiosOptions, transformID, transformIDElement } from "./utils";

/**
 * Is it right to change the _id in id here? 
 * Don't know, but works neat now.
 * 
 * @param payload 
 * @param accessToken 
 */
const getAllVouchers = async (payload: {barId: string} | undefined, accessToken: string) : Promise<PaginationResponse<Voucher>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>>  = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/voucher/`,
        optionDecorator({ params: payload }, { "x-bar": payload?.barId })
    );
    const body = response.data;
    body.data = transformID(body.data);
    return body;
};

/**
 * 
 * @param payload Voucher
 * @param accessToken 
 */
const getVoucherById = async (payload: { id: string, barId: string }, accessToken: string) : Promise<Voucher> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<Voucher> = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/voucher/${payload.id}`,
        optionDecorator({}, { "x-bar": payload?.barId })

    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload Voucher
 * @param accessToken 
 */
const updateVoucher = async (
    payload: any | undefined,
    accessToken: string
  ): Promise<PaginationResponse<Voucher>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
      `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/voucher/${payload.id}`,
      payload,
      optionDecorator({}, { "x-bar": payload?.barId })

    );
    return transformIDElement(response.data);
  };

/**
 * 
 * @param payload Voucher
 * @param accessToken 
 */
const createVoucher = async (
    payload: any | undefined,
    accessToken: string
  ): Promise<PaginationResponse<Voucher>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>> = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/voucher`,
      payload,
      optionDecorator({}, { "x-bar": payload?.barId })

    );
    return transformIDElement(response.data);
  };

const api = {
    getAllVouchers,
    getVoucherById,
    updateVoucher,
    createVoucher,
};
export default api;
