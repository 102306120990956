import React, { useImperativeHandle } from 'react'
import "./react-table-config.d"
import { lighten, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
  SortingRule,
  Filters,
} from 'react-table'
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import InputFilter from './filters/InputFilter'
import TableLoader from './TableLoader'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: "auto"
  },
  head: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  row: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.9)
    },
  }
}));

export interface FetchDataArgs {
  pageIndex: number,
  pageSize: number,
  sortBy: SortingRule<object>[],
  filters: Filters<object>,
};

export type EnhancedTableRef = {
  refresh: () => void;
}

interface EnhancedTableProps {
  columns: Column<any>[];
  data: any[];
  totalCount: number;
  isLoading: boolean;
  onRowClick: (row: any) => any; // Must be a useCallback
  fetchData: (arg: FetchDataArgs) => any; // Must be a useCallback
  ref?: React.RefObject<EnhancedTableRef>
}

/** This is ti avoid fetchData Misfire when the total count changes */
// let cachedTotalCount: number | null = null;
/**
 * onChangeFilters and fetchData functions must use the useCallback hook so that those can be memoized.
 * 
 * @doc https://react-table.tanstack.com/docs/examples/pagination-controlled
 * @doc https://react-table.tanstack.com/docs/examples/material-ui-components
 * @doc for typings https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table
 */
const EnhancedTable = React.forwardRef<EnhancedTableRef, EnhancedTableProps>(({ columns, data, totalCount, isLoading, onRowClick, fetchData }, ref) => {
  const { search } = useLocation();
  const query = queryString.parse(search);
  const history = useHistory();
  const defaultPageSize = 5;
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: InputFilter, //DefaultColumnFilter,
    }),
    []
  );
  let presetFilters = []
  try {
    presetFilters = query?.filters ? JSON.parse(query.filters as string) : [];
  } catch {}

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    gotoPage,
    setPageSize,
    setFilter,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: query?.pageIndex ? parseInt(query.pageIndex as string) : 0,
        pageSize: query?.pageSize? parseInt(query.pageSize as string) : defaultPageSize,
        filters: presetFilters || [] // pick from location
      }, // Pass our hoisted table state
      manualSortBy: true, // Tell the useSortBy
      defaultCanSort: true,
      manualPagination: true, // Tell the usePagination
      pageCount: Math.ceil(totalCount / defaultPageSize),
      autoResetPage: true,
      manualFilters: true, // Tell the useFilters
    },
    useFilters,
    useSortBy,
    usePagination,
  );
  
  useImperativeHandle(ref, () => {
    return {
      refresh: () => fetchData({ pageIndex, pageSize, sortBy, filters })
    };
  });

  const classes = useStyles();

  // Listen for changes in pagination or filters and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy, filters });

  }, [fetchData, pageIndex, pageSize, sortBy, filters])

  // Save Filter state on location search
  React.useEffect(() => {
    const currentQuery = queryString.parse(history.location.search);
    history.push("?"+queryString.stringify({ ...currentQuery, filters: JSON.stringify(filters) }));
  }, [history, filters])
  
  // listen to pageSize and pageIndex from location search and trigger the page change on react-table
  // @TODO: PROBLEM WITH FILTERS: if I setFilter on the InputFilter component i cannot setAllFilters here from the location listener, because I would have a double filter trigger...
  // We can have the InputFilter(s) component change the location instead of setting filters, so we can set filters here or ignore it like it is now.
  //
  // Improvement 08/09/2021: we added filter as a dependency, now it triggers only when a filter change outside the table.
  // As a dirty implementation, the filter on the createdAt column will be handled here if you change the history.search outside the table itself.
  React.useEffect(() => {
    return history.listen((location) => {
      const queryNew = queryString.parse(location.search);
      const filtersArray: any[] = JSON.parse((queryNew?.filters as string | null) || '[]');
      if ( filtersArray.length && (filtersArray.find((el: any) => el.id === 'createdAt') || filters.find(el => el.id === 'createdAt')) ) {
        if ((filtersArray).filter((el: any) => el.id === 'createdAt') !== filters.filter(el => el.id === 'createdAt')) {
          const newFilter = (filtersArray).filter((el: any) => el.id === 'createdAt')[0] || {};
          setFilter('createdAt', newFilter.value);
        }
      }
      queryNew?.pageSize && setPageSize(parseInt(queryNew.pageSize as string));
      queryNew?.pageIndex && gotoPage(parseInt(queryNew.pageIndex as string));
    });
  }, [gotoPage, history, setPageSize, filters]);

  const handleChangeRowsPerPage = (event: any) => {
    const currentQuery = queryString.parse(history.location.search);
    const pageSize = parseInt(event.target.value, 10);
    history.push("?"+queryString.stringify({ ...currentQuery, pageSize }));
  };
  const handleChangePage = (event: any, newPage: number) => {
    const currentQuery = queryString.parse(history.location.search);
    history.push("?"+queryString.stringify({ ...currentQuery, pageIndex: newPage }));
  };

  return (
    <div className={classes.container}>
      <MuiTable {...getTableProps()}>
        <TableHead>
          <TableRow>
            {headers.map(column => (
              <TableCell classes={{ head: classes.head }} {...column.getHeaderProps()}>
                {(column.canSort && !column.disableSortBy) ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                      {...column.getSortByToggleProps()}
                    >{column.render('Header')}</TableSortLabel>
                ) : <span>{column.render('Header')}</span>}
                {column.canFilter ? column.render('Filter') : null}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {(isLoading && <TableLoader columns={columns.length} />) || (
            (totalCount === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Box margin={1} height={ pageSize * 68 } textAlign="center">
                    No Results
                  </Box>
                </TableCell>
              </TableRow>
            )) || (
            rows.map((row, i) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()} className={classes.row} onClick={() => onRowClick(row.original)}>
                  {row.cells.map(cell => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }))
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                25
              ]}
              count={totalCount}
              rowsPerPage={pageSize}
              page={pageIndex}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </MuiTable>
    </div>
  )

});


export default EnhancedTable;