import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import {
  getGlobalFlavourById,
  selectGlobalFlavourById,
} from "./globalFlavourSlice";
import EditGlobalFlavourForm from "./EditGlobalFlavourForm";

const GlobalFlavourShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const flavour = useSelector(selectGlobalFlavourById(id));

  useEffect(() => {
    dispatch(
      getGlobalFlavourById({
        id,
        onFailure: () => history.push("/global-ingredients"),
      })
    );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={"Super Admin"}
        breadcrumb={[
          {
            label: "Global Ingredients",
            location: "/global-ingredients/bottles",
          },
          { label: "Flavours", location: "/global-ingredients/flavours" },
          flavour?.name,
        ]}
      />
      {flavour && <EditGlobalFlavourForm flavour={flavour} />}

      <Paper>
        {isLoading && <LinearProgress />}
        <code>
          <JSONTree data={flavour} />
        </code>
      </Paper>
    </Box>
  );
};

export default GlobalFlavourShowScreen;
