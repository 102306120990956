import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import { PaginationResponse } from "../../../services/types/pagination";
import { DispenserBib, Valv } from "../../../services/types/dispenserBib";
import { Bar } from "../../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface DispenserBibState {
  data: DispenserBib[];
  map: MapLike<DispenserBib>;
  count: number;
  // page: number;
  // perPage: number;
}

// INITIAL STATE
const initialState: DispenserBibState = {
  data: [],
  map: {},
  count: 0,
  // page: 1,
  // perPage: 2,
};

// ACTIONS
export const getAllDispenserBib = createAction<
  PrepareAction<{ barId: string }>
>("dispenserBib/getAllDispenserBib", (bar: Bar) => ({
  payload: {
    barId: bar.id,
  },
  meta: {
    fetch: "dispensers",
    resource: "getAllDispenserBib",
  },
}));
export const getAllDispenserBibSuccess = createAction<
  PaginationResponse<DispenserBib>
>("dispenserBib/getAllDispenserBib_SUCCESS");
export const updateDispenserBib = createAction<PrepareAction<DispenserBib>>(
  "dispenserBib/updateDispenserBib",
  (dispenser: DispenserBib, onSuccess, onFailure) => ({
    payload: {
      ...dispenser,
    },
    meta: {
      fetch: "dispensers",
      resource: "updateDispenserBib",
      onSuccess,
      onFailure,
    },
  })
);
export const updateDispenserBibSuccess = createAction<DispenserBib>(
  "dispenserBib/updateDispenserBib_SUCCESS"
);

export const manualCommandValve = createAction<PrepareAction<{id: string, quantity: number, dispensingTime: number}>>(
  "dispenserBib/updateDispenserBib",
  (valve: Valv, quantity, dispensingTime, onSuccess, onFailure) => ({
    payload: {
      id: valve.id,
      barId: valve.barId,
      quantity,
      dispensingTime
    },
    meta: {
      fetch: "dispensers",
      resource: "manualCommandValve",
      onSuccess,
      onFailure,
    },
  })
);

/* export const findBibByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "dispenserBib/findBibByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findBibByName",
      onSuccess,
      onFailure,
    },
  })
); */

// REDUCERS
const dispenserBibReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllDispenserBibSuccess,
      (
        state: DispenserBibState,
        { payload: { data, count, page, perPage } }
      ) => {
        state.data = data;
        state.map = _.keyBy(data, (el: DispenserBib) => el.id);
        state.count = count;
        // state.page = page;
        // state.perPage = perPage;
      }
    )
    .addCase(
      updateDispenserBibSuccess,
      (state: DispenserBibState, { payload }) => {
        state.map[payload.id] = payload;
        state.data = state.data.map((el) => {
          if (el.id === payload.id) {
            return payload;
          }
          return el;
        });
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDispenserBib = (state: RootState) =>
  state.dispensers.bib.data;

export default dispenserBibReducer;
