import React from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { FunctionComponent } from "react";

type InputSelectProps = {
  name: string;
  label: string;
  defaultValue?: any;
  control: any;
  onChangeProp?: (evt: any) => void;
};

const FieldArraySelect: FunctionComponent<InputSelectProps> = ({
  control,
  defaultValue,
  onChangeProp,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={defaultValue}
      render={({ value, onChange, ...rest }) => (
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor={props.name} shrink={true}>
            {props.label}
          </InputLabel>
          <Select {...rest} value={value} onChange={(e) => {onChange(e); onChangeProp && onChangeProp(e);}}>
            {props.children}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FieldArraySelect;
