import { DashboardGraphItem } from "../../services/types/dashboardGraphItem";
import { createAction, createReducer, PrepareAction } from "@reduxjs/toolkit";
import { Bar } from "../../services/types/bar";
import { RootState } from "../../store";

interface MyBarState {
  data: DashboardGraphItem;
}

const initialState: MyBarState = {
  data: {},
};

//ACTIONS
export const getTodayOrders = createAction<PrepareAction<any>>(
  "myBar/getTodayOrders",
  (bar: Bar) => ({
    payload: {
      barId: bar.id,
    },
    meta: {
      fetch: "dashboard",
      resource: "getTodayOrders",
    },
  })
);

export const getTodayOrdersSuccess = createAction<DashboardGraphItem>(
  "myBar/getTodayOrders_SUCCESS"
);

//Reducers
const dashboardReducer = createReducer(initialState, (builder) => {
  builder.addCase(getTodayOrdersSuccess, (state: MyBarState, { payload }) => {
    state.data = payload;
  });
});

//Selector
export const selectTodayOrders = (state: RootState) =>
  state.order.dashboard.data;
export default dashboardReducer;
