import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";

import { Recipe } from "../../services/types/recipe";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { DragHandle as DragHandleIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import InputText from "../../components/form/InputText";
import InputNumber from "../../components/form/InputNumber";
import MainSpacer from "../../components/layout/MainSpacer";
import { ReactSortable, SortableEvent } from "react-sortablejs";
import IngredientActionRadio from "./IngredientActionRadio";
import IngredientTypeSelect from "./IngredientTypeSelect";
import IngredientLookup from "./IngredientLookup";
import { Bar } from "../../services/types/bar";
import RecipeFormRecap from "./RecipeFormRecap";
import CupTypeRadio from "./CupTypeRadio";
import CoffeeTypeSelect from "./CoffeeTypeSelect";

interface RecipeFormProps {
  onFormSubmit: (elem: any) => any;
  recipe: Partial<Recipe>;
  bar: Bar | null;
  disabled: boolean;
  isCoffee?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid #ccc",
      borderRadius: "5px",
      margin: "0.6rem 0",
      padding: "1rem",
      width: "48vw",
    },
  })
);

const IngredientRules: Record<string, { max: number }> = {
  Ice: { max: 8 },
  Garnish: { max: 2 },
  Granular: { max: 2 },
  Leaf: { max: 2 },
  Bottle: { max: 14 },
  Beverage: { max: 14 },
  Coffee: { max: 14 },
};

const RecipeForm: FunctionComponent<RecipeFormProps> = ({
  recipe,
  bar,
  onFormSubmit,
  disabled,
  isCoffee,
}) => {
  const classes = useStyles();

  const [firstCarbonatedIndex, setFirstCarbonatedIndex] = useState(
    Number.MAX_SAFE_INTEGER
  );

  const [parts, setParts] = useState(0);
  const MAX_PARTS = 14;
  const [alcoholPerc, setAlcoholPerc] = useState(0);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
  } = useForm({
    defaultValues: recipe,
  });
  const { fields, remove, move, append } = useFieldArray({
    control,
    name: "ingredients",
    keyName: "keyId",
  });

  const ingredientWatch = watch("ingredients");
  useEffect(() => {
    let found = 1000;
    (ingredientWatch || []).forEach((f, idx) => {
      if (f.carbonated && idx < found) {
        found = idx;
      }
    });
    setFirstCarbonatedIndex(found);

    const partsCount = (ingredientWatch || []).reduce((acc, i) => {
      if (
        i.ingredientType === "Bottle" ||
        i.ingredientType === "Ice" ||
        i.ingredientType === "Beverage" ||
        i.ingredientType === "Coffee"
      ) {
        return acc + i.quantity;
      }
      return acc;
    }, 0);
    setParts(partsCount);

    const alcohol = (ingredientWatch || []).reduce((acc, i) => {
      return acc + (i?.alcoholPerc || 0);
    }, 0);
    setAlcoholPerc(partsCount === 0 ? 0 : alcohol / partsCount);
  }, [ingredientWatch]);

  const handleSortIngredients = (evt: SortableEvent) => {
    // if ((evt.oldIndex as number) === minCarbonatedIndex) {setMinCarbonatedIndex(evt.newIndex as number);}
    move(evt.oldIndex as number, evt.newIndex as number);
  };

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onFormSubmit({
          ...recipe,
          ...data,
        })
      )}
    >
      <Grid container spacing={2}>
        {recipe?.id && (
          <Grid item xs={12}>
            <Typography>ID: {recipe?.id}</Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <InputText
            name="name"
            label="Name"
            required
            fullWidth
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputNumber
            name="price"
            label="Price"
            helperText="Leave it empty to inherit the category price"
            fullWidth
            inputProps={{ step: 0.01 }}
            register={register}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          {isCoffee ? (
            <CoffeeTypeSelect
              control={control}
              label={"Coffee Group"}
              defaultValue={recipe?.coffeeType}
              name="coffeeType"
            />
          ) : (
            <div></div>
          )}

          <RecipeFormRecap
            maxParts={MAX_PARTS}
            alcoholPerc={alcoholPerc}
            parts={parts}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CupTypeRadio
            control={control}
            defaultValue={recipe?.cup}
            name="cup"
          />
        </Grid>
      </Grid>
      <MainSpacer />
      <Box height="40vh" overflow="scroll">
        <ReactSortable
          tag="tbody"
          handle=".drag-handle-ingredient"
          list={fields as any[]}
          setList={() => {}} // BUG IN THE LIBRARY
          onEnd={handleSortIngredients}
        >
          {fields.map((field, index) => {
            register({
              name: `ingredients.${index}.id`,
              defaultValue: field.id,
              value: field.id,
            });
            register({
              name: `ingredients.${index}.ingredientId`,
              defaultValue: field.ingredientId,
              value: field.ingredientId,
            });
            register({
              name: `ingredients.${index}.carbonated`,
              defaultValue: field.carbonated,
              value: field.carbonated,
            });
            register({
              name: `ingredients.${index}.alcoholPerc`,
              defaultValue: field.alcoholPerc,
              value: field.alcoholPerc,
            });
            register({
              name: `ingredients.${index}.color`,
              defaultValue: field.color,
              value: field.color,
            });
            return (
              <Grid
                container
                spacing={1}
                key={field.keyId}
                className={classes.container}
              >
                <Grid item md={2} lg={3}>
                  <IngredientTypeSelect
                    name={`ingredients.${index}.ingredientType`}
                    label="Ingredient Type"
                    defaultValue={field.ingredientType}
                    control={control}
                    onChange={(
                      evt: ChangeEvent<{
                        name?: string | undefined;
                        value: string;
                      }>
                    ) => {
                      if (evt.target.value) {
                        setValue(`ingredients.${index}.ingredient`, null);
                        setValue(`ingredients.${index}.ingredientId`, "");
                        if (MAX_PARTS - parts >= 1) {
                          setValue(`ingredients.${index}.quantity`, 1);
                        }
                        setValue(`ingredients.${index}.action`, "");
                        setValue(`ingredients.${index}.color`, "");
                        setValue(`ingredients.${index}.alcoholPerc`, 0);
                        setValue(`ingredients.${index}.carbonated`, false);
                        if (evt.target.value === "Ice") {
                          setValue(
                            `ingredients.${index}.ingredientName`,
                            "Ice"
                          );
                        } else {
                          setValue(`ingredients.${index}.ingredientName`, "");
                        }
                      }
                    }}
                    disabled={disabled}
                  />
                  <InputNumber
                    name={`ingredients.${index}.quantity`}
                    label="Quantity"
                    fullWidth
                    required
                    defaultValue={field.quantity}
                    register={register}
                    inputProps={{
                      max: Math.min(
                        IngredientRules[
                          watch(`ingredients.${index}.ingredientType`) as string
                        ]?.max,
                        MAX_PARTS -
                          parts +
                          (watch(`ingredients.${index}.quantity`) as number)
                      ),
                      min: 1,
                    }}
                  />
                </Grid>
                <Grid item md={8} lg={7}>
                  <IngredientLookup
                    name={`ingredients.${index}.ingredientName`}
                    label="Ingredient"
                    control={control}
                    setError={setError}
                    setValue={setValue}
                    getValues={getValues}
                    barId={bar?.id}
                    index={index}
                    required
                    disabled={disabled}
                    defaultValue={field.ingredientName}
                  />
                  <IngredientActionRadio
                    name={`ingredients.${index}.action`}
                    label="Action"
                    index={index}
                    control={control}
                    disabled={disabled}
                    setValue={setValue}
                    defaultValue={field.action}
                    shakerable={index < firstCarbonatedIndex}
                  />
                </Grid>
                <Grid container sm={2} alignItems="center">
                  <IconButton
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <span
                    className="drag-handle-ingredient"
                    onClick={(evt: any) => {
                      evt.stopPropagation();
                    }}
                  >
                    <IconButton>
                      <DragHandleIcon />
                    </IconButton>
                  </span>
                </Grid>
              </Grid>
            );
          })}
        </ReactSortable>
      </Box>
      <MainSpacer />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            append({
              alcolPerc: 0,
              carbonated: false,
              ingredientId: "",
              ingredientName: "",
              ingredientType: "",
              quantity: 0,
              action: "",
              color: "",
            });
          }}
        >
          Add Ingredient
        </Button>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
};
export default RecipeForm;
