import React, { ReactEventHandler } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import * as colors from '@material-ui/core/colors';
import { Refresh as RefreshIcon } from "@material-ui/icons";
import clsx from 'clsx';
import { FunctionComponent } from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "450px",
    minHeight: "360px",
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 5,
    position: "relative",
  },
  dot: {
    backgroundColor: theme.palette.grey[900],
    borderRadius: "100%",
    position: 'absolute',
    width: "15px",
    height: "15px",
  },
  rightTopDot: {
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  rightBottomDot: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  leftTopDot: {
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  leftBottomDot: {
    bottom: theme.spacing(1),
    left: theme.spacing(1),
  },
  button: {
    position: "absolute",
    borderRadius: "100%",
    border: "2px solid",
    borderColor: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[600],
    width: "65px",
    height: "65px",
    cursor: "pointer",
    "& > *": {
      verticalAlign: "middle",
      color: theme.palette.grey[800],
    },
    "&:hover": {
      border: "1px solid",
      borderColor: colors.common.white,
      backgroundColor: theme.palette.grey[700],
    }
  },
  abortR1: {
    backgroundColor: colors.amber[800],
    top: "50px",
    left: "50px",
    "&:hover": {
      backgroundColor: colors.amber[500],
    }
  },
  stopR1: {
    backgroundColor: colors.red[800],
    top: "150px",
    left: "50px",
    "&:hover": {
      backgroundColor: colors.red[500],
    }
  },
  startR1: {
    backgroundColor: colors.green[800],
    bottom: "50px",
    left: "50px",
    "&:hover": {
      backgroundColor: colors.green[500],
    }
  },
  abortR2: {
    backgroundColor: colors.amber[800],
    top: "50px",
    left: "50%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: colors.amber[500],
    }
  },
  stopR2: {
    backgroundColor: colors.red[800],
    top: "150px",
    left: "50%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: colors.red[500],
    }
  },
  startR2: {
    backgroundColor: colors.green[800],
    bottom: "50px",
    left: "50%",
    transform: "translateX(-50%)",
    "&:hover": {
      backgroundColor: colors.green[500],
    }
  },
  disabledLight: {
    backgroundColor: colors.grey[800],
    top: "50px",
    right: "50px",
    // "&:hover": {
    //   backgroundColor: colors.red[500],
    // }
  },
  restoreFences: {
    backgroundColor: colors.common.white,
    top: "150px",
    right: "50px",
    "&:hover": {
      backgroundColor: colors.grey[100],
    }
  },
  restoreEmergency: {
    backgroundColor: colors.common.white,
    bottom: "50px",
    right: "50px",
    "&:hover": {
      backgroundColor: colors.grey[100],
    }
  },
  btnLabel: {
    display: 'inline-block',
    position:'relative',
    top: '47px',
    left: '-5px',
    textTransform: "uppercase",
    fontWeight: 800,
    textAlign: 'left',
  },

}));

interface PushButtonBoxVeloceProps {
  handleAbortR1?: ReactEventHandler<HTMLButtonElement>,
  handleStopR1?: ReactEventHandler<HTMLButtonElement>,
  handleStartR1?: ReactEventHandler<HTMLButtonElement>,
  handleAbortR2?: ReactEventHandler<HTMLButtonElement>,
  handleStopR2?: ReactEventHandler<HTMLButtonElement>,
  handleStartR2?: ReactEventHandler<HTMLButtonElement>,
  handleRestoreEmergency?: ReactEventHandler<HTMLButtonElement>,
  handleRestoreFences?: ReactEventHandler<HTMLButtonElement>,
}

const PushButtonBoxVeloce: FunctionComponent<PushButtonBoxVeloceProps> = ({
  handleAbortR1,
  handleStopR1,
  handleStartR1,
  handleAbortR2,
  handleStopR2,
  handleStartR2,
  handleRestoreEmergency,
  handleRestoreFences,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={clsx([classes.dot, classes.rightTopDot])}></div>
      <div className={clsx([classes.dot, classes.rightBottomDot])}></div>
      <div className={clsx([classes.dot, classes.leftTopDot])}></div>
      <div className={clsx([classes.dot, classes.leftBottomDot])}></div>

      <button className={clsx([classes.button, classes.abortR1])} onClick={handleAbortR1}>
        <span className={classes.btnLabel}>Abort R1</span>
      </button>
      <button className={clsx([classes.button, classes.stopR1])} onClick={handleStopR1}>
        <span className={classes.btnLabel}>Stop R1</span>
      </button>
      <button className={clsx([classes.button, classes.startR1])} onClick={handleStartR1}>
        <span className={classes.btnLabel}>Start R1</span>
      </button>
      <button className={clsx([classes.button, classes.abortR2])} onClick={handleAbortR2}>
        <span className={classes.btnLabel}>Abort R2</span>
      </button>
      <button className={clsx([classes.button, classes.stopR2])} onClick={handleStopR2}>
        <span className={classes.btnLabel}>Stop R2</span>
      </button>
      <button className={clsx([classes.button, classes.startR2])} onClick={handleStartR2}>
        <span className={classes.btnLabel}>Start R2</span>
      </button>
      <button className={clsx([classes.button, classes.disabledLight])} disabled></button>

      <button className={clsx([classes.button, classes.restoreEmergency])} onClick={handleRestoreEmergency}>
        <span className={classes.btnLabel}>Emergency</span>
      </button>
      <button className={clsx([classes.button, classes.restoreFences])} onClick={handleRestoreFences}>
        <span className={classes.btnLabel}>Fences</span>
      </button>
    </div>
  );
}

export default PushButtonBoxVeloce
