import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { FETCH_ERROR } from '../../features/loading/actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { signInSuccess, signOut, UserType } from '../../features/auth/authSlice';
import { showNotification } from '../../features/notification/notificationSlice';

function utf8_to_b64(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

function* handleCheckAuthFromSession() {
  const encodedAuth = localStorage.getItem("auth");
  if (encodedAuth) {
    const user: UserType = JSON.parse(b64_to_utf8(encodedAuth));
    yield put(signInSuccess(user));
  }
}

function handleAuthSuccess (action: PayloadAction<UserType>) {
  const { payload } = action;

  const encodedAuth = utf8_to_b64(JSON.stringify(payload));
  localStorage.setItem("auth", encodedAuth);
}

function* handleFetchError (action: PayloadAction<AxiosError>) {
  const { payload } = action;
  const status = payload?.response?.status;
  if (status === 401 || status === 403) {
    localStorage.removeItem('auth');
    localStorage.removeItem('bar');
    yield put(signOut());
  } else {
    yield put(showNotification(payload?.response?.data?.message || payload.message, "error"))
  }
};

function handleSignOut (action: PayloadAction) {
  localStorage.removeItem('auth');
  // @todo: somehow clear state
}

function* watchAuth() {
  yield call(handleCheckAuthFromSession);
  yield all([
    takeEvery(signInSuccess.match, handleAuthSuccess),
    takeLatest(FETCH_ERROR, handleFetchError),
    takeEvery(signOut.match, handleSignOut),
  ]);
};

export default watchAuth;