import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  MenuItem,
  DialogActions,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Flavour } from "../../../services/types/flavour";
import { showNotification } from "../../notification/notificationSlice";
import { createGlobalFlavour } from "./globalFlavourSlice";
import { flavourCategories } from "../../constants";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputButton from "../../../components/form/InputButton";

interface CreateGlobalFlavourFormProps {
  onCreateSuccess: (flavour: Flavour) => any;
}

const CreateGlobalFlavourForm: FunctionComponent<CreateGlobalFlavourFormProps> = ({
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      name: "",
      price: null,
      category: flavourCategories[0],
    },
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Flavour) => {
    setDisabled(true);
    const flavour = {
      name: data.name,
      category: data.category,
    };
    dispatch(
      createGlobalFlavour(
        flavour,
        ({ payload }: any) => {
          dispatch(showNotification("Global Flavour Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Global Flavour
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            name="name"
            label="Name"
            register={register}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {flavourCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <MainSpacer />
      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Flavour!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateGlobalFlavourForm;
