import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import clsx from "clsx";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { Bar } from "../../../services/types/bar";
import InputText from "../../../components/form/InputText";
import InputCheckbox from "../../../components/form/InputCheckbox";
import InputRadio from "../../../components/form/InputRadio";
import FieldArrayRounding from "./FieldArrayRounding";
import { RegisterOptions } from "react-hook-form";
import InputTextMultiBadge from "../../../components/form/InputTextMultiBadge";
import Shift4LoginButton from "./Shift4LoginButton";
import SatispayLoginButton from "./SatispayLoginButton";

interface PaymentProps {
  bar: Bar;
  register: (arg: RegisterOptions) => any;
  control: any;
  errors: any;
  classes: any;
  className: any;
  setValue: any;
  watch: any;
}

const ComponentPayment: FunctionComponent<PaymentProps> = ({
  bar,
  register,
  control,
  classes,
  errors,
  setValue,
  watch,
  ...props
}) => {
  return (
    <div {...props}>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Currency and Taxes
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputSelect
              name="paymentSettings.currency.currencyNameISO"
              label="Local Currency"
              control={control}
              fullWidth
              error={errors?.paymentSettings?.currency?.currencyNameISO?.type}
              required
            >
              <MenuItem value="EUR">Euro - €</MenuItem>
              <MenuItem value="USD">Dollar - $</MenuItem>
              <MenuItem value="GBP">Pound - £</MenuItem>
              <MenuItem value="CZK">Czech Corona - CZK</MenuItem>
              <MenuItem value="AED">AE Dirham - AED</MenuItem>
              <MenuItem value="CHF">Swiss Franc - CHF</MenuItem>
            </InputSelect>
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputRadio
              name="paymentSettings.currency.currencySymbolLeft"
              label="Symbol Position"
              radioValues={[
                { label: "Left", value: true },
                { label: "Right", value: false },
              ]}
              control={control}
              rules={{
                setValueAs: (value: any) => value === "true",
                validate: (value) => value !== null,
              }}
              error={
                errors?.paymentSettings?.currency?.currencySymbolLeft?.type
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputNumber
              name="paymentSettings.taxes.percentage"
              label="Taxes Percentage"
              register={register}
              required
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputCheckbox
              name="paymentSettings.taxes.included"
              label="Include in Total Cost"
              control={control}
              classes={classes.gutterTopLabel}
            />
            <InputCheckbox
              name="paymentSettings.taxes.showAtCheckout"
              label="Show at checkout"
              control={control}
              classes={classes.gutterTopLabel}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputNumber
              name="paymentSettings.tips.percentage"
              label="Tips Percentage"
              register={register}
              required
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputCheckbox
              name="paymentSettings.tips.included"
              label="Include in Total Cost"
              control={control}
              classes={classes.gutterTopLabel}
            />
            <InputCheckbox
              name="paymentSettings.tips.showAtCheckout"
              label="Show at checkout"
              control={control}
              classes={classes.gutterTopLabel}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Cost rules - Drink Builder
        </Typography>
        <Typography
          variant="subtitle1"
          component="h5"
          className={clsx([classes.upperTitle, classes.gutterTopLabel])}
          gutterBottom
        >
          Alcoholic
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputCheckbox
              name="paymentSettings.drinkBuilder.alcoholic.fixed"
              label="Drink cost is fixed"
              control={control}
              classes={classes.gutterTopLabel}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputNumber
              name="paymentSettings.drinkBuilder.alcoholic.fixedCost"
              label="Alcoholic Cost"
              register={register}
              fullWidth
            />
          </Grid>
        </Grid>
        <FieldArrayRounding
          name="paymentSettings.drinkBuilder.alcoholic.rounding"
          control={control}
          inputRef={register}
          classes={classes}
        />
        <Typography
          variant="subtitle1"
          component="h5"
          className={clsx([classes.upperTitle, classes.gutterTopLabel])}
        >
          Non-Alcoholic
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputCheckbox
              name="paymentSettings.drinkBuilder.nonAlcoholic.fixed"
              label="Drink cost is fixed"
              control={control}
              classes={classes.gutterTopLabel}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputNumber
              name="paymentSettings.drinkBuilder.nonAlcoholic.fixedCost"
              label="Alcoholic Cost"
              register={register}
              fullWidth
            />
          </Grid>
        </Grid>
        <FieldArrayRounding
          name="paymentSettings.drinkBuilder.nonAlcoholic.rounding"
          control={control}
          inputRef={register}
          classes={classes}
        />
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Payment Methods
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputCheckbox
              name="paymentSettings.voucherEnabled"
              label="Voucher Enabled"
              control={control}
              classes={classes.gutterTopLabel}
            />
          </Grid>
          {/* <Grid item sm={6} xs={12}>
            <Typography
              variant="subtitle1"
              component="h5"
              className={clsx([classes.upperTitle, classes.gutterTopLabel])}
              gutterBottom
            >
              Kiosk App
            </Typography>

            <FieldCheckbox
              name="paymentSettings.allowedPayment.venueApp"
              control={control}
            />
          </Grid> */}
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Sumup Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <InputText
              name="paymentSettings.sumUpAffiliateKey"
              label="Affiliate Key"
              register={register}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Vantiv Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.vantivGateway.merchantId"
              label="MerchantId"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.vantivGateway.password"
              label="Password"
              type="password"
              register={register}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Shift4 Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                {(watch("paymentSettings.shift4Gateway.accessToken") != null &&
                  watch("paymentSettings.shift4Gateway.accessToken") !== "" && (
                    <InputText
                      name="paymentSettings.shift4Gateway.accessToken"
                      label="Access Token"
                      register={register}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  )) || (
                  <InputText
                    name="paymentSettings.shift4Gateway.authToken"
                    label="Auth Token"
                    register={register}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={2}>
                {(watch("paymentSettings.shift4Gateway.accessToken") == null ||
                  watch("paymentSettings.shift4Gateway.accessToken") ===
                    "") && (
                  <Shift4LoginButton
                    setValue={(value) =>
                      setValue(
                        "paymentSettings.shift4Gateway.accessToken",
                        value
                      )
                    }
                    value={watch("paymentSettings.shift4Gateway.authToken")}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.shift4Gateway.utgBaseUrl"
              label="UTG Base Url"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.shift4Gateway.utgFingerprint"
              label="UTG Fingerprint"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.shift4Gateway.utgBaseUrl"
              label="UTG Base Url"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.shift4Gateway.destinationPostalCode"
              label="Destination Postal Code"
              register={register}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Satispay Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.satispaySettings.publicKey"
              label="Public Key"
              register={register}
              fullWidth
              multiline
              InputProps={{
                readOnly:
                  watch("paymentSettings.satispaySettings.keyId") == null ||
                  watch("paymentSettings.satispaySettings.keyId") === ""
                    ? false
                    : true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.satispaySettings.privateKey"
              label="Private Key"
              register={register}
              fullWidth
              multiline
              InputProps={{
                readOnly:
                  watch("paymentSettings.satispaySettings.keyId") == null ||
                  watch("paymentSettings.satispaySettings.keyId") === ""
                    ? false
                    : true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.satispaySettings.token"
              label="Satispay token"
              register={register}
              type="password"
              fullWidth
              InputProps={{
                readOnly:
                  watch("paymentSettings.satispaySettings.keyId") == null ||
                  watch("paymentSettings.satispaySettings.keyId") === ""
                    ? false
                    : true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {(watch("paymentSettings.satispaySettings.keyId") == null ||
              watch("paymentSettings.satispaySettings.keyId") === "") && (
              <SatispayLoginButton
                setKeyId={(keyId) => {
                  setValue("paymentSettings.satispaySettings.keyId", keyId);
                }}
                publicKey={watch("paymentSettings.satispaySettings.publicKey")}
                privateKey={watch(
                  "paymentSettings.satispaySettings.privateKey"
                )}
                token={watch("paymentSettings.satispaySettings.token")}
              />
            )}
          </Grid>
          <Grid item sm={12}>
            <InputText
              name="paymentSettings.satispaySettings.keyId"
              label="Key Id"
              register={register}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Adyen Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={4} xs={12}>
            <InputText
              name="paymentSettings.adyenGateway.apiKey"
              label="Api Key"
              type="password"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputText
              name="paymentSettings.adyenGateway.clientKey"
              label="Client Key"
              type="password"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <InputText
              name="paymentSettings.adyenGateway.publicKey"
              label="Public Key"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputTextMultiBadge
              name="paymentSettings.adyenGateway.pointsOfSale"
              label="POS Ids available"
              control={control}
              fullWidth
            />
            <InputText
              name="paymentSettings.adyenGateway.merchantAccountPOS"
              label="Merchant Account - POS"
              register={register}
              fullWidth
            />
            <InputText
              name="paymentSettings.adyenGateway.merchantAccountECOM"
              label="Merchant Account - App"
              register={register}
              fullWidth
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <InputText
              name="paymentSettings.adyenGateway.countryCode"
              label="Country Code"
              helperText="Two letters"
              register={register}
              fullWidth
            />
            <InputText
              name="paymentSettings.adyenGateway.locale"
              label="Locale"
              helperText="example: en_US"
              register={register}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ComponentPayment;
