import React, { FunctionComponent, MouseEvent, useState } from "react";
import { Row } from "react-table";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import Button from "../../components/layout/Button";
import Dialog from "../../components/dialog/Dialog";
import { AppOrder } from "../../services/types/appOrder";
import RefundAppOrder from "./RefundAppOrder";

export const TableActions: FunctionComponent<{ row: Row<AppOrder> }> = ({
  row,
  ...rest
}) => {
  const [deleteDialog, setDeleteDialog] = useState<{
    appOrder: AppOrder | null;
    open: boolean;
  }>({ appOrder: null, open: false });


  return (
    <>
      {(row.original.paymentMethod === 'shift4' && row.original.status === 'paid') && <Button
        variant="outlined"
        buttonColor="error"
        endIcon={<MoneyIcon />}
        onClick={(event: MouseEvent) => {
          event.stopPropagation();
          setDeleteDialog({
            appOrder: row.original,
            open: true,
          });
        }}
      >
        Void
      </Button>
      }
      <Dialog
        title="Are you sure you want to void this transaction?"
        open={deleteDialog.open}
        onAbort={(evt) => {
          (evt as MouseEvent).stopPropagation();
          setDeleteDialog({
            ...deleteDialog,
            open: false,
          });
        }
        }
      >
        <RefundAppOrder
          appOrder={deleteDialog.appOrder as AppOrder}
          onSubmit={(evt: MouseEvent) => {
            setDeleteDialog({
              appOrder: null,
              open: false,
            });
          }
          }
        />
      </Dialog>
    </>
  );
};
