import React, { FunctionComponent, useEffect } from "react";
import { Bar } from "../../../services/types/bar";
import Grid from "@material-ui/core/Grid";
import GranularCard from "./GranularCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDispenserGranulars,
  selectDispenserGranulars,
} from "./dispenserGranularSlice";

interface GranularDispenserProps {
  bar: Bar | null;
}

const GranularDispenserList: FunctionComponent<GranularDispenserProps> = ({
  bar,
}) => {
  const select = useSelector(selectDispenserGranulars);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bar) {
      dispatch(getAllDispenserGranulars(bar));
    }
  }, [dispatch, bar]);

  return (
    <>
      <Grid container spacing={2}>
        {select.map((el, i) => (
          <Grid item xs={6} key={i}>
            <GranularCard bar={bar} position={i} dispenser={el} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default GranularDispenserList;
