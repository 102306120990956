import axios, { AxiosResponse } from "axios";
import { User } from "./types/user";
import { PaginationResponse } from "./types/pagination";
import { getAccessTokenRequestForAxiosOptions, transformID, transformIDElement } from "./utils";

/**
 * Is it right to change the _id in id here? 
 * Don't know, but works neat now.
 * 
 * @param payload 
 * @param accessToken 
 */
const getAllUsers = async (payload: {} | undefined, accessToken: string) : Promise<PaginationResponse<User>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>>  = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uuser/members/`,
        optionDecorator({ params: payload }),
    );
    const body = response.data;
    body.data = transformID(body.data);
    return body;
};

/**
 * 
 * @param payload User
 * @param accessToken 
 */
const createUser = async (payload: User, accessToken: string) : Promise<User> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<User> = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uuser/members/create`,
        payload,
        optionDecorator(),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload User
 * @param accessToken 
 */
const getUserById = async (payload: { id: string}, accessToken: string) : Promise<User> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<User> = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uuser/members/${payload.id}`,
        optionDecorator(),
    );
    return transformIDElement(response.data);
};

/**
 * 
 * @param payload User
 * @param accessToken 
 */
const updateUser = async (
    payload: any | undefined,
    accessToken: string
  ): Promise<PaginationResponse<User>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
      `${process.env.REACT_APP_AUTH_BASE_URL}/uuser/members/${payload.id}`,
      payload,
      optionDecorator()
    );
    return transformIDElement(response.data);
  };

const api = {
    getAllUsers,
    createUser,
    getUserById,
    updateUser,
};
export default api;
