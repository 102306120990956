import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Bottle } from "../../../services/types/bottle";
import { Bar } from "../../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface BottleState {
  data: Bottle[];
  map: MapLike<Bottle>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: BottleState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllBottles = createAction<
  PrepareAction<PaginationPayloadByBar>
>("bottles/getAllBottles", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar.id,
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllBottles",
  },
}));

export const getAllBottlesSuccess = createAction<PaginationResponse<Bottle>>(
  "bottles/getAllBottles_SUCCESS"
);
export const getBottleById = createAction<PrepareAction<{ id: string }>>(
  "bottles/getBottleById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getBottleById",
      onFailure,
    },
  })
);
export const getBottleByIdSuccess = createAction<Bottle>(
  "bottles/getBottleById_SUCCESS"
);
export const updateBottle = createAction<PrepareAction<Bottle>>(
  "bottles/updateBottles",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateBottle",
      onSuccess,
      onFailure,
    },
  })
);
export const updateBottleSuccess = createAction<Bottle>(
  "bottles/updateBottle_SUCCESS"
);
export const findGlobalBottleByName = createAction<
  PrepareAction<{ name: string }>
>("bottles/findGlobalBottleByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalBottleByName",
    onSuccess,
    onFailure,
  },
}));
export const createBottle = createAction<PrepareAction<Bottle>>(
  "bottles/createBottles",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createBottle",
      onSuccess,
      onFailure,
    },
  })
);
export const createBottleSuccess = createAction<Bottle>(
  "bottles/createBottle_SUCCESS"
);

// REDUCERS
const bottlesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllBottlesSuccess,
      (state: BottleState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Bottle) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getBottleByIdSuccess, (state: BottleState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(updateBottleSuccess, (state: BottleState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBottles = (state: RootState) =>
  state.ingredients.bottle.data;
export const selectBottlesCount = (state: RootState) =>
  state.ingredients.bottle.count;
export const selectBottleById = (id: string) => (state: RootState) =>
  state.ingredients.bottle.map[id];

export default bottlesReducer;
