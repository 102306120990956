import React, { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Button, Popover } from '@material-ui/core';

import { ArrowDropDownCircleTwoTone as ArrowDropDownCircleTwoToneIcon } from '@material-ui/icons';

import { impersonateBar, selectAvailableBars, selectCurrentInstanceBar } from './instanceBarSlice';
import AvailableBarList from "./AvaliableBarList";
import { Bar } from '../../services/types/bar';

export const SelectedBar: FunctionComponent = () => {
  const bar = useSelector(selectCurrentInstanceBar);
  const availableBars = useSelector(selectAvailableBars);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBarSelect = (bar: Bar) => {
    dispatch(impersonateBar(bar));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="text" color="inherit" size="large" endIcon={<ArrowDropDownCircleTwoToneIcon />} onClick={handleClick}>
        {(bar) ? (
          <span>{bar.name}</span>
        ) : <span>Select a bar</span>}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <AvailableBarList bars={availableBars} onClick={handleBarSelect}/>
      </Popover>
    </div>
  );
};


