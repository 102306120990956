import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Granular } from "../../../services/types/granular";
import { MapLike } from "typescript";

// STATE INTERFACE
interface GranularState {
  data: Granular[];
  map: MapLike<Granular>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: GranularState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalGranulars = createAction<
  PrepareAction<PaginationPayload>
>(
  "granulars/getAllGlobalGranulars",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getAllGlobalGranulars",
    },
  })
);

export const getAllGlobalGranularsSuccess = createAction<
  PaginationResponse<Granular>
>("granulars/getAllGlobalGranulars_SUCCESS");

export const getGlobalGranularById = createAction<
  PrepareAction<{ id: string }>
>("granulars/getGlobalGranularById", ({ id, onFailure }) => ({
  payload: {
    id,
  },
  meta: {
    fetch: "globalIngredients",
    resource: "getGlobalGranularById",
    onFailure,
  },
}));
export const getGlobalGranularByIdSuccess = createAction<Granular>(
  "granulars/getGlobalGranularById_SUCCESS"
);

export const updateGlobalGranular = createAction<PrepareAction<Granular>>(
  "granulars/updateGlobalGranulars",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalGranular",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalGranularSuccess = createAction<Granular>(
  "granulars/updateGlobalGranular_SUCCESS"
); /* 
export const findGlobalGranularByName = createAction<
  PrepareAction<{ name: string }>
>("granulars/findGlobalGranularByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalGranularByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalGranular = createAction<PrepareAction<Granular>>(
  "granulars/createGlobalGranulars",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalGranular",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalGranularSuccess = createAction<Granular>(
  "granulars/createGlobalGranular_SUCCESS"
);

// REDUCERS
const globalGranularsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalGranularsSuccess,
      (state: GranularState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Granular) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGlobalGranularByIdSuccess,
      (state: GranularState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      }
    )
    .addCase(
      updateGlobalGranularSuccess,
      (state: GranularState, { payload }) => {
        state.map[payload.id] = payload;
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalGranulars = (state: RootState) =>
  state.globalIngredients.granular.data;
export const selectGlobalGranularsCount = (state: RootState) =>
  state.globalIngredients.granular.count;
export const selectGlobalGranularById = (id: string) => (state: RootState) =>
  state.globalIngredients.granular.map[id];

export default globalGranularsReducer;
