import { createAction, createReducer, PrepareAction } from "@reduxjs/toolkit";
import { MapLike } from "typescript";
import _ from "lodash";

import { Bar } from "../../services/types/bar";
import { Drink } from "../../services/types/drink";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../services/types/pagination";
import { RootState } from "../../store";

interface QueueState {
  data: Drink[];
  map: MapLike<Drink>;
  count: number;
}

const initialState: QueueState = {
  data: [],
  map: {},
  count: 0,
};

//ACTIONS
export const getAllActive = createAction<PrepareAction<PaginationPayloadByBar>>(
  "queue/getAllActive",
  (bar: Bar) => ({
    payload: {
      barId: bar.id,
    },
    meta: {
      fetch: "drinks",
      resource: "getAllActive",
    },
  })
);

export const getAllActiveSuccess = createAction<PaginationResponse<Drink>>(
  "queue/getAllActive_SUCCESS"
);
export const orderInQueueUpdatedRemotely = createAction<Drink>(
  "queue/orderInQueueUpdatedRemotely"
);

export const updateDrinkPriority = createAction<PrepareAction<Drink>>(
  "queue/updateDrinkPriority",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "drinks",
      resource: "updateDrinkPriority",
      onSuccess,
      onFailure,
    },
  })
);
export const updateDrinkPrioritySuccess = createAction<Drink>(
  "queue/updateDrinkPriority_SUCCESS"
);

export const deleteDrink = createAction<PrepareAction<Drink>>(
  "queue/deleteDrink",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "drinks",
      resource: "deleteDrink",
      onSuccess,
      onFailure,
    },
  })
);
export const deleteDrinkSuccess = createAction<Drink>(
  "queue/deleteDrink_SUCCESS"
);


export const unlockConveyorBar = createAction(
  'activity/unlockConveyorBar',
  (number, bar: Bar, onSuccess, onFailure) => ({
    payload: { barId: bar.id, command: 'unlockConveyorBar', number },
    meta: {
      fetch: 'bar',
      resource: 'unlockConveyorBar',
      onSuccess,
      onFailure,
    },
  })
);


//REDUCERS
const queueReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    getAllActiveSuccess,
    (state: QueueState, { payload: { data, count } }) => {
      state.data = data;
      state.map = _.keyBy(data, (el: Drink) => el.id);
      state.count = count;
    }
  )
  .addCase(
    updateDrinkPrioritySuccess,
    (state: QueueState, { payload }) => {
      state.data = state.data.map(el => {
        if (payload.id === el.id) {
          return payload;
        }
        return el;
      });
      state.map[payload.id] = payload;
    }
  )
  .addCase(
    deleteDrinkSuccess,
    (state: QueueState, { payload }) => {
      state.data = state.data.filter(el => payload.id !== el.id);
      delete state.map[payload.id];
    }
  )
  .addCase(
    orderInQueueUpdatedRemotely,
    (state: QueueState, { payload }) => {
      if (payload.status === 'open') {
        state.data.push(payload);
      } if (payload.status === 'delivered') {
        const index = state.data.findIndex(el => el.id === payload.id);
        state.data.splice(index, 1);
      } else {
        state.data = state.data.map(el => {
          if (payload.id === el.id) {
            return payload;
          }
          return el;
        });
      }
    }
  );
});

//SELECTOR
export const selectQueued = (state: RootState) => state.order.queue.data;

export default queueReducer;
