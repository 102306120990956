import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Beverage } from "../../../services/types/beverage";
import { MapLike } from "typescript";

// STATE INTERFACE
interface BeverageState {
  data: Beverage[];
  map: MapLike<Beverage | undefined>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: BeverageState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalBeverages = createAction<
  PrepareAction<PaginationPayload>
>(
  "beverages/getAllGlobalBeverages",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getAllGlobalBeverages",
    },
  })
);

export const getAllGlobalBeveragesSuccess = createAction<
  PaginationResponse<Beverage>
>("beverages/getAllGlobalBeverages_SUCCESS");

export const getGlobalBeverageById = createAction<
  PrepareAction<{ id: string }>
>("beverages/getGlobalBeverageById", ({ id, onFailure }) => ({
  payload: {
    id,
  },
  meta: {
    fetch: "globalIngredients",
    resource: "getGlobalBeverageById",
    onFailure,
  },
}));
export const getGlobalBeverageByIdSuccess = createAction<Beverage>(
  "beverages/getGlobalBeverageById_SUCCESS"
);

export const updateGlobalBeverage = createAction<PrepareAction<Beverage>>(
  "beverages/updateGlobalBeverages",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalBeverage",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalBeverageSuccess = createAction<Beverage>(
  "beverages/updateGlobalBeverage_SUCCESS"
); /* 
export const findGlobalBeverageByName = createAction<
  PrepareAction<{ name: string }>
>("beverages/findGlobalBeverageByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalBeverageByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalBeverage = createAction<PrepareAction<Beverage>>(
  "beverages/createGlobalBeverages",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalBeverage",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalBeverageSuccess = createAction<Beverage>(
  "beverages/createGlobalBeverage_SUCCESS"
);

// REDUCERS
const globalBeveragesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalBeveragesSuccess,
      (state: BeverageState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Beverage) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGlobalBeverageByIdSuccess,
      (state: BeverageState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      }
    )
    .addCase(
      updateGlobalBeverageSuccess,
      (state: BeverageState, { payload }) => {
        state.map[payload.id] = payload;
      }
    );
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalBeverages = (state: RootState) =>
  state.globalIngredients.beverage.data;
export const selectGlobalBeveragesCount = (state: RootState) =>
  state.globalIngredients.beverage.count;
export const selectGlobalBeverageById = (id: string) => (state: RootState) =>
  state.globalIngredients.beverage.map[id];

export default globalBeveragesReducer;
