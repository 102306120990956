import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider } from '@material-ui/core'
import {
  LocalDrink as LocalDrinkIcon,
  Equalizer as EqualizerIcon,
  Gamepad as GamepadIcon,
  Tune as TuneIcon,
  LocalBar as LocalBarIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Build as BuildIcon,
  Storefront as StorefrontIcon,
  AttachMoney as AttachMoneyIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Home as HomeIcon,
  ImportExport as ImportExportIcon,
} from "@material-ui/icons";
import { useHasPermission } from '../auth/hooks';
import { selectCurrentInstanceBar } from '../instanceBar/instanceBarSlice';

const SidebarMenu = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>();
  const hasSuperadminRole = useHasPermission("SUPERADMIN");
  const bar = useSelector(selectCurrentInstanceBar);
  return (
    <>
      { hasSuperadminRole && (
        <>
          <List>
            <ListItem button onClick={() => setMenuOpen(!menuOpen)}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText primary="Operations" />
              {menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={menuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  component={NavLink}
                  to="/user-management"
                  exact
                  activeClassName="Mui-selected"
                >
                  <ListItemIcon />
                  <ListItemText primary="Users" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to="/bars"
                  exact
                  activeClassName="Mui-selected"
                >
                  <ListItemIcon />
                  <ListItemText primary="Bars" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to="/robots"
                  exact
                  activeClassName="Mui-selected"
                >
                  <ListItemIcon />
                  <ListItemText primary="Robots" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to="/global-ingredients/bottles"
                  exact
                  activeClassName="Mui-selected"
                >
                  <ListItemIcon />
                  <ListItemText primary="Global Ingredients" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
        </>
      ) }
      { bar && (
        <>
          <List>
            <ListItem
              button
              component={NavLink}
              to="/mybar"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"MyBar"} />
            </ListItem>
            { bar?.paymentSettings?.voucherEnabled && (
              <ListItem
                button
                component={NavLink}
                to="/vouchers"
                exact
                activeClassName="Mui-selected"
              >
                <ListItemIcon>
                  <ConfirmationNumberIcon />
                </ListItemIcon>
                <ListItemText primary={"Vouchers"} />
              </ListItem>
            )}
            <ListItem
              button
              component={NavLink}
              to="/app-orders"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary={"App Orders"} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText primary={"Activity"} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/drink-queue"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <LocalDrinkIcon />
              </ListItemIcon>
              <ListItemText primary={"Drink Queue"} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/production/delivered"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary={"Production"} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              component={NavLink}
              to="/ingredients/bottles"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary={"Ingredients"} />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/dispensers/bottles"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <GamepadIcon />
              </ListItemIcon>
              <ListItemText primary={"Positions"} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              component={NavLink}
              to="/drinks"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <LocalBarIcon />
              </ListItemIcon>
              <ListItemText primary={"Menu"} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              component={NavLink}
              to="/export-requests"
              exact
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <ImportExportIcon />
              </ListItemIcon>
              <ListItemText primary={"Exports"} />
            </ListItem>
          </List>
        </>
      )}
    </>
  );
}

export default SidebarMenu
