import React, { FunctionComponent, useState } from "react";
import Box from "@material-ui/core/Box";
import PageTitle from "../../components/layout/PageTitle";

import Dialog from "../../components/dialog/Dialog";
import BarTable from "./BarTable";
import { useHistory } from "react-router-dom";
import { Bar } from "../../services/types/bar";
import FloatingActionButton from "../../components/layout/FloatingActionButton";
import TheNewCreateBarForm from "./TheNewCreateBarForm";

const BarListScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <Box>
      <PageTitle pageTitle={"Bars"} breadcrumb={["Operations", "Bars"]} />

      <BarTable
        onBarSelected={(bar: Bar) => {
          history.push(`/bars/${bar.id}`);
          // setOpen(false);
        }}
      />
      <FloatingActionButton onClick={() => setOpen(true)} />
      <Dialog title="Create New Bar" open={open} onAbort={() => setOpen(false)}>
        <TheNewCreateBarForm
          onCreateSuccess={(bar: Bar) => {
            history.push(`/bars/${bar.id}`);
            // setOpen(false);
          }}
        ></TheNewCreateBarForm>
      </Dialog>
    </Box>
  );
};

export default BarListScreen;
