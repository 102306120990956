import React, { FunctionComponent } from "react";
import { LinearProgress, MenuItem } from "@material-ui/core";
import { getAllGlobalCoffeeType } from "../globalIngredientScreen/coffee-type/globalCoffeeTypeSlice";
import { useDispatch } from "react-redux";
import InputSelect from "../../components/form/InputSelect";
import { CoffeeType } from "../../services/types/coffeeType";
import { PaginationResponse } from "../../services/types/pagination";

interface SelectProps {
  name: string;
  label: string;
  control: any;
  defaultValue?: any;
}

const CoffeeTypeSelect: FunctionComponent<SelectProps> = ({
  label,
  defaultValue,
  name,
  control,
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = React.useState<CoffeeType[]>([]); // type me please
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    dispatch(
      getAllGlobalCoffeeType(
        { page: 0, perPage: 10000 },
        (result: { payload: PaginationResponse<CoffeeType> }) => {
          setOptions(result.payload.data);
          setLoading(false);
        },
        (e: any) => {
          console.error(e);
          setLoading(false);
        }
      )
    );
  }, [dispatch]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <InputSelect
        name={name}
        label={"Coffee Recipe Group"}
        control={control}
        defaultValue={defaultValue || ''}
        fullWidth
        required
      >
        <MenuItem key={"0"}>
          No Coffee Group
        </MenuItem>
        {options.map(({ name }) => {
          return (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          );
        })}
      </InputSelect>
    </>
  );
};

export default CoffeeTypeSelect;
