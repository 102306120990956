import { Button, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import MainSpacer from "../../components/layout/MainSpacer";
import { Bar } from "../../services/types/bar";
import { Recipe } from "../../services/types/recipe";
import { showNotification } from "../notification/notificationSlice";
import { deleteRecipe } from "./recipeSlice";

interface DeleteRecipeDialogProps {
  recipe: Recipe;
  bar: Bar | null;
  onSubmit: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

const DeleteRecipeDialog: FunctionComponent<DeleteRecipeDialogProps> = ({
  recipe,
  bar,
  onSubmit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDelete = (id: string, bar: Bar | null) => {
    dispatch(deleteRecipe(id, bar, ({ payload }: any) => {
        dispatch(showNotification("Recipe deleted!", "success"));
        onSubmit();
      },
      (e: any) => {
        console.error(e);
        dispatch(showNotification("Error in Recipe delete!", "error"));
      })
    );
  };

  return (
    <>
      <Typography variant="h5" component="h2">
        Are you sure you want to delete {recipe?.name}
      </Typography>
      <MainSpacer />
      <div className={classes.navigation}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubmit}
        >
          NO
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDelete(recipe.id, bar)}
          className={classes.button}
        >
          DELETE
        </Button>
      </div>
    </>
  );
};

export default DeleteRecipeDialog;
