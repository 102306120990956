import React, { FunctionComponent, useState } from "react";

import {
  Paper,
  Box,
  Typography,
  Grid,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bottle } from "../../../services/types/bottle";
import { showNotification } from "../../notification/notificationSlice";
import { updateGlobalBottle } from "./globalBottleSlice";
import { bottleCategories } from "../../constants";
import InputColor from "../../../components/form/InputColor";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";

interface EditBottleFormProps {
  bottle: Bottle;
}

const EditGlobalBottleForm: FunctionComponent<EditBottleFormProps> = ({
  bottle,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: bottle,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Bottle) => {
    setDisabled(true);
    bottle = {
      ...bottle,
      name: data.name,
      category: data.category,
      alcoholPerc: data.alcoholPerc || 0,
      color: data.color,
    };
    console.log(bottle);
    dispatch(
      updateGlobalBottle(
        bottle,
        () => {
          dispatch(showNotification("Bottle Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!bottle) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Global Bottle
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                control={control}
                required
                fullWidth
              >
                {bottleCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
              <InputNumber
                name="alcoholPerc"
                label="Alcoholic Percentage"
                register={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditGlobalBottleForm;
