import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper } from "@material-ui/core";

import {
  getAllAppOrders,
  getAllAppOrdersRaw,
  selectAppOrders,
  selectAppOrdersCount,
} from "./appOrderSlice";
import { useLoading } from "../loading/hooks";
import { AppOrder } from "../../services/types/appOrder";
import { Column } from "react-table";
import EnhancedTable, {
  FetchDataArgs,
} from "../../components/table/EnhancedTable";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import DateTimeCell from "../../components/table/cells/DateTimeCell";
import SelectFilter, {
  withOptions,
} from "../../components/table/filters/SelectFilter";
import NumberFilter from "../../components/table/filters/NumberFilter";

import TableHeaderExport from "../../components/table/TableHeaderExport";
import MainSpacer from "../../components/layout/MainSpacer";
import { AxiosResponse } from "axios";
import { createExportRequest } from "../exportRequestScreen/exportRequestSlice";
import { useHistory } from "react-router";
import { showNotification } from "../notification/notificationSlice";
import { TableActions } from "./TableActions";
const sourceOption = ["Admin", "Kiosk", "App"];

const statusOption = ["open", "paid", "error"];

const columns: Column<any>[] = [
  { Header: "Nickname", accessor: "nickname" },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: DateTimeCell,
    disableFilters: true,
  },
  {
    Header: "Source",
    accessor: "type",
    Filter: withOptions(sourceOption, SelectFilter),
  },
  { Header: "Payment Method", accessor: "paymentMethod" },
  { Header: "Price", accessor: "price", Filter: NumberFilter },
  {
    Header: "Status",
    accessor: "status",
    Filter: withOptions(statusOption, SelectFilter),
  },
  {
      Header: "Actions",
      Cell: TableActions,
      disableFilters: true,
      disableSortBy: true,
  },
];



interface AppOrderTableProps {
  onAppOrderSelected: (beverage: AppOrder) => any;
}

const AppOrderTable: FunctionComponent<AppOrderTableProps> = ({
  onAppOrderSelected,
}) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const AppOrders = useSelector(selectAppOrders);
  const totalCount = useSelector(selectAppOrdersCount);
  const bar = useSelector(selectCurrentInstanceBar);
  const history = useHistory();

    const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      // console.log({filters, selectedDateFrom, selectedDateTo});
      dispatch(
        getAllAppOrders(
          { page: pageIndex, perPage: pageSize, sort: sortBy, search: filters},
          bar
        )
      );
    },
    [bar?.id, dispatch]
  );

  const handleFetchDataForExport = useCallback(
      (filters, exportType) => {
        dispatch(
            createExportRequest(
                bar?.id,
                'AppOrder',
                JSON.stringify(filters),
                exportType,
                (payload: any) => {
                  dispatch(showNotification("Export created! It will be available shortly", "success"));
                  history.push("/export-requests");
                },
                (payload: any) => {
                  dispatch(showNotification("Export creation error!", "error"));
                }
            )
        );
      },
      [bar?.id, dispatch, history]
  );

  const handleRowClick = useCallback(
    (AppOrder: AppOrder) => {
      onAppOrderSelected(AppOrder);
    },
    [onAppOrderSelected]
  );

  const headers = [
    {label: "Id", key: "id"},
    {label: "Nickname", key: "nickname"},
    {label: "Type", key: "type"},
    {label: "Payment Method", key: "paymentMethod"},
    {label: "Status", key: "status"},
    {label: "Price", key: "price"},
    {label: "Created At", key: "createdAt"},
  ];

  return (
    <Paper>
      <TableHeaderExport headers={headers} onExport={handleFetchDataForExport}/>
      <MainSpacer/>
      <Box p={3}>
        <EnhancedTable
          columns={columns}
          data={AppOrders}
          totalCount={totalCount}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          fetchData={handleFetchData}
        />
      </Box>
    </Paper>
  );
};

export default AppOrderTable;
