import React, { FunctionComponent, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Grid, LinearProgress, Paper } from "@material-ui/core";
import PageTitle from "../../components/layout/PageTitle";
import PieChartComponent from "./PieChartComponent";
import SquareCounter from "./SquareCounter";
import AreaChartComponent from "./AreaChartComponent";
import TopDrinks from "./TopDrinks";
import BarChartComponent from "./BarChartComponent";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { getTodayOrders, selectTodayOrders } from "./myBarSlice";
import { Bar } from "../../services/types/bar";
import { useLoading } from "../loading/hooks";
import { usePriceFormatter } from "../instanceBar/hooks";

const MyBarScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);
  const data = useSelector(selectTodayOrders);
  const formatPrice = usePriceFormatter();

  useEffect(() => {
    const refreshData = (myBar: Bar | null) => {
      if (myBar) {
        dispatch(getTodayOrders(myBar));
      }
    };
    refreshData(bar);
  }, [bar, dispatch]);

  return (
    <Box>
      <PageTitle
        pageTitle={"Dashboard"}
        breadcrumb={[bar?.name || "My Bar", "Dashboard"]}
      />

      <Paper>
        {isLoading && <LinearProgress />}
        <Grid container spacing={3}>
          <Grid item md={8}>
            <Grid container>
              <Grid item md={6}>
                <SquareCounter
                  title={"Daily Orders"}
                  data={data?.totalOrders}
                />
              </Grid>
              <Grid item md={6}>
                <SquareCounter
                  title={"Daily Income"}
                  data={formatPrice(data?.totalAmount || 0)}
                />
              </Grid>
            </Grid>
            <AreaChartComponent data={data.sales} />
          </Grid>
          <Grid item md={4}>
            <TopDrinks data={data.drinks} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={5}>
            <PieChartComponent data={data.categories} />
          </Grid>
          <Grid item md={7}>
            <BarChartComponent data={data.ingredients} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default MyBarScreen;
