import React, { FunctionComponent } from 'react';

import Box from "@material-ui/core/Box";
import PageTitle from "../../components/layout/PageTitle";

import { useSelector } from 'react-redux';
import { selectCurrentInstanceBar } from '../instanceBar/instanceBarSlice';
import AppOrderTable from './AppOrderTable';
import { useHistory } from 'react-router-dom';
import { AppOrder } from '../../services/types/appOrder';


const AppOrderListScreen: FunctionComponent = () => {
  const bar = useSelector(selectCurrentInstanceBar);
  const history = useHistory();
  return (
    <Box>
      <PageTitle
        pageTitle={"App Orders"}
        breadcrumb={[bar?.name || "Bar", "App Orders"]}
      />

      
      <AppOrderTable onAppOrderSelected={(appOrder: AppOrder) => {
        history.push(`/app-orders/${appOrder.id}`);
      }} />
    </Box>
  );
}
export default AppOrderListScreen
