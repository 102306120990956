import { createAction, PrepareAction } from '@reduxjs/toolkit';
import { Bar } from '../../services/types/bar';
import { Robot } from '../../services/types/robot';

// STATE INTERFACE
// INITIAL STATE
// ACTIONS
export const startRobot = createAction<PrepareAction<Robot>>(
  'activity/startRobot',
  (robot: Robot, onSuccess, onFailure) => ({
    payload: robot,
    meta: {
      fetch: 'robot',
      resource: 'startRobot',
      onSuccess,
      onFailure,
    },
  })
);
export const stopRobot = createAction<PrepareAction<Robot>>(
  'activity/stopRobot',
  (robot: Robot, onSuccess, onFailure) => ({
    payload: robot,
    meta: {
      fetch: 'robot',
      resource: 'stopRobot',
      onSuccess,
      onFailure,
    },
  })
);
export const abortRobot = createAction<PrepareAction<Robot>>(
  'activity/abortRobot',
  (robot: Robot, onSuccess, onFailure) => ({
    payload: robot,
    meta: {
      fetch: 'robot',
      resource: 'abortRobot',
      onSuccess,
      onFailure,
    },
  })
);
export const restoreFences = createAction(
  'activity/restoreFences',
  (bar: Bar, onSuccess, onFailure) => ({
    payload: { barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'restoreFences',
      onSuccess,
      onFailure,
    },
  })
);
export const restoreEmergency = createAction(
  'activity/restoreEmergency',
  (bar: Bar, onSuccess, onFailure) => ({
    payload: { barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'restoreEmergency',
      onSuccess,
      onFailure,
    },
  })
);
export const generalStart = createAction(
  'activity/generalStart',
  (bar: Bar, onSuccess, onFailure) => ({
    payload: { barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'generalStart',
      onSuccess,
      onFailure,
    },
  })
);
export const restoreCupError = createAction(
  'activity/restoreCupError',
  (bar: Bar, onSuccess, onFailure) => ({
    payload: { barId: bar.id },
    meta: {
      fetch: 'bar',
      resource: 'restoreCupError',
      onSuccess,
      onFailure,
    },
  })
);
export const restoreCupErrorSuccess = createAction<Bar>(
  'activity/restoreCupError_SUCCESS'
);

export const restartViz = createAction(
  'activity/restartViz',
  (bar: Bar, onSuccess, onFailure) => ({
    payload: { barId: bar.id, command: 'restart' },
    meta: {
      fetch: 'bar',
      resource: 'restartViz',
      onSuccess,
      onFailure,
    },
  })
);
export const moveConveyor = createAction(
  'activity/moveConveyor',
  (bar: Bar, number, position, onSuccess, onFailure) => ({
    payload: { barId: bar.id, command: 'moveConveyor', number, position },
    meta: {
      fetch: 'bar',
      resource: 'moveConveyor',
      onSuccess,
      onFailure,
    },
  })
);

// REDUCERS

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
