import React, { FunctionComponent } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import DialogTitle from "./DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    minWidth: "50%",
  },
  content: {
    padding: theme.spacing(2),
  },
  actions: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

interface DialogProps {
  title: string;
  open: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  onAbort: (event?: {}) => any;
  renderActions?: () => React.ReactElement;
}

const Dialog: FunctionComponent<DialogProps> = ({
  children,
  title,
  open,
  onAbort,
  renderActions,
  maxWidth,
}) => {
  const classes = useStyles();

  return (
    <MuiDialog
      onClose={onAbort}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick={true}
      PaperProps={{ className: classes.dialog }}
      maxWidth={maxWidth}
    >
      <DialogTitle id="customized-dialog-title" onClose={onAbort}>
        {title}
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {children}
      </DialogContent>
      {renderActions && (
        <DialogActions className={classes.actions}>
          {renderActions()}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
