import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Bottle } from "../../../services/types/bottle";
import { MapLike } from "typescript";

// STATE INTERFACE
interface BottleState {
  data: Bottle[];
  map: MapLike<Bottle>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: BottleState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalBottles = createAction<
  PrepareAction<PaginationPayload>
>(
  "bottles/getAllGlobalBottles",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getAllGlobalBottles",
    },
  })
);

export const getAllGlobalBottlesSuccess = createAction<
  PaginationResponse<Bottle>
>("bottles/getAllGlobalBottles_SUCCESS");

export const getGlobalBottleById = createAction<PrepareAction<{ id: string }>>(
  "bottles/getGlobalBottleById",
  ({ id, onFailure }) => ({
    payload: {
      id,
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getGlobalBottleById",
      onFailure,
    },
  })
);
export const getGlobalBottleByIdSuccess = createAction<Bottle>(
  "bottles/getGlobalBottleById_SUCCESS"
);

export const updateGlobalBottle = createAction<PrepareAction<Bottle>>(
  "bottles/updateGlobalBottles",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalBottle",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalBottleSuccess = createAction<Bottle>(
  "bottles/updateGlobalBottle_SUCCESS"
); /* 
export const findGlobalBottleByName = createAction<
  PrepareAction<{ name: string }>
>("bottles/findGlobalBottleByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalBottleByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalBottle = createAction<PrepareAction<Bottle>>(
  "bottles/createGlobalBottles",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalBottle",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalBottleSuccess = createAction<Bottle>(
  "bottles/createGlobalBottle_SUCCESS"
);

// REDUCERS
const globalBottlesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalBottlesSuccess,
      (state: BottleState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Bottle) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getGlobalBottleByIdSuccess, (state: BottleState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(updateGlobalBottleSuccess, (state: BottleState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalBottles = (state: RootState) =>
  state.globalIngredients.bottle.data;
export const selectGlobalBottlesCount = (state: RootState) =>
  state.globalIngredients.bottle.count;
export const selectGlobalBottleById = (id: string) => (state: RootState) =>
  state.globalIngredients.bottle.map[id];

export default globalBottlesReducer;
