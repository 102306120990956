import { Button, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, {MouseEvent} from "react";
import { FunctionComponent } from "react";
import MainSpacer from "../../components/layout/MainSpacer";

interface DeleteDialogProps {
  name: string;
  onConfirm: () => any;
  onSubmit: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

const DeleteDialog: FunctionComponent<DeleteDialogProps> = ({
  name,
  onConfirm,
  onSubmit,
}) => {
  const classes = useStyles();

  const handleConfirm = (e: MouseEvent) => {
    onConfirm();
    onSubmit();
  }

  return (
    <>
      <Typography variant="h5" component="h2">
        Are you sure you want to delete {name}
      </Typography>
      <MainSpacer />
      <div className={classes.navigation}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubmit}
        >
          NO
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          className={classes.button}
        >
          DELETE
        </Button>
      </div>
    </>
  );
};

export default DeleteDialog;
