import { Chip } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { CellProps } from 'react-table'
import { UserRole } from '../../services/types/user'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1) / 2,
  },
}));

interface BarNameChipCellProps  {
  cell: CellProps<any, UserRole[]>;
}

const BarNameChipCell: FunctionComponent<BarNameChipCellProps> = ({ cell }) => {
  const classes = useStyles();
  return (
    <div>
      {cell.value.map((role) => <Chip className={classes.chip} label={role.barName} />)}
    </div>
  )
}

export default BarNameChipCell;
