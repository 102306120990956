import React, { useState, FunctionComponent, Fragment } from "react";
import clsx from "clsx";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ErrorBoundary from "./ErrorBoundary";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "../dialog/Dialog";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    maxWidth: "100%",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -theme.drawerWidth,
  },
  contentUnauth: {
    flexGrow: 1,
  },
  contentShift: {
    maxWidth: `calc(100% - ${theme.drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "max-width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

type LayoutProps = {
  title: React.ReactNode;
  isAuthenticated: boolean;
  userAccountComponent: React.ReactNode;
  sidebarMenuComponent: React.ReactNode;
  barControlsComponent: React.ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = ({
  title,
  isAuthenticated,
  userAccountComponent,
  sidebarMenuComponent,
  barControlsComponent,
  children,
}) => {
  const classes = useStyles();
  const [sidebarOpenStatus, setSidebarOpenStatus] = useState<boolean>(true);

  const closeSidebar = () => setSidebarOpenStatus(false);
  const openSidebar = () => setSidebarOpenStatus(true);


  const [PreviewDialog, setPreviewDialog] = useState<{
    open: boolean;
  }>({ open: isAuthenticated });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        {isAuthenticated && (
          <Fragment>
            <Header
              handleMenuClick={openSidebar}
              sidebarOpenStatus={sidebarOpenStatus}
              title={title}
              barControlsComponent={barControlsComponent}
            />
            <Sidebar
              open={sidebarOpenStatus}
              userAccountComponent={userAccountComponent}
              sidebarMenuComponent={sidebarMenuComponent}
              handleClose={closeSidebar}
            />
          </Fragment>
        )}
        <main
          className={clsx({
            [classes.contentUnauth]: !isAuthenticated,
            [classes.content]: isAuthenticated,
            [classes.contentShift]: isAuthenticated && sidebarOpenStatus,
          })}
        >
          <div className={clsx({ [classes.toolbar]: isAuthenticated })} />
          <ErrorBoundary>{children}</ErrorBoundary>
          <Dialog
                title="Explore Our New Web Portal!"
                open={PreviewDialog.open}
                onAbort={(evt) => {
                (evt as MouseEvent).stopPropagation();
                setPreviewDialog({
                    open: false,
                });
                }}
                renderActions={() => {
                    return <>
                        <Button variant="text" color="default" onClick={() =>  setPreviewDialog({
                    open: false,
                })}>Stay on Current Version</Button>
                        <Button variant="contained" color="primary" href="https://preview.makrshakr.app">Explore now</Button>
                    </>
                }}
            >
                <Typography>
                We are excited to announce a brand-new version of our web console, designed with your needs in mind! Our team has been working hard to improve your experience, and we invite you to try out the new features and design.
                </Typography>
                <br></br>
                <Typography variant="h5">
                    Key Features:
                </Typography>
                <Typography>
                <ul>
                    <li>Enhanced User Interface</li>
                    <li>New Analitics Dashboard</li>
                    <li>Better exports management</li>
                    <li>Faster Performance</li>
                </ul>
                </Typography>

                <Typography variant="subtitle2"><Button variant="text" color="primary" size="large" href="https://preview.makrshakr.app">👉 Try the New Web Portal</Button></Typography>
                <Typography variant="h6">
                    Support Information:
                </Typography>
                <Typography>
                If you encounter any issues or have feedback, please reach out to us at <a href="mailto:support@makrshakr.com" target="_blank">support@makrshakr.com</a>.
                </Typography>
                
                
            </Dialog>
        </main>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default Layout;
