import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import { Flavour } from "../../../services/types/flavour";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import {
  getAllFlavours,
  selectFlavours,
  selectFlavourCount,
} from "./flavourSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  { Header: "Category", accessor: "category" },
];

interface FlavourTableProps {
  onFlavourSelected: (flavour: Flavour) => any;
}

const FlavourTable: FunctionComponent<FlavourTableProps> = ({
  onFlavourSelected,
}) => {
  const dispatch = useDispatch();
  const flavours = useSelector(selectFlavours);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectFlavourCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllFlavours(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (flavour: Flavour) => {
      onFlavourSelected(flavour);
    },
    [onFlavourSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={flavours}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default FlavourTable;
