import React, { FunctionComponent, useState } from "react";

import {
  Paper,
  Box,
  Typography,
  Grid,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { Beverage } from "../../../services/types/beverage";
import { showNotification } from "../../notification/notificationSlice";
import { useFieldArray, useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputColor from "../../../components/form/InputColor";
import InputSelect from "../../../components/form/InputSelect";
import {
  beverageCategories,
  milkCategories,
  solubleCategories,
} from "../../constants";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import InputSwitch from "../../../components/form/InputSwitch";
import { Delete } from "@material-ui/icons";
import { findFlavourByName } from "../flavour/flavourSlice";
import FlavourLookup from "../FlavourLookup";
import { updateSoluble } from "./solubleSlice";

interface EditSolubleFormProps {
  soluble: Beverage;
  bar: Bar | null;
}

const EditSolubleForm: FunctionComponent<EditSolubleFormProps> = ({
  soluble,
  bar,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: soluble,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "flavours",
    keyName: "keyId",
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(soluble.category);

  const onSubmit = (data: Beverage) => {
    soluble = {
      ...soluble,
      name: data.name,
      category: data.category,
      isVegan: selectedCategory === "Milk" ? data.isVegan : false,
      color: data.color,
      disabled: data.disabled,
      visible: data.visible,
      price: data.price || 0,
      alcoholPerc: data.alcoholPerc || 0,
      flavours: data.flavours || [],
    };
    console.log(soluble);
    dispatch(
      updateSoluble(
        soluble,
        bar,
        () => {
          dispatch(showNotification("Soluble Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!soluble) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Soluble
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputSelect
                label="Category"
                name="category"
                control={control}
                required
                fullWidth
                onChange={() => {
                  setSelectedCategory(control.getValues().category);
                }}
              >
                {solubleCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
            <Grid item md={6}>
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
              <br />
              <br />
              {selectedCategory === "Milk" && (
                <InputSwitch
                  name="isVegan"
                  label="Is Vegan?"
                  type="checkbox"
                  control={control}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Selected Flavours: {fields.length}/3
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <List>
                {fields.map((field, idx) => {
                  register({
                    name: `flavours[${idx}].id`,
                    defaultValue: field.id,
                    value: field.id,
                  });
                  return (
                    <div key={field.keyId}>
                      <ListItem>
                        <FlavourLookup
                          label={`Flavour ${idx + 1}`}
                          name={`flavours[${idx}].name`}
                          control={control}
                          setError={setError}
                          bar={bar}
                          findIngredient={findFlavourByName}
                          required
                          defaultValue={field.name}
                          onChange={(data: any) => {
                            if (data) {
                              setValue(`flavours[${idx}].id`, data?.id);
                            }
                          }}
                          fullWidth
                          disableAdd
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            color="primary"
                            onClick={() => {
                              setValue(`flavours[${idx}]`, "");
                              remove(idx);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText>
                          <InputNumber
                            label="Dispensing Time"
                            name={`flavours[${idx}].dispensingTime`}
                            defaultValue={field.dispensingTime}
                            register={register}
                            fullWidth
                          />
                        </ListItemText>
                      </ListItem>
                    </div>
                  );
                })}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (fields.length < 3)
                      append({ name: "", dispensingTime: 0 });
                  }}
                >
                  Add Flavour
                </Button>
              </List>
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                Ingredient Info
              </Typography>
              <InputNumber
                name="alcoholPerc"
                label="Alcoholic Percentage"
                register={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>

        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditSolubleForm;
