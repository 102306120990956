import React, { FunctionComponent } from "react";
import {
  Box,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import * as _ from "lodash";
import { useDispatch } from "react-redux";
import { createBar } from "./barSlice";
import { showNotification } from "../notification/notificationSlice";
import TheNewGeneralInfo from "./create-bar-form-components/TheNewGeneralInfo";
import { Bar } from "../../services/types/bar";
import TheNewBarType from "./create-bar-form-components/TheNewBarType";
import RecapBarCreate from "./create-bar-form-components/RecapBarCreate";

interface TheNewCreateBarFormProps {
  onCreateSuccess: (...args: any) => any;
}

const stepTitles = [
  "General Info",
  "Bar Type",
  // "Standard - Bib Dispensers",
  // "Standard - Beer And Wine Dispensers",
  // "Standard - Other Dispensers",
  "Recap",
]

const TheNewCreateBarForm: FunctionComponent<TheNewCreateBarFormProps> = ({
  onCreateSuccess,
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [totalSteps, setTotalSteps] = React.useState(stepTitles.length);
  const [bar, setBar] = React.useState<Partial<Bar>>({});

  const [activeStepName, setActiveStepName] = React.useState(stepTitles[activeStep]);
  const handleNext = async (newBar: Partial<Bar>) => {
    const activeBar = {
      ...bar,
      ...newBar
    }
    setBar(activeBar);
    if (activeStep === 1 && activeBar.version === 'veloce') {
      setActiveStep(2); // 5
      setActiveStepName(stepTitles[2]); // 5
    } else {
      setActiveStep(activeStep+1);
      setActiveStepName(stepTitles[activeStep+1]);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep-1);
  };

  const handleConfirm = () => {
    const prevStep = activeStep;
    setActiveStep(-1);
    console.log(bar);
    dispatch(
      createBar(
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Bar Saved!", "success"));
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Bar creation Error!", "error"));
          setActiveStep(prevStep);
        }
      )
    );
  };

  return (
    <div>
      <LinearProgress value={(activeStep + 1) * 100 / stepTitles.length} variant="determinate" />
      <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="2em">
        <Typography component="h2" variant="h5">
          {activeStepName}
        </Typography>
        <Typography component="h2" variant="h5">
          {activeStep + 1}/{totalSteps}
        </Typography>
      </Box>
      { activeStep === 0 && (
        <TheNewGeneralInfo initialValues={bar} onComplete={handleNext}/>
      )}
      { activeStep === 1 && (
        <TheNewBarType initialValues={bar} onComplete={handleNext} onBack={handleBack}/>
      )}
      { activeStep === 2 && ( //5
        <RecapBarCreate initialValues={bar} onComplete={handleConfirm} onBack={() =>  {
          if (bar.version === 'veloce') {
            setActiveStep(1);
          } else {
            handleBack();
          }
        }}/>
      )}
    </div>
  );
};

export default TheNewCreateBarForm;
