import React, { FunctionComponent } from "react";

import { RecipeCategory } from "../../services/types/recipeCategory";
import { Bar } from "../../services/types/bar";
import RecipeForm from "./RecipeForm";
import { createRecipe } from "./recipeSlice";
import { useDispatch } from "react-redux";
import { showNotification } from "../notification/notificationSlice";

interface UpdateRecipeFormProps {
  onSuccess: (elem: any) => any;
  category: RecipeCategory;
  bar: Bar | null;
}



const CreateRecipeForm: FunctionComponent<UpdateRecipeFormProps> = ({
  category,
  bar,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(false);

  const recipe = {
    id: "",
    name: "",
    order: 0,
    barId: bar?.id || "test",
    categoryId: category.id,
    ingredients: [],
  };

  const handleCreate = (data: any) => {
    setDisabled(true);
    dispatch(
      createRecipe(
        data,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Recipe Created!", "success"));
          setDisabled(false);
          onSuccess(payload);
        },
        (e: any) => {
          dispatch(showNotification(`Recipe creation error!`, "error"));
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };


  return (
    <RecipeForm
      recipe={recipe}
      bar={bar}
      onFormSubmit={handleCreate}
      disabled={disabled}
      isCoffee={category.isCoffee}
    />
  );
};
export default CreateRecipeForm;
