import React, { FunctionComponent, useState } from "react";

import { Paper, Box, Typography, Grid, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Granular } from "../../../services/types/granular";
import { showNotification } from "../../notification/notificationSlice";
import { updateGlobalGranular } from "./globalGranularSlice";
import { granularCategories } from "../../constants";
import InputColor from "../../../components/form/InputColor";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputButton from "../../../components/form/InputButton";

interface EditGranularFormProps {
  granular: Granular;
}

const EditGlobalGranularForm: FunctionComponent<EditGranularFormProps> = ({
  granular,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: granular,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Granular) => {
    setDisabled(true);
    granular = {
      ...granular,
      name: data.name,
      category: data.category,
      color: data.color,
    };
    dispatch(
      updateGlobalGranular(
        granular,
        () => {
          dispatch(showNotification("Granular Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!granular) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Global Granular
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                fullWidth
                required
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                control={control}
                required
                fullWidth
              >
                {granularCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
          </Grid>
        </Box>
        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditGlobalGranularForm;
