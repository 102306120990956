import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import {
  getGlobalBottleById,
  selectGlobalBottleById,
} from "./globalBottleSlice";
import EditGlobalBottleForm from "./EditGlobalBottleForm";

const GlobalBottleShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const bottle = useSelector(selectGlobalBottleById(id));

  useEffect(() => {
      dispatch(
        getGlobalBottleById(
          {
            id,
            onFailure: () => history.push("/global-ingredients"),
          }
        )
      );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={"Super Admin"}
        breadcrumb={[{label: "Global Ingredients", location: "/global-ingredients/bottles"}, {label: "Bottles", location: "/global-ingredients/bottles"}, bottle?.name ]}
      />
      {bottle && <EditGlobalBottleForm bottle={bottle} />}
      <Paper>
        {isLoading && <LinearProgress />}
        <code>
          <JSONTree data={bottle} />
        </code>
      </Paper>
    </Box>
  );
};

export default GlobalBottleShowScreen;
