import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import { Granular } from "../../../services/types/granular";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import {
  getAllGranulars,
  selectGranulars,
  selectGranularCount,
} from "./granularSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
];

interface GranularTableProps {
  onGranularSelected: (granular: Granular) => any;
}

const GranularTable: FunctionComponent<GranularTableProps> = ({
  onGranularSelected,
}) => {
  const dispatch = useDispatch();
  const granulars = useSelector(selectGranulars);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectGranularCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllGranulars(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (granular: Granular) => {
      onGranularSelected(granular);
    },
    [onGranularSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={granulars}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default GranularTable;
