import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import InputSelect from "../../../components/form/InputSelect";
import { 
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon 
} from "@material-ui/icons";
import { Bar } from "../../../services/types/bar";

interface BarTypeProps {
  initialValues: Partial<Bar>;
  onComplete: (data: Partial<Bar>) => void;
  onBack: () => void;
}

const TheNewBarType: FunctionComponent<BarTypeProps> = ({ initialValues, onComplete, onBack }) => {
  const { control, handleSubmit } = useForm({ defaultValues: initialValues });

  const onSubmit = (data: Partial<Bar>) => {
      onComplete(data);
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputSelect
            name="version"
            label="Bar Type"
            control={control}
            fullWidth
            required
          >
            <MenuItem value="ms3.1">Toni Standard</MenuItem>
            <MenuItem value="veloce">Toni Veloce</MenuItem>
            <MenuItem value="compatto">Toni Compatto</MenuItem>
            <MenuItem value="ms1">VEGAS Tipsy1</MenuItem>
          </InputSelect>
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" marginTop="2em">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<NavigateNextIcon />}
        >
          Next
        </Button>
        <Button
          variant="contained"
          color="default"
          type="button"
          startIcon={<NavigateBeforeIcon />}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
    </form>
  );
};

export default TheNewBarType;
