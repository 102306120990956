import React, { FunctionComponent, useState } from "react";
import { Box, Button, LinearProgress, Typography } from "@material-ui/core";
import { signIn } from "../auth/authSlice";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import InputText from "../../components/form/InputText";
import InputPassword from "../../components/form/InputPassword";

interface LoginFormProps {
  onLoginSuccess: Function;
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ onLoginSuccess }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    setDisabled(true);
    const payload = {
      email: data.email,
      password: data.password,
    };
    dispatch(
      signIn(
        payload,
        ({ payload }: any) => {
          setDisabled(false);
          onLoginSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setError(e?.payload?.data?.message || e?.error || "General Error");
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputText
        id="email"
        name="email"
        label="Email"
        register={register}
        rules={{
          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }}
        required
        fullWidth
        variant="outlined"
        margin="normal"
      />

      <InputPassword
        id="password"
        name="password"
        label="Password"
        inputRef={register}
        required
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <br />
      {errors.email && (
        <Typography color="error">Invalid Email Format</Typography>
      )}
      <br />
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        disabled={disabled}
        type="submit"
      >
        Sign In
      </Button>
      {disabled && <LinearProgress />}
      {error && (
        <Box paddingTop={2}>
          <Typography align="center">{error}</Typography>
        </Box>
      )}
    </form>
  );
};

export default LoginForm;
