import {
  all,
  fork,
  put,
  race,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAvailableBars, getCurrentBar, getCurrentBarFailure, getCurrentBarSuccess, impersonateBar } from '../../features/instanceBar/instanceBarSlice';
import { Bar } from '../../services/types/bar';
import { signInSuccess } from '../../features/auth/authSlice';


function* handleImpersonateBarFromSession() {
  const encodedBar = localStorage.getItem("bar");
  if (encodedBar) {
    const bar: Bar = JSON.parse(Buffer.from(encodedBar, 'base64').toString());
    yield put(getCurrentBar(bar));
    const { currentBarResult, error } =  yield race({
      currentBarResult: take(getCurrentBarSuccess.match),
      error: take(getCurrentBarFailure.match)
    });
    console.log({ currentBarResult, error });
    if (currentBarResult) {
      yield put(impersonateBar(currentBarResult.payload));
    } else {
      console.error(error);
      localStorage.removeItem("bar");
    }
  }
}

function handleImpersonateBar (action: PayloadAction<Bar>) {
  const { payload } = action;

  const encodedBar = Buffer.from(JSON.stringify(payload)).toString('base64');
  localStorage.setItem("bar", encodedBar);
}

function* handleFetchAvailableBars() {
  yield put(getAvailableBars());
}

function* watchBar() {
  yield fork(handleImpersonateBarFromSession);
  yield all([
    takeEvery(signInSuccess.match, handleFetchAvailableBars),
    takeEvery(impersonateBar.match, handleImpersonateBar),
  ]);
};

export default watchBar;