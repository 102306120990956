import React, { FunctionComponent, MouseEvent, ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import {
  Menu as MenuIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${theme.drawerWidth}px)`,
    marginLeft: theme.drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

type HeaderProps = {
  handleMenuClick: (event: MouseEvent) => void;
  sidebarOpenStatus: boolean;
  title: ReactNode;
  barControlsComponent: ReactNode;
};

const Header: FunctionComponent<HeaderProps> = ({
  handleMenuClick,
  sidebarOpenStatus,
  title,
  barControlsComponent,
}) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: sidebarOpenStatus,
      })}
    >
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          component="a"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {barControlsComponent}
        
      </Toolbar>
    </AppBar>
  );
};

export default Header;
