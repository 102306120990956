import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { Bar } from "../../services/types/bar";
import { showNotification } from "../notification/notificationSlice";
import { createRecipeCategory } from "./recipeCategorySlice";
import { InnerForm } from "./RecipeCategoryForm";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { RecipeCategory } from "../../services/types/recipeCategory";

interface CreateRecipeCategoryFormProps {
  onFormSubmit: (elem: any) => any;
  bar: Bar | null;
}
const CreateRecipeCategoryForm: FunctionComponent<CreateRecipeCategoryFormProps> = ({
  bar,
  onFormSubmit,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
      price: 0,
      color: "",
    },
  });

  const onSubmit = (data: RecipeCategory) => {
    setDisabled(true);
    const recipeCategory = {
      ...data,
    };
    console.log(recipeCategory);
    dispatch(
      createRecipeCategory(
        recipeCategory,
        bar,
        ({ payload }: any) => {
          setDisabled(false);
          dispatch(showNotification("Category Saved!", "success"));
          onFormSubmit(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InnerForm
        inputRef={register}
        control={control}
        setValue={setValue}
        disabled={disabled}
        hasCoffee={bar?.features.coffee}
      />
    </form>
  );
};
export default CreateRecipeCategoryForm;
