import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import { selectSolubleById, getSolubleById } from "./solubleSlice";
import { useHasPermission } from "../../auth/hooks";
import EditSolubleForm from "./EditSolubleForm";

const SolubleShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const soluble = useSelector(selectSolubleById(id));
  const isSuperAdmin = useHasPermission("SUPERADMIN");

  useEffect(() => {
    if (bar) {
      dispatch(
        getSolubleById({ id, onFailure: () => history.push("/solubles") }, bar)
      );
    }
  }, [dispatch, id, bar, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={bar?.name || "Soluble"}
        breadcrumb={[
          bar?.name || "Bar",
          { label: "Ingredients", location: "/ingredients" },
          { label: "Solubles", location: "/ingredients/solubles" },
          soluble?.name,
        ]}
      />
      {soluble && <EditSolubleForm soluble={soluble} bar={bar} />}

      {isSuperAdmin && (
        <Paper>
          {isLoading && <LinearProgress />}
          <code>
            <JSONTree data={soluble} />
          </code>
        </Paper>
      )}
    </Box>
  );
};

export default SolubleShowScreen;
