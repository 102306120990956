import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlProps,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { RegisterOptions, useController } from "react-hook-form";

type InputRadioProps = FormControlProps & {
  name: string;
  label: string;
  radioValues: {label: string, value: any}[];
  control: any;
  rules?: RegisterOptions;
}

const InputRadio: FunctionComponent<InputRadioProps> = ({
  name,
  label,
  radioValues,
  control,
  defaultValue,
  required,
  rules,
  ...rest
}) => {
  const labelId = `${name}-label`;

  const { 
    field: { onChange, onBlur, value, name: _name, ...inputProps },
    meta: { invalid }
  } = useController({name, control, rules: { required, ...rules }, defaultValue});
  // console.log({value, radioValues});
  return (
    <FormControl margin="normal" {...rest}>
      <FormLabel id={labelId} error={invalid}>{label}</FormLabel>
      <RadioGroup name={name} {...inputProps} >
        {radioValues.map((radio, idx) => (
          <FormControlLabel
            value={radio.value}
            key={idx}
            control={<Radio />}
            label={radio.label}
            onChange={(e) => onChange(radio.value.toString())}
            {...{onBlur}}
            checked={value?.toString() === radio.value.toString()}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default InputRadio;
