import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../services/types/pagination";
import { Bar } from "../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface BarState {
  data: Bar[];
  map: {
    [key: string]: Bar;
  };
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: BarState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllBars = createAction<PrepareAction<PaginationPayload>>(
  "bar/getAllBars",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "bar",
      resource: "getAllBars",
    },
  })
);
export const getBarById = createAction<PrepareAction<{ id: string }>>(
  "bar/getBarById",
  ({ id, onFailure }) => ({
    payload: { id },
    meta: {
      fetch: "bar",
      resource: "getBarById",
      onFailure,
    },
  })
);
export const createBar = createAction<PrepareAction<Bar>>(
  "bar/createBar",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "bar",
      resource: "createBar",
      onSuccess,
      onFailure,
    },
  })
);
export const updateBar = createAction<PrepareAction<Bar>>(
  "bar/updateBar",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "bar",
      resource: "updateBar",
      onSuccess,
      onFailure,
    },
  })
);
export const barJwtGenerate = createAction<PrepareAction<Bar>>(
  "bar/barJwtGenerate",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "bar",
      resource: "barJwtGenerate",
      onSuccess,
      onFailure,
    },
  })
);

export const barShift4GetAccessToken = createAction<
  PrepareAction<{ credential: { accessToken: string } }>
>("bar/shift4GetAccessToken", (payload, onSuccess, onFailure) => ({
  payload,
  meta: {
    fetch: "bar",
    resource: "shift4GetAccessToken",
    onSuccess,
    onFailure,
  },
}));

export const barSatispayGetKeyId = createAction<
  PrepareAction<{ publicKey: string; privateKey: string; token: string }>
>("bar/satispayGetKeyId", (payload, onSuccess, onFailure) => ({
  payload,
  meta: {
    fetch: "bar",
    resource: "satispayGetKeyId",
    onSuccess,
    onFailure,
  },
}));

export const createBarSuccess = createAction<Bar>("bar/createBar_SUCCESS");
export const updateBarSuccess = createAction<Bar>("bar/updateBar_SUCCESS");
export const getBarByIdSuccess = createAction<Bar>("bar/getBarById_SUCCESS");
export const getAllBarsSuccess = createAction<PaginationResponse<Bar>>(
  "bar/getAllBars_SUCCESS"
);

// REDUCERS
const barsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getBarByIdSuccess, (state: BarState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(
      getAllBarsSuccess,
      (state: BarState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Bar) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(updateBarSuccess, (state: BarState, { payload }) => {
      state.map[payload.id] = payload;
    })
    .addCase(createBarSuccess, (state: BarState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectBars = (state: RootState) => state.bars.data;
export const selectBarsCount = (state: RootState) => state.bars.count;
export const selectBarById = (id: string) => (state: RootState) =>
  state.bars.map[id];
export const selectBarsPagination = (state: RootState) => ({
  page: state.bars.page,
  perPage: state.bars.perPage,
});

export default barsReducer;
