import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { FunctionComponent } from "react";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

type FabProps = {
  onClick: () => any;
};

const FloatingActionButton: FunctionComponent<FabProps> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab className={classes.fab} onClick={onClick} color="primary">
      <AddIcon />
    </Fab>
  );
};

export default FloatingActionButton;
