import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import {
  getGlobalGranularById,
  selectGlobalGranularById,
} from "./globalGranularSlice";
import EditGlobalGranularForm from "./EditGlobalGranularForm";

const GlobalGranularShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const granular = useSelector(selectGlobalGranularById(id));

  useEffect(() => {
    dispatch(
      getGlobalGranularById(
        {
          id,
          onFailure: () => history.push("/global-ingredients"),
        },
      )
    );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={"Super Admin"}
        breadcrumb={[{label: "Global Ingredients", location: "/global-ingredients/bottles"}, {label: "Granulars", location: "/global-ingredients/granulars"}, granular?.name ]}
      />
      {granular && <EditGlobalGranularForm granular={granular} />}

      <Paper>
        {isLoading && <LinearProgress />}
        <code>
          <JSONTree data={granular} />
        </code>
      </Paper>
    </Box>
  );
};

export default GlobalGranularShowScreen;
