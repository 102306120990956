import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createBottle, findGlobalBottleByName } from "./bottleSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Bottle } from "../../../services/types/bottle";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { bottleCategories } from "../../constants";
import InputText from "../../../components/form/InputText";
interface CreateBottleFormProps {
  bar: Bar | null;
  onCreateSuccess: (bottle: Bottle) => any;
}

const CreateBottleForm: FunctionComponent<CreateBottleFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      category: "",
      subCategory: "",
      color: "",
      price: 0,
      alcoholPerc: 0,
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Bottle) => {
    setDisabled(true);
    const bottle = {
      name: data.name,
      category: data.category,
      subCategory: data.subCategory,
      color: data.color,
      price: data.price || 0,
      alcoholPerc: data.alcoholPerc || 0,
    };
    console.log(bottle);
    dispatch(
      createBottle(
        bottle,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Bottle Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Bottle
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>

          <GlobalIngredientLookup
            label="Name"
            name="name"
            control={control}
            setError={setError}
            onChange={(data: any) => {
              if (data) {
                data.color && setValue("color", data.color);
                data.alcoholPerc && setValue("alcoholPerc", data.alcoholPerc);
                data.category && setValue("category", data.category);
                data.subCategory && setValue("subCategory", data.subCategory);
                setValue("price", 0);
              }
            }}
            findGlobalIngredient={findGlobalBottleByName}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {bottleCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputText
            name="subCategory"
            label="Subcategory"
            register={register}
            fullWidth
          />
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="price"
            label="Price"
            register={register}
            fullWidth
          />
          <InputNumber
            name="alcoholPerc"
            label="Alcoholic Percentage"
            register={register}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Bottle!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateBottleForm;
