import { Box } from "@material-ui/core";
import React, { FunctionComponent } from "react"
import { useSelector } from 'react-redux';
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const BarGuard: FunctionComponent = ({ children }) => {
  const bar = useSelector(selectCurrentInstanceBar);
  if (bar) {
    return <>{children}</>;
  }
  return (
    <Box>
      <h2>First of all, select one of the available bar.</h2>
    </Box>
  );
}

export default BarGuard;