import axios, { AxiosResponse } from "axios";
import { RecipeCategory } from "./types/recipeCategory";
import { PaginationResponse } from "./types/pagination";
import {
  getAccessTokenRequestForAxiosOptions,
  transformID,
  transformIDElement,
} from "./utils";
import { Recipe } from "./types/recipe";

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllRecipeCategories = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<RecipeCategory>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/categories/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload {id: string, barId: string}
 * @param accessToken
 */
const getRecipeCategoryById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<RecipeCategory> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<RecipeCategory> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/categories/${payload.id}`,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload RecipeCategory
 * @param accessToken
 */
const createRecipeCategory = async (
  payload: RecipeCategory,
  accessToken: string
): Promise<RecipeCategory> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<RecipeCategory> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/categories/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload RecipeCategory
 * @param accessToken
 */
const updateRecipeCategory = async (
  payload: RecipeCategory,
  accessToken: string
): Promise<RecipeCategory> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<RecipeCategory> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/categories/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Recipe
 * @param accessToken
 */
const deleteRecipeCategory = async (
  payload: RecipeCategory,
  accessToken: string
): Promise<RecipeCategory> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<RecipeCategory> = await axios.delete(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/categories/${payload.id}`,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload RecipeCategory
 * @param accessToken
 */
const updateSortingRecipeCategory = async (
  payload: { recipeCategories: RecipeCategory[]; barId: string },
  accessToken: string
): Promise<RecipeCategory[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<RecipeCategory[]> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/categories/sorting`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllRecipes = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Recipe>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/recipes/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Recipe
 * @param accessToken
 */
const getRecipeById = async (
  payload: Recipe,
  accessToken: string
): Promise<Recipe> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Recipe> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/recipes/${payload.id}`,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Recipe
 * @param accessToken
 */
const createRecipe = async (
  payload: Recipe,
  accessToken: string
): Promise<Recipe> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Recipe> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/recipes/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Recipe
 * @param accessToken
 */
const updateRecipe = async (
  payload: Recipe,
  accessToken: string
): Promise<Recipe> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Recipe> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/recipes/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Recipe
 * @param accessToken
 */
const deleteRecipe = async (
  payload: Recipe,
  accessToken: string
): Promise<Recipe> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Recipe> = await axios.delete(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/recipes/${payload.id}`,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Recipe
 * @param accessToken
 */
const updateSortingRecipe = async (
  payload: { recipes: Recipe[]; barId: string },
  accessToken: string
): Promise<Recipe[]> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Recipe[]> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udrink/recipes/sorting`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformID(response.data);
};

const api = {
  getAllRecipeCategories,
  getRecipeCategoryById,
  createRecipeCategory,
  updateRecipeCategory,
  deleteRecipeCategory,
  updateSortingRecipeCategory,
  getAllRecipes,
  getRecipeById,
  createRecipe,
  updateRecipe,
  deleteRecipe,
  updateSortingRecipe,
};
export default api;
