import { Box, Button, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import {
  Done as DoneIcon,
  NavigateBefore as NavigateBeforeIcon
} from "@material-ui/icons";
import { Bar } from "../../../services/types/bar";

interface RecapBarCreateProps {
  initialValues: Partial<Bar>;
  onComplete: (data: Partial<Bar>) => void;
  onBack: () => void;
}

const RecapBarCreate: FunctionComponent<RecapBarCreateProps> = ({ initialValues, onComplete, onBack }) => {

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{initialValues.name}</Typography>
          <Typography variant="subtitle2">{initialValues.instanceInfos?.placeName}</Typography>
          <b>Lat:</b> {initialValues.geoPosition?.lat} - <b>Lng:</b> {initialValues.geoPosition?.lng}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Bar Version:</Typography>
          {initialValues.version}
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" marginTop="2em">
        <Button
          variant="contained"
          color="primary"
          type="button"
          endIcon={<DoneIcon />}
          onClick={() => onComplete(initialValues)}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="default"
          type="button"
          startIcon={<NavigateBeforeIcon />}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
    </>
  );
};

export default RecapBarCreate;
