import { DashboardGraphItem } from "./types/dashboardGraphItem";
import { getAccessTokenRequestForAxiosOptions } from "./utils";
import axios, { AxiosResponse } from "axios";

const getTodayOrders = async (
  payload: any | undefined,
  accessToken: string
): Promise<DashboardGraphItem> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<DashboardGraphItem> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uorder/drink/today`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

const api = {
  getTodayOrders,
};

export default api;
