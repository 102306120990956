import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Beverage } from "../../../services/types/beverage";
import { milkCategories } from "../../constants";

interface MilkState {
  data: Beverage[];
  map: MapLike<Beverage>;
  /* milkCategories: string[]; */
  count: number;
  page: number;
  perPage: number;
}

const initialState: MilkState = {
  data: [],
  map: {},
  /* milkCategories: milkCategories, */
  count: 0,
  page: 1,
  perPage: 2,
};

//Actions
export const getAllMilks = createAction<
  PrepareAction<PaginationPayloadByBar>
>("milk/getAllMilks", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar.id,
    /* milkCategories, */
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllMilks",
  },
}));

export const getAllMilksSuccess = createAction<PaginationResponse<Beverage>>(
  "milk/getAllMilks_SUCCESS"
);

export const getMilkById = createAction<PrepareAction<{ id: string }>>(
  "milk/getMilkById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getMilkById",
      onFailure,
    },
  })
);

export const getMilkByIdSuccess = createAction<Beverage>(
  "milk/getMilkById_SUCCESS"
);

export const getMilkByName = createAction<PrepareAction<{ name: string }>>(
  "milk/getMilkByName",
  ({ name, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      name,
    },
    meta: {
      fetch: "ingredients",
      resource: "getMilkByName",
      onFailure,
    },
  })
);

export const getMilkByNameSuccess = createAction<Beverage>(
  "milk/getMilkByName_SUCCESS"
);

export const updateMilk = createAction<PrepareAction<Beverage>>(
  "milk/updateMilks",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateMilk",
      onSuccess,
      onFailure,
    },
  })
);

export const updateMilkSuccess = createAction<Beverage>(
  "milk/updateMilk_SUCCESS"
);

export const findGlobalMilkByName = createAction<
  PrepareAction<{ name: string }>
>("milk/findGlobalMilkByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalMilkByName",
    onSuccess,
    onFailure,
  },
}));

export const createMilk = createAction<PrepareAction<Beverage>>(
  "milk/createMilk",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createMilk",
      onSuccess,
      onFailure,
    },
  })
);

export const createMilkSuccess = createAction<Beverage>(
  "milk/createMilk_SUCCESS"
);

//Reducers
const milkReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllMilksSuccess,
      (state: MilkState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Beverage) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getMilkByIdSuccess, (state: MilkState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(getMilkByNameSuccess, (state: MilkState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(updateMilkSuccess, (state: MilkState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

//Selector
export const selectMilks = (state: RootState) =>
  state.ingredients.milk.data;
export const selectMilkCount = (state: RootState) =>
  state.ingredients.milk.count;
export const selectMilkById = (id: string) => (state: RootState) =>
  state.ingredients.milk.map[id];

export default milkReducer;
