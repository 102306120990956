import React, { FunctionComponent } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core';
import { makeStyles, fade } from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    color: theme.palette.primary.main,
    border: `1px solid ${fade(theme.palette.primary.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: fade(theme.palette.primary.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorSecondary: {
    color: theme.palette.success.main,
    border: `1px solid ${fade(theme.palette.success.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.success.main}`,
      backgroundColor: fade(theme.palette.success.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorError: {
    color: theme.palette.error.main,
    border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorSuccess: {
    color: theme.palette.success.main,
    border: `1px solid ${fade(theme.palette.success.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.success.main}`,
      backgroundColor: fade(theme.palette.success.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  colorWarning: {
    color: theme.palette.warning.main,
    border: `1px solid ${fade(theme.palette.warning.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.warning.main}`,
      backgroundColor: fade(theme.palette.warning.main, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));


interface SquaredIconButtonProp extends MuiButtonProps {
  buttonColor?: "primary" | "secondary" | 'success' | 'warning' | 'error',
}
  
const SquaredIconButton: FunctionComponent<SquaredIconButtonProp> = ({color, buttonColor, children, ...props}) => {
  const classes = useStyles();
  return (
    <MuiButton classes={{ root: clsx({
      [classes.colorPrimary]: buttonColor === 'primary',
      [classes.colorSecondary]: buttonColor === 'secondary',
      [classes.colorWarning]: buttonColor === 'warning',
      [classes.colorSuccess]: buttonColor === 'success',
      [classes.colorError]: buttonColor === 'error',
    }) }}
      {...props}
      >
      {children}
    </MuiButton>
  );
}

export default SquaredIconButton