import axios, { AxiosResponse } from "axios";
import { ExportRequest } from "./types/exportRequest";
import { PaginationResponse } from "./types/pagination";
import { getAccessTokenRequestForAxiosOptions, transformID, transformIDElement } from "./utils";

/**
 * Is it right to change the _id in id here? 
 * Don't know, but works neat now.
 * 
 * @param payload 
 * @param accessToken 
 */
const getAllExportRequests = async (payload: any | undefined, accessToken: string) : Promise<PaginationResponse<ExportRequest>> => {
    const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
    const response: AxiosResponse<PaginationResponse<any>>  = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/uexport/export-request/`,
        optionDecorator({ params: payload }, { "x-bar": payload?.barId })
    );
    const body = response.data;
    body.data = transformID(body.data);
    return body;
};


/**
 * 
 * @param payload ExportRequest
 * @param accessToken 
 */
 const createExportRequest = async (payload: ExportRequest, accessToken: string) : Promise<ExportRequest> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<ExportRequest> = await axios.post(
      `${process.env.REACT_APP_AUTH_BASE_URL}/uexport/export-request/create`,
      payload,
      optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};


/**
 *
 * @param payload {id: string, barId: string}
 * @param accessToken
 */
 const getExportRequestDownloadById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<AxiosResponse> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<any> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uexport/export-request/download/${payload.id}`,
    optionDecorator({responseType: 'arraybuffer'}, { "x-bar": payload?.barId })
  );
  return response;
};

const api = {
  getAllExportRequests,
  createExportRequest,
  getExportRequestDownloadById,
};
export default api;
