import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../services/types/pagination";
import { Recipe } from "../../services/types/recipe";
import { Bar } from "../../services/types/bar";
import { MapLike } from "typescript";
import appOrder from "../../services/appOrder";

// STATE INTERFACE
interface RecipeState {
  data: Recipe[];
  map: MapLike<Recipe>;
  count: number;
  // page: number;
  // perPage: number;
}

// INITIAL STATE
const initialState: RecipeState = {
  data: [],
  map: {},
  count: 0,
  // page: 1,
  // perPage: 2,
};

// ACTIONS
export const getAllRecipes = createAction<
  PrepareAction<PaginationPayloadByBar>
>("recipe/getAllRecipes", (categoryId: string, bar: Bar) => ({
  payload: {
    barId: bar?.id,
    search: { categoryId },
  },
  meta: {
    fetch: "recipes",
    resource: "getAllRecipes",
  },
}));
export const getAllRecipesSuccess = createAction<PaginationResponse<Recipe>>(
  "recipe/getAllRecipes_SUCCESS"
);
export const getRecipeById = createAction<
  PrepareAction<{ id: string; barId: string }>
>("recipe/getRecipeById", (id, bar: Bar) => ({
  payload: {
    id,
    barId: bar.id,
  },
  meta: {
    fetch: "recipes",
    resource: "getRecipeById",
  },
}));

export const deleteRecipe = createAction<
  PrepareAction<{ id: string; barId: string }>
>("recipe/deleteRecipe", (id, bar: Bar, onSuccess, onFailure) => ({
  payload: {
    id,
    barId: bar?.id,
  },
  meta: {
    fetch: "recipes",
    resource: "deleteRecipe",
    onSuccess,
    onFailure,
  },
}));
export const deleteRecipeSuccess = createAction<Recipe>(
  "recipe/deleteRecipe_SUCCESS"
);

export const orderRecipeForBar = createAction<
  PrepareAction<{
    id: string;
    barId: string;
    orderName: string;
    appOrderId?: string;
  }>
>(
  "recipe/orderRecipeForBar",
  (recipe: any, barId: string, appOrderId: string | undefined, onSuccess, onFailure) => ({
    payload: {
      id: recipe.id,
      barId: barId,
      orderName: recipe.orderName,
      appOrderId: appOrderId,
    },
    meta: {
      fetch: "bar",
      resource: "orderRecipe",
      onSuccess,
      onFailure,
    },
  })
);

export const getRecipeByIdSuccess = createAction<Recipe>(
  "recipe/getRecipeById_SUCCESS"
);
export const updateRecipe = createAction<PrepareAction<Recipe>>(
  "recipe/updateRecipe",
  (recipe: Recipe, onSuccess, onFailure) => ({
    payload: recipe,
    meta: {
      fetch: "recipes",
      resource: "updateRecipe",
      onSuccess,
      onFailure,
    },
  })
);
export const updateRecipeSuccess = createAction<Recipe>(
  "recipe/updateRecipe_SUCCESS"
);
export const createRecipe = createAction<PrepareAction<Recipe>>(
  "recipe/createRecipe",
  (recipe: Recipe, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      ...recipe,
      barId: bar.id,
    },
    meta: {
      fetch: "recipes",
      resource: "createRecipe",
      onSuccess,
      onFailure,
    },
  })
);
export const createRecipeSuccess = createAction<Recipe>(
  "recipe/createRecipe_SUCCESS"
);

export const updateSortingRecipe = createAction<
  PrepareAction<{ recipes: Recipe[]; barId: string }>
>(
  "recipe/updateSortingRecipe",
  (recipes: Recipe[], bar: Bar, onSuccess, onFailure) => ({
    payload: {
      recipes,
      barId: bar.id,
    },
    meta: {
      fetch: "recipes",
      resource: "updateSortingRecipe",
      onSuccess,
      onFailure,
    },
  })
);
export const findBeverageByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "recipe/findBeverageByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findBeverageByName",
      onSuccess,
      onFailure,
    },
  })
);
export const findGarnishByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "recipe/findGarnishByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findGarnishByName",
      onSuccess,
      onFailure,
    },
  })
);
export const findGranularByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "recipe/findGranularByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findGranularByName",
      onSuccess,
      onFailure,
    },
  })
);
export const findLeafByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "recipe/findLeafByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findLeafByName",
      onSuccess,
      onFailure,
    },
  })
);
export const findCoffeeByName = createAction<
  PrepareAction<{ name: string; barId: string }>
>(
  "recipe/findCoffeeByName",
  (name: string, barId: string, onSuccess, onFailure) => ({
    payload: {
      name,
      barId: barId,
    },
    meta: {
      fetch: "ingredients",
      resource: "findCoffeeByName",
      onSuccess,
      onFailure,
    },
  })
);

// REDUCERS
const recipeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllRecipesSuccess,
      (state: RecipeState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Recipe) => el.id);
        state.count = count;
        // state.page = page;
        // state.perPage = perPage;
      }
    )
    .addCase(getRecipeByIdSuccess, (state: RecipeState, { payload }) => {
      state.data = state.data.filter((el) => el.id === payload.id);

      state.map[payload.id] = payload;
    })
    .addCase(updateRecipeSuccess, (state: RecipeState, { payload }) => {
      state.map[payload.id] = payload;
      state.data = state.data.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
    })
    .addCase(createRecipeSuccess, (state: RecipeState, { payload }) => {
      state.map[payload.id] = payload;
      state.data.push(payload);
    })
    .addCase(
      updateSortingRecipe,
      (state: RecipeState, { payload: { recipes } }) => {
        recipes.forEach((recipe) => {
          state.map[recipe.id] = recipe;
          state.data = state.data.map((el) => {
            if (el.id === recipe.id) {
              return recipe;
            }
            return el;
          });
        });
        state.data.sort((a, b) => a.order - b.order);
      }
    )
    .addCase(deleteRecipeSuccess, (state: RecipeState, { payload }) => {
      state.data = state.data.filter((el) => el.id !== payload.id);
      delete state.map[payload.id];
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRecipes = (state: RootState) => state.menu.recipe.data;
export const selectRecipeById = (id: string) => (state: RootState) =>
  state.menu.recipe.map[id];

export default recipeReducer;
