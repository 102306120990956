import axios from "axios";


export type AuthSignInPayload = {
    email: string;
    password: string;
}

export type AuthSignInResponseType = {
    username: string;
    expiresIn: string;
    accessToken: string;
    roleMapping: any;
    roles: [any];
}


const authSignIn = async (payload: AuthSignInPayload) : Promise<AuthSignInResponseType> => {
    const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/auth/login`,
        payload
    );
    
    return response.data;
};


const whoAmI = async (payload: {} | undefined, accessToken: string) : Promise<AuthSignInResponseType> => {

    const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/auth/whoami`,
        payload,
        {
            headers: {
                "Authorization" : `Bearer ${accessToken}`
            },
        }
    );
    return response.data;
};

const auth = {
    authSignIn,
    whoAmI
};
export default auth;
