import { FormControl, FormControlLabel, FormControlProps, Radio, RadioGroup } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { RegisterOptions, useController } from 'react-hook-form';
import { selectCurrentInstanceCupsCode } from '../instanceBar/instanceBarSlice';
import * as _ from 'lodash';

type CupTypeRadioProps = FormControlProps & {
  name: string;
  control: any;
  rules?: RegisterOptions;
};

const CupTypeRadio: FunctionComponent<CupTypeRadioProps> = ({
  name,
  control,
  defaultValue,
  required,
  rules,
  ...rest
}) => {

  const cupsType = _.uniqBy(useSelector(selectCurrentInstanceCupsCode), (a) => a.code);

  
  const { 
    field: { onChange, onBlur, value, name: _name, ...inputProps },
  } = useController({name, control, rules: { required, ...rules }, defaultValue});
  return (
    <FormControl margin="normal" {...rest}>
      <RadioGroup name={name} {...inputProps} >
        {cupsType.map((radio, idx) => (
          <FormControlLabel
            value={radio.code}
            key={idx}
            control={<Radio />}
            label={radio.name}
            onChange={(e) => onChange(radio.code.toString())}
            {...{onBlur}}
            checked={value?.toString() === radio.code.toString()}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default CupTypeRadio
