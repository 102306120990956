import { createReducer, createAction, PrepareAction, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { SyntheticEvent } from 'react';
import { WS_DISCONNECTED, WS_SUBSCRIBED } from '../../sagas/websocketSaga';
import { RootState } from '../../store';

export type NotificationType = 'info' | 'warning' | 'error';


export interface NotificationPayload {
  readonly key: string;
  readonly message: string;
  readonly type?: NotificationType;
  dismissed: boolean;
  onClose?: (event: SyntheticEvent | null, reason: string, key?:any) => any 
}

// STATE INTERFACE
interface NotificationState {
  data: NotificationPayload[];
  topic: string | null;
  unread: number;
}

// INITIAL STATE
const initialState: NotificationState = {
  data: [],
  topic: null,
  unread: 0,
};

// ACTIONS

/**
 * Shows a snackbar/toast notification on the screen
 *
 * @see {@link https://material-ui.com/api/snackbar/|Material ui snackbar component}
 * @see {@link https://material.io/guidelines/components/snackbars-toasts.html|Material ui reference document on snackbar}
 */
export const showNotification = createAction<PrepareAction<NotificationPayload>>("notification/show", 
  (message: string, type?: NotificationType) => {
    return {
      payload: { 
        key: uniqueId(),
        message,
        type: type,
        dismissed: false
      }
    }
  }
);

export const hideNotification = createAction<any>('notification/hide');



// REDUCERS
const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(showNotification, (state: NotificationState, { payload }) => {
      state.data.push(payload);
      state.unread++;
    })
    .addCase(hideNotification, (state: NotificationState, { payload: key }) => {
      state.data = state.data.filter(notification => notification.key !== key);
      state.unread--;
    })
    .addCase<string, PayloadAction<string>>(WS_SUBSCRIBED, (state: NotificationState, { payload }) => {
      state.topic = payload;
    })
    .addCase(WS_DISCONNECTED, (state: NotificationState) => {
      state.topic = null;
      state.unread = 0;
      state.data = [];
    })
    ;
});


// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectNotifications = (state: RootState) => state.notifications.data;
export const selectUnreadNotificationsCount = (state: RootState) => state.notifications.unread;
export const selectTopicSubscribed = (state: RootState) => state.notifications.topic;


export default notificationsReducer;