import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Garnish } from "../../../services/types/garnish";

interface GarnishState {
  data: Garnish[];
  map: MapLike<Garnish>;
  count: number;
  page: number;
  perPage: number;
}

const initialState: GarnishState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

//Actions
export const getAllGarnishes = createAction<
  PrepareAction<PaginationPayloadByBar>
>("garnishes/getAllGarnishes", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar?.id,
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllGarnishes",
  },
}));

export const getAllGarnishesSuccess = createAction<PaginationResponse<Garnish>>(
  "garnishes/getAllGarnishes_SUCCESS"
);

export const getGarnishById = createAction<PrepareAction<{ id: string }>>(
  "garnishes/getGarnishById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getGarnishById",
      onFailure,
    },
  })
);

export const getGarnishByIdSuccess = createAction<Garnish>(
  "garnishes/getGarnishById_SUCCESS"
);

export const updateGarnish = createAction<PrepareAction<Garnish>>(
  "garnishes/updateGarnishes",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateGarnish",
      onSuccess,
      onFailure,
    },
  })
);

export const updateGarnishSuccess = createAction<Garnish>(
  "garnishes/updateGarnish_SUCCESS"
);

export const findGlobalGarnishByName = createAction<
  PrepareAction<{name: string}>
  >("garnishes/findGlobalGarnishByName", (payload, onSuccess, onFailure) =>({
    payload: {
      name: payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "findGlobalGarnishByName",
      onSuccess,
      onFailure
    },
  }));

export const createGarnish = createAction<PrepareAction<Garnish>>(
  "garnishes/createGarnishes",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createGarnish",
      onSuccess,
      onFailure,
    },
  })
);

export const createGarnishSuccess  = createAction<Garnish>(
  "garnishes/createGarnish_SUCCESS"
);

//Reducers
const garnishesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGarnishesSuccess,
      (state: GarnishState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Garnish) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGarnishByIdSuccess,
      (state: GarnishState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;      
      })
    .addCase(updateGarnishSuccess, (state: GarnishState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

//Selector
export const selectGarnishes = (state: RootState) =>
  state.ingredients.garnish.data;
export const selectGarnishCount = (state: RootState) =>
  state.ingredients.garnish.count;
export const selectGarnishById = (id: string) => (state: RootState) =>
  state.ingredients.garnish.map[id];

export default garnishesReducer;
