import React, { FunctionComponent, useState } from "react";
import Box from "@material-ui/core/Box";
import PageTitle from "../../components/layout/PageTitle";

import Dialog from "../../components/dialog/Dialog";
import RobotTable from "./RobotTable";
// import CreateBarForm from "./CreateBarForm";
import { useHistory } from "react-router-dom";
import { Robot } from "../../services/types/robot";
import FloatingActionButton from "../../components/layout/FloatingActionButton";

const RobotListScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <Box>
      <PageTitle pageTitle={"Robots"} breadcrumb={["Operations", "Robot"]} />

      <RobotTable
        onRobotSelected={(robot: Robot) => {
          history.push(`/robots/${robot.id}`);
          // setOpen(false);
        }}
      />

      <FloatingActionButton onClick={() => setOpen(true)} />
      <Dialog
        title="Create New Robot"
        open={open}
        onAbort={() => setOpen(false)}
      >
        {/* <CreateRobotForm onCreateSuccess={(robot:Robot) => {
          history.push(`/robots/${robot.id}`);
          // setOpen(false);
        }}></CreateRobotForm> */}
      </Dialog>
    </Box>
  );
};

export default RobotListScreen;
