import {
  createReducer,
  createAction,
  PrepareAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../store";
import { PaginationResponse } from "../../services/types/pagination";
import { RecipeCategory } from "../../services/types/recipeCategory";
import { Bar } from "../../services/types/bar";
import { MapLike } from "typescript";

// STATE INTERFACE
interface RecipeCategoryState {
  data: RecipeCategory[];
  map: MapLike<RecipeCategory>;
  count: number;
  // page: number;
  // perPage: number;
}

// INITIAL STATE
const initialState: RecipeCategoryState = {
  data: [],
  map: {},
  count: 0,
  // page: 1,
  // perPage: 2,
};

// ACTIONS
export const getAllRecipeCategories = createAction<
  PrepareAction<{ barId: string }>
>("recipeCategory/getAllRecipeCategories", (bar: Bar) => ({
  payload: {
    barId: bar.id,
  },
  meta: {
    fetch: "recipes",
    resource: "getAllRecipeCategories",
  },
}));
export const getAllRecipeCategoriesSuccess = createAction<
  PaginationResponse<RecipeCategory>
>("recipeCategory/getAllRecipeCategories_SUCCESS");
export const getRecipeCategoryById = createAction<
  PrepareAction<{ barId: string; id: string }>
>("recipeCategory/getRecipeCategoryById", (id, bar: Bar) => ({
  payload: {
    id,
    barId: bar.id,
  },
  meta: {
    fetch: "recipes",
    resource: "getRecipeCategoryById",
  },
}));
export const getRecipeCategoryByIdSuccess = createAction<RecipeCategory>(
  "recipeCategory/getRecipeCategoryById_SUCCESS"
);

export const deleteRecipeCategory = createAction<
  PrepareAction<{ id: string; barId: string }>
>("recipeCategory/deleteRecipeCategory", (id, bar: Bar, onSuccess, onFailure) => ({
  payload: {
    id,
    barId: bar.id,
  },
  meta: {
    fetch: "recipes",
    resource: "deleteRecipeCategory",
    onSuccess,
    onFailure
  },
}));

export const deleteRecipeCategorySuccess = createAction<RecipeCategory>(
  "recipeCategory/deleteRecipeCategory_SUCCESS"
);

export const updateRecipeCategory = createAction<PrepareAction<RecipeCategory>>(
  "recipeCategory/updateRecipeCategory",
  (recipeCategory: RecipeCategory, onSuccess, onFailure) => ({
    payload: {
      ...recipeCategory,
    },
    meta: {
      fetch: "recipes",
      resource: "updateRecipeCategory",
      onSuccess,
      onFailure,
    },
  })
);
export const updateRecipeCategorySuccess = createAction<RecipeCategory>(
  "recipeCategory/updateRecipeCategory_SUCCESS"
);
export const createRecipeCategory = createAction<PrepareAction<RecipeCategory>>(
  "recipeCategory/createRecipeCategory",
  (recipeCategory: RecipeCategory, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      ...recipeCategory,
      barId: bar.id,
    },
    meta: {
      fetch: "recipes",
      resource: "createRecipeCategory",
      onSuccess,
      onFailure,
    },
  })
);
export const createRecipeCategorySuccess = createAction<RecipeCategory>(
  "recipeCategory/createRecipeCategory_SUCCESS"
);

export const updateSortingRecipeCategories = createAction<
  PrepareAction<{ recipeCategories: RecipeCategory[]; barId: string }>
>(
  "recipeCategory/updateSortingRecipeCategory",
  (recipeCategories: RecipeCategory[], bar: Bar, onSuccess, onFailure) => ({
    payload: {
      recipeCategories,
      barId: bar.id,
    },
    meta: {
      fetch: "recipes",
      resource: "updateSortingRecipeCategory",
      onSuccess,
      onFailure,
    },
  })
);

// REDUCERS
const recipeCategoriesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllRecipeCategoriesSuccess,
      (
        state: RecipeCategoryState,
        { payload: { data, count, page, perPage } }
      ) => {
        state.data = data;
        state.map = _.keyBy(data, (el: RecipeCategory) => el.id);
        state.count = count;
        // state.page = page;
        // state.perPage = perPage;
      }
    )
    .addCase(
      getRecipeCategoryByIdSuccess,
      (state: RecipeCategoryState, { payload }) => {
        state.map[payload.id] = payload;
        state.data = state.data.map((el) => {
          if (el.id === payload.id) {
            return payload;
          }
          return el;
        });
      }
    )
    .addCase(
      updateRecipeCategorySuccess,
      (state: RecipeCategoryState, { payload }) => {
        state.map[payload.id] = payload;
        state.data = state.data.map((el) => {
          if (el.id === payload.id) {
            return payload;
          }
          return el;
        });
      }
    )
    .addCase(
      createRecipeCategorySuccess,
      (state: RecipeCategoryState, { payload }) => {
        state.map[payload.id] = payload;
        state.data.push(payload);
      }
    )
    .addCase(
      updateSortingRecipeCategories,
      (state: RecipeCategoryState, { payload: { recipeCategories } }) => {
        recipeCategories.forEach((category) => {
          state.map[category.id] = category;
          state.data = state.data.map((el) => {
            if (el.id === category.id) {
              return category;
            }
            return el;
          });
        });
        state.data.sort((a, b) => a.order - b.order);
      }
    )
    .addCase(deleteRecipeCategorySuccess,
      (state: RecipeCategoryState, { payload }) => {
        state.data = state.data.filter((el) => el.id !== payload.id);
        delete state.map[payload.id];    
      })
    ;
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRecipeCategories = (state: RootState) =>
  state.menu.recipeCategory.data;
export const selectRecipeCategoryById = (id: string) => (state: RootState) =>
  state.menu.recipeCategory.map[id];

export default recipeCategoriesReducer;
