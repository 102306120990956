export const transformIDElement = (el: any) => {
  if (el instanceof Object) {
    el.id = el._id;
    delete el._id;
  }
  return el;
};

export const transformID = (obj: any[]) => {
  return obj.map(el => {
    el.id = el._id;
    delete el._id;
    return el;
  });
};

export const getAccessTokenRequestForAxiosOptions = (accessToken: string) => (options = {}, additionalHeaders = {}) => {
  return {
    ...options,
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      ...additionalHeaders
    },
  }
}