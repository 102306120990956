import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import SelectFilter, {
  withOptions,
} from "../../../components/table/filters/SelectFilter";
import { Beverage } from "../../../services/types/beverage";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import { solubleCategories } from "../../constants";
import {
  getAllSolubles,
  selectSolubles,
  selectSolubleCount,
} from "./solubleSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  {
    Header: "Category",
    accessor: "category",
    Filter: withOptions(solubleCategories, SelectFilter),
  },
  /*   {
    Header: "Type",
    accessor: "type",
    Filter: withOptions(solubleCategories, SelectFilter),
  }, */
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
];

interface SolubleTableProps {
  onSolubleSelected: (beverage: Beverage) => any;
}

const SolubleTable: FunctionComponent<SolubleTableProps> = ({
  onSolubleSelected,
}) => {
  const dispatch = useDispatch();
  const solubles = useSelector(selectSolubles);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectSolubleCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllSolubles(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (beverage: Beverage) => {
      onSolubleSelected(beverage);
    },
    [onSolubleSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={solubles}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default SolubleTable;
