import React, { FunctionComponent } from 'react'
import { Typography } from "@material-ui/core";

interface RecipeFormRecapProps {
  parts: number;
  alcoholPerc: number;
  maxParts: number;
}

const RecipeFormRecap: FunctionComponent<RecipeFormRecapProps> = ({
  parts,
  alcoholPerc,
  maxParts,
}) => {
  return (
    <>
      <Typography variant="subtitle1">
        Total Parts: {parts}/{maxParts}
      </Typography>
      <Typography variant="subtitle1">
        Alcohol Percentage: {alcoholPerc.toFixed(1)}%
      </Typography>
    </>
  );
}

export default RecipeFormRecap
