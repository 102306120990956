import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { CoffeeType } from "../../../services/types/coffeeType";
import { MapLike } from "typescript";

// STATE INTERFACE
interface CoffeeTypeState {
  data: CoffeeType[];
  map: MapLike<CoffeeType>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: CoffeeTypeState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalCoffeeType = createAction<
  PrepareAction<PaginationPayload>
>(
  "coffee/getAllGlobalCoffeeType",
  ({ page, perPage, sort, search }, onSuccess, onFailure) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getAllGlobalCoffeeType",
      onSuccess,
      onFailure,
    },
  })
);

export const getAllGlobalCoffeeTypeSuccess = createAction<
  PaginationResponse<CoffeeType>
>("coffee/getAllGlobalCoffeeType_SUCCESS");

export const getGlobalCoffeeTypeById = createAction<PrepareAction<{ id: string }>>(
  "coffee/getGlobalCoffeeTypeById",
  ({ id, onFailure }) => ({
    payload: {
      id,
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getGlobalCoffeeTypeById",
      onFailure,
    },
  })
);
export const getGlobalCoffeeTypeByIdSuccess = createAction<CoffeeType>(
  "coffee/getGlobalCoffeeTypeById_SUCCESS"
);

export const updateGlobalCoffeeType = createAction<PrepareAction<CoffeeType>>(
  "coffee/updateGlobalCoffeeType",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalCoffeeType",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalCoffeeTypeSuccess = createAction<CoffeeType>(
  "coffee/updateGlobalCoffeeType_SUCCESS"
); /* 
export const findGlobalCoffeeTypeByName = createAction<
  PrepareAction<{ name: string }>
>("coffee/findGlobalCoffeeTypeByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalCoffeeTypeByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalCoffeeType = createAction<PrepareAction<CoffeeType>>(
  "coffee/createGlobalCoffeeType",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalCoffeeType",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalCoffeeTypeSuccess = createAction<CoffeeType>(
  "coffee/createGlobalCoffeeType_SUCCESS"
);

// REDUCERS
const globalCoffeeTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalCoffeeTypeSuccess,
      (state: CoffeeTypeState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: CoffeeType) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGlobalCoffeeTypeByIdSuccess,
      (state: CoffeeTypeState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      }
    )
    .addCase(updateGlobalCoffeeTypeSuccess, (state: CoffeeTypeState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalCoffeeType = (state: RootState) =>
  state.globalIngredients.coffeeType.data;
export const selectGlobalCoffeeTypeCount = (state: RootState) =>
  state.globalIngredients.coffeeType.count;
export const selectGlobalCoffeeTypeById = (id: string) => (state: RootState) =>
  state.globalIngredients.coffeeType.map[id];

export default globalCoffeeTypeReducer;
