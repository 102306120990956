import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import { CoffeeType } from "../../../services/types/coffeeType";
import { useLoading } from "../../loading/hooks";
import {
  getAllGlobalCoffeeType,
  selectGlobalCoffeeType,
  selectGlobalCoffeeTypeCount,
} from "./globalCoffeeTypeSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
];

interface CoffeeTypeTableProps {
  onCoffeeTypeSelected: (coffeeType: CoffeeType) => any;
}

const GlobalCoffeeTypeTable: FunctionComponent<CoffeeTypeTableProps> = ({
  onCoffeeTypeSelected,
}) => {
  const dispatch = useDispatch();
  const coffeeType = useSelector(selectGlobalCoffeeType);
  const isLoading = useLoading();

  const totalCount = useSelector(selectGlobalCoffeeTypeCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      dispatch(
        getAllGlobalCoffeeType({
          page: pageIndex,
          perPage: pageSize,
          sort: sortBy,
          search: filters,
        })
      );
    },
    [dispatch]
  );

  const handleRowClick = useCallback(
    (coffeeType: CoffeeType) => {
      onCoffeeTypeSelected(coffeeType);
    },
    [onCoffeeTypeSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={coffeeType}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default GlobalCoffeeTypeTable;
