import React from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useController } from "react-hook-form";

type InputSwitchProps = {
  name: string;
  label: string;
  control: any;
  type: string;
};

const InputSwitch: FunctionComponent<InputSwitchProps> = ({
  label,
  type,
  name,
  control,
  ...rest
}) => {
  const {
    field: { value, ref, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });
  return (
    <FormControlLabel
      label={label}
      control={
        <Switch
          inputRef={ref}
          type={type}
          value={true}
          onChange={(evt, value) => {
            onChange(value);
            onBlur();
          }}
          checked={!!value}
        />
      }
      {...rest}
    />
  );
};

export default InputSwitch;
