import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import SelectFilter, {
  withOptions,
} from "../../../components/table/filters/SelectFilter";
import { Beverage } from "../../../services/types/beverage";
import { useLoading } from "../../loading/hooks";
import { ingredientCategories } from "../../constants";
import {
  getAllGlobalBeverages,
  selectGlobalBeverages,
  selectGlobalBeveragesCount,
} from "./globalBeverageSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  {
    Header: "Category",
    accessor: "category",
    Filter: withOptions(ingredientCategories, SelectFilter),
  },
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
  // { Header: 'Actions', Cell: BarRowActions, disableFilters: true, disableSortBy: true },
];

interface BeverageTableProps {
  onBeverageSelected: (beverage: Beverage) => any;
}

const GlobalBeverageTable: FunctionComponent<BeverageTableProps> = ({
  onBeverageSelected,
}) => {
  const dispatch = useDispatch();
  const beverages = useSelector(selectGlobalBeverages);
  const isLoading = useLoading();
  const totalCount = useSelector(selectGlobalBeveragesCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      dispatch(
        getAllGlobalBeverages({
          page: pageIndex,
          perPage: pageSize,
          sort: sortBy,
          search: filters,
        })
      );
    },
    [dispatch]
  );

  const handleRowClick = useCallback(
    (beverage: Beverage) => {
      onBeverageSelected(beverage);
    },
    [onBeverageSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={beverages}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default GlobalBeverageTable;
