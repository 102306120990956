import {
  Action,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import authReducer from "../features/auth/authSlice";
import barReducer from "../features/barsScreen/barSlice";
import instanceBarReducer from "../features/instanceBar/instanceBarSlice";
import loadingReducer from "../features/loading/loadingSlice";
import notificationReducer from "../features/notification/notificationSlice";
import robotReducer from "../features/robotsScreen/robotSlice";
import userManagementReducer from "../features/userManagementScreen/userManagementSlice";

import bottlesReducer from "../features/ingredientsScreen/bottle/bottleSlice";
import garnishesReducer from "../features/ingredientsScreen/garnish/garnishSlice";

import dispenserBottlesReducer from "../features/positionScreen/bottle/dispenserBottleSlice";
import recipeCategoriesReducer from "../features/recipeCategoryScreen/recipeCategorySlice";
import recipeReducer from "../features/recipeScreen/recipeSlice";

import appOrderReducer from "../features/appOrdersScreen/appOrderSlice";
import queueReducer from "../features/drinkQueueScreen/drinkQueueSlice";
import exportRequestReducer from "../features/exportRequestScreen/exportRequestSlice";
import globalBeveragesReducer from "../features/globalIngredientScreen/beverage/globalBeverageSlice";
import globalBottlesReducer from "../features/globalIngredientScreen/bottle/globalBottleSlice";
import globalCoffeeReducer from "../features/globalIngredientScreen/coffee/globalCoffeeSlice";
import globalCoffeeTypeReducer from "../features/globalIngredientScreen/coffee-type/globalCoffeeTypeSlice";
import globalFlavoursReducer from "../features/globalIngredientScreen/flavour/globalFlavourSlice";
import globalGarnishesReducer from "../features/globalIngredientScreen/garnish/globalGarnishSlice";
import globalGranularsReducer from "../features/globalIngredientScreen/granular/globalGranularSlice";
import globalLeavesReducer from "../features/globalIngredientScreen/leaf/globalLeafSlice";
import beveragesReducer from "../features/ingredientsScreen/beverage/beverageSlice";
import coffeeReducer from "../features/ingredientsScreen/coffee/coffeeSlice";
import flavoursReducer from "../features/ingredientsScreen/flavour/flavourSlice";
import granularsReducer from "../features/ingredientsScreen/granular/granularSlice";
import leavesReducer from "../features/ingredientsScreen/leaf/leafSlice";
import dashboardReducer from "../features/myBarScreen/myBarSlice";
import dispenserBibReducer from "../features/positionScreen/bib-dispenser/bibSlice";
import dispenserCoffeeReducer from "../features/positionScreen/coffee-dispenser/dispenserCoffeeSlice";
import fruitCuttersReducer from "../features/positionScreen/fruit-cutter/fruitCutterSlice";
import dispenserGranularsReducer from "../features/positionScreen/granular-dispenser/dispenserGranularSlice";
import dispenserIceReducer from "../features/positionScreen/ice-dispenser/dispenserIceSlice";
import dispenserLeavesReducer from "../features/positionScreen/leaf-dispenser/dispenserLeafSlice";
import productionReducer from "../features/productionScreen/productionSlice";
import voucherReducer from "../features/vouchersScreen/voucherSlice";
import rootSaga from "../sagas";
import solublesReducer from "../features/ingredientsScreen/soluble/solubleSlice";
import milkReducer from "../features/ingredientsScreen/milk/milkSlice";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    loading: loadingReducer,
    auth: authReducer,
    bars: barReducer,
    users: userManagementReducer,
    instanceBar: instanceBarReducer,
    robots: robotReducer,
    notifications: notificationReducer,
    ingredients: combineReducers({
      bottle: bottlesReducer,
      garnish: garnishesReducer,
      granular: granularsReducer,
      leaf: leavesReducer,
      beverage: beveragesReducer,
      flavour: flavoursReducer,
      coffee: coffeeReducer,
      soluble: solublesReducer,
      milk: milkReducer,
    }),
    globalIngredients: combineReducers({
      bottle: globalBottlesReducer,
      beverage: globalBeveragesReducer,
      flavour: globalFlavoursReducer,
      garnish: globalGarnishesReducer,
      granular: globalGranularsReducer,
      leaf: globalLeavesReducer,
      coffee: globalCoffeeReducer,
      coffeeType: globalCoffeeTypeReducer,
    }),
    dispensers: combineReducers({
      bottle: dispenserBottlesReducer,
      bib: dispenserBibReducer,
      granular: dispenserGranularsReducer,
      garnish: fruitCuttersReducer,
      leaf: dispenserLeavesReducer,
      ice: dispenserIceReducer,
      coffee: dispenserCoffeeReducer,
    }),
    menu: combineReducers({
      recipeCategory: recipeCategoriesReducer,
      recipe: recipeReducer,
    }),
    order: combineReducers({
      queue: queueReducer,
      production: productionReducer,
      dashboard: dashboardReducer,
    }),
    appOrders: appOrderReducer,
    vouchers: voucherReducer,
    exportRequests: exportRequestReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
