import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
  ListItemText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createSoluble, findGlobalSolubleByName } from "./solubleSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Beverage } from "../../../services/types/beverage";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useFieldArray, useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { solubleCategories } from "../../constants";
import { Delete } from "@material-ui/icons";
import { findFlavourByName } from "../flavour/flavourSlice";
import FlavourLookup from "../FlavourLookup";
import InputSwitch from "../../../components/form/InputSwitch";
import InputText from "../../../components/form/InputText";
interface CreateSolubleFormProps {
  bar: Bar | null;
  onCreateSuccess: (beverage: Beverage) => any;
}

const CreateSolubleForm: FunctionComponent<CreateSolubleFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      category: "",
      isVegan: false,
      color: "",
      price: 0,
      alcoholPerc: 0,
      flavours: [""],
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "flavours",
    keyName: "keyId",
  });

  const onSubmit = (data: Beverage) => {
    //setDisabled(true);
    const soluble = {
      name: data.name,
      category: data.category,
      isVegan: selectedCategory === "Milk" ? data.isVegan : false,
      subCategory: data.subCategory,
      color: data.color,
      price: data.price || 0,
      alcoholPerc: data.alcoholPerc || 0,
      flavours: data.flavours,
    };
    console.log(soluble);
    dispatch(
      createSoluble(
        soluble,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Soluble Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Soluble
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            label="Name"
            name="name"
            register={register}
            onChange={(data: any) => {
              if (data) {
                console.log(data);
                data.color && setValue("color", data.color);
                data.alcoholPerc && setValue("alcoholPerc", data.alcoholPerc);
                data.category && setValue("category", data.category);
                data.isVegan && setValue("isVegan", data.isVegan);
                data.flavours &&
                  setValue(
                    "flavours",
                    data.flavours.map((f: any) => {
                      f.globalId = f.id;
                      return f;
                    })
                  );
                setValue("price", 0);
              }
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            onChange={() => setSelectedCategory(control.getValues().category)}
            required
            fullWidth
          >
            {solubleCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
        {selectedCategory === "Milk" && (
          <Grid item xs={12}>
            <InputSwitch
              name="isVegan"
              label="Is Vegan?"
              type="checkbox"
              control={control}
            />
          </Grid>
        )}
      </Grid>
      <br />
      <Typography variant="h5" component="h2" gutterBottom>
        Selected Flavours: {fields.length}/3
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List>
            {fields.map((field, idx) => {
              register({
                name: `flavours[${idx}].id`,
                defaultValue: field.id,
                value: field.id,
              });
              register({
                name: `flavours[${idx}].globalId`,
                defaultValue: field.globalId,
                value: field.globalId,
              });
              return (
                <div key={field.keyId}>
                  <ListItem>
                    <FlavourLookup
                      label={`Flavour ${idx + 1}`}
                      name={`flavours[${idx}].name`}
                      control={control}
                      setError={setError}
                      bar={bar}
                      findIngredient={findFlavourByName}
                      //required
                      onChange={(data: any) => {
                        console.log({ DATA: data });
                        setValue(`flavours[${idx}].id`, data.id);
                      }}
                      defaultValue={field.name}
                      fullWidth
                      disableAdd
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        color="primary"
                        onClick={() => remove(idx)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <InputNumber
                        label="Dispensing Time"
                        name={`flavours[${idx}].dispensingTime`}
                        defaultValue={field.dispensingTime}
                        register={register}
                      />
                    </ListItemText>
                  </ListItem>
                </div>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (fields.length < 3) append({ name: "", dispensingTime: 0 });
              }}
            >
              Add Flavour
            </Button>
          </List>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="price"
            label="Price"
            register={register}
            fullWidth
          />
          <InputNumber
            name="alcoholPerc"
            label="Alcoholic Percentage"
            register={register}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Soluble!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateSolubleForm;
