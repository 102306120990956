import React, { FunctionComponent, useState, ChangeEvent } from "react";
import { Paper, Box, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import PageTitle from "../../components/layout/PageTitle";
import Dialog from "../../components/dialog/Dialog";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import BottleTable from "./bottle/BottleTable";
import GarnishTable from "./garnish/GarnishTable";
import GranularTable from "./granular/GranularTable";
import LeafTable from "./leaf/LeafTable";
import BeverageTable from "./beverage/BeverageTable";
import CreateBottleForm from "./bottle/CreateBottleForm";
import { Bottle } from "../../services/types/bottle";
import { Garnish } from "../../services/types/garnish";
import { Granular } from "../../services/types/granular";
import CreateGarnishForm from "./garnish/CreateGarnishForm";
import CreateGranularForm from "./granular/CreateGranularForm";
import CreateLeafForm from "./leaf/CreateLeafForm";
import CreateBeverageForm from "./beverage/CreateBeverageForm";
import { Leaf } from "../../services/types/leaf";
import { Beverage } from "../../services/types/beverage";
import { capitalize } from "lodash";
import FlavourTable from "./flavour/FlavourTable";
import CreateFlavourForm from "./flavour/CreateFlavourForm";
import { Flavour } from "../../services/types/flavour";
import FloatingActionButton from "../../components/layout/FloatingActionButton";
import { Coffee } from "../../services/types/coffee";
import CoffeeTable from "./coffee/CoffeeTable";
import CreateCoffeeForm from "./coffee/CreateCoffeeForm";
import SolubleTable from "./soluble/SolubleTable";
import CreateSolubleForm from "./soluble/CreateSolubleForm";
import CreateMilkForm from "./milk/CreateMilkForm";
import MilkTable from "./milk/MilkTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const IngredientListScreen: FunctionComponent = (props) => {
  //const [tabActive, setTabActive] = useState<string>(ingredientType[0]);
  const { tabActive } = useParams<{ tabActive: string }>();
  const [open, setOpen] = useState(false);
  const bar = useSelector(selectCurrentInstanceBar);
  const classes = useStyles();
  const history = useHistory();

  const setTabActive = (tab: string) => {
    history.push(`/ingredients/${tab}`);
  };

  return (
    <Box className={classes.root}>
      <PageTitle
        pageTitle={bar?.name || "Ingredient"}
        breadcrumb={[
          bar?.name || "Bar",
          { label: "Ingredients", location: "/ingredients/bottles" },
          capitalize(tabActive),
        ]}
      />

      <Paper>
        <Tabs
          value={tabActive}
          onChange={(event: ChangeEvent<{}>, value) => setTabActive(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {bar?.features.bottles && <Tab label="Bottle" value="bottles" />}
          {bar?.features.bibs && <Tab label="Beverage" value="beverages" />}
          {bar?.features.bibs && <Tab label="Flavour" value="flavours" />}
          {bar?.features.garnish && <Tab label="Garnish" value="garnishes" />}
          {bar?.features.granulars && (
            <Tab label="Granular" value="granulars" />
          )}
          {bar?.features.leaves && <Tab label="Leaves" value="leaves" />}
          {bar?.features.coffee && <Tab label="Coffee" value="coffee" />}
          {bar?.features.coffee && <Tab label="Solubles" value="solubles" />}
          {bar?.features.coffee && <Tab label="Milk" value="milk" />}
        </Tabs>
        {tabActive === "bottles" && (
          <>
            <Box p={3}>
              <BottleTable
                onBottleSelected={(bottle) => {
                  history.push(`/ingredients/bottles/${bottle.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Bottle"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateBottleForm
                bar={bar}
                onCreateSuccess={(bottle: Bottle) => {
                  history.push(`/ingredients/bottles/${bottle.id}`);
                  // setOpen(false);
                }}
              ></CreateBottleForm>
            </Dialog>
          </>
        )}
        {tabActive === "beverages" && (
          <>
            <Box p={3}>
              <BeverageTable
                onBeverageSelected={(beverage) => {
                  history.push(`/ingredients/beverages/${beverage.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Beverage"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateBeverageForm
                bar={bar}
                onCreateSuccess={(beverage: Beverage) => {
                  history.push(`/ingredients/beverages/${beverage.id}`);
                  // setOpen(false);
                }}
              ></CreateBeverageForm>
            </Dialog>
          </>
        )}
        {tabActive === "flavours" && (
          <>
            <Box p={3}>
              <FlavourTable
                onFlavourSelected={(flavour) => {
                  history.push(`/ingredients/flavours/${flavour.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Flavour"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateFlavourForm
                bar={bar}
                onCreateSuccess={(flavour: Flavour) => {
                  history.push(`/ingredients/flavours/${flavour.id}`);
                }}
              ></CreateFlavourForm>
            </Dialog>
          </>
        )}
        {tabActive === "garnishes" && (
          <>
            <Box p={3}>
              <GarnishTable
                onGarnishSelected={(garnish) => {
                  history.push(`/ingredients/garnishes/${garnish.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Garnish"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGarnishForm
                bar={bar}
                onCreateSuccess={(garnish: Garnish) => {
                  history.push(`/ingredients/garnishes/${garnish.id}`);
                }}
              ></CreateGarnishForm>
            </Dialog>
          </>
        )}
        {tabActive === "granulars" && (
          <>
            <Box p={3}>
              <GranularTable
                onGranularSelected={(granular) => {
                  history.push(`/ingredients/granulars/${granular.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Granular"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGranularForm
                bar={bar}
                onCreateSuccess={(granular: Granular) => {
                  history.push(`/ingredients/granulars/${granular.id}`);
                }}
              ></CreateGranularForm>
            </Dialog>
          </>
        )}
        {tabActive === "leaves" && (
          <>
            <Box p={3}>
              <LeafTable
                onLeafSelected={(leaf) => {
                  history.push(`/ingredients/leaves/${leaf.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Leaf"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateLeafForm
                bar={bar}
                onCreateSuccess={(leaf: Leaf) => {
                  history.push(`/ingredients/leaves/${leaf.id}`);
                }}
              ></CreateLeafForm>
            </Dialog>
          </>
        )}
        {tabActive === "coffee" && (
          <>
            <Box p={3}>
              <CoffeeTable
                onCoffeeSelected={(coffee) => {
                  history.push(`/ingredients/coffee/${coffee.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Coffee"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateCoffeeForm
                bar={bar}
                onCreateSuccess={(coffee: Coffee) => {
                  history.push(`/ingredients/coffee/${coffee.id}`);
                }}
              ></CreateCoffeeForm>
            </Dialog>
          </>
        )}
        {tabActive === "solubles" && (
          <>
            <Box p={3}>
              <SolubleTable
                onSolubleSelected={(beverage) => {
                  history.push(`/ingredients/solubles/${beverage.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Soluble"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateSolubleForm
                bar={bar}
                onCreateSuccess={(beverage: Beverage) => {
                  history.push(`/ingredients/solubles/${beverage.id}`);
                }}
              ></CreateSolubleForm>
            </Dialog>
          </>
        )}
        {tabActive === "milk" && (
          <>
            <Box p={3}>
              <MilkTable
                onMilkSelected={(beverage) => {
                  history.push(`/ingredients/milk/${beverage.id}`);
                }}
              />
            </Box>

            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Milk"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateMilkForm
                bar={bar}
                onCreateSuccess={(beverage: Beverage) => {
                  history.push(`/ingredients/milk/${beverage.id}`);
                }}
              ></CreateMilkForm>
            </Dialog>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default IngredientListScreen;
