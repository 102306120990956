import React, { FunctionComponent, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';


import * as d3 from "d3";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative"
  },
  loaderContainer: {
    width: "100%",
    height: "500px",
    position: "absolute",
    background: "rgba(255, 255, 255, 0.75)"
  },
  loader: {
    position: "absolute",
    top: "250px",
    marginLeft:"50%"
  },
  svg: {
    "& #stage .hexGroup text": {
      textAnchor: "middle", 
      fontSize: "18px",
      fontWeight: theme.typography.fontWeightBold,
      fill: theme.palette.text.primary,
      "&.ingredientName": {
        maxWidth: "100px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }
    },
    "& #stage .hexGroup": {
      cursor: "pointer",
      "& polygon": {
        fill: theme.palette.secondary.main,
        stroke: theme.palette.text,
      },
    },
    "& #stage .hexGroup.fake": { 
      cursor: "not-allowed",
      "& polygon": {
        fill: "transparent",
        stroke: theme.palette.primary.light,
      },
      "& text": {
        display: "none"
      }
    },
    "& #stage .hexGroup.disabled": { 
      "& polygon": {
        fill: theme.palette.grey.A100,
        stroke: theme.palette.primary.light
      },
      "& text": {
        fill: theme.palette.text.disabled,
      },
    },
    "& #stage .hexGroup.danger": { 
      "& polygon": {
        fill: theme.palette.error.dark,
        stroke: theme.palette.error.contrastText
      },
      "& text": {
        fill: theme.palette.primary.contrastText,
      },

    },
    "& #stage .hexGroup.warning": { 
      "& polygon": {
        fill: theme.palette.warning.dark,
        stroke: theme.palette.warning.contrastText
      },
      "& text": {
        fill: theme.palette.primary.contrastText,
      },
    },
    
  }
}));
const D3JsBottleDispensers: FunctionComponent<{ 
  onClick: (evt: any) => any; 
  dispensers: any[];
  isLoading: boolean;
}> = ({ onClick, dispensers, isLoading }) => {
  const ref = useRef(null);
  const classes = useStyles();
  
  function trimText(text: string, threshold: number) {
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat("...");
  }

  useEffect(() => {
    const WIDTH_FACTOR = 90;
    const HEIGHT_FACTOR = 50;

    if (ref.current) {
      var svg = d3.select(ref.current);
      var group = svg.append('g').attr("id", "stage");
      var hexGroup = group
        .selectAll("g")
        .data(dispensers)
        .enter().append("g")
        .on("click", (_evt, d) => {
          onClick(d);
        });
      hexGroup
        .attr("class", (d: any) => clsx({ 
            hexGroup: true, 
            fake: d.fake,
            disabled: !d.fake && (!d.enabled || !d.ingredient),
            danger: !d.fake && d.enabled && d.actualLevel <= d.alarmLevel,
            warning: !d.fake && d.enabled && d.actualLevel > d.alarmLevel && (d.actualLevel/d.dispenserCapacity) < 0.3,
          })
        )
        .append('polygon')
        .attr('points', (element) => {
          const w = (element.column * WIDTH_FACTOR);
          const h = ((element.row * 2 + 1 - ((element.column + 1) % 2)) * HEIGHT_FACTOR);
          return `${w + 100},${h + 50} ${w + 75},${h + 90} ${w + 25},${h + 90} ${w},${h + 50} ${w + 25},${h + 10} ${w + 75},${h + 10}`;
        });
      hexGroup
        .append("text")
        .attr("x", (d) => (d.column * WIDTH_FACTOR + 50))
        .attr("y", (d) => ((d.row * 2 + 1 - ((d.column + 1) % 2)) * HEIGHT_FACTOR + 30))
        .text((d) => d?.graphicCode || '.')
      ;
      hexGroup
        .append("text")
        .attr("class", "ingredientName")
        .attr("x", (d) => (d.column * WIDTH_FACTOR + 50))
        .attr("y", (d) => ((d.row * 2 + 1 - ((d.column + 1) % 2)) * HEIGHT_FACTOR + 60))
        .text((d) => d?.ingredient?.name ? trimText(d?.ingredient?.name, 9) : 'Unassigned')
      ;
      var zoom = d3.zoom()
        .scaleExtent([0.5, 8])

        .on("zoom", (event) => {
          const { transform } = event;
          group.attr("transform", transform);
          group.attr("stroke-width", 1 / transform.k);
        });
      // @ts-ignore
      svg.call(zoom);

      if (ref?.current) {
        const prevTransform = d3.zoomTransform(ref?.current as unknown as Element);
        group.attr("transform", prevTransform.toString());
        group.attr("stroke-width", 1 / prevTransform.k);
        console.log(d3.zoomTransform(ref?.current as unknown as Element));
      }

      return () => {
        svg.selectChildren().remove();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, dispensers]);

  return <div className={classes.container}>
    {isLoading ? <div className={classes.loaderContainer}><CircularProgress color="inherit" size={20} className={classes.loader} /></div> : null}
    <svg width="100%" height="500" ref={ref} className={classes.svg}/>
  </div>
};

export default D3JsBottleDispensers;