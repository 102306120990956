import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FunctionComponent } from "react";
import { RegisterOptions } from "react-hook-form";
import InputFile from "../../../components/form/InputFile";
import InputNumber from "../../../components/form/InputNumber";
import InputText from "../../../components/form/InputText";
import { Bar } from "../../../services/types/bar";

interface BarInfoProps {
  register: (arg:RegisterOptions) => any;
  errors: any;
  control: any;
  bar: Bar;
  setValue: (...arg: any) => any;
  className: any;
}

const ComponentBarInfo: FunctionComponent<BarInfoProps> = ({
  register,
  control,
  errors,
  bar,
  setValue,
  ...props
}) => {
  return (
    <div {...props}>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Name and locations
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <InputText
              name="name"
              label="Name"
              register={register}
              error={errors.name?.type === 'required'}
              required
              fullWidth
            />
            <InputText
              name="instanceInfos.placeName"
              label="Location"
              register={register}
              error={errors.instanceInfos?.placeName?.type === 'required'}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputNumber
              name="geoPosition.lat"
              label="Latitude"
              register={register}
              inputProps={{ step: 0.00001 }}
              required
              error={errors.geoPosition?.lat?.type === 'required'}
              fullWidth
            />
            <InputNumber
              name="geoPosition.lng"
              label="Longitude"
              register={register}
              inputProps={{ step: 0.00001 }}
              required
              error={errors.geoPosition?.lng?.type === 'required'}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Infos
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <InputText
              name="instanceInfos.privacyPolicy"
              label="Privacy Policy"
              register={register}
              rules={{pattern: /^(https?):\/\/[^\s$.?#].[^\s]*$/gm}}
              error={errors.instanceInfos?.privacyPolicy?.type}
              required
              fullWidth
            />
            <InputText
              name="instanceInfos.webSite"
              label="Website"
              register={register}
              rules={{pattern: /^(https?):\/\/[^\s$.?#].[^\s]*$/gm}}
              error={errors.instanceInfos?.webSite?.type}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputText
              name="instanceInfos.vatNumber"
              label="Vat No."
              register={register}
              error={errors.instanceInfos?.privacyPolicy?.type === 'required'}
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Media
        </Typography>
        <Grid container spacing={1}>
          <InputFile
            name="instanceInfos.coverImage"
            label="Cover Image"
            control={control}
            setValue={setValue}
          />
          <InputFile
            name="instanceInfos.logo"
            label="Logo Image"
            control={control}
            setValue={setValue}
          />
          <InputFile
            name="instanceInfos.logoBW"
            label="Logo Black And White. Do we need this?"
            control={control}
            setValue={setValue}
          />
        </Grid>
      </Box>
    </div>
  );
};

export default ComponentBarInfo;
