import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../services/types/pagination";
import { User } from "../../services/types/user";
import { MapLike } from "typescript";

// STATE INTERFACE
interface UserManagementState {
  data: User[];
  map: {
    [key: string]: User;
  };
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: UserManagementState = {
  data: [],
  map: {},
  count: 0,
  page: 0,
  perPage: 10,
};

// ACTIONS
export const getAllUsers = createAction<PrepareAction<PaginationPayload>>(
  "user/getAllUsers",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "user",
      resource: "getAllUsers",
    },
  })
);
export const getUserById = createAction<PrepareAction<{ id: string }>>(
  "user/getUserById",
  ({ id, onFailure }) => ({
    payload: { id },
    meta: {
      fetch: "user",
      resource: "getUserById",
      onFailure,
    },
  })
);
export const createUser = createAction<PrepareAction<User>>(
  "user/createUser",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "user",
      resource: "createUser",
      onSuccess,
      onFailure,
    },
  })
);
export const updateUser = createAction<PrepareAction<User>>(
  "user/updateUser",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "user",
      resource: "updateUser",
      onSuccess,
      onFailure,
    },
  })
);
export const updateUserSuccess = createAction<User>("user/updateUser_SUCCESS");
export const getUserByIdSuccess = createAction<User>(
  "user/getUserById_SUCCESS"
);
export const getAllUsersSuccess = createAction<PaginationResponse<User>>(
  "user/getAllUsers_SUCCESS"
);

export const findBarByName = createAction<PrepareAction<{ name: string }>>(
  "user/findBarByName",
  (name: string, onSuccess, onFailure) => ({
    payload: { name },
    meta: {
      fetch: "bar",
      resource: "findBarByName",
      onSuccess,
      onFailure,
    },
  })
);

// REDUCERS
export const userManagementReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getUserByIdSuccess, (state: UserManagementState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(
      getAllUsersSuccess,
      (
        state: UserManagementState,
        { payload: { data, count, page, perPage } }
      ) => {
        state.data = data;
        state.map = _.keyBy(data, (el: User) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(updateUserSuccess, (state: UserManagementState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsers = (state: RootState) => state.users.data;
export const selectUsersCount = (state: RootState) => state.users.count;
export const selectUserById = (id: string) => (state: RootState) =>
  state.users.map[id];
export const selectUsersPagination = (state: RootState) => ({
  page: state.users.page,
  perPage: state.users.perPage,
});

export default userManagementReducer;
