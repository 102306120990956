import React, { FunctionComponent } from "react";

import { RecipeCategory } from "../../services/types/recipeCategory";
import { Recipe } from "../../services/types/recipe";
import { Bar } from "../../services/types/bar";
import RecipeForm from "./RecipeForm";
import { updateRecipe } from "./recipeSlice";
import { showNotification } from "../notification/notificationSlice";
import { useDispatch } from "react-redux";

interface UpdateRecipeFormProps {
  onSuccess: (elem: any) => any;
  recipe: Recipe;
  category: RecipeCategory;
  bar: Bar | null;
}

const UpdateRecipeForm: FunctionComponent<UpdateRecipeFormProps> = ({
  category,
  recipe,
  bar,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(false);


  const handleUpdate = (data: any) => {
    setDisabled(true);
    dispatch(
      updateRecipe(
        data,
        ({ payload }: any) => {
          dispatch(showNotification("Recipe Updated!", "success"));
          setDisabled(false);
          onSuccess(payload);
        },
        (e: any) => {
          dispatch(showNotification(`Recipe update error!`, "error"));
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <RecipeForm
      recipe={recipe}
      bar={bar}
      onFormSubmit={handleUpdate}
      disabled={disabled}
      isCoffee={category.isCoffee}
    />
  );
};
export default UpdateRecipeForm;
