import { Box, Grid } from "@material-ui/core";
import React from "react";
import { FunctionComponent } from "react";
import InputSwitch from "../../../components/form/InputSwitch";
import InputTime from "../../../components/form/InputTime";
import { Bar } from "../../../services/types/bar";
import { useHasPermission } from "../../auth/hooks";

interface BarStateProps {
  bar: Bar;
  control: any;
  errors: any;
  className: any;
}

const ComponentBarState: FunctionComponent<BarStateProps> = ({
  bar,
  control,
  errors,
  ...props
}) => {
  const isSuperAdmin = useHasPermission("SUPERADMIN")
  return (
    <Box p={3} {...props}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputSwitch
            name="open"
            label="Is Bar Open?"
            type="checkbox"
            control={control}
          />
          {isSuperAdmin && (
            <>
              <br />  
              <InputSwitch
                name="hidden"
                label="Do you want to hide the bar from the APP?"
                type="checkbox"
                control={control}
              />
              <br />
              <InputSwitch
                name="blocked"
                label="Blocked bar from administration"
                type="checkbox"
                control={control}
              />
              <br />
              <InputSwitch
                name="enableDrinkBuilder"
                label="Is Drink Builder Enabled?"
                type="checkbox"
                control={control}
              />
            </>
          )}
        </Grid>
        <Grid item md={6}>
          <InputTime
            name="instanceInfos.openingTime"
            label="Opening Time"
            control={control}
            fullWidth
          />

          <InputTime
            name="instanceInfos.closingTime"
            label="Closing Time"
            control={control}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComponentBarState;
