import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import { AuthSignInPayload } from "../../services/auth";
import { RootState } from "../../store";
import Hotjar from "@hotjar/browser";

export interface UserType {
  id: string;
  email: string;
  superadmin: boolean;
  roles: [
    {
      barId: string;
      role: string;
      barName: string;
    }
  ];
  expiresIn: string;
  accessToken: string;
}

// STATE INTERFACE
export interface AuthState {
  isAuthenticated: boolean;
  user?: UserType;
}

// INITIAL STATE
const initialState: AuthState = {
  isAuthenticated: false,
};

// ACTIONS
export const signIn = createAction<PrepareAction<AuthSignInPayload>>(
  "auth/signIn",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "auth",
      resource: "authSignIn",
      onSuccess,
      onFailure,
    },
  })
);
export const signInSuccess = createAction<UserType>("auth/signIn_SUCCESS");
export const signOut = createAction("auth/signOut");

// REDUCERS
const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(signInSuccess, (state: AuthState, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;

      if (process.env.REACT_APP_HOTJAR_SITE_ID) {
        Hotjar.identify(payload.id, {
          email: payload.email
        });
      }

    })
    .addCase(signOut, () => ({ isAuthenticated: false }));
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.auth.user;
export const selectRole = (state: RootState) => {
  if (state.auth.user?.superadmin) {
    return "SUPERADMIN";
  } else if (state.auth.user?.roles?.length && state.instanceBar.current?.id) {
    return (
      (
        state.auth.user?.roles.find(
          (role) => role.barId === state.instanceBar.current?.id
        ) || {}
      ).role || "GUEST"
    );
  }
  return "GUEST";
};
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export default authReducer;
