import {
  call,
  put,
  take,
  select,
  takeEvery,
  fork,
  cancel,
  cancelled,
} from 'redux-saga/effects';
import { EventChannel, eventChannel, Task } from 'redux-saga'
import mqtt, { MqttClient } from 'mqtt';
import { PayloadAction } from '@reduxjs/toolkit';
import { barInstanceUpdatedRemotely, impersonateBar, robotInstanceUpdatedRemotely } from '../../features/instanceBar/instanceBarSlice';
import { signInSuccess, signOut, UserType } from '../../features/auth/authSlice';
import { Bar } from '../../services/types/bar';
import MqttPayload from './types/MqttPayload';
import { selectTopicSubscribed } from '../../features/notification/notificationSlice';
import { orderInQueueUpdatedRemotely } from '../../features/drinkQueueScreen/drinkQueueSlice';
import { Drink } from '../../services/types/drink';
import { Robot } from '../../services/types/robot';

export const WS_MESSAGE = "notification/WS_MESSAGE";
export const WS_CONNECTED = "notification/WS_CONNECTED";
export const WS_DISCONNECTED = "notification/WS_DISCONNECTED";
export const WS_ERROR = "notification/WS_ERROR";
export const WS_SUBSCRIBED = "notification/WS_SUBSCRIBED";


function createWebSocketConnection(action: PayloadAction<UserType>): MqttClient {
  return mqtt.connect(process.env.REACT_APP_MQTT_URL, {
    clientId: `web_${action.payload.email}_${Math.floor((Math.random() * 9000) + 1000)}`,
    username: action.payload.email,
    password: action.payload.accessToken,
  });
}

function createSocketChannel(socket: MqttClient) {
  return eventChannel<PayloadAction<any | null> | Error>(emit => {

    socket.on('connect', () => {
      emit({ type: WS_CONNECTED, payload: null });
    });
    socket.on('error', (err) => {
      console.error('Connection error: ', err);
      emit({ type: WS_ERROR, payload: err });
    });
    socket.on('reconnect', () => {
      console.info('MQTT - Reconnecting');
    });
    socket.on('message', (topic, message) => {
      try {
        const payload: MqttPayload = { topic, message: JSON.parse(message.toString()) };
        console.log('MQTT - Message received. ', payload);
        emit({ type: WS_MESSAGE, payload });
      } catch (e) {
        console.error("Malformed MQTT message", {topic, message});
      }
    });

    const unsubscribe = () => {
      console.warn("Closing eventChannel - Disconnecting");
      socket.end();
    }
    return unsubscribe;
  });
}


function* handleSubscribeToBarTopic(socket: MqttClient) {
  try {    
    while (true) {
      const impersonateBarAction : PayloadAction<Bar> = yield take(impersonateBar.match);
      const topic = `admin/${impersonateBarAction.payload.id}`;
      const previouslySubscribedTopic: string | null = yield select(selectTopicSubscribed);
      (previouslySubscribedTopic) && socket.unsubscribe(previouslySubscribedTopic);
      socket.subscribe(topic);
      yield put({ type: WS_SUBSCRIBED, payload: topic });
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      console.log("handleSubscribeToBarTopic CANCELLED");
    }    
  }
}

function* handleWsIncomingMessages(socketChannel: EventChannel<any | null>) {
  try {    
    while (true) {
      const action : PayloadAction = yield take(socketChannel)
      console.log({ action });
      yield put(action);
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      socketChannel.close();
    }    
  }
}

function* handleMessageDispatch(message: PayloadAction<MqttPayload>) {
  if (message.payload.message.type === 'drink') {
    yield put(orderInQueueUpdatedRemotely(message.payload.message.payload as unknown as Drink));
  } else if (message.payload.message.type === 'bar') { 
    yield put(barInstanceUpdatedRemotely(message.payload.message.payload as unknown as Bar));
  } else if (message.payload.message.type === 'robot') {
    yield put(robotInstanceUpdatedRemotely(message.payload.message.payload as unknown as Robot));
  } else {
    throw new Error("MQTT - Unexpected Message Type");
  }

}

function* handleAuthSuccess(loginAction: PayloadAction<UserType>) {
  const socket: MqttClient = yield call(createWebSocketConnection, loginAction);
  const socketChannel: EventChannel<any | null> = yield call(createSocketChannel, socket);

  const subscriberTask: Task = yield fork(handleSubscribeToBarTopic, socket);
  const task: Task = yield fork(handleWsIncomingMessages, socketChannel);
  yield take(signOut.match);
  yield cancel(subscriberTask);
  yield cancel(task);
  yield put({ type: WS_DISCONNECTED });
}

function* websocketSaga() {
  yield takeEvery(signInSuccess.match, handleAuthSuccess);
  yield takeEvery(WS_MESSAGE, handleMessageDispatch);
}

export default websocketSaga;