import React from "react";
import { FunctionComponent } from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { Bar } from "../../services/types/bar";
import { Recipe } from "../../services/types/recipe";
import InputText from "../../components/form/InputText";
import MainSpacer from "../../components/layout/MainSpacer";
import { orderRecipeForBar } from "./recipeSlice";
import { useDispatch } from "react-redux";
import { showNotification } from "../notification/notificationSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

interface OrderRecipeFormProps {
  recipe: Recipe;
  bar: Bar | null;
  onFormSubmit: () => any;
}

const OrderRecipeForm: FunctionComponent<OrderRecipeFormProps> = ({
  recipe,
  bar,
  onFormSubmit,
}) => {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    const payload = {
      ...recipe,
      orderName: data.orderName,
    };
    console.log(payload);

    dispatch(
      orderRecipeForBar(
        payload,
        bar?.id,
        undefined,
        () =>
          dispatch(showNotification("Drink Ordered Successfully", "success")),
        (e: any) => {
          console.error(e);
        }
      )
    );
    onFormSubmit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h2" variant="h5">
        Do you want to order {recipe?.name}?
      </Typography>

      <InputText
        name="orderName"
        label="Order Details"
        fullWidth
        register={register}
      />
      <MainSpacer />
      <div className={classes.navigation}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onFormSubmit}
        >
          NO
        </Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
        >
          ORDER
        </Button>
      </div>
    </form>
  );
};

export default OrderRecipeForm;
