import React, { FunctionComponent, useState } from "react";

import { Box, FormControl, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showNotification } from "../../notification/notificationSlice";
import { findCoffeeByName, updateDispenserCoffee } from "./dispenserCoffeeSlice";
import { DispenserCoffee } from "../../../services/types/dispenserCoffee";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import IngredientDispenserLookup from "../IngredientDispenserLookup";

interface EditCoffeeFormProps {
  onSuccess: () => any;
  dispenser: DispenserCoffee;
}

const EditCoffeeForm: FunctionComponent<EditCoffeeFormProps> = ({
  onSuccess,
  dispenser,
}) => {
  const { register, control, handleSubmit, setError } = useForm({
    defaultValues: dispenser,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: DispenserCoffee) => {
    setDisabled(true);
    dispatch(
      updateDispenserCoffee(
        {
          ...dispenser,
          ...data,
          ingredientId: data.ingredient?.id || null,
        },
        () => {
          dispatch(showNotification("Dispenser Saved!", "success"));
          onSuccess();
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <FormControl margin="normal" fullWidth>
            <IngredientDispenserLookup
                label="Name"
                name="ingredient"
                barId={dispenser.barId}
                control={control}
                setError={setError}
                // onChange={(data: any) => {
                //   if (data && data.id) {
                //     console.log(data);
                //     setValue("ingredientId", data.id);
                //   } else {
                //     setValue("ingredientId", "");
                //   }
                // }}
                findIngredient={findCoffeeByName}
                fullWidth
              />
              <Grid item xs={12} md={6}>

              <InputNumber
                name="grinderTime"
                label="Grinder Time"
                register={register}
                required
                fullWidth
              />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <MainSpacer />
      </Box>
      <InputButton disabled={disabled}>Save!</InputButton>
    </form>
  );
};

export default EditCoffeeForm;
