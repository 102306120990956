import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper } from "@material-ui/core";


import { getAllUsers, selectUsers, selectUsersCount } from "./userManagementSlice";
import { useLoading } from "../loading/hooks";
import { User } from '../../services/types/user';
import { Column } from 'react-table';
import EnhancedTable, { FetchDataArgs } from '../../components/table/EnhancedTable';
import BarNameChipCell from './BarNameChipCell';
import RoleBadgeCell from './RolesBadgeCell';

interface UserManagementTableParams {
  onUserSelected: (User: User) => any;
}

const columns: Column<any>[] = [
  { Header: 'Name', accessor: "firstName" },
  { Header: 'Surname', accessor: "lastName" },
  { Header: 'Email', accessor: "email" },
  { Header: 'Roles', accessor: "roles", disableFilters: true, disableSortBy: true, Cell: RoleBadgeCell },
  { Header: 'Bars', id: "barName", accessor: "roles", disableFilters: true, disableSortBy: true, Cell: BarNameChipCell },
];

const UserManagementTable: FunctionComponent<UserManagementTableParams> = ({ onUserSelected }) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const Users = useSelector(selectUsers);
  const totalCount = useSelector(selectUsersCount);


  const handleFetchData = useCallback(({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
    dispatch(getAllUsers({ page: pageIndex, perPage: pageSize, sort: sortBy, search: filters }));
  }, [dispatch]);

  const handleRowClick = useCallback((User: User) => {
    onUserSelected(User);
  }, [onUserSelected]);

  return (
    <Paper>
      <Box p={3}>
        <EnhancedTable
          columns={columns}
          data={Users}
          totalCount={totalCount}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          fetchData={handleFetchData}
        />
      </Box>
    </Paper>

  );
}

export default UserManagementTable
