import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  MenuItem,
  DialogActions,
  LinearProgress,
  List,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Beverage } from "../../../services/types/beverage";
import { showNotification } from "../../notification/notificationSlice";
import { createGlobalBeverage } from "./globalBeverageSlice";
import { beverageCategories } from "../../constants";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useFieldArray, useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputColor from "../../../components/form/InputColor";
import InputButton from "../../../components/form/InputButton";
import { Delete } from "@material-ui/icons";
import InputNumber from "../../../components/form/InputNumber";
import GlobalIngredientLookup from "../../ingredientsScreen/GlobalIngredientLookup";
import { findGlobalFlavourByName } from "../../ingredientsScreen/flavour/flavourSlice";

interface CreateGlobalBeverageFormProps {
  onCreateSuccess: (beverage: Beverage) => any;
}

const CreateGlobalBeverageForm: FunctionComponent<CreateGlobalBeverageFormProps> = ({
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "flavours",
    keyName: "keyId",
  });

  const onSubmit = (data: Beverage) => {
    //setDisabled(true);
    const beverage = {
      name: data.name,
      category: data.category,
      color: data.color,
      alcoholPerc: data.alcoholPerc || 0,
      flavours: data.flavours,
    };
    console.log(beverage);
    dispatch(
      createGlobalBeverage(
        beverage,
        ({ payload }: any) => {
          dispatch(showNotification("Global Beverage Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Global Beverage
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            name="name"
            label="Name"
            register={register}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {beverageCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <Typography variant="h5" component="h2" gutterBottom>
        Selected Flavours: {fields.length}/3
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List>
            {fields.map((field, idx) => (
              <div key={field.keyId}>
                <input
                  name={`flavours[${idx}].id`}
                  type="hidden"
                  ref={register()}
                  //defaultValue={field.id} // make sure to set up defaultValue
                />
                <ListItem>
                  {/* <FieldArraySelect
                    label={`Flavour ${idx + 1}`}
                    name={`flavours[${idx}].name`}
                    defaultValue={field.name}
                    control={control}
                    onChangeProp={(evt) => {
                      setValue(
                        `flavours[${idx}].id`,
                        flavours.find((f) => f.name === evt.target.value)?.id
                      );
                      setValue(
                        `flavours[${idx}].name`,
                        flavours.find((f) => f.name === evt.target.value)?.name
                      );
                    }}
                  >
                    {flavours.map((sub, jdx) => (
                      <MenuItem key={jdx} value={sub.name}>
                        {sub.name}
                      </MenuItem>
                    ))}
                  </FieldArraySelect> */}

                  <GlobalIngredientLookup
                    label={`Flavour ${idx + 1}`}
                    name={`flavours[${idx}].name`}
                    control={control}
                    setError={setError}
                    findGlobalIngredient={findGlobalFlavourByName}
                    required
                    onChange={(data: any) => {
                      console.log({ DATA: data });
                      setValue(`flavours[${idx}].id`, data?._id);
                    }}
                    fullWidth
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="primary"
                      onClick={() => remove(idx)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <InputNumber
                      label="Dispensing Time"
                      name={`flavours[${idx}].dispensingTime`}
                      defaultValue={field.dispensingTime}
                      register={register}
                    />
                  </ListItemText>
                </ListItem>
              </div>
            ))}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (fields.length < 3) append({ name: "", dispensingTime: 0 });
              }}
            >
              Add Flavour
            </Button>
          </List>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="alcoholPerc"
            label="Alcoholic Percentage"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            register={register}
            fullWidth
          />
        </Grid>
      </Grid>
      <MainSpacer />
      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Beverage!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateGlobalBeverageForm;
