import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import { selectBeverageById, getBeverageById } from "./beverageSlice";
import EditBeverageForm from "./EditBeverageForm";
import { useHasPermission } from "../../auth/hooks";

const BeverageShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const beverage = useSelector(selectBeverageById(id));
  const isSuperAdmin = useHasPermission("SUPERADMIN");

  useEffect(() => {
    if (bar) {
      dispatch(
        getBeverageById(
          { id, onFailure: () => history.push("/ingredients") },
          bar
        )
      );
    }
  }, [dispatch, id, bar, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={bar?.name || "Beverage"}
        breadcrumb={[bar?.name || "Bar", {label: "Ingredients", location: "/ingredients/bottles"}, {label: "Beverages", location: "/ingredients/beverages"}, beverage?.name]}
      />
      {beverage && <EditBeverageForm beverage={beverage} bar={bar} />}

      {isSuperAdmin && (
        <Paper>
          {isLoading && <LinearProgress />}
          <code>
            <JSONTree data={beverage} />
          </code>
        </Paper>
      )}
    </Box>
  );
};

export default BeverageShowScreen;
