import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper } from "@material-ui/core";


import { getAllRobots, selectRobots, selectRobotsCount } from "./robotSlice";
import { useLoading } from "../loading/hooks";
import { Robot } from '../../services/types/robot';
import EnhancedTable, { FetchDataArgs } from '../../components/table/EnhancedTable';
import { Column } from 'react-table';


interface RobotTableParams {
  onRobotSelected: (robot: Robot) => any;
}

const RobotTable: FunctionComponent<RobotTableParams> = ({ onRobotSelected }) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const robots = useSelector(selectRobots);
  const totalCount = useSelector(selectRobotsCount);

  const columns: Column<any>[] = [
    { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: "tagName" },
    { Header: 'Display Name', accessor: "displayName" },
  ];
  
  const handleFetchData = useCallback(({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
    dispatch(getAllRobots({ page: pageIndex, perPage: pageSize, sort: sortBy, search: filters }));
  }, [dispatch]);

  const handleRowClick = (robot: Robot) => {
    onRobotSelected(robot);
  }

  return (
    <Paper>
      <Box p={3}>
        <EnhancedTable
          columns={columns}
          data={robots}
          totalCount={totalCount}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          fetchData={handleFetchData}
        />
      </Box>
    </Paper>

  )
}

export default RobotTable
