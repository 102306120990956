import React, { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showNotification } from "../notification/notificationSlice";
import { updateRecipeCategory } from "./recipeCategorySlice";
import { InnerForm } from "./RecipeCategoryForm";
import { RecipeCategory } from "../../services/types/recipeCategory";
import { useForm } from "react-hook-form";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";

interface UpdateRecipeCategoryFormProps {
  recipeCategory: RecipeCategory;
  onFormSubmit: (elem: any) => any;
}
const UpdateRecipeCategoryForm: FunctionComponent<UpdateRecipeCategoryFormProps> = ({
  recipeCategory,
  onFormSubmit,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: recipeCategory,
  });

  const bar = useSelector(selectCurrentInstanceBar);

  const onSubmit = (data: RecipeCategory) => {
    setDisabled(true);
    recipeCategory = {
      ...recipeCategory,
      ...data,
    };
    console.log(recipeCategory);
    dispatch(
      updateRecipeCategory(
        recipeCategory,
        ({ payload }: any) => {
          setDisabled(false);
          dispatch(showNotification("Category Saved!", "success"));
          onFormSubmit(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InnerForm
        inputRef={register}
        control={control}
        setValue={setValue}
        disabled={disabled}
        hasCoffee={bar?.features.coffee}
      />
    </form>
  );
};
export default UpdateRecipeCategoryForm;
