import React, { FunctionComponent } from "react";
import {
  DialogActions,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createUser } from "./userManagementSlice";
import { useForm } from "react-hook-form";
import { showNotification } from "../notification/notificationSlice";
import InputText from "../../components/form/InputText";
import InputButton from "../../components/form/InputButton";
import MainSpacer from "../../components/layout/MainSpacer";

interface CreateUserFormProps {
  onCreateSuccess: (...args: any) => any;
}

const CreateUserForm: FunctionComponent<CreateUserFormProps> = ({
  onCreateSuccess,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
  } = useForm();


  const onSubmit = (data: any) => {
    setDisabled(true);
    console.log(data);
    dispatch(
      createUser(
        data,
        ({ payload }: any) => {
          dispatch(showNotification("User Created!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          dispatch(showNotification(`User creation error!`, "error"));
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputText
              name="firstName"
              label="First Name"
              register={register}
              required
              fullWidth
              error={errors.firstName?.type === 'required'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputText
              name="lastName"
              label="Last Name"
              register={register}
              required
              fullWidth
              error={errors.lastName?.type === 'required'}
            />
          </Grid>
          <Grid item xs={12} >
            <InputText
              name="email"
              label="Email"
              register={register}
              rules={{
                required: { value: true, message: "Email is required" },
                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Email is invalid" },
              }}
              required
              fullWidth
              error={errors.email}
              helperText={errors.email && errors.email.message}
            />
          </Grid>
          <Grid item xs={12} >
            <InputText
              name="password"
              label="Password"
              type="password"
              register={register}
              required
              fullWidth
              error={errors.password}
            />
          </Grid>
        </Grid>
        <MainSpacer />
        <DialogActions style={{ padding: 0 }}>
          <InputButton disabled={disabled}>Create User!</InputButton>
        </DialogActions>
        {disabled && <LinearProgress />}
      </form>
  );
};

export default CreateUserForm;
