import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import Box from "@material-ui/core/Box";

import PageTitle from "../../components/layout/PageTitle";
import Dialog from "../../components/dialog/Dialog";
import VoucherTable from "./VoucherTable";

import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { Voucher } from "../../services/types/voucher";
import CreateVoucherForm from "./CreateVoucherForm";
import FloatingActionButton from "../../components/layout/FloatingActionButton";

const AppOrderListScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const bar = useSelector(selectCurrentInstanceBar);
  const history = useHistory();

  return (
    <Box>
      <PageTitle
        pageTitle={"Vouchers"}
        breadcrumb={[bar?.name || "Bar", "Vouchers"]}
      />

      <VoucherTable
        onVoucherSelected={(voucher: Voucher) => {
          history.push(`/vouchers/${voucher.id}`);
        }}
      />

      <FloatingActionButton onClick={() => setOpen(true)} />
      <Dialog
        title="Create New Voucher"
        open={open}
        onAbort={() => setOpen(false)}
      >
        <CreateVoucherForm
          bar={bar}
          onCreateSuccess={(voucher: Voucher) => {
            history.push(`/vouchers/${voucher.id}`);
            // setOpen(false);
          }}
        ></CreateVoucherForm>
      </Dialog>
    </Box>
  );
};
export default AppOrderListScreen;
