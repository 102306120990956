import { Button, Grid, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { FunctionComponent } from "react";
import MainSpacer from "../../layout/MainSpacer";

interface FilePreviewProps {
  name: string;
  value: any;
  label: string;
  setValue: (...args: any) => any;
  styles: any;
}

const InputFilePreview: FunctionComponent<FilePreviewProps> = ({
  name,
  value,
  label,
  styles,
  setValue,
}) => {
  let url;
  try {
    if (value instanceof Array && value.length) {
      url = URL.createObjectURL(value[0])
    } else {
      url = value;
    }
  } catch (e) {
    console.error("image parsing error", e, value);
  }
  return (
    <Grid item xs={12} className={styles.root}>
      <div className={styles.column}>
        <img
          src={url}
          alt={`${label} failed to load`}
          className={styles.image}
        />
      </div>
      <div className={styles.infos}>
        <Typography component="h2" variant="h5">
          {label}
        </Typography>
        <MainSpacer />
        <Button
          className={styles.buttons}
          variant="contained"
          fullWidth
          color="primary"
          endIcon={<DeleteIcon />}
          onClick={() => setValue(name, "")}
        >
          Remove
        </Button>
      </div>
    </Grid>
  );
};

export default InputFilePreview;
