import React, { FunctionComponent } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

const useStyle = makeStyles((theme) => (
    {
        mainSpacer: {
            margin: theme.spacing(3, 0),
        }
    }
))

const MainSpacer: FunctionComponent = () => {
    const classes = useStyle();
    return (
        <Divider className={classes.mainSpacer}/>
    )
}

export default MainSpacer;
