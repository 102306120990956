import {createAction, createReducer, PrepareAction} from "@reduxjs/toolkit";
import {MapLike} from "typescript";
import _ from "lodash";
import {Bar} from "../../services/types/bar";
import {Drink} from "../../services/types/drink";
import {
    PaginationPayloadByBar,
    PaginationResponse,
} from "../../services/types/pagination";
import {RootState} from "../../store";

interface ProductionState {
    data: Drink[];
    map: MapLike<Drink>;
    count: number;
    page: number;
    perPage: number;
}

const initialState: ProductionState = {
    data: [],
    map: {},
    count: 0,
    page: 1,
    perPage: 5,
};

//ACTIONS
const getAllByStatusAction: PrepareAction<PaginationPayloadByBar> = ({page, perPage, sort, search}, status: string, bar: Bar, onSuccess, onFailure) => ({
  payload: {
      barId: bar.id,
      page,
      perPage,
      sort: (sort || []).reduce(
          (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
              acc[sorter.id] = sorter.desc ? -1 : 1;
              return acc;
          },
          {}
      ),
      search: (search || []).reduce(
          (acc: MapLike<string>, searcher: { id: string; value: string }) => {
              acc[searcher.id] = searcher.value;
              return acc;
          },
          {}
      ),
      status: status,
  },
  meta: {
      fetch: "drinks",
      resource: "getAllByStatus",
      onSuccess,
      onFailure,
  },
});
export const getAllByStatus = createAction<PrepareAction<PaginationPayloadByBar>>("production/getAllByStatus", getAllByStatusAction);
export const getAllByStatusRaw = createAction<PrepareAction<PaginationPayloadByBar>>("production/getAllByStatusRaw", getAllByStatusAction);

export const getAllByStatusSuccess = createAction<PaginationResponse<Drink>>(
  "production/getAllByStatus_SUCCESS"
);

export const updateDrinkErrorAck = createAction<PrepareAction<Drink>>(
  "queue/updateDrinkErrorAck",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "drinks",
      resource: "updateDrinkErrorAck",
      onSuccess,
      onFailure,
    },
  })
);
export const updateDrinkErrorAckSuccess = createAction<Drink>(
  "queue/updateDrinkErrorAck_SUCCESS"
);

//REDUCERS
const productionReducer = createReducer(initialState, (builder) => {
    builder
      .addCase(
        getAllByStatusSuccess,
        (state: ProductionState, { payload: { data, count } }) => {
          state.data = data;
          // .map((el) => {
          //   el.createdAt = (timeElapsed(
          //     new Date(),
          //     new Date(el.createdAt)
          //   ) as unknown) as Date;
          //   return el;
          // });
          state.map = _.keyBy(data, (el: Drink) => el.id);
          state.count = count;
        }
      )
      .addCase(
        updateDrinkErrorAckSuccess,
        (state: ProductionState, { payload }) => {
          state.map[payload.id] = payload;
          state.data = state.data.map((el) => {
            if (el.id === payload.id) {
              // payload.createdAt = (timeElapsed(
              //   new Date(),
              //   new Date(payload.createdAt)
              // ) as unknown) as Date;
              return payload;
            }
            return el;
          });
        }
      )
});


//SELECTOR
export const selectProduction = (state: RootState) =>
    state.order.production.data;
export const selectProductionCount = (state: RootState) =>
    state.order.production.count;

export default productionReducer;
