import { Paper, Box } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import DateTimeCell from '../../components/table/cells/DateTimeCell';

import EnhancedTable, { EnhancedTableRef, FetchDataArgs } from '../../components/table/EnhancedTable';
import { useLoading } from '../loading/hooks';
import { selectExportRequests, selectExportRequestsCount, getAllExportRequests } from './exportRequestSlice';
import { ExportRequest } from '../../services/types/exportRequest';
import { selectCurrentInstanceBar } from '../instanceBar/instanceBarSlice';
import { DownloadExportCell } from './DownloadExportCell';


const columns: Column<any>[] = [
  { Header: 'Collection', accessor: "collectionName" },
  { Header: 'User', accessor: "userDisplay" },
  { Header: 'File', accessor: "filepath", Cell: DownloadExportCell, disableSortBy: true },
  {Header: "Created At", accessor: "createdAt", Cell: DateTimeCell, disableFilters: true},
];


const ExportRequestTable: FunctionComponent = () => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);
  const isLoading = useLoading();
  const ExportRequests = useSelector(selectExportRequests);
  const totalCount = useSelector(selectExportRequestsCount);

  const handleFetchData = useCallback(({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
    if (bar) {
      dispatch(getAllExportRequests({ page: pageIndex, perPage: pageSize, sort: sortBy, search: filters }, bar));
    }
  }, [dispatch, bar]);

  const handleRowClick = useCallback((ExportRequest: ExportRequest) => {
    console.log(ExportRequest)
  }, []);

  const tableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(tableRef);
      tableRef?.current?.refresh();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Paper>
      <Box p={3}>
        <EnhancedTable
          columns={columns}
          data={ExportRequests}
          totalCount={totalCount}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          fetchData={handleFetchData}
          ref={tableRef}
        />
      </Box>
    </Paper>
  )
}
export default ExportRequestTable;
