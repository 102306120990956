import React, { FunctionComponent, useEffect } from "react";

import JSONTree from "react-json-tree";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, LinearProgress, Paper } from "@material-ui/core";

import PageTitle from "../../components/layout/PageTitle";
import { useLoading } from "../loading/hooks";
import { getUserById, selectUserById } from "./userManagementSlice";
import EditUserForm from "./EditUserForm";
// import EditUserForm from "./EditUserForm";

const UserManagementShowScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const user = useSelector(selectUserById(id));

  useEffect(() => {
    dispatch(
      getUserById({
        id,
        onFailure: () => history.push("/user-management"),
      })
    );
  }, [dispatch, id, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={user?.email || "User Detail"}
        breadcrumb={["Operations", {label: "Users", location: "/user-management"}]}
        />

      <Paper>
        {isLoading && <LinearProgress />}
        {user && <EditUserForm user={user} />}
        <code>
          <JSONTree data={user} />
        </code>
      </Paper>
    </Box>
  );
};

export default UserManagementShowScreen;
