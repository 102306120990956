import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Flavour } from "../../../services/types/flavour";

interface FlavourState {
  data: Flavour[];
  map: MapLike<Flavour>;
  count: number;
  page: number;
  perPage: number;
}

const initialState: FlavourState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

//Actions
export const getAllFlavours = createAction<
  PrepareAction<PaginationPayloadByBar>
>("flavours/getAllFlavours", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar.id,
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllFlavours",
  },
}));

export const getAllFlavoursSuccess = createAction<PaginationResponse<Flavour>>(
  "flavours/getAllFlavours_SUCCESS"
);

export const getFlavourById = createAction<PrepareAction<{ id: string }>>(
  "flavours/getFlavourById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getFlavourById",
      onFailure,
    },
  })
);

export const getFlavourByIdSuccess = createAction<Flavour>(
  "flavours/getFlavourById_SUCCESS"
);

export const findFlavourByName = createAction<PrepareAction<{ name: string }>>(
  "flavours/findFlavourByName",
  (name, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar?.id,
      name,
    },
    meta: {
      fetch: "ingredients",
      resource: "findFlavourByName",
      onSuccess,
      onFailure,
    },
  })
);

export const findFlavourByNameSuccess = createAction<Flavour>(
  "flavours/findFlavourByName_SUCCESS"
);

export const updateFlavour = createAction<PrepareAction<Flavour>>(
  "flavours/updateFlavours",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateFlavour",
      onSuccess,
      onFailure,
    },
  })
);

export const updateFlavourSuccess = createAction<Flavour>(
  "flavours/updateFlavour_SUCCESS"
);

export const findGlobalFlavourByName = createAction<
  PrepareAction<{ name: string }>
>("flavours/findGlobalFlavourByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalFlavourByName",
    onSuccess,
    onFailure,
  },
}));

export const createFlavour = createAction<PrepareAction<Flavour>>(
  "flavours/createFlavour",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createFlavour",
      onSuccess,
      onFailure,
    },
  })
);

export const createFlavourSuccess = createAction<Flavour>(
  "flavours/createFlavour_SUCCESS"
);

//Reducers
const flavoursReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllFlavoursSuccess,
      (state: FlavourState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Flavour) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getFlavourByIdSuccess, (state: FlavourState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    /*  .addCase(findFlavourByNameSuccess, (state: FlavourState, { payload }) => {
      state.data.push(payload);
      state.map[payload.name] = payload;
    }) */
    .addCase(updateFlavourSuccess, (state: FlavourState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

//Selector
export const selectFlavours = (state: RootState) =>
  state.ingredients.flavour.data;
export const selectFlavourCount = (state: RootState) =>
  state.ingredients.flavour.count;
export const selectFlavourById = (id: string) => (state: RootState) =>
  state.ingredients.flavour.map[id];

export default flavoursReducer;
