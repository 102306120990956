import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Garnish } from "../../../services/types/garnish";
import { MapLike } from "typescript";

// STATE INTERFACE
interface GarnishState {
  data: Garnish[];
  map: MapLike<Garnish>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: GarnishState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalGarnishes = createAction<
  PrepareAction<PaginationPayload>
>(
  "garnishes/getAllGlobalGarnishes",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getAllGlobalGarnishes",
    },
  })
);

export const getAllGlobalGarnishesSuccess = createAction<
  PaginationResponse<Garnish>
>("garnishes/getAllGlobalGarnishes_SUCCESS");

export const getGlobalGarnishById = createAction<PrepareAction<{ id: string }>>(
  "garnishes/getGlobalGarnishById",
  ({ id, onFailure }) => ({
    payload: {
      id,
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getGlobalGarnishById",
      onFailure,
    },
  })
);
export const getGlobalGarnishByIdSuccess = createAction<Garnish>(
  "garnishes/getGlobalGarnishById_SUCCESS"
);

export const updateGlobalGarnish = createAction<PrepareAction<Garnish>>(
  "garnishes/updateGlobalGarnishes",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalGarnish",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalGarnishSuccess = createAction<Garnish>(
  "garnishes/updateGlobalGarnish_SUCCESS"
); /* 
export const findGlobalGarnishByName = createAction<
  PrepareAction<{ name: string }>
>("garnishes/findGlobalGarnishByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalGarnishByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalGarnish = createAction<PrepareAction<Garnish>>(
  "garnishes/createGlobalGarnishes",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalGarnish",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalGarnishSuccess = createAction<Garnish>(
  "garnishes/createGlobalGarnish_SUCCESS"
);

// REDUCERS
const globalGarnishesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalGarnishesSuccess,
      (state: GarnishState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Garnish) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGlobalGarnishByIdSuccess,
      (state: GarnishState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      }
    )
    .addCase(updateGlobalGarnishSuccess, (state: GarnishState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalGarnishes = (state: RootState) =>
  state.globalIngredients.garnish.data;
export const selectGlobalGarnishesCount = (state: RootState) =>
  state.globalIngredients.garnish.count;
export const selectGlobalGarnishById = (id: string) => (state: RootState) =>
  state.globalIngredients.garnish.map[id];

export default globalGarnishesReducer;
