import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormControlProps,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { FunctionComponent } from "react";

type InputSelectProps = FormControlProps & {
  control: any;
  name: string;
  label: string;
  onChange?: (evt: any) => void;
};

const InputSelect: FunctionComponent<InputSelectProps> = (props) => {
  const {
    name,
    label,
    control,
    defaultValue,
    onChange: onParentChange,
    ...rest
  } = props;
  
  const labelId = `${name}-label`;
  return (
    <FormControl margin="normal" {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={(
          { onChange, onBlur, value, name, ref },
          { invalid, isTouched, isDirty }
        ) => (
          <Select labelId={labelId} label={label} onChange={(evt) => {onChange(evt); onParentChange && onParentChange(evt)}} {...{onBlur, value, name}}>
            {props.children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
};

export default InputSelect;
