import React, { FunctionComponent } from 'react';
// Material components
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import pageNotFoundSvg from '../../assets/images/page_not_found.svg';
// Component styles
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  contentWrapper: {
    textAlign: 'center'
  },
  capitalize: {
    textTransform: "uppercase"
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  }
}));

const NotFound404: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container justify="center" >
        <Grid item lg={6} xs={12}>
          <div className={classes.contentWrapper}>
            <Typography variant="h2" className={classes.capitalize}>
              The page you are looking for isn’t here
              </Typography>
            <Typography variant="subtitle1">
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
              </Typography>
            <img
              alt="Under development"
              className={classes.image}
              src={pageNotFoundSvg}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
  ;


export default NotFound404;