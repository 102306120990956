import React from 'react';
import { useSelector } from "react-redux";

import Layout from './components/layout/Layout';
import { selectIsAuthenticated } from './features/auth/authSlice';
import ConnectedUserAccount from './features/auth/ConnectedUserAccount';
import Notifier from './features/notification/Notifier';

import Router from './features/router';
import Routes from './features/router/routes';
import { SelectedBar } from './features/instanceBar/SelectedBar';
import SidebarMenu from './features/router/SidebarMenu';
import HeaderBarControls from './features/instanceBar/HeaderBarControls';

function App() {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Router>
      <Notifier />
      <Layout 
        title={<SelectedBar />} 
        isAuthenticated={isAuthenticated} 
        userAccountComponent={<ConnectedUserAccount />}
        sidebarMenuComponent={<SidebarMenu />}
        barControlsComponent={<HeaderBarControls />}
        >
        <Routes />
      </Layout>
    </Router>
  );
}

export default App;
