import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper } from "@material-ui/core";


import { getAllBars, selectBars, selectBarsCount } from "./barSlice";
import { useLoading } from "../loading/hooks";
import { Bar } from '../../services/types/bar';
import { Column } from 'react-table';
import EnhancedTable, { FetchDataArgs } from '../../components/table/EnhancedTable';
import { BarRowActions } from './BarRowActions';
import NumberFilter from '../../components/table/filters/NumberFilter';

interface BarTableParams {
  onBarSelected: (bar: Bar) => any;
}

const columns: Column<any>[] = [
  { Header: 'Name', accessor: "name" },
  { Header: 'Position', accessor: "instanceInfos.placeName" },
  { Header: 'Latitude', accessor: "geoPosition.lat", Filter: NumberFilter },
  { Header: 'Longitude', accessor: 'geoPosition.lng', Filter: NumberFilter },
  { Header: 'Actions', Cell: BarRowActions, disableFilters: true, disableSortBy: true },
];

const BarTable: FunctionComponent<BarTableParams> = ({ onBarSelected }) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const bars = useSelector(selectBars);
  const totalCount = useSelector(selectBarsCount);


  const handleFetchData = useCallback(({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
    dispatch(getAllBars({ page: pageIndex, perPage: pageSize, sort: sortBy, search: filters }));
  }, [dispatch]);

  const handleRowClick = useCallback((bar: Bar) => {
    onBarSelected(bar);
  }, [onBarSelected]);

  return (
    <Paper>
      <Box p={3}>
        <EnhancedTable
          columns={columns}
          data={bars}
          totalCount={totalCount}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          fetchData={handleFetchData}
        />
      </Box>
    </Paper>

  );
}

export default BarTable
