import React, { FunctionComponent } from "react";
import Box from "@material-ui/core/Box";
import SignIn from "../../components/screens/SignIn";
import LoginForm from "./LoginForm";
import { useHistory, useLocation } from "react-router-dom";
import { UserType } from "../auth/authSlice";

interface LocationState {
  from: {
    pathname: string;
  };
}

const LoginScreen: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { from } = location.state || { from: { pathname: "/" } };
  const handleLoginSuccess = (user: UserType) => {
    history.replace(from);
  };

  return (
    <Box>
      <SignIn><LoginForm onLoginSuccess={handleLoginSuccess} /></SignIn>
    </Box>
  );
}

export default LoginScreen;