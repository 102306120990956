import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createCoffee, findGlobalCoffeeByName } from "./coffeeSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Coffee } from "../../../services/types/coffee";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { coffeeCategories } from "../../constants";
interface CreateCoffeeFormProps {
  bar: Bar | null;
  onCreateSuccess: (coffee: Coffee) => any;
}

const CreateCoffeeForm: FunctionComponent<CreateCoffeeFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      color: "",
      price: null,
      category: coffeeCategories[0],
      infusionTime: 0,
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Coffee) => {
    setDisabled(true);
    dispatch(
      createCoffee(
        data,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Coffee Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Coffee
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <GlobalIngredientLookup
            label="Name"
            name="name"
            control={control}
            setError={setError}
            onChange={(data: any) => {
              if (data) {
                data.color && setValue("color", data.color);
                data.category && setValue("category", data.category);
                data.infusionTime && setValue("infusionTime", data.infusionTime);
                setValue("price", 0);
              }
            }}
            findGlobalIngredient={findGlobalCoffeeByName}
            required
            fullWidth
          />
        </Grid>
        <Grid item md={6}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {coffeeCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="infusionTime"
            label="Infusion Time"
            register={register}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="price"
            label="Price"
            register={register}
            fullWidth
          />
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Coffee!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateCoffeeForm;
