import { useSelector } from "react-redux";
import { selectCurrentInstanceBar } from "./instanceBarSlice";

enum Symbols {
  EUR = "€",
  USD = "$",
  GBP = "£",
  CZK = "CZK",
  AED = "AED",
  CHF = "CHF",
};

/**
* Hook for formatting price based on settings of the current bar
 *
 *
 * @example
 *
 * import { usePriceFormatter } from './feature/instanceBar/hooks'
 *
 * const MyComponent = () => {
 *      const priceFormatter = usePriceFormatter("ADMIN");
 *      return <div>{priceFormatter(12.3)}</div>; // output 12.30 € or $12.30 if the settings want so.
 * }
 */
export const usePriceFormatter = () => {
  const bar = useSelector(selectCurrentInstanceBar);
  const currencySettings = bar?.paymentSettings.currency || { currencySymbolLeft: false, currencyNameISO: "EUR" };
  const symbol = Symbols[currencySettings.currencyNameISO as keyof typeof Symbols];
  
  return (price: number): string => {
    return currencySettings.currencySymbolLeft ? `${symbol}${price.toFixed(2)}` : `${price.toFixed(2)} ${symbol}`;
  };
};
