import { Button, Tooltip } from '@material-ui/core'
import React, { FunctionComponent, useEffect, useState } from 'react'
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../notification/notificationSlice';
import { barJwtGenerate } from '../barSlice';

interface GenerateAndCopyButtonProps {
  barId: string;
  value: string;
  setValue: (value: string) => void;
}
const GenerateAndCopyButton: FunctionComponent<GenerateAndCopyButtonProps> = ({ barId, value, setValue }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleGenerate = () => {
    dispatch(
      barJwtGenerate(
        { id: barId },
        ({ payload }: { payload: string }) => {
          console.log(payload);
          setValue(payload);
        },
        (e: any) => {
          dispatch(showNotification("JWT Generation Failed!", "error"));
          console.error(e);
        }
      )
    );
  }

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    copy(value);
    setOpen(true);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    const tout = setTimeout(() => {
      handleTooltipClose();
    }, 5000)
    return () => {
      clearTimeout(tout);
    }
  }, [open]);

  return (
    <>
      <Button 
          style={{ top: "20px", width:"50%" }}
          onClick={handleGenerate}
          variant="outlined"
          color="primary"
        >Generate</Button>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied!"
        arrow
      >
        <Button 
          style={{ top: "20px", width:"50%" }}
          onClick={handleTooltipOpen}
          variant="outlined"
        >Copy</Button>
      </Tooltip>
    </>
  )
}

export default GenerateAndCopyButton
