import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  MenuItem,
  InputAdornment,
  DialogActions,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bottle } from "../../../services/types/bottle";
import { showNotification } from "../../notification/notificationSlice";
import { createGlobalBottle } from "./globalBottleSlice";
import { bottleCategories } from "../../constants";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputColor from "../../../components/form/InputColor";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";

interface CreateGlobalBottleFormProps {
  onCreateSuccess: (bottle: Bottle) => any;
}

const CreateGlobalBottleForm: FunctionComponent<CreateGlobalBottleFormProps> = ({
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue } = useForm();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Bottle) => {
    setDisabled(true);
    const bottle = {
      name: data.name,
      category: data.category,
      subCategory: data.subCategory,
      alcoholPerc: data.alcoholPerc || 0,
      color: data.color,
    };
    console.log(bottle);
    dispatch(
      createGlobalBottle(
        bottle,
        ({ payload }: any) => {
          dispatch(showNotification("Global Bottle Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Global Bottle
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            name="name"
            label="Name"
            register={register}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {bottleCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
          <InputText
            name="subCategory"
            label="Subcategory"
            register={register}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="alcoholPerc"
            label="Alcoholic Percentage"
            register={register}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <MainSpacer />
      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Bottle!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateGlobalBottleForm;
