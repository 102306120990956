import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Leaf } from "../../../services/types/leaf";

interface LeafState {
  data: Leaf[];
  map: MapLike<Leaf>;
  count: number;
  page: number;
  perPage: number;
}

const initialState: LeafState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

//Actions
export const getAllLeaves = createAction<
  PrepareAction<PaginationPayloadByBar>
>("leaves/getAllLeaves", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar.id,
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllLeaves",
  },
}));

export const getAllLeavesSuccess = createAction<
  PaginationResponse<Leaf>
>("leaves/getAllLeaves_SUCCESS");

export const getLeafById = createAction<PrepareAction<{ id: string }>>(
  "leaves/getLeafById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getLeafById",
      onFailure,
    },
  })
);

export const getLeafByIdSuccess = createAction<Leaf>(
  "leaves/getLeafById_SUCCESS"
);

export const updateLeaf = createAction<PrepareAction<Leaf>>(
  "leaves/updateLeaves",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateLeaf",
      onSuccess,
      onFailure,
    },
  })
);

export const updateLeafSuccess = createAction<Leaf>(
  "leaves/updateLeaf_SUCCESS"
);

export const findGlobalLeafByName = createAction<
  PrepareAction<{ name: string }>
>("leaves/findGlobalLeafByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalLeafByName",
    onSuccess,
    onFailure,
  },
}));

export const createLeaf = createAction<PrepareAction<Leaf>>(
  "leaves/createLeaf",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createLeaf",
      onSuccess,
      onFailure,
    },
  })
);

export const createLeafSuccess = createAction<Leaf>(
  "leaves/createLeaf_SUCCESS"
);

//Reducers
const leavesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllLeavesSuccess,
      (state: LeafState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Leaf) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getLeafByIdSuccess, (state: LeafState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(updateLeafSuccess, (state: LeafState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

//Selector
export const selectLeaves = (state: RootState) =>
  state.ingredients.leaf.data;
export const selectLeafCount = (state: RootState) =>
  state.ingredients.leaf.count;
export const selectLeafById = (id: string) => (state: RootState) =>
  state.ingredients.leaf.map[id];

export default leavesReducer;
