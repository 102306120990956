import React from "react";
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useFieldArray } from "react-hook-form";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import InputNumber from "../../../components/form/InputNumber";
import InputText from "../../../components/form/InputText";
import InputSelect from "../../../components/form/InputSelect";
import InputSwitch from "../../../components/form/InputSwitch";

interface FieldArrayCupsProps {
  name: string;
  control: any;
  register: any;
}

const FieldArrayCups: FunctionComponent<FieldArrayCupsProps> = ({
  name,
  control,
  register,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
    keyName: "keyId",
  });

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.keyId}>
          <Grid container spacing={4} key={index} alignItems="center">
            <Grid item sm={1} xs={12}>
              <IconButton
                onClick={() => remove(index)} // remove a friend from the list
              >
                <RemoveIcon />
              </IconButton>
            </Grid>
            <Grid item sm={2} xs={12}>
              <InputLabel></InputLabel>
              <InputSelect
                name={`${name}.${index}.code`}
                label="Cup Code"
                control={control}
                fullWidth
                required
              >
                <MenuItem value="SHOT">SHOT</MenuItem>
                <MenuItem value="DRINK">DRINK</MenuItem>
                <MenuItem value="ESPRESSO">ESPRESSO</MenuItem>
                <MenuItem value="MEDIUM">MEDIUM</MenuItem>
                <MenuItem value="CAPPUCCINO">CAPPUCCINO</MenuItem>
                <MenuItem value="GRANDE">GRANDE</MenuItem>
              </InputSelect>
            </Grid>
            <Grid item sm={2} xs={12}>
              <div style={{ paddingTop: "30px" }}>
                <InputSwitch
                  name={`${name}.${index}.needsMetafork`}
                  label="Needs Metafork"
                  control={control}
                  type="checkbox"
                />
              </div>
            </Grid>
            <Grid item sm={5} xs={12}>
              <InputText
                name={`${name}.${index}.name`}
                defaultValue={field.name}
                label="Name"
                register={register}
                fullWidth
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <InputNumber
                name={`${name}.${index}.position`}
                label="Position"
                defaultValue={field.position}
                register={register}
                inputProps={{ step: 1 }}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      ))}
      
      <Button variant="outlined" onClick={() => append({})}>
        Add Cup Setting
      </Button>
    </>
  );
};

export default FieldArrayCups;
