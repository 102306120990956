import React, { FunctionComponent, useRef, useState } from 'react'
import { Box, Button, Grid, IconButton, MenuItem, Typography } from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import InputButton from '../../components/form/InputButton';
import InputText from '../../components/form/InputText';
import MainSpacer from '../../components/layout/MainSpacer';
import { User } from '../../services/types/user'
import { showNotification } from '../notification/notificationSlice';
import { updateUser } from './userManagementSlice';
import FieldArraySelect from '../../components/form/FieldArraySelect';
import { ROLEMAP } from '../auth/hooks';
import BarNameLookup from './BarNameLookup';

interface EditUserFormProps {
  user: User;
}

const EditUserForm: FunctionComponent<EditUserFormProps> = ({user}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { register, handleSubmit, control, errors, setError, setValue, watch } = useForm({
    defaultValues: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      password: '',
      confirmPassword: '',
      roles: user.roles,
    },
  });
  register({ name: 'id' });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "roles",
    keyName: "keyId",
  });

  const onSubmit = (data: Partial<User>) => {
    setDisabled(true);
    dispatch(
      updateUser(
        data,
        () => {
          dispatch(showNotification("User saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          dispatch(showNotification("User update error!", "error"));
          setDisabled(false);
        }
      )
    );
  };
  const handleAddRole = () => {
    append({ id: null, barId: null, barName: "", role: "ADMIN" });
  }


  const password = useRef({});
  password.current = watch("password", "");

  const handleValidatePassChange = (name: any) => (value: any) => {
    return name === "password" ||
      ((name === "confirmPassword" && value === password.current) || 'The passwords do not match')
    ;
  }

  if (!user) {
    return null;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit {user.email}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InputText
                name="firstName"
                label="Name"
                register={register}
                required
                fullWidth
                error={errors.firstName?.type === 'required'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText
                name="lastName"
                label="Last Name"
                register={register}
                required
                fullWidth
                error={errors.lastName?.type === 'required'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Typography variant="h6" component="h3">
            Change Password
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <InputText
                name="password"
                label="Password"
                register={register}
                rules={{validate: handleValidatePassChange("password")}}
                fullWidth
                type="password"
                helperText="Leave it blank to keep the current password"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText
                name="confirmPassword"
                label="Confirm Password"
                register={register}
                rules={{validate: handleValidatePassChange("confirmPassword")}}
                helperText={errors.confirmPassword && errors.confirmPassword.message}
                error={errors.confirmPassword?.type === 'validate'}
                fullWidth
                type="password"
              />
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          <Typography variant="h6" component="h3">
            Roles
            <Button color="secondary" size="small" variant="contained" style={{marginLeft: '16px'}} onClick={handleAddRole}>
              Add role <AddIcon />
            </Button>
          </Typography>
          {fields.map((field, idx) => { 
            register({ name: `roles[${idx}].barId`, defaultValue: field.barId })
            return (
            <Grid key={field.keyId} container spacing={4} alignItems="flex-end">
              <input type="hidden" name={`roles[${idx}].id`} ref={register()} defaultValue={field.id} />
              <Grid item xs={12} sm={5}>
                <FieldArraySelect
                  label={`Role`}
                  name={`roles[${idx}].role`}
                  control={control}
                  defaultValue={field.role}
                >
                  {ROLEMAP.filter(r => !(r === "SUPERADMIN")).map((roleName, I) => (
                    <MenuItem key={I} value={roleName}>
                      {roleName}
                    </MenuItem>
                  ))}
                </FieldArraySelect>
              </Grid>
              <Grid item xs={12} sm={5}>
                <BarNameLookup
                  name={`roles[${idx}].barName`}
                  label="Bar"
                  control={control}
                  setError={setError}
                  defaultValue={field.barName}
                  onChange={(bar) => {
                    if (bar) {
                      console.log({id: bar.id});
                      setValue(`roles[${idx}].barId`, bar.id);
                    }
                  }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  onClick={() => remove(idx)}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          )})}

          <MainSpacer />
          <InputButton disabled={disabled}>Save!</InputButton>
        </Box>
      </form>
  )
}

export default EditUserForm
