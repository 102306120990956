import React, { FunctionComponent } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

import { findBottleByName } from "../positionScreen/bottle/dispenserBottleSlice"; // Duplicate this here in the same slice?
import {
  findBeverageByName,
  findGarnishByName,
  findGranularByName,
  findLeafByName,
  findCoffeeByName,
} from "./recipeSlice"; // Duplicate this here in the same slice?
import { Controller, ErrorOption, useController } from "react-hook-form";

interface IngredientLookupProps {
  name: string;
  label: string;
  barId: string | undefined;
  index: number;
  control: any;
  required: boolean;
  disabled: boolean;
  defaultValue?: any;
  setValue: (...args: any) => any;
  getValues: (...args: any) => any;
  setError: (name: any, error: ErrorOption) => void;
  //minCarbonatedIndex: number;
  //setMinCarbonatedIndex: (...args: any) => any;
}
const IngredientLookup: FunctionComponent<IngredientLookupProps> = ({
  name,
  label,
  barId,
  index,
  control,
  disabled,
  defaultValue,
  setValue,
  getValues,
  setError,
  required,
  //minCarbonatedIndex,
  //setMinCarbonatedIndex,
}) => {
  const dispatch = useDispatch();

  const {
    field: { value },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });

  const [inputValue, setInputValue] = React.useState(value ? value.name : "");
  const [options, setOptions] = React.useState<any[]>([]); // type me please
  const [isLoading, setLoading] = React.useState(false);
  const loaded = React.useRef(false);

  const getFetchTypeAction = (ingredientType: string) => {
    if (ingredientType === "Bottle") {
      return findBottleByName;
    }
    if (ingredientType === "Beverage") {
      return findBeverageByName;
    }
    if (ingredientType === "Garnish") {
      return findGarnishByName;
    }
    if (ingredientType === "Granular") {
      return findGranularByName;
    }
    if (ingredientType === "Leaf") {
      return findLeafByName;
    }
    if (ingredientType === "Coffee") {
      return findCoffeeByName;
    }
    // if (ingredientType === 'Ice') {
    // }
    return null;
  };

  const handleSetIngredient = (event: any, newValue: any | null) => {
    console.log(newValue);
    if (newValue) {
      setOptions([newValue, ...options]);
      setValue(`ingredients.${index}.ingredientId`, newValue.id);
      setValue(`ingredients.${index}.ingredientName`, newValue.name);
      setValue(`ingredients.${index}.carbonated`, false);
      setValue(`ingredients.${index}.color`, newValue.color);
      if (newValue.flavours) {
        const carb = newValue.flavours.find(
          (el: any) => el.name === "Carbonated Water"
        );
        if (carb as boolean) {
          setValue(`ingredients.${index}.carbonated`, true);
          //setMinCarbonatedIndex(Math.min(index, minCarbonatedIndex));
        }
      }

      setValue(`ingredients.${index}.alcoholPerc`, 0);
      if (newValue.alcoholPerc !== null && newValue.alcoholPerc !== undefined) {
        setValue(`ingredients.${index}.alcoholPerc`, newValue.alcoholPerc);
      }
    } else {
      setOptions(options);
    }
  };

  const fetch = React.useMemo(
    () =>
      debounce(
        (inputValue) => {
          const fetchAction = getFetchTypeAction(
            getValues(`ingredients.${index}.ingredientType`)
          );
          if (fetchAction) {
            setLoading(true);
            return dispatch(
              fetchAction(
                inputValue,
                barId,
                (result: { payload: any }) => {
                  setOptions(result.payload); // type me plese
                  setLoading(false);
                },
                (e: any) => {
                  console.error(e);
                  setError(
                    name,
                    e?.payload?.data?.message ||
                      e?.error ||
                      "General Error - Drink Department"
                  );
                  setLoading(false);
                }
              )
            );
          }
        },
        300,
        { maxWait: 1000 }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      barId,
      dispatch,
      index,
      name,
      setError,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      getValues(`ingredients.${index}.ingredientType`),
    ]
  );

  React.useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (loaded.current && inputValue.length > 2) {
      fetch(inputValue);
    }
    loaded.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Controller
      name={name}
      label={label}
      defaultValue={defaultValue}
      control={control}
      render={({ value, onChange, onBlur, name }) => (
        <Autocomplete
          defaultValue={defaultValue}
          getOptionLabel={(option) => option?.name || option}
          filterOptions={(x) => x}
          options={options}
          freeSolo
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          disabled={
            disabled ||
            getValues(`ingredients.${index}.ingredientType`) === "Ice"
          }
          loading={isLoading}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(evt: any, newValue) => {
            if (newValue) {
              onChange(newValue);
            } else {
              onChange(null);
            }
            handleSetIngredient(evt, newValue);
            onBlur();
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              // value={inputValue}
              label={label}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default IngredientLookup;
