import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Flavour } from "../../../services/types/flavour";
import { MapLike } from "typescript";

// STATE INTERFACE
interface FlavourState {
  data: Flavour[];
  map: MapLike<Flavour>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: FlavourState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalFlavours = createAction<
  PrepareAction<PaginationPayload>
>("flavours/getAllGlobalFlavours", ({ page, perPage, sort, search }) => ({
  payload: {
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "globalIngredients",
    resource: "getAllGlobalFlavours",
  },
}));

export const getAllGlobalFlavoursSuccess = createAction<
  PaginationResponse<Flavour>
>("flavours/getAllGlobalFlavours_SUCCESS");

export const getGlobalFlavourById = createAction<PrepareAction<{ id: string }>>(
  "flavours/getGlobalFlavourById",
  ({ id, onFailure }) => ({
    payload: {
      id,
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getGlobalFlavourById",
      onFailure,
    },
  })
);
export const getGlobalFlavourByIdSuccess = createAction<Flavour>(
  "flavours/getGlobalFlavourById_SUCCESS"
);

export const updateGlobalFlavour = createAction<PrepareAction<Flavour>>(
  "flavours/updateGlobalFlavours",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalFlavour",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalFlavourSuccess = createAction<Flavour>(
  "flavours/updateGlobalFlavour_SUCCESS"
); /* 
export const findGlobalFlavourByName = createAction<
  PrepareAction<{ name: string }>
>("flavours/findGlobalFlavourByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalFlavourByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalFlavour = createAction<PrepareAction<Flavour>>(
  "flavours/createGlobalFlavours",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalFlavour",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalFlavourSuccess = createAction<Flavour>(
  "flavours/createGlobalFlavour_SUCCESS"
);

// REDUCERS
const globalFlavoursReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalFlavoursSuccess,
      (state: FlavourState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Flavour) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGlobalFlavourByIdSuccess,
      (state: FlavourState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      }
    )
    .addCase(updateGlobalFlavourSuccess, (state: FlavourState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalFlavours = (state: RootState) =>
  state.globalIngredients.flavour.data;
export const selectGlobalFlavoursCount = (state: RootState) =>
  state.globalIngredients.flavour.count;
export const selectGlobalFlavourById = (id: string) => (state: RootState) =>
  state.globalIngredients.flavour.map[id];

export default globalFlavoursReducer;
