import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import InputFileDropzone from "./input-file-components/InputFileDropzone";
import InputFilePreview from "./input-file-components/InputFilePreview";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#eee",
    margin: "0.8em 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "5px",
    height: "60vh",
    alignSelf: "center",
    flexDirection: "column",
    "@media (min-width:1025px)": {
      // eslint-disable-line no-useless-computed-key
      height: "50vh",
      flexDirection: "row",
    },
  },
  image: {
    objectFit: "contain",
    height: "100%",
    width: "100%",
    padding: "1.2em 0.5em",
    "@media (min-width:1025px)": {
      // eslint-disable-line no-useless-computed-key
    },
  },
  infos: {
    width: "100%",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "left",
    padding: "5%",
    "@media (min-width:1025px)": {
      // eslint-disable-line no-useless-computed-key
      width: "60%",
      height: "100%",
    },
  },
  column: {
    height: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    "@media (min-width:1025px)": {
      height: "100%",
      width: "40%",
      // eslint-disable-line no-useless-computed-key
    },
  },
  buttons: {
    padding: "0.8em 0.4em",
    fontSize: "1.2em",
  },
  zone: {
    height: "100%",
    width: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginTop: "16px",
    color: "#888",
    fontSize: "42px",
  },
});

interface InputFileProps {
  name: string;
  label: string;
  control: any;
  setValue: (...args: any) => any;
}

const InputFile: FunctionComponent<InputFileProps> = ({
  name,
  label,
  control,
  setValue,
}) => {
  const styles = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, onBlur, value }) => (
        <>
          {value ? (
            <InputFilePreview
              name={name}
              value={value}
              label={label}
              setValue={setValue}
              styles={styles}
            />
          ) : (
            <InputFileDropzone
              name={name}
              label={label}
              styles={styles}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
        </>
      )}
    ></Controller>
  );
};

export default InputFile;
