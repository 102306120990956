import React, { FunctionComponent, useEffect } from "react";
import { Bar } from "../../../services/types/bar";
import Grid from "@material-ui/core/Grid";
import CoffeeCard from "./CoffeeCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllDispenserCoffee, selectDispenserCoffee } from "./dispenserCoffeeSlice";

interface CoffeeDispenserProps {
  bar: Bar | null;
}

const CoffeeDispenserList: FunctionComponent<CoffeeDispenserProps> = ({ bar }) => {
  const select = useSelector(selectDispenserCoffee);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bar) {
      dispatch(getAllDispenserCoffee(bar));
    }
  }, [dispatch, bar]);

  return (
    <>
      <Grid container spacing={1}>
        {select.map((el, i) => (
          <Grid item xs={6} key={i}>
            <CoffeeCard bar={bar} position={i} dispenser={el} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CoffeeDispenserList;
