export const ingredientCategories = [
  "Basics",
  "Garnishes",
  "Spirits",
  "Sodas",
  "Syrups",
  "Leaves",
  "Granulars",
  "Juices",
  "Beers",
  "Wines",
  "Water",
];

export const beverageCategories = [
  "Beers",
  "Juices",
  "Sodas",
  "Wines",
  "Water",
];

export const solubleCategories = ["Coffee", "Milk", "Tea", "Chocolate"];

export const bottleCategories = [
  "Beers",
  "Juices",
  "Sodas",
  "Spirits",
  "Syrups",
  "Wines",
];

export const milkCategories = ["Milk"]; // Diary Vegetable

export const flavourCategories = ["Flavours", "Solubles"];

export const garnishCategories = ["Garnishes"];

export const granularCategories = ["Granulars"];

export const leafCategories = ["Leaves"];

export const coffeeCategories = ["Coffee"];

export const ingredientTypesArray = [
  "Bottle",
  "Beverage",
  "Garnish",
  "Granular",
  "Leaf",
  "Ice",
  "Coffee",
];

export const ingredientActionsArray = ["SHAKE", "STIR", "CRASH"];

export const dispenserCapacities = [
  {
    _id: "5d9da47ac95c4b43a9a2acc3",
    capacity: 14,
    metrics: 375,
    imperial: 12.75,
    multiple: "ml",
    type: "bottle",
    description: "375 ml bottle",
  },

  {
    _id: "5d9da4c9c95c4b43a9a2acc4",
    capacity: 18,
    metrics: 500,
    imperial: 16.5,
    multiple: "ml",
    type: "bottle",
    description: "500 ml bottle",
  },
  {
    _id: "5d9da444c95c4b43a9a2acc2",
    capacity: 25,
    metrics: 700,
    imperial: 23.25,
    multiple: "ml",
    type: "bottle",
    description: "700 ml bottle",
  },

  {
    _id: "5b311b4bab0ef6d4af14b5dd",
    capacity: 27,
    metrics: 750,
    imperial: 25.36,
    multiple: "ml",
    type: "bottle",
    description: "750 ml bottle",
  },

  {
    _id: "5b311b35ab0ef6d4af14b5dc",
    capacity: 37,
    metrics: 1,
    imperial: 33.75,
    multiple: "lt",
    type: "bottle",
    description: "1 lt bottle",
  },

  {
    _id: "5d9da4f0c95c4b43a9a2acc5",
    capacity: 56,
    metrics: 1500,
    imperial: 51,
    multiple: "ml",
    type: "bottle",
    description: "1.5 l bottle",
  },

  {
    _id: "5bda4b8b05b8fe000ed706a2",
    capacity: 66,
    metrics: 1.75,
    imperial: 59.25,
    multiple: "lt",
    type: "bottle",
    description: "1.75 lt bottle",
  },
];
