import React from "react";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FunctionComponent } from "react";
import { RegisterOptions } from "react-hook-form";

type InputNumberProps = TextFieldProps & {
  register: (arg:RegisterOptions) => any;
  rules?: RegisterOptions;
};

const InputNumber: FunctionComponent<InputNumberProps> = ({
  register,
  rules,
  defaultValue,
  required,
  ...props
}) => {
  return (
    <TextField
      margin="normal"
      type="number"
      inputRef={register({
        valueAsNumber: true,
        required,
        ...rules
      })}
      defaultValue={defaultValue}
      required={required}
      {...props}
    />
  );
};

export default InputNumber;
