import React, { MouseEvent } from "react";
import { AppOrder } from "../../services/types/appOrder";
import { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import MainSpacer from "../../components/layout/MainSpacer";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { refundAppOrder } from "./appOrderSlice";
import { Bar } from "../../services/types/bar";
import { showNotification } from "../notification/notificationSlice";

interface RefundAppOrderProps {
  appOrder: AppOrder;
  onSubmit: (...args: any) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    navigation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

const RefundAppOrder: FunctionComponent<RefundAppOrderProps> = ({
  appOrder,
  onSubmit,
}) => {
  const classes = useStyles();
  const bar: Bar | null = useSelector(selectCurrentInstanceBar);
  const dispatch = useDispatch();

  const handleRefund = (id: String) => {
    dispatch(refundAppOrder(id, bar, ({ payload }: any) => {
        dispatch(showNotification("Order Refund!", "success"));
        onSubmit();
      },
      (e: any) => {
        console.error(e);
        dispatch(showNotification("Error in Order Refund!", "error"));
      })
    );
  };
  
  return (
    <>
      <Typography variant="h5" component="h2">
        Are you sure you want to void this Transaction (id: {appOrder?.id})?
      </Typography>
      <MainSpacer />
      <div className={classes.navigation}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubmit}
        >
          NO
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={(evt: MouseEvent) => {
            evt.stopPropagation();
            handleRefund(appOrder.id);
          }}
        >
          YES
        </Button>
      </div>
    </>
  );
};

export default RefundAppOrder;
