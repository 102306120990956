import React, { FunctionComponent, ChangeEvent } from "react";
import { Paper, Box, Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import PageTitle from "../../components/layout/PageTitle";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import BottleDispenserList from "./bottle/DispenserBottleList";
import BibDispenserList from "./bib-dispenser/BibDispenserList";
import GranularDispenserList from "./granular-dispenser/GranularDispenserList";
import FruitCutterList from "./fruit-cutter/FruitCutterList";
import LeafDispenserList from "./leaf-dispenser/LeafDispenserList";
import IceDispenserList from "./ice-dispenser/IceDispenserList";
import CoffeeDispenserList from "./coffee-dispenser/CoffeeDispenserList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const PositionScreen: FunctionComponent = (props) => {
  const bar = useSelector(selectCurrentInstanceBar);
  const classes = useStyles();
  const { tabActive } = useParams<{ tabActive: string }>();
  const history = useHistory();

  const setTabActive = (tab: string) => {
    history.push(`/dispensers/${tab}`);
  };

  return (
    <Box className={classes.root}>
      <PageTitle
        pageTitle={bar?.name || "Position"}
        breadcrumb={[bar?.name || "Bar", "Positions", tabActive]}
      />

      <Paper>
        <Tabs
          value={tabActive}
          onChange={(event: ChangeEvent<{}>, value) => setTabActive(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {(bar?.features.bottles) && (<Tab label="Bottle" value="bottles" />)}
          {(bar?.features.bibs) && (<Tab label="Bibs" value="bibs" />)}
          {(bar?.features.granulars) && (<Tab label="Granulars Dispenser" value="granular" />)}
          {(bar?.features.garnish) && (<Tab label="Fruit Cutter" value="cutter" />)}
          {(bar?.features.leaves) && (<Tab label="Leaves" value="leaves" />)}
          {(bar?.features.ice) && (<Tab label="Ice" value="ice" />)}
          {(bar?.features.coffee) && (<Tab label="Coffee" value="coffee" />)}
        </Tabs>
        {tabActive === "bottles" && (
          <Box>
            <BottleDispenserList bar={bar} />
          </Box>
        )}
        {tabActive === "bibs" && (
          <Box>
            <BibDispenserList bar={bar} />
          </Box>
        )}
        {tabActive === "granular" && (
          <Box>
            <GranularDispenserList bar={bar} />
          </Box>
        )}
        {tabActive === "cutter" && (
          <Box>
            <FruitCutterList bar={bar} />
          </Box>
        )}
        {tabActive === "leaves" && (
          <Box>
            <LeafDispenserList bar={bar} />
          </Box>
        )}
        {tabActive === "ice" && (
          <Box>
            <IceDispenserList bar={bar} />
          </Box>
        )}
        {tabActive === "coffee" && (
          <Box>
            <CoffeeDispenserList bar={bar} />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default PositionScreen;
