import { Box, Button, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import InputNumber from "../../../components/form/InputNumber";
import InputText from "../../../components/form/InputText";
import { NavigateNext as NavigateNextIcon } from "@material-ui/icons";
import { Bar } from "../../../services/types/bar";

interface TheNewGeneralInfoProps {
  initialValues: Partial<Bar>;
  onComplete: (data: Partial<Bar>) => void;
}

const TheNewGeneralInfo: FunctionComponent<TheNewGeneralInfoProps> = ({
  initialValues,
  onComplete
}) => {
  const { register, handleSubmit } = useForm({ defaultValues: initialValues });

  const onSubmit = (data: Partial<Bar>) => {
    onComplete(data)
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputText
            register={register}
            name="name"
            label="Name"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputText
            register={register}
            name="instanceInfos.placeName"
            label="Location"
            required
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputNumber
            register={register}
            name="geoPosition.lat"
            label="Latitude"
            fullWidth
            required
            inputProps={{ step: 0.00001 }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputNumber
            register={register}
            name="geoPosition.lng"
            label="Longitude"
            fullWidth
            required
            inputProps={{ step: 0.00001 }}
          />
        </Grid>

      </Grid>
      <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" marginTop="2em">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<NavigateNextIcon />}
        >
          Next
        </Button>
      </Box>
    </form>
  );
};

export default TheNewGeneralInfo;
