import React, { FunctionComponent } from 'react';
import { Button } from "@material-ui/core";
import { Row } from 'react-table';
import { useDispatch } from 'react-redux';
import { impersonateBar } from '../instanceBar/instanceBarSlice';
import { Bar } from '../../services/types/bar';


export const BarRowActions: FunctionComponent<{ row: Row<Bar>; }> = ({ row, ...rest }) => {
  const dispatch = useDispatch();  
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(impersonateBar(row.original));
  }

  return (
    <Button component="a" onClick={handleClick}>
      Impersonate
    </Button>
  );
};
