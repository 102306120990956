import React, { useState, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../../../components/dialog/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditIceForm from "./EditIceForm";
import { Bar } from "../../../services/types/bar";
import { FunctionComponent } from "react";
import { DispenserIce } from "../../../services/types/dispenserIce";
import { Tab, Tabs } from "@material-ui/core";
import ManualCommandIce from "./ManualCommandIce";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 15,
      marginTop: 25,
      height: 250,
      borderRadius: 10,
      backgroundColor: "#F1F1F1",
    },
  })
);

interface IceCardProps {
  bar: Bar | null;
  position: number;
  dispenser: DispenserIce;
}

const IceCard: FunctionComponent<IceCardProps> = ({
  bar,
  position,
  dispenser,
}) => {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    view: string;
  }>({ open: false, view: "edit" });

  const handleCloseDialog = () => {
    setDialogState({
      ...dialogState,
      open: false,
    });
  };

  const handleClick = useCallback(() => {
    setDialogState({
      ...dialogState,
      open: true,
    });
  }, []);

  return (
    <>
      <Card className={classes.root}>
        {/* <div className={classes.overlay}> */}
        <CardHeader
          title={`Dispenser ${dispenser.graphicCode}`}
          subheader={`Dispensing Time: ${dispenser.dispensingTime}`}
        />
        <CardContent>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <ListItemText primary="Ice" />
              <ListItemSecondaryAction>
                <IconButton aria-label="settings" onClick={handleClick}>
                  <EditOutlinedIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        {/* </div> */}
      </Card>
      <Dialog
        title="Edit Dispenser"
        open={dialogState.open}
        onAbort={handleCloseDialog}
      >
        <Tabs
          value={dialogState.view}
          onChange={(_event, value) => {
            setDialogState({
            ...dialogState,
            view: value,
          });}}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Edit Valve" value={"edit"} />
          <Tab label="Test Valve" value={"test"} />
        </Tabs>
        {dispenser && (dialogState.view === "edit") && (
          <EditIceForm onSuccess={handleCloseDialog} dispenser={dispenser} />
        )}
        {dispenser && (dialogState.view === "test") && (
          <ManualCommandIce dispenserIce={dispenser}></ManualCommandIce>
        )}
      </Dialog>
    </>
  );
};

export default IceCard;
