import React, { FunctionComponent, MouseEvent } from "react";
import {
  Drawer,
  IconButton,
  Divider,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  ChevronLeft as ChevronLeftIcon,
} from "@material-ui/icons";
import logo from "../../assets/images/MS_all_color.png";
import Copyright from "./Copyright";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.drawerWidth,
  },
  drawerLogo: {
    height: (theme.mixins.toolbar.minHeight as number) - theme.spacing(1),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  },
  copyright: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "100%",
    textAlign: 'center',
  },
  menuItemNested: {
    paddingLeft: theme.spacing(4),
  },
}));

type SidebarProps = {
  userAccountComponent: React.ReactNode;
  sidebarMenuComponent: React.ReactNode;
  handleClose: (event: MouseEvent) => void;
  open: boolean;
};


const Sidebar: FunctionComponent<SidebarProps> = ({
  open,
  userAccountComponent,
  sidebarMenuComponent,
  handleClose,
}) => {
  const classes = useStyles();
  const versionString: string = process?.env?.REACT_APP_PACKAGE_VERSION || 'v.DEV';

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img alt="Logo" src={logo} className={classes.drawerLogo} />
        <IconButton onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {userAccountComponent}
      <Divider />
      {sidebarMenuComponent}
      <div className={classes.copyright}>
        <Copyright />
        <br />
        <Typography variant="caption">{versionString}</Typography>
      </div>
    </Drawer>
  );
};

export default Sidebar;
