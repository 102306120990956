import React, { FunctionComponent } from "react";
import {
  DialogActions,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createVoucher } from "./voucherSlice";
import { useForm, useWatch } from "react-hook-form";
import { showNotification } from "../notification/notificationSlice";
import InputText from "../../components/form/InputText";
import InputButton from "../../components/form/InputButton";
import MainSpacer from "../../components/layout/MainSpacer";
import InputRadio from "../../components/form/InputRadio";
import InputNumber from "../../components/form/InputNumber";
import { Bar } from "../../services/types/bar";

interface CreateVoucherFormProps {
  onCreateSuccess: (...args: any) => any;
  bar: Bar | null;
}

const CreateVoucherForm: FunctionComponent<CreateVoucherFormProps> = ({
  onCreateSuccess,
  bar,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = React.useState(false);
  const {
    register,
    handleSubmit,
    errors,
    control,
  } = useForm();


  const onSubmit = (data: any) => {
    setDisabled(true);
    console.log(data);
    dispatch(
      createVoucher(
        data,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Voucher Created!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          dispatch(showNotification(`Voucher creation error!`, "error"));
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  const typeWatch = useWatch({ control, name: "type" });

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputText
              name="nickname"
              label="Voucher For"
              register={register}
              required
              fullWidth
              error={errors.nickname?.type === 'required'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputText
              name="email"
              label="Email"
              register={register}
              rules={{
                // required: { value: true, message: "Email is required" },
                pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: "Email is invalid" },
              }}
              // required
              fullWidth
              error={errors.email}
              helperText={errors.email && errors.email.message}
            />
            
          </Grid>
          <Grid item xs={12} md={6} >
            <InputRadio
              name="type"
              label="Type"
              radioValues={[{label: "Free Cart", value: "free"}, {label: "Fixed Value", value: "valued"}]}
              control={control}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} >
            <InputNumber
              name="value"
              label="Value"
              register={register}
              rules={{validate: (value:any) => typeWatch === "free" || value > 0}}
              
              fullWidth
              error={errors.value}
            />
          </Grid>
        </Grid>
        <MainSpacer />
        <DialogActions style={{ padding: 0 }}>
          <InputButton disabled={disabled}>Create Voucher!</InputButton>
        </DialogActions>
        {disabled && <LinearProgress />}
      </form>
  );
};

export default CreateVoucherForm;
