import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";

import { Garnish } from "../../../services/types/garnish";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import {
  getAllGarnishes,
  selectGarnishes,
  selectGarnishCount,
} from "./garnishSlice";

const columns: Column<any>[] = [
  /*{
    Header: "Category",
    accessor: "category",
    Filter: withOptions(ingredientCategories, SelectFilter),
  },*/
  { Header: "Name", accessor: "name" },
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
  // { Header: 'Actions', Cell: BarRowActions, disableFilters: true, disableSortBy: true },
];

interface GarnishTableProps {
  onGarnishSelected: (garnish: Garnish) => any;
}

const GarnishTable: FunctionComponent<GarnishTableProps> = ({
  onGarnishSelected,
}) => {
  const dispatch = useDispatch();
  const garnishes = useSelector(selectGarnishes);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectGarnishCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllGarnishes(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (garnish: Garnish) => {
      onGarnishSelected(garnish);
    },
    [onGarnishSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={garnishes}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default GarnishTable;
