import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Beverage } from "../../../services/types/beverage";
import { solubleCategories } from "../../constants";

interface SolubleState {
  data: Beverage[];
  map: MapLike<Beverage>;
  /* solubleCategories: string[]; */
  count: number;
  page: number;
  perPage: number;
}

const initialState: SolubleState = {
  data: [],
  map: {},
  /* solubleCategories: solubleCategories, */
  count: 0,
  page: 1,
  perPage: 2,
};

//Actions
export const getAllSolubles = createAction<
  PrepareAction<PaginationPayloadByBar>
>("solubles/getAllSolubles", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar.id,
    /* solubleCategories, */
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllSolubles",
  },
}));

export const getAllSolublesSuccess = createAction<PaginationResponse<Beverage>>(
  "solubles/getAllSolubles_SUCCESS"
);

export const getSolubleById = createAction<PrepareAction<{ id: string }>>(
  "solubles/getSolubleById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getSolubleById",
      onFailure,
    },
  })
);

export const getSolubleByIdSuccess = createAction<Beverage>(
  "solubles/getSolubleById_SUCCESS"
);

export const getSolubleByName = createAction<PrepareAction<{ name: string }>>(
  "solubles/getSolubleByName",
  ({ name, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      name,
    },
    meta: {
      fetch: "ingredients",
      resource: "getSolubleByName",
      onFailure,
    },
  })
);

export const getSolubleByNameSuccess = createAction<Beverage>(
  "solubles/getSolubleByName_SUCCESS"
);

export const updateSoluble = createAction<PrepareAction<Beverage>>(
  "solubles/updateSolubles",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateSoluble",
      onSuccess,
      onFailure,
    },
  })
);

export const updateSolubleSuccess = createAction<Beverage>(
  "solubles/updateSoluble_SUCCESS"
);

export const findGlobalSolubleByName = createAction<
  PrepareAction<{ name: string }>
>("solubles/findGlobalSolubleByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalSolubleByName",
    onSuccess,
    onFailure,
  },
}));

export const createSoluble = createAction<PrepareAction<Beverage>>(
  "solubles/createSoluble",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createSoluble",
      onSuccess,
      onFailure,
    },
  })
);

export const createSolubleSuccess = createAction<Beverage>(
  "solubles/createSoluble_SUCCESS"
);

//Reducers
const solublesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllSolublesSuccess,
      (state: SolubleState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Beverage) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(getSolubleByIdSuccess, (state: SolubleState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(getSolubleByNameSuccess, (state: SolubleState, { payload }) => {
      state.data.push(payload);
      state.map[payload.id] = payload;
    })
    .addCase(updateSolubleSuccess, (state: SolubleState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

//Selector
export const selectSolubles = (state: RootState) =>
  state.ingredients.soluble.data;
export const selectSolubleCount = (state: RootState) =>
  state.ingredients.soluble.count;
export const selectSolubleById = (id: string) => (state: RootState) =>
  state.ingredients.soluble.map[id];

export default solublesReducer;
