import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction, } from 'redux';
import { RootState } from '../../store';
import {
    FETCH_START,
    FETCH_END,
    FETCH_ERROR,
    FETCH_CANCEL,
} from './actions';

type LoadingState = number; //redundant

const initialState: LoadingState = 0;

const isFetchAction = (action: AnyAction): action is PayloadAction => (([
  FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  FETCH_CANCEL,
]).indexOf(action.type) >= 0);

const loadingReducer = createReducer(initialState, (builder) => {
  builder
    // You can apply a "matcher function" to incoming actions
    .addMatcher(isFetchAction, (state, { type }) => {
      switch (type) {
        case FETCH_START:
            return state + 1;
        case FETCH_END:
        case FETCH_ERROR:
        case FETCH_CANCEL:
            return Math.max(state - 1, 0);
        default:
            return state;
      }
    })
    ;
} );

export const selectLoading = (state: RootState) => state.loading;

export default loadingReducer;