import axios, { AxiosResponse } from "axios";
import { Bottle } from "./types/bottle";
import { Garnish } from "./types/garnish";
import { Granular } from "./types/granular";
import { Leaf } from "./types/leaf";
import { PaginationResponse } from "./types/pagination";
import {
  getAccessTokenRequestForAxiosOptions,
  transformID,
  transformIDElement,
} from "./utils";

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllDispenserBottles = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Bottle>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/bottles/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const updateDispenserBottle = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Bottle>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/bottles/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const getAllDispenserBib = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/bib/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  body.data.forEach((el) => transformID(el.valveIds));
  return body;
};

const updateDispenserBib = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/valv/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const manualCommandValve = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/valv/${payload.id}/manualCommand`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const manualCommandIce = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/ice/${payload.id}/manualCommand`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const getAllDispenserGranulars = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/granulars/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const updateDispenserGranular = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Granular>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/granulars/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const getAllFruitCutters = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/garnishes/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const updateFruitCutter = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Garnish>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/garnishes/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const getAllDispenserLeaves = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/leaves/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const updateDispenserLeaf = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Leaf>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/leaves/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

const getAllDispenserIce = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/ice/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

const updateDispenserIce = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Leaf>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/ice/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};


const getAllDispenserCoffee = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<any>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/coffee/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

const updateDispenserCoffee = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Leaf>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/udispenser/coffee/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

const api = {
  getAllDispenserBottles,
  updateDispenserBottle,
  getAllDispenserBib,
  updateDispenserBib,
  manualCommandValve,
  manualCommandIce,
  getAllDispenserGranulars,
  updateDispenserGranular,
  getAllFruitCutters,
  updateFruitCutter,
  getAllDispenserLeaves,
  updateDispenserLeaf,
  getAllDispenserIce,
  updateDispenserIce,
  getAllDispenserCoffee,
  updateDispenserCoffee,
};
export default api;
