import React, { FunctionComponent } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useLoading } from "../../loading/hooks";
import { useController } from "react-hook-form";
import { Flavour } from "../../../services/types/flavour";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { findFlavourByName } from "../../ingredientsScreen/flavour/flavourSlice";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";

interface BibDispenserLookupProps {
  name: string;
  label: string;
  fullWidth?: boolean;
  required?: boolean;
  control: any;
  onChange?: (valueObject: any) => void;
}

const BibDispenserLookup: FunctionComponent<BibDispenserLookupProps> = (
  props
) => {
  const filter = createFilterOptions();
  const {
    name,
    label,
    fullWidth,
    control,
    onChange: onParentChange,
    required,
  } = props;

  const {
    field: { value, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });
  const bar = useSelector(selectCurrentInstanceBar);
  const [options, setOptions] = React.useState<Flavour[]>([]);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = React.useState(value ? value.name : "");
  const isLoading = useLoading();

  const fetch = React.useMemo(
    () =>
      debounce(
        (inputValue) =>
          dispatch(
            findFlavourByName(
              inputValue,
              bar,
              (result: { payload: Flavour[] }) => {
                console.log(result.payload);
                setOptions(result.payload);
              },
              (e: any) => {
                console.error(e);
              }
            )
          ),
        300,
        { maxWait: 1000 }
      ),
    [bar, dispatch]
  );

  /*  const handleLabel = (option: any) => {
    return flavours.find((flav) => flav.id === option)?.name || option;
  };

  const handleValue = (value: string) => {
    return flavours.find((flav) => flav.name === value)?.id || "";
  };*/

  React.useEffect(() => {
    fetch(inputValue);
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      //options={flavours.map((option) => option.name)}
      //getOptionLabel={(option) => handleLabel(option)}
      getOptionLabel={(option) => option?.name || option}
      options={options}
      autoComplete
      fullWidth={fullWidth}
      value={value}
      includeInputInList
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered;
      }}
      filterSelectedOptions
      loading={isLoading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event: any, newValue) => {
        /*if (newValue && newValue.inputValue) {
          onChange(newValue.inputValue);
          onChange(handleValue(newValue)); //valore dato da autocomplete
        } else*/ if (newValue) {
          onChange(newValue);
        } else {
          onChange(null);
        }
        onParentChange && onParentChange(newValue);
        onBlur();
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          label={label}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default BibDispenserLookup;
