import React, { FunctionComponent, useEffect } from "react";

import JSONTree from "react-json-tree";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, LinearProgress, Paper } from "@material-ui/core";

import PageTitle from "../../components/layout/PageTitle";
import { useLoading } from "../loading/hooks";
import { getRobotById, selectRobotById } from "./robotSlice";

const RobotShowScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const { id }= useParams<{id: string}>();
  const history = useHistory();
  const robot = useSelector(selectRobotById(id));

  useEffect(() => {
    dispatch(getRobotById({
      id,
      onFailure: () => history.push("/robots")
    }));
  }, [dispatch, id, history]);
  
  return (
    <Box>
      <PageTitle
        pageTitle={"Robot Detail"}
        breadcrumb={["Operations", "Robot"]}
      />
      <Paper>
        {isLoading && <LinearProgress />}
        <code><JSONTree data={robot} /></code>
      </Paper>
    </Box>
  );
}

export default RobotShowScreen;