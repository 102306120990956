import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format, parseISO, subHours } from "date-fns";
import React, { FunctionComponent } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";
import { usePriceFormatter } from "../instanceBar/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F6F6F6",
      marginLeft: "2rem",
      borderRadius: "10px",
      boxShadow: "0px 8px 10px -10px rgba(0,0,0,1);",
    },
    title: {
      paddingTop: "1rem",
      paddingLeft: "2rem",
    },
    tooltip: {
      borderRadius: "0.25rem",
      background: "rgba(0, 0, 0, 0.7)",
      color: "#fff",
      padding: "0.3rem 1rem 0.3rem 1rem",
      textAlign: "center",
    },
  })
);

const data: any[] | undefined = [];
for (let num = 12; num >= 0; num--) {
  data.push({
    date: subHours(new Date(), num).toISOString(),
    value: 100 + 150 * Math.random(),
  });
}

type AreaChartProps = {
  data: any;
};

const AreaChartComponent: FunctionComponent<AreaChartProps> = ({
  data,
}) => {
  const classes = useStyles();
  const formatPrice = usePriceFormatter();
  const newData = data
    ?.map((el: { value: any; date: number }) => {
      return {
        value: el.value,
        date: subHours(new Date(), el.date - 1).toISOString(),
      };
    })
    .reverse();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" component="h2">
        Sales Today
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={newData}
          margin={{
            top: 25,
            right: 40,
            left: 25,
            bottom: 15,
          }}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#EB008B" stopOpacity={0.4} />
              <stop offset="75%" stopColor="#EB008B" stopOpacity={0.05} />
            </linearGradient>
          </defs>

          <Area dataKey="value" stroke="#EB008B" fill="url(#color)" />

          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tickFormatter={(str) => {
              const date = parseISO(str);
              if (date.getHours() % 3 === 0) {
                return format(date, "HH:mm");
              }
              return "";
            }}
          />

          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
            tickCount={4}
            tickFormatter={formatPrice}
          />

          <Tooltip content={<CustomTooltip />} />

          <CartesianGrid opacity={0.1} vertical={false} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  label?: any;
}

const CustomTooltip: FunctionComponent<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  const classes = useStyles();
  const formatPrice = usePriceFormatter();

  if (active) {
    return (
      <div className={classes.tooltip}>
        <h4>{label && format(parseISO(label), "HH:mm ,eeee, d MMM")}</h4>
        <p>
          {formatPrice(payload && payload[0].value)}
        </p>
      </div>
    );
  }
  return null;
};

export default AreaChartComponent;
