import { Breadcrumbs, Link, Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import React, { Fragment, FunctionComponent } from 'react'
import MainSpacer from './MainSpacer'


type BreadCrumbItem = {
  label: string,
  location: string,
} | string;
interface PageTitleProps {
  pageTitle: string;
  breadcrumb: BreadCrumbItem[];
}

const PageTitle: FunctionComponent<PageTitleProps> = ({ pageTitle, breadcrumb }) => {
  return (
    <Fragment>

      <Typography variant="h4" component="h1" gutterBottom>
        {pageTitle}
          </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumb.map((el, idx) => {
          if (typeof el === 'string' ) {
            return (<Typography key={idx} color="textPrimary">{el}</Typography>);
          }
          return (<Link component={NavLink} key={idx} color="inherit" to={el?.location || '/'} underline="none">{el?.label}</Link>);
        })}
      </Breadcrumbs>

      <MainSpacer />
    </Fragment>
  )
}

export default PageTitle
