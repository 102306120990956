import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Button,
  ListItemText,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createBeverage, findGlobalBeverageByName } from "./beverageSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Beverage } from "../../../services/types/beverage";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useFieldArray, useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { beverageCategories } from "../../constants";
import { Delete } from "@material-ui/icons";
import { findFlavourByName } from "../flavour/flavourSlice";
import FlavourLookup from "../FlavourLookup";
interface CreateBeverageFormProps {
  bar: Bar | null;
  onCreateSuccess: (beverage: Beverage) => any;
}

const CreateBeverageForm: FunctionComponent<CreateBeverageFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      category: "",
      color: "",
      price: 0,
      alcoholPerc: 0,
      flavours: [""],
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "flavours",
    keyName: "keyId",
  });

  const onSubmit = (data: Beverage) => {
    //setDisabled(true);
    const beverage = {
      name: data.name,
      category: data.category,
      subCategory: data.subCategory,
      color: data.color,
      price: data.price || 0,
      alcoholPerc: data.alcoholPerc || 0,
      flavours: data.flavours,
    };
    console.log(beverage);
    dispatch(
      createBeverage(
        beverage,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Beverage Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Beverage
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <GlobalIngredientLookup
            label="Name"
            name="name"
            control={control}
            setError={setError}
            onChange={(data: any) => {
              if (data) {
                console.log(data);
                data.color && setValue("color", data.color);
                data.alcoholPerc && setValue("alcoholPerc", data.alcoholPerc);
                data.category && setValue("category", data.category);
                data.flavours && setValue("flavours", data.flavours.map((f: any) => {
                  f.globalId = f.id;
                  return f;
                }));
                setValue("price", 0);
              }
            }}
            findGlobalIngredient={findGlobalBeverageByName}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {beverageCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <Typography variant="h5" component="h2" gutterBottom>
        Selected Flavours: {fields.length}/3
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List>
            {fields.map((field, idx) => {
              register({
                name: `flavours[${idx}].id`,
                defaultValue: field.id,
                value: field.id,
              });
              register({
                name: `flavours[${idx}].globalId`,
                defaultValue: field.globalId,
                value: field.globalId,
              });
              return (
              <div key={field.keyId}>
                <ListItem>
                  <FlavourLookup
                    label={`Flavour ${idx + 1}`}
                    name={`flavours[${idx}].name`}
                    control={control}
                    setError={setError}
                    bar={bar}
                    findIngredient={findFlavourByName}
                    //required
                    onChange={(data: any) => {
                      console.log({ DATA: data });
                      setValue(`flavours[${idx}].id`, data.id);
                    }}
                    defaultValue={field.name}
                    fullWidth
                    disableAdd
                  />
                  {/* <FieldArraySelect
                    label={`Flavour ${idx + 1}`}
                    name={`flavours[${idx}].name`}
                    defaultValue={field.name}
                    control={control}
                    onChangeProp={(evt) => {
                      setValue(
                        `flavours[${idx}].id`,
                        flavours.find((f) => f.name === evt.target.value)?.id
                      );
                      setValue(
                        `flavours[${idx}].name`,
                        flavours.find((f) => f.name === evt.target.value)?.name
                      );
                    }}
                  >
                    {flavours.map((sub, jdx) => (
                      <MenuItem key={jdx} value={sub.name}>
                        {sub.name}
                      </MenuItem>
                    ))}
                  </FieldArraySelect> */}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      color="primary"
                      onClick={() => remove(idx)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <InputNumber
                      label="Dispensing Time"
                      name={`flavours[${idx}].dispensingTime`}
                      defaultValue={field.dispensingTime}
                      register={register}
                    />
                  </ListItemText>
                </ListItem>
              </div>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (fields.length < 3) append({ name: "", dispensingTime: 0 });
              }}
            >
              Add Flavour
            </Button>
          </List>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="price"
            label="Price"
            register={register}
            fullWidth
          />
          <InputNumber
            name="alcoholPerc"
            label="Alcoholic Percentage"
            register={register}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Beverage!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateBeverageForm;
