import React, { useState, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../../../components/dialog/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditGranularForm from "./EditGranularForm";
import { Bar } from "../../../services/types/bar";
import { FunctionComponent } from "react";
import { DispenserGranular } from "../../../services/types/dispenserGranular";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 15,
      marginTop: 25,
      height: 250,
      borderRadius: 10,
      backgroundColor: "#F1F1F1",
      /*    backgroundImage:
        // eslint-disable-next-line no-useless-concat
        "url(" +
        "https://www.ledgerinsights.com/wp-content/uploads/2020/10/sugar.2-810x476.jpg" +
        ")",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat", */
    },
    /*          overlay: {
      position: "relative",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: "rgba(225,225,225,.3)",
      zIndex: 2,
    }, */
  })
);

interface GranularCardProps {
  bar: Bar | null;
  position: number;
  dispenser: DispenserGranular;
}

const GranularCard: FunctionComponent<GranularCardProps> = ({
  bar,
  position,
  dispenser,
}) => {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
  }>({ open: false });

  const handleCloseDialog = () => {
    setDialogState({
      open: false,
    });
  };

  const handleClick = useCallback(() => {
    setDialogState({
      open: true,
    });
  }, []);

  return (
    <>
      <Card className={classes.root}>
        {/* <div className={classes.overlay}> */}
        <CardHeader
          title={`Dispenser ${dispenser.graphicCode}`}
          subheader={`Dispensing Time: ${dispenser.dispensingTime}`}
        />
        <CardContent>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <ListItemText
                primary={dispenser.ingredient?.name}
                secondary={!dispenser.ingredient?.name && "UNASSIGNED"}
              />
              <ListItemSecondaryAction>
                <IconButton aria-label="settings" onClick={handleClick} >
                  {dispenser.ingredient?.name ? (
                    <EditOutlinedIcon color="primary" />
                  ) : (
                    <AddIcon color="primary" />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        {/* </div> */}
      </Card>
      <Dialog
        title="Edit Dispenser"
        open={dialogState.open}
        onAbort={handleCloseDialog}
      >
        <EditGranularForm
          onSuccess={handleCloseDialog}
          dispenser={dispenser}
        ></EditGranularForm>
      </Dialog>
    </>
  );
};

export default GranularCard;
