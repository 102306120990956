import React, { FunctionComponent, useEffect } from "react";
import { Paper, Box, LinearProgress } from "@material-ui/core";
import PageTitle from "../../../components/layout/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useHistory, useParams } from "react-router-dom";
import { useLoading } from "../../loading/hooks";
import JSONTree from "react-json-tree";
import { getLeafById, selectLeafById } from "./leafSlice";
import EditLeafForm from "./EditLeafForm";
import { useHasPermission } from "../../auth/hooks";

const LeafShowScreen: FunctionComponent = (props) => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);
  const isLoading = useLoading();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const leaf = useSelector(selectLeafById(id));
  const isSuperAdmin = useHasPermission("SUPERADMIN");

  useEffect(() => {
    if (bar) {
      dispatch(
        getLeafById(
          {
            id,
            onFailure: () => history.push("/ingredients"),
          },
          bar
        )
      );
    }
  }, [dispatch, id, bar, history]);

  return (
    <Box>
      <PageTitle
        pageTitle={bar?.name || "Leaf"}
        breadcrumb={[bar?.name || "Bar", {label: "Ingredients", location: "/ingredients/bottles"}, {label: "Leaf", location: "/ingredients/leaves"}, leaf?.name]}
      />
      {leaf && <EditLeafForm leaf={leaf} bar={bar} />}

      {isSuperAdmin && (
        <Paper>
          {isLoading && <LinearProgress />}
          <code>
            <JSONTree data={leaf} />
          </code>
        </Paper>
      )}
    </Box>
  );
};

export default LeafShowScreen;
