import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Paper } from "@material-ui/core";


import { getAllVouchers, selectVouchers, selectVouchersCount } from "./voucherSlice";
import { useLoading } from "../loading/hooks";
import { Voucher } from '../../services/types/voucher';
import { Column } from 'react-table';
import EnhancedTable, { FetchDataArgs } from '../../components/table/EnhancedTable';
import { selectCurrentInstanceBar } from '../instanceBar/instanceBarSlice';
import DateTimeCell from '../../components/table/cells/DateTimeCell';
import SelectFilter, { withOptions } from '../../components/table/filters/SelectFilter';

const typeOption = [
  "free",
  "valued",
];

const statusOption = [
  "created",
  "printed",
  "emailed",
  "used",
  "disabled",
  "expired",
];



const columns: Column<any>[] = [
  { Header: 'Created At', accessor: "createdAt", Cell: DateTimeCell, disableFilters: true },
  { Header: 'Type', accessor: "type", Filter: withOptions(typeOption, SelectFilter) },
  { Header: 'Code', accessor: "code" },
  { Header: 'Value', accessor: "value" },
  { Header: 'Status', accessor: "status", Filter: withOptions(statusOption, SelectFilter)  },
];

interface VoucherTableProp {
  onVoucherSelected: (Voucher: Voucher) => any,
}

const VoucherTable: FunctionComponent<VoucherTableProp> = ({ onVoucherSelected }) => {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);
  const Vouchers = useSelector(selectVouchers);
  const totalCount = useSelector(selectVouchersCount);


  const handleFetchData = useCallback(({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
    dispatch(getAllVouchers({ page: pageIndex, perPage: pageSize, sort: sortBy, search: filters }, bar));
  }, [bar, dispatch]);

  const handleRowClick = useCallback((Voucher: Voucher) => {
    onVoucherSelected(Voucher);
  }, [onVoucherSelected]);

  return (
    <Paper>
      <Box p={3}>
        <EnhancedTable
          columns={columns}
          data={Vouchers}
          totalCount={totalCount}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          fetchData={handleFetchData}
        />
      </Box>
    </Paper>
  )
}

export default VoucherTable
