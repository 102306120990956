import React, { useState, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../../../components/dialog/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditCoffeeForm from "./EditCoffeeForm";
import { Bar } from "../../../services/types/bar";
import { FunctionComponent } from "react";
import { DispenserCoffee } from "../../../services/types/dispenserCoffee";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 15,
      marginTop: 25,
      height: 250,
      borderRadius: 10,
      backgroundColor: "#F1F1F1",
    },
  })
);

interface CoffeeCardProps {
  bar: Bar | null;
  position: number;
  dispenser: DispenserCoffee;
}

const CoffeeCard: FunctionComponent<CoffeeCardProps> = ({
  bar,
  position,
  dispenser,
}) => {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
  }>({ open: false });

  const handleCloseDialog = () => {
    setDialogState({
      open: false,
    });
  };

  const handleClick = useCallback(() => {
    setDialogState({
      open: true,
    });
  }, []);

  return (
    <>
      <Card className={classes.root}>
        {/* <div className={classes.overlay}> */}
        <CardHeader
          title={`Dispenser ${dispenser.graphicCode}`}
          subheader={`Grinder Time: ${dispenser.grinderTime}`}
        />
        <CardContent>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <ListItemText
                primary={dispenser.ingredient?.name}
                secondary={!dispenser.ingredient?.name && "UNASSIGNED"}
              />
              <ListItemSecondaryAction>
                <IconButton aria-label="settings" onClick={handleClick}>
                  <EditOutlinedIcon color="primary" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        {/* </div> */}
      </Card>
      <Dialog
        title="Edit Dispenser"
        open={dialogState.open}
        onAbort={handleCloseDialog}
      >
        {dispenser && (
          <EditCoffeeForm onSuccess={handleCloseDialog} dispenser={dispenser} />
        )}
      </Dialog>
    </>
  );
};

export default CoffeeCard;
