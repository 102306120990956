import React, { FunctionComponent } from 'react';
import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  colorPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
  colorError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  colorWhite: {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
  },
}));


interface BadgeProp extends MuiBadgeProps {
  badgeColor?: "primary" | "secondary" | 'success' | 'warning' | 'white' | 'error',
}
  
const Badge: FunctionComponent<BadgeProp> = ({color, badgeColor, children, ...props}) => {
  const classes = useStyles();
  return (
    <MuiBadge classes={{ badge: clsx({
      [classes.colorPrimary]: badgeColor === 'primary',
      [classes.colorSecondary]: badgeColor === 'secondary',
      [classes.colorWarning]: badgeColor === 'warning',
      [classes.colorWhite]: badgeColor === 'white',
      [classes.colorSuccess]: badgeColor === 'success',
      [classes.colorError]: badgeColor === 'error',
    }) }}
      {...props}
      >
      {children}
    </MuiBadge>
  );
}

export default Badge