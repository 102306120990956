import React, { ChangeEvent, FunctionComponent } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
} from "@material-ui/core";
import { ingredientActionsArray } from "../constants";
import { Controller } from "react-hook-form";

interface InputRadioProps {
  name: string;
  label: string;
  control: any;
  index: number;
  disabled: boolean;
  shakerable: boolean;
  defaultValue?: any;
  setValue: (...args: any) => any;
}

const IngredientActionRadio: FunctionComponent<InputRadioProps> = ({
  name,
  label,
  control,
  index,
  disabled,
  shakerable,
  defaultValue,
  setValue,
}) => {
  const handleChange = React.useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      // if (evt.target.value) {
      setValue(name, evt.target.value);
      // }
    },
    [name, setValue]
  );
  return (
      <Controller
        name={name}
        label={label}
        control={control}
        defaultValue={defaultValue}
        render={({ value, onChange, onBlur, name }) => (
          <FormControl margin="normal" fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            <RadioGroup
              aria-label="gender"
              name={name}
              value={value}
              //defaultValue={defaultValue}
              row
              onChange={handleChange}
              style={{marginTop: "1em"}}
              
            >
              {ingredientActionsArray.map((action, idx) => (
                <FormControlLabel
                  key={idx}
                  disabled={
                    disabled ||
                    //(minCarbonatedIndex <= index &&
                    !shakerable &&
                      action === "SHAKE"
                  }
                  
                  value={action}
                  control={<Radio size="small" />}
                  label={action}
                  
                />
              ))}
              <FormControlLabel
                value={""}
                control={<Radio size="small" />}
                label={"No Action"}
              />
            </RadioGroup>
          </FormControl>
        )}
      />
  );
};

export default IngredientActionRadio;
