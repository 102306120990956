import React, { FunctionComponent, useState } from "react";
import Box from "@material-ui/core/Box";
import PageTitle from "../../components/layout/PageTitle";

import Dialog from "../../components/dialog/Dialog";
import { useHistory } from "react-router-dom";
import { User } from "../../services/types/user";
import UserManagementTable from "./UserManagementTable";
import CreateUserForm from "./CreateUserForm";
import FloatingActionButton from "../../components/layout/FloatingActionButton";

const UserListScreen: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  return (
    <Box>
      <PageTitle pageTitle={"Users"} breadcrumb={["Operations", "Users"]} />

      <UserManagementTable
        onUserSelected={(user: User) => {
          history.push(`/user-management/${user.id}`);
          // setOpen(false);
        }}
      />

      <FloatingActionButton onClick={() => setOpen(true)} />
      <Dialog
        title="Create New User"
        open={open}
        onAbort={() => setOpen(false)}
      >
        <CreateUserForm
          onCreateSuccess={(user: User) => {
            history.push(`/user-management/${user.id}`);
            // setOpen(false);
          }}
        ></CreateUserForm>
      </Dialog>
    </Box>
  );
};

export default UserListScreen;
