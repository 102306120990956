import React from 'react';

export default class ErrorBoundary extends React.PureComponent<any> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, errorInfo: any) {
    console.error(
      'Error caught.. Will you do something about it?',
      error,
      errorInfo,
    );
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? (
      <div>Something went wrong. Our team is investigating the problem</div>
    ) : (
        this.props.children
      );
  }
}