import React, { FunctionComponent } from 'react'

import { TextField } from '@material-ui/core';

import { ColumnInstance, useAsyncDebounce } from 'react-table';
import FilterLayout from './FilterLayout';


interface InputFilterProps {
  column: ColumnInstance;
  setFilter: (columnId: string, value: string | null) => any
}

const InputFilter: FunctionComponent<InputFilterProps> = ({column, setFilter, ...props}) => {

  const deboucedSetFilter = useAsyncDebounce((value) => setFilter(column.id as string, value), 300);
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => deboucedSetFilter(event.target.value);

  return (
    <FilterLayout onClose={() => setFilter(column.id as string, null)}>
      <TextField 
        placeholder="Search in column..."
        onChange={handleSearchChange}
        defaultValue={column.filterValue || '' }
        fullWidth
      ></TextField>
    </FilterLayout>
      
  )
}

export default InputFilter
