import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../store";
import {
  PaginationPayload,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Coffee } from "../../../services/types/coffee";
import { MapLike } from "typescript";

// STATE INTERFACE
interface CoffeeState {
  data: Coffee[];
  map: MapLike<Coffee>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: CoffeeState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

// ACTIONS
export const getAllGlobalCoffee = createAction<
  PrepareAction<PaginationPayload>
>(
  "coffee/getAllGlobalCoffee",
  ({ page, perPage, sort, search }) => ({
    payload: {
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getAllGlobalCoffee",
    },
  })
);

export const getAllGlobalCoffeeSuccess = createAction<
  PaginationResponse<Coffee>
>("coffee/getAllGlobalCoffee_SUCCESS");

export const getGlobalCoffeeById = createAction<PrepareAction<{ id: string }>>(
  "coffee/getGlobalCoffeeById",
  ({ id, onFailure }) => ({
    payload: {
      id,
    },
    meta: {
      fetch: "globalIngredients",
      resource: "getGlobalCoffeeById",
      onFailure,
    },
  })
);
export const getGlobalCoffeeByIdSuccess = createAction<Coffee>(
  "coffee/getGlobalCoffeeById_SUCCESS"
);

export const updateGlobalCoffee = createAction<PrepareAction<Coffee>>(
  "coffee/updateGlobalCoffee",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "updateGlobalCoffee",
      onSuccess,
      onFailure,
    },
  })
);
export const updateGlobalCoffeeSuccess = createAction<Coffee>(
  "coffee/updateGlobalCoffee_SUCCESS"
); /* 
export const findGlobalCoffeeByName = createAction<
  PrepareAction<{ name: string }>
>("coffee/findGlobalCoffeeByName", (payload, onSuccess, onFailure) => ({
  payload: {
    name: payload,
  },
  meta: {
    fetch: "ingredients",
    resource: "findGlobalCoffeeByName",
    onSuccess,
    onFailure,
  },
})); */
export const createGlobalCoffee = createAction<PrepareAction<Coffee>>(
  "coffee/createGlobalCoffee",
  (payload, onSuccess, onFailure) => ({
    payload,
    meta: {
      fetch: "globalIngredients",
      resource: "createGlobalCoffee",
      onSuccess,
      onFailure,
    },
  })
);
export const createGlobalCoffeeSuccess = createAction<Coffee>(
  "coffee/createGlobalCoffee_SUCCESS"
);

// REDUCERS
const globalCoffeeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllGlobalCoffeeSuccess,
      (state: CoffeeState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Coffee) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getGlobalCoffeeByIdSuccess,
      (state: CoffeeState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;
      }
    )
    .addCase(updateGlobalCoffeeSuccess, (state: CoffeeState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectGlobalCoffee = (state: RootState) =>
  state.globalIngredients.coffee.data;
export const selectGlobalCoffeeCount = (state: RootState) =>
  state.globalIngredients.coffee.count;
export const selectGlobalCoffeeById = (id: string) => (state: RootState) =>
  state.globalIngredients.coffee.map[id];

export default globalCoffeeReducer;
