import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    drawerWidth: number;
  }
  interface ThemeOptions {
    drawerWidth: number;
  }
}

// A custom theme for this app
const theme = createMuiTheme({
  drawerWidth: 250,
  palette: {
    primary: {
      main: "#EB008B",
    },
    secondary: {
      main: '#5AC1BE',
    },
    success: {
      main: "#6C7EA4",
    },
    error: {
      main: "#EE4036",
    },
    background: {
      default: "#eee",
    },
  },
  typography: {
    fontFamily: [
      "decima",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
