import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useController } from "react-hook-form";

type InputSwitchrops = {
  name: string;
  label: string;
  control: any;
  classes: any;
  val?: string;
};

const InputSwitch: FunctionComponent<InputSwitchrops> = ({
  name,
  label,
  control,
  classes,
  val,
}) => {
  const {
    field: { value, ref, onChange, onBlur },
    // meta: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
  });
  return (
    <FormControlLabel
      label={label}
      className={classes}
      control={
        <Checkbox
          inputRef={ref}
          onChange={(evt, value) => {
            onChange(value);
            onBlur();
          }}
          checked={!!value}
        />
      }
    />
  );
};

export default InputSwitch;
