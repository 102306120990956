import React, {
  FunctionComponent,
  useEffect,
  useState,
  MouseEvent,
} from "react";
import {
  Paper,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  LinearProgress,
  Typography,
  IconButton,
  TableHead,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  DragHandle as DragHandleIcon,
  Cancel as CancelIcon,
  Check as CheckIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable, SortableEvent } from "react-sortablejs";
import arrayMove from "array-move";

import Dialog from "../../components/dialog/Dialog";
import PageTitle from "../../components/layout/PageTitle";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import {
  getAllRecipeCategories,
  selectRecipeCategories,
  updateSortingRecipeCategories,
} from "./recipeCategorySlice";
import { RecipeCategory } from "../../services/types/recipeCategory";
import { useLoading } from "../loading/hooks";
import CreateRecipeCategoryForm from "./CreateRecipeCategoryForm";
import ColorCell from "../../components/table/cells/ColorCell";
import UpdateRecipeCategoryForm from "./UpdateRecipeCategoryForm";
import { useHistory } from "react-router-dom";
import { usePriceFormatter } from "../instanceBar/hooks";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteRecipeCategory from "./DeleteRecipeCategory";
import FloatingActionButton from "../../components/layout/FloatingActionButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const RecipeCategoryScreen: FunctionComponent = (props) => {
  const bar = useSelector(selectCurrentInstanceBar);
  const classes = useStyles();
  const categories = useSelector(selectRecipeCategories);
  const isLoading = useLoading();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [updateDialog, setUpdateDialog] = useState<{
    recipeCategory: RecipeCategory | null;
    open: boolean;
  }>({ recipeCategory: null, open: false });
  const [deleteDialog, setDeleteDialog] = useState<{
    recipeCategory: RecipeCategory | null;
    open: boolean;
  }>({ recipeCategory: null, open: false });

  const dispatch = useDispatch();
  const history = useHistory();
  const priceFormatter = usePriceFormatter();

  useEffect(() => {
    if (bar) {
      dispatch(getAllRecipeCategories(bar));
    }
  }, [dispatch, bar]);

  const handleSort = (evt: SortableEvent) => {
    const toBeUpdated = arrayMove(
      categories,
      evt.oldIndex as number,
      evt.newIndex as number
    )
      .filter((el, idx) => {
        if (
          (idx >= (evt.oldIndex as number) &&
            idx <= (evt.newIndex as number)) ||
          (idx <= (evt.oldIndex as number) && idx >= (evt.newIndex as number))
        ) {
          return true;
        }
        return false;
      })
      .map((el, idx) => ({
        ...el,
        order: idx + Math.min(evt.oldIndex as number, evt.newIndex as number),
      }));
    dispatch(updateSortingRecipeCategories(toBeUpdated, bar));
  };

  return (
    <Box className={classes.root}>
      <PageTitle
        pageTitle={bar?.name || "Recipe Categories"}
        breadcrumb={[bar?.name || "Bar", "Recipe Categories"]}
      />

      {categories.length === 0 && !isLoading && (
        <Typography variant="subtitle2" align="center">
          Click on the "Add" button below to add your first menu category
        </Typography>
      )}
      <Paper>
        {isLoading && <LinearProgress />}
        {categories.length > 0 && (
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category Price</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Visible</TableCell>
                  {bar?.features.coffee ? (
                    <TableCell>Is Coffee</TableCell>
                  ) : null}
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <ReactSortable
                tag="tbody"
                handle=".drag-handle"
                list={categories}
                setList={() => {}} // BUG IN THE LIBRARY
                onEnd={handleSort}
              >
                {categories.map((row: RecipeCategory) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => {
                      history.push(`/drinks/${row.id}`);
                    }}
                  >
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{priceFormatter(row.price)}</TableCell>
                    <TableCell>
                      <ColorCell value={row.color} />
                    </TableCell>
                    <TableCell>
                      {(row.enabled && <CheckIcon color="secondary" />) || (
                        <CancelIcon htmlColor="red" />
                      )}
                    </TableCell>
                    {bar?.features.coffee ? (
                      <TableCell>
                        {(row.isCoffee && <CheckIcon color="secondary" />) || (
                          <CancelIcon htmlColor="red" />
                        )}
                      </TableCell>
                    ) : null}
                    <TableCell align="right">
                      <IconButton
                        onClick={(evt: MouseEvent) => {
                          evt.stopPropagation();
                          setUpdateDialog({
                            recipeCategory: row,
                            open: true,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={(evt: MouseEvent) => {
                          evt.stopPropagation();
                          setDeleteDialog({
                            recipeCategory: row,
                            open: true,
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <span
                        className="drag-handle"
                        onClick={(evt: MouseEvent) => {
                          evt.stopPropagation();
                        }}
                      >
                        <IconButton>
                          <DragHandleIcon />
                        </IconButton>
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </ReactSortable>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <FloatingActionButton onClick={() => setCreateDialogOpen(true)} />

      <Dialog
        title="Create New Menu Category"
        open={createDialogOpen}
        onAbort={() => setCreateDialogOpen(false)}
      >
        <CreateRecipeCategoryForm
          bar={bar}
          onFormSubmit={() => setCreateDialogOpen(false)}
        />
      </Dialog>
      <Dialog
        title="Update Menu Category"
        open={updateDialog.open}
        onAbort={() =>
          setUpdateDialog({
            ...updateDialog,
            open: false,
          })
        }
      >
        <UpdateRecipeCategoryForm
          recipeCategory={updateDialog.recipeCategory as RecipeCategory}
          onFormSubmit={() =>
            setUpdateDialog({
              recipeCategory: null,
              open: false,
            })
          }
        />
      </Dialog>
      <Dialog
        title="Delete Menu Category"
        open={deleteDialog.open}
        onAbort={() =>
          setDeleteDialog({
            ...deleteDialog,
            open: false,
          })
        }
      >
        <DeleteRecipeCategory
          recipeCategory={deleteDialog.recipeCategory as RecipeCategory}
          onSubmit={() =>
            setDeleteDialog({
              recipeCategory: null,
              open: false,
            })
          }
        />
      </Dialog>
    </Box>
  );
};

export default RecipeCategoryScreen;
