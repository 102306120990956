import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { usePriceFormatter } from "../instanceBar/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F6F6F6",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "space-around",
      height: "8rem",
      margin: "2rem 0rem 2rem 2rem",
      padding: "1rem 2rem",
      borderRadius: "10px",
      boxShadow: "0px 8px 10px -10px rgba(0,0,0,1);",
    },
    number: {
      color: theme.palette.primary.main,
    },
  })
);

type SquareCounterProps = {
  title: string;
  data: any;
};

const SquareCounter: FunctionComponent<SquareCounterProps> = ({
  title,
  data
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h5">
        {title}
      </Typography>
      <Typography className={classes.number} component="h2" variant="h5">
        {data}
      </Typography>
    </div>
  );
};

export default SquareCounter;
