import React, {FunctionComponent, useState, useEffect, useRef } from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Button, Theme, Typography} from "@material-ui/core";
import {addMonths, subMonths} from "date-fns";
import queryString from "query-string";
import {useHistory} from "react-router-dom";
import { AxiosResponse } from "axios";
import Dialog from "../dialog/Dialog";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
            padding: theme.spacing(3),
            paddingBottom: theme.spacing(1),
        },
        left: {
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "flex-start",
        },
        right: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        margin: {
            marginLeft: "1rem"
        },
    })
);

interface TableHeaderExportProps {
    headers: any[];
    onExport: (filter: any, type: string) => any; // Must be a useCallback; 
}


const TableHeaderExport: FunctionComponent<TableHeaderExportProps> = ({headers, onExport}) => {
    const classes = useStyles();
    const history = useHistory();
    const currentQuery = queryString.parse(history.location.search);
    const initialFilter = JSON.parse(currentQuery?.filters as string || '[]').filter((el: any) => el.id === 'createdAt').pop();
            
    const [dateFrom, setDateFrom] = useState<Date | null>(initialFilter?.value?.dateFrom ? new Date(initialFilter?.value?.dateFrom) : null);
    const [dateTo, setDateTo] = useState<Date | null>(initialFilter?.value?.dateTo ? new Date(initialFilter?.value?.dateTo)  : null);
    
    const [applied, setApplied] = useState<boolean>(dateFrom !== null && dateTo !== null);

    const [PreviewDialog, setPreviewDialog] = useState<{
        open: boolean;
      }>({ open: false });

    const onApply =
        () => {
            const currentQuery = queryString.parse(history.location.search);
            const filters = JSON.parse(currentQuery?.filters as string || '[]').filter((el: any) => el.id !== 'createdAt');
            if (dateFrom !== null && dateTo !== null) {
                filters.push({
                    id: 'createdAt',
                    value: {
                        dateTo: dateTo?.toISOString(), dateFrom: dateFrom?.toISOString()
                    }
                });
                setApplied(true);
                currentQuery.pageIndex="0";
            } else {
                setApplied(false);
            }
            history.push("?" + queryString.stringify({...currentQuery, filters: JSON.stringify(filters)}));
        };

    useEffect(() => {
        if (dateTo == null || dateFrom == null ) {
            onApply()
        }
    }, [dateTo, dateFrom])

    const handleCsvExport = () => {
        // const filters = JSON.parse(currentQuery?.filters as string || '[]');
        // onExport(filters, 'csv');
        setPreviewDialog({open: true});

    }
    
    const handleXlsxExport = () => {
        // const filters = JSON.parse(currentQuery?.filters as string || '[]');
        // onExport(filters, 'xlsx');
        setPreviewDialog({open: true});
    }
    return (
        <div className={classes.root}>
            <div className={classes.left}>
                <Typography>Date: </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className={classes.margin}
                        clearable
                        label={"From"}
                        format={"dd/MM/yyyy"}
                        minDate={dateTo === null ? undefined : subMonths(dateTo, 3)}
                        maxDate={dateTo === null ? undefined : dateTo}
                        value={dateFrom}
                        onChange={(newValue) => {
                            setDateFrom(newValue);
                        }}
                        animateYearScrolling={false}
                    />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        className={classes.margin}
                        clearable
                        minDate={dateFrom === null ? undefined : dateFrom}
                        maxDate={dateFrom === null ? undefined : addMonths(dateFrom, 3)}
                        label={"To"}
                        format={"dd/MM/yyyy"}
                        value={dateTo}
                        onChange={(newValue) => {
                            setDateTo(newValue);
                        }}
                        animateYearScrolling={false}

                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className={classes.right}>

                <Button variant={"contained"} color="primary" onClick={onApply}
                        disabled={dateFrom == null || dateTo == null}
                >
                    Apply
                </Button>
                <Button variant={"contained"} color={"secondary"} className={classes.margin}
                        disabled={!applied}
                        onClick={handleCsvExport}
                >
                        Export csv
                </Button>
                <Button variant={"contained"} color={"secondary"} className={classes.margin}
                        disabled={!applied}
                        onClick={handleXlsxExport}
                >
                        Export Excel
                </Button>
            </div>

            <Dialog
                title="Explore Our New Web Portal!"
                open={PreviewDialog.open}
                onAbort={(evt) => {
                (evt as MouseEvent).stopPropagation();
                setPreviewDialog({
                    open: false,
                });
                }}
                renderActions={() => {
                    return <>
                        <Button variant="text" color="default" onClick={() =>  setPreviewDialog({
                    open: false,
                })}>Stay on Current Version</Button>
                        <Button variant="contained" color="primary" href="https://preview.makrshakr.app">Explore now</Button>
                    </>
                }}
            >
                <Typography>
                We are excited to announce a brand-new version of our web console, designed with your needs in mind! Our team has been working hard to improve your experience, and we invite you to try out the new features and design.
                </Typography>
                <br></br>
                <Typography variant="h5">
                    Key Features:
                </Typography>
                <Typography>
                <ul>
                    <li>Enhanced User Interface</li>
                    <li>New Analitics Dashboard</li>
                    <li>Better exports management</li>
                    <li>Faster Performance</li>
                </ul>
                </Typography>

                <Typography variant="subtitle2"><Button variant="text" color="primary" size="large" href="https://preview.makrshakr.app">👉 Try the New Web Portal</Button></Typography>
                <Typography variant="h6">
                    Support Information:
                </Typography>
                <Typography>
                If you encounter any issues or have feedback, please reach out to us at <a href="mailto:support@makrshakr.com" target="_blank">support@makrshakr.com</a>.
                </Typography>
                
                
            </Dialog>
        </div>
    );
}

export default TableHeaderExport;