import { createReducer, createAction, PrepareAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { MapLike } from "typescript";
import { Bar } from "../../../services/types/bar";
import { RootState } from "../../../store";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../../services/types/pagination";
import { Coffee } from "../../../services/types/coffee";

interface CoffeeState {
  data: Coffee[];
  map: MapLike<Coffee>;
  count: number;
  page: number;
  perPage: number;
}

const initialState: CoffeeState = {
  data: [],
  map: {},
  count: 0,
  page: 1,
  perPage: 2,
};

//Actions
export const getAllCoffees = createAction<
  PrepareAction<PaginationPayloadByBar>
>("coffee/getAllCoffees", ({ page, perPage, sort, search }, bar: Bar) => ({
  payload: {
    barId: bar?.id,
    page,
    perPage,
    sort: (sort || []).reduce(
      (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
        acc[sorter.id] = sorter.desc ? -1 : 1;
        return acc;
      },
      {}
    ),
    search: (search || []).reduce(
      (acc: MapLike<string>, searcher: { id: string; value: string }) => {
        acc[searcher.id] = searcher.value;
        return acc;
      },
      {}
    ),
  },
  meta: {
    fetch: "ingredients",
    resource: "getAllCoffees",
  },
}));

export const getAllCoffeesSuccess = createAction<PaginationResponse<Coffee>>(
  "coffee/getAllCoffees_SUCCESS"
);

export const getCoffeeById = createAction<PrepareAction<{ id: string }>>(
  "coffee/getCoffeeById",
  ({ id, onFailure }, bar: Bar) => ({
    payload: {
      barId: bar.id,
      id,
    },
    meta: {
      fetch: "ingredients",
      resource: "getCoffeeById",
      onFailure,
    },
  })
);

export const getCoffeeByIdSuccess = createAction<Coffee>(
  "coffee/getCoffeeById_SUCCESS"
);

export const updateCoffee = createAction<PrepareAction<Coffee>>(
  "coffee/updateCoffee",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "updateCoffee",
      onSuccess,
      onFailure,
    },
  })
);

export const updateCoffeeSuccess = createAction<Coffee>(
  "coffee/updateCoffee_SUCCESS"
);

export const findGlobalCoffeeByName = createAction<
  PrepareAction<{name: string}>
  >("coffee/findGlobalCoffeeByName", (payload, onSuccess, onFailure) =>({
    payload: {
      name: payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "findGlobalCoffeeByName",
      onSuccess,
      onFailure
    },
  }));

export const createCoffee = createAction<PrepareAction<Coffee>>(
  "coffee/createCoffee",
  (payload, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      ...payload,
    },
    meta: {
      fetch: "ingredients",
      resource: "createCoffee",
      onSuccess,
      onFailure,
    },
  })
);

export const createCoffeeSuccess  = createAction<Coffee>(
  "coffee/createCoffee_SUCCESS"
);

//Reducers
const coffeeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllCoffeesSuccess,
      (state: CoffeeState, { payload: { data, count, page, perPage } }) => {
        state.data = data;
        state.map = _.keyBy(data, (el: Coffee) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    .addCase(
      getCoffeeByIdSuccess,
      (state: CoffeeState, { payload }) => {
        state.data.push(payload);
        state.map[payload.id] = payload;      
      })
    .addCase(updateCoffeeSuccess, (state: CoffeeState, { payload }) => {
      state.map[payload.id] = payload;
    });
});

//Selector
export const selectCoffee = (state: RootState) =>
  state.ingredients.coffee.data;
export const selectCoffeeCount = (state: RootState) =>
  state.ingredients.coffee.count;
export const selectCoffeeById = (id: string) => (state: RootState) =>
  state.ingredients.coffee.map[id];

export default coffeeReducer;
