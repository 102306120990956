import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { FunctionComponent } from "react";
import { RegisterOptions } from "react-hook-form";
import InputNumber from "../../../components/form/InputNumber";
import InputSwitch from "../../../components/form/InputSwitch";
import { Bar } from "../../../services/types/bar";
import FieldArrayCups from "./FieldArrayCups";

interface RoboticSettingsProps {
  register: (arg:RegisterOptions) => any;
  control: any;
  bar: Bar;
  errors: any;
  className: any;
}

const ComponentRoboticSettings: FunctionComponent<RoboticSettingsProps> = ({
  register,
  errors,
  control,
  bar,
  ...props
}) => {
  return (
    <div {...props}>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Robotic Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <InputSwitch
              name="roboticSystem.danceMode"
              label="Dance Mode"
              control={control}
              type="checkbox"
            />
          </Grid>
          <Grid item md={6}>
            <InputSwitch
              name="roboticSystem.ghostMode"
              label="Simulation mode (Ghost mode)"
              control={control}
              type="checkbox"
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Washing Settings
        </Typography>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <InputNumber
              name="roboticSystem.bibWashingTime"
              label="Bib Washing Time"
              register={register}
              // required
              fullWidth
            />
            <InputNumber
              name="roboticSystem.shakerWashingTime"
              label="Shaker Washing Time"
              register={register}
              // required
              fullWidth
            />
            <InputNumber
              name="roboticSystem.cutterTime"
              label="Cutter Time"
              register={register}
              // required
              fullWidth
            />
          </Grid>
          <Grid item md={6}>
            <InputNumber
              name="roboticSystem.cupDispenserTime"
              label="Cup Dispenser Time"
              register={register}
              // required
              fullWidth
            />
            <InputNumber
              name="roboticSystem.crusherTime"
              label="Crusher Time"
              register={register}
              // required
              fullWidth
            />
            <InputNumber
              name="roboticSystem.leavesDispenserTime"
              label="Leaves Dispensing Time"
              register={register}
              // required
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box p={3}>
        <Typography variant="h5" component="h2" gutterBottom>
          Cups Settings
        </Typography>
          <FieldArrayCups
            name="roboticSystem.cups"
            control={control}
            register={register}
          />

      </Box>
    </div>
  );
};

export default ComponentRoboticSettings;
