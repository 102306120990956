import React from "react";
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useFieldArray } from "react-hook-form";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import InputNumber from "../../../components/form/InputNumber";

interface FieldArrayProps {
  name: string;
  control: any;
  inputRef: any;
  classes: any;
}

const FieldArrayRounding: FunctionComponent<FieldArrayProps> = ({
  name,
  control,
  inputRef,
  classes,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
    keyName: "keyId",
  });

  return (
    <Box className={classes}>
      {fields.map((field, index) => (
        <div key={field.keyId}>
          <Grid container spacing={4} key={index} alignItems="center">
            <Grid item sm={3} xs={12}>
              <IconButton
                onClick={() => remove(index)} // remove a friend from the list
              >
                <RemoveIcon />
              </IconButton>
              <IconButton
                onClick={() => append({})} // insert an empty string at a position
              >
                <AddIcon />
              </IconButton>
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputNumber
                name={`${name}.${index}.from`}
                defaultValue={field.from}
                label="From"
                register={inputRef}
                inputProps={{ step: 0.01 }}
                fullWidth
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputNumber
                name={`${name}.${index}.to`}
                defaultValue={field.to}
                label="to"
                register={inputRef}
                inputProps={{ step: 0.01 }}
                fullWidth
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputNumber
                name={`${name}.${index}.roundTo`}
                label="Round to"
                defaultValue={field.roundTo}
                register={inputRef}
                inputProps={{ step: 0.01 }}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      ))}
      {fields.length === 0 && (
        <Button variant="outlined" onClick={() => append({})}>
          Add Rounding rule
        </Button>
      )}
    </Box>
  );
};

export default FieldArrayRounding;
