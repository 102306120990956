import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

import PageTitle from "../../components/layout/PageTitle";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import ExportRequestTable from "./ExportRequestTable";

const ExportRequestListScreen: FunctionComponent = () => {
  const bar = useSelector(selectCurrentInstanceBar);

  return (
    <Box>
       <PageTitle
        pageTitle={"Exports"}
        breadcrumb={[bar?.name || "Bar", "Exports"]}
      />
      
      <ExportRequestTable />

    </Box>
  )
}

export default ExportRequestListScreen
