import React, { FunctionComponent } from 'react'

import { MenuItem, MenuList, Paper, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from '../../services/types/bar';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
interface AvaliableBarListProps {
  bars: Bar[],
  onClick: (bar: Bar) => any
};

const AvaliableBarList: FunctionComponent<AvaliableBarListProps> = ({ bars, onClick }) => {
  const classes = useStyles();

  if (!bars.length) {
    return <Typography className={classes.typography}>You have no selectable bars.</Typography>
  }
  return (
    <Paper>
      <MenuList>
        {bars.map((bar, key) => {
          return (
            <MenuItem key={key} onClick={() => onClick(bar)}>{bar.name}</MenuItem>
          )
        })}
      </MenuList>
    </Paper>
  )
}

export default AvaliableBarList
