import React, { FunctionComponent, useState } from "react";

import { Paper, Box, Typography, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { CoffeeType } from "../../../services/types/coffeeType";
import { showNotification } from "../../notification/notificationSlice";
import { updateGlobalCoffeeType } from "./globalCoffeeTypeSlice";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputButton from "../../../components/form/InputButton";

interface EditCoffeeTypeFormProps {
  coffeeType: CoffeeType;
}

const EditGlobalCoffeeTypeForm: FunctionComponent<EditCoffeeTypeFormProps> = ({
  coffeeType,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: coffeeType,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Partial<CoffeeType>) => {
    setDisabled(true);
    dispatch(
      updateGlobalCoffeeType(
        { ...coffeeType, ...data },
        () => {
          dispatch(showNotification("CoffeeType Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!coffeeType) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Global CoffeeType
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditGlobalCoffeeTypeForm;
