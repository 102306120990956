import {
    all,
    call,
    cancelled,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects';

import { fetchStart, fetchEnd, fetchError, fetchCancel, FETCH_END, FETCH_ERROR } from '../../features/loading/actions'; 

import * as serviceFactory from "../../services";
import { AxiosError } from 'axios';
import { RootState } from '../../store';
import { UserType } from '../../features/auth/authSlice';

export interface ActionWithFetch {
    type: string;
    payload: any;
    meta: {
        fetch: string;
        resource: string;
        onSuccess?: (...args: any) => any;
        onFailure?: (...args: any) => any;
    };
}

export function* handleFetch(
    action: ActionWithFetch
) {
    const {
        type,
        payload,
        meta: { fetch: serviceName, onSuccess, onFailure, ...meta },
    } = action;
    const restType = serviceName;
    const successSideEffects = onSuccess instanceof Function ? onSuccess : () => {};
    const failureSideEffects = onFailure instanceof Function ? onFailure : () => {};

    try {
        const service = serviceFactory.getService(serviceName);

        yield all([
            put({ type: `${type}_LOADING`, payload, meta }),
            put(fetchStart()),
        ]);
        const user: UserType | undefined = yield select((state: RootState)=>state.auth.user);
        const response = yield call(
            service[meta.resource],
            payload,
            user?.accessToken,
        );
        yield put({
            type: `${type}_SUCCESS`,
            payload: response,
            requestPayload: payload,
            meta: {
                ...meta,
                fetchResponse: restType,
                fetchStatus: FETCH_END,
            },
        });
        yield put(fetchEnd());
        yield call(successSideEffects, {
            payload: response,
            requestPayload: payload,
        });
    } catch (e) {
        console.error(e);
        const error: AxiosError = e;
        yield put({
            type: `${type}_FAILURE`,
            error: (error && (error.message ? error.message : error)) || null,
            payload: (error && error.response) || null,
            requestPayload: payload,
            meta: {
                ...meta,
                fetchResponse: restType,
                fetchStatus: FETCH_ERROR,
            },
        });
        yield put(fetchError(error));
        console.log({failureSideEffects});
        yield call(failureSideEffects, {
            error: (error && (error.message ? error.message : error)) || null,
            payload: (error && error.response) || null,
        });
    } finally {
        if (yield cancelled()) {
            yield put(fetchCancel());
            return;
        }
    }
}

export const takeFetchAction = (action: any) => action.meta && action.meta.fetch;

function* watchFetch() {
    yield takeEvery(takeFetchAction, handleFetch);
};

export default watchFetch;