import React, { FunctionComponent } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  color: {
    borderRadius: theme.shape.borderRadius,
    width: "35px",
    height: "35px",
    display: "block",
  }
}));

interface ColorCellProps {
  value: string;
}
const ColorCell: FunctionComponent<ColorCellProps> = ({value: color}) => {
  const classes = useStyles();

  return (
    <span className={classes.color} style={{backgroundColor: color}}></span>
  )
}

export default ColorCell
