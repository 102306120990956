import React, { FunctionComponent, useState } from "react";

import { Box, FormControl, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showNotification } from "../../notification/notificationSlice";
import { updateDispenserGranular } from "./dispenserGranularSlice";
import { DispenserGranular } from "../../../services/types/dispenserGranular";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import { findGranularByName } from "../../recipeScreen/recipeSlice";
import IngredientDispenserLookup from "../IngredientDispenserLookup";

interface EditGranularFormProps {
  onSuccess: () => any;
  dispenser: DispenserGranular;
}

const EditGranularForm: FunctionComponent<EditGranularFormProps> = ({
  onSuccess,
  dispenser,
}) => {
  const { register, control, handleSubmit, setError } = useForm({
    defaultValues: {
      ingredient: dispenser.ingredient,
      dispensingTime: dispenser.dispensingTime,
    },
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: any) => {
    setDisabled(true);
    dispatch(
      updateDispenserGranular(
        {
          ...dispenser,
          ingredient: data.ingredient,
          ingredientId: data.ingredient?.id || null,
          dispensingTime: data.dispensingTime,
        },
        () => {
          dispatch(showNotification("Dispenser Saved!", "success"));
          onSuccess();
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <FormControl margin="normal" fullWidth>
              <IngredientDispenserLookup
                label="Name"
                name="ingredient"
                barId={dispenser.barId}
                control={control}
                setError={setError}
                // onChange={(data: any) => {
                //   if (data && data.id) {
                //     console.log(data);
                //     setValue("ingredientId", data.id);
                //   } else {
                //     setValue("ingredientId", "");
                //   }
                // }}
                findIngredient={findGranularByName}
                fullWidth
              />
              <Grid item xs={12} md={6}>
                <InputNumber
                  name="dispensingTime"
                  label="Dispensing Time"
                  register={register}
                  required
                  fullWidth
                />
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
        <MainSpacer />
      </Box>
      <InputButton disabled={disabled}>Save!</InputButton>
    </form>
  );
};

export default EditGranularForm;
