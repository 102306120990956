import React, { FunctionComponent, useEffect } from "react";

import JSONTree from "react-json-tree";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Box, LinearProgress, Paper } from "@material-ui/core";

import PageTitle from "../../components/layout/PageTitle";
import { useLoading } from "../loading/hooks";
import { getVoucherById, selectVoucherById } from "./voucherSlice";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";

const VoucherShowScreen: FunctionComponent = () => {
  const dispatch = useDispatch();
  const bar = useSelector(selectCurrentInstanceBar);
  const isLoading = useLoading();
  const { id }= useParams<{id: string}>();
  const history = useHistory();
  const Voucher = useSelector(selectVoucherById(id));

  useEffect(() => {
    dispatch(getVoucherById({
      id,
      onFailure: () => history.push("/vouchers")
    }, bar));
  }, [dispatch, id, history, bar]);
  
  return (
    <Box>
      <PageTitle
        pageTitle={"Voucher Detail"}
        breadcrumb={[bar?.name || "Bar", {label: "Vouchers", location: "/vouchers"}, id]}
      />
      <Paper>
        {isLoading && <LinearProgress />}
        <code><JSONTree data={Voucher} /></code>
      </Paper>
    </Box>
  );
}

export default VoucherShowScreen;