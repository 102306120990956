import React, { FunctionComponent, useState } from "react";

import { Box, FormControl, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showNotification } from "../../notification/notificationSlice";
import { updateDispenserBib } from "./bibSlice";
import { Valv } from "../../../services/types/dispenserBib";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import BibDispenserLookup from "./BibDispenserLookup";

interface EditBibFormProps {
  onSuccess: () => any;
  valve: Valv;
}

const EditBibForm: FunctionComponent<EditBibFormProps> = ({
  onSuccess,
  valve,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      flavour: valve.flavourName,
    },
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: any) => {
    setDisabled(true);
    console.log(data);
    valve = {
      ...valve,
      flavourId: data.flavour?.id || null,
      flavourName: data.flavour?.name || null,
    };
    console.log(valve);

    dispatch(
      updateDispenserBib(
        valve,
        () => {
          dispatch(showNotification("Dispenser Saved!", "success"));
          onSuccess();
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p={1}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <FormControl margin="normal" fullWidth>
              <BibDispenserLookup
                label="Flavour"
                name="flavour"
                control={control}
                fullWidth
              />
            </FormControl>
          </Grid>
        </Grid>
        <MainSpacer />
      </Box>
      <InputButton disabled={disabled}>Save!</InputButton>
    </form>
  );
};

export default EditBibForm;
