import React from "react";
import { FunctionComponent } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "../../components/layout/PageTitle";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import { useSelector } from "react-redux";
import { Box, Paper, Tabs, Tab } from "@material-ui/core";
import { ChangeEvent } from "react";
import ProductionTable from "./ProductionTable";

const ProductionScreen: FunctionComponent = () => {
  const { tabActive } = useParams<{ tabActive: string }>();
  const bar = useSelector(selectCurrentInstanceBar);
  const history = useHistory();

  const setTabActive = (tab: string) => {
    history.push(`/production/${tab}`);
  };


  return (
    <Box>
      <PageTitle
        pageTitle={bar?.name || "Production"}
        breadcrumb={[bar?.name || "Bar", "Production", tabActive]}
      />

      <Paper>
        <Tabs
          value={tabActive}
          onChange={(event: ChangeEvent<{}>, value) => setTabActive(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Delivered" value="delivered" />
          <Tab label="Error" value="error" />
          <Tab label="Canceled" value="canceled" />
        </Tabs>
        {tabActive === "delivered" && (
          <Box>
            <ProductionTable status={tabActive} />
          </Box>
        )}
        {tabActive === "error" && (
          <Box>
            <ProductionTable status={tabActive} />
          </Box>
        )}
        {tabActive === "canceled" && (
          <Box>
            <ProductionTable status={tabActive} />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ProductionScreen;
