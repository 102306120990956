import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchStart, fetchEnd } from "./actions";
import { selectLoading } from "./loadingSlice";


/**
 * Get the loading status, i.e. a boolean indicating if at least one request is pending
 *
 *
 * @example
 *
 * import { useLoading } from './feature/loading/hooks'
 *
 * const MyComponent = () => {
 *      const loading = useLoading();
 *      return loading ? <Squeleton /> : <RealContent>;
 * }
 */
export const useLoading = () => (useSelector(selectLoading) > 0);

/**
 * Update the loading count, which starts or stops the loading indicator.
 *
 * To be used to show the loading indicator when you don't use the dataProvider.
 *
 * @return {Object} startLoading and stopLoading callbacks
 *
 * @example
 * import { useUpdateLoading } from './features/loading/hooks'
 *
 * const MyComponent = () => {
 *      const { startLoading, stopLoading } = useUpdateLoading();
 *      useEffect(() => {
 *          startLoading();
 *          fetch('http://my.domain.api/foo')
 *              .finally(() => stopLoading());
 *      }, []);
 *      return <span>Foo</span>;
 * }
 */
export const useUpdateLoading = () => {
  const dispatch = useDispatch();

  const startLoading = useCallback(() => {
    dispatch(fetchStart());
  }, [dispatch]);

  const stopLoading = useCallback(() => {
    dispatch(fetchEnd());
  }, [dispatch]);

  return { startLoading, stopLoading };
};
