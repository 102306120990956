import React, { FunctionComponent, useState } from "react";

import { Paper, Box, Typography, Grid, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { Granular } from "../../../services/types/granular";
import { showNotification } from "../../notification/notificationSlice";
import { updateGranular } from "./granularSlice";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputColor from "../../../components/form/InputColor";
import InputSelect from "../../../components/form/InputSelect";
import { granularCategories } from "../../constants";
import InputButton from "../../../components/form/InputButton";
import InputNumber from "../../../components/form/InputNumber";
import InputSwitch from "../../../components/form/InputSwitch";

interface EditGranularFormProps {
  granular: Granular;
  bar: Bar | null;
}

const EditGranularForm: FunctionComponent<EditGranularFormProps> = ({
  granular,
  bar,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: granular,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Granular) => {
    setDisabled(true);
    granular = {
      ...granular,
      name: data.name,
      category: data.category,
      color: data.color,
      disabled: data.disabled,
      visible: data.visible,
      price: data.price || 0,
    };
    console.log(granular);
    dispatch(
      updateGranular(
        granular,
        bar,
        () => {
          dispatch(showNotification("Granular Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!granular) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Granular
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                //defaultValue={granular.category}
                control={control}
                required
                fullWidth
              >
                {granularCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
            </Grid>
          </Grid>
        </Box>
        {/* <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Ingredient Info
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputSwitch
                label="Disabled ingredient?"
                name="disabled"
                type="checkbox"
                control={control}
              />
              <br />

              <InputSwitch
                label="Visible?"
                type="checkbox"
                name="visible"
                control={control}
              />
            </Grid>
            <Grid item md={6}>
              <InputNumber
                name="price"
                label="Price"
                register={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box> */}

        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditGranularForm;
