import React, { useState, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "../../../components/dialog/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import EditFruitCutterForm from "./EditFruitCutterForm";
import { Bar } from "../../../services/types/bar";
import { FunctionComponent } from "react";
import { FruitCutter } from "../../../services/types/fruitCutter";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 15,
      marginTop: 25,
      height: 250,
      borderRadius: 10,
      backgroundColor: "#F1F1F1",
    },
  })
);

interface FruitCutterCardProps {
  bar: Bar | null;
  position: number;
  dispenser: FruitCutter;
}

const FruitCutterCard: FunctionComponent<FruitCutterCardProps> = ({
  bar,
  position,
  dispenser,
}) => {
  const classes = useStyles();
  const [dialogState, setDialogState] = useState<{
    open: boolean;
  }>({ open: false });

  const handleCloseDialog = () => {
    setDialogState({
      open: false,
    });
  };

  const handleClick = useCallback(() => {
    setDialogState({
      open: true,
    });
  }, []);

  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title={`Cutter ${dispenser.graphicCode}`}
          subheader={`Dispensing Time: ${dispenser.dispensingTime}`}
        />
        <CardContent>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <ListItemText
                primary={dispenser.ingredient?.name}
                secondary={!dispenser.ingredient?.name && "UNASSIGNED"}
              />
              <ListItemSecondaryAction>
                <IconButton aria-label="settings" onClick={handleClick} >
                  {dispenser.ingredient?.name ? (
                    <EditOutlinedIcon color="primary" />
                  ) : (
                    <AddIcon color="primary"/>
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Dialog
        title="Edit Cutter"
        open={dialogState.open}
        onAbort={handleCloseDialog}
      >
        <EditFruitCutterForm
          onSuccess={handleCloseDialog}
          dispenser={dispenser}
        ></EditFruitCutterForm>
      </Dialog>
    </>
  );
};

export default FruitCutterCard;
