import { Box, Grid, MenuItem, Paper, Typography } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import InputText from "../../../components/form/InputText";
import { Bar } from "../../../services/types/bar";
import { Coffee } from "../../../services/types/coffee";
import { coffeeCategories } from "../../constants";
import { showNotification } from "../../notification/notificationSlice";
import { updateCoffee } from "./coffeeSlice";

// import InputSwitch from "../../../components/form/InputSwitch";

interface EditCoffeeFormProps {
  coffee: Coffee;
  bar: Bar | null;
}

const EditCoffeeForm: FunctionComponent<EditCoffeeFormProps> = ({
  coffee,
  bar,
}) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: coffee,
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Coffee) => {
    setDisabled(true);
    dispatch(
      updateCoffee(
        {...coffee, ...data},
        bar,
        () => {
          dispatch(showNotification("Coffee Saved!", "success"));
          setDisabled(false);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  if (!coffee) {
    return null;
  }
  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Edit Coffee
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputText
                name="name"
                label="Name"
                register={register}
                required
                fullWidth
              />
              <InputColor
                name="color"
                label="Color"
                control={control}
                setValue={setValue}
                required
              />
            </Grid>
            <Grid item md={6}>
              <InputSelect
                label="Category"
                name="category"
                //defaultValue={coffee.category}
                control={control}
                required
                fullWidth
              >
                {coffeeCategories.map((category, idx) => (
                  <MenuItem key={idx} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </InputSelect>
              <InputNumber 
                name="infusionTime"
                label="Infusion Time"
                register={register}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        {/* <Box p={3}>
          <Typography variant="h5" component="h2" gutterBottom>
            Ingredient Info
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <InputSwitch
                label="Disabled ingredient?"
                name="disabled"
                type="checkbox"
                control={control}
              />
              <br />

              <InputSwitch
                label="Visible?"
                type="checkbox"
                name="visible"
                control={control}
              />
            </Grid>
            <Grid item md={6}>
              <InputNumber
                name="price"
                label="Price"
                register={register}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box> */}

        <InputButton disabled={disabled}>Save!</InputButton>
      </form>
    </Paper>
  );
};

export default EditCoffeeForm;
