import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { selectCurrentInstanceBar } from "../instanceBar/instanceBarSlice";
import PageTitle from "../../components/layout/PageTitle";
import {
  getAllActive,
  selectQueued,
  updateDrinkPriority,
  deleteDrink,
  unlockConveyorBar,
} from "./drinkQueueSlice";
import Dialog from "../../components/dialog/Dialog";
import { Bar } from "../../services/types/bar";
import { showNotification } from "../notification/notificationSlice";
import { orderRecipeForBar } from "../recipeScreen/recipeSlice";
import { Drink } from "../../services/types/drink";
import SquaredIconButton from "../../components/layout/SquaredIconButton";
import DeleteDialog from "./DeleteDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(2),
    },
    number: {
      color: theme.palette.primary.main,
    },
    hasPriority: {
      backgroundColor: "#00E335",
      color: "#eee",
    },
  })
);

const DrinkQueueScreen: FunctionComponent = () => {
  const bar = useSelector(selectCurrentInstanceBar);
  const dispatch = useDispatch();
  const queue = useSelector(selectQueued);
  const classes = useStyles();

  const [deleteDialog, setDeleteDialog] = useState<{
    drink: Drink | null;
    open: boolean;
  }>({ drink: null, open: false });

  useEffect(() => {
    const refreshQueue = (myBar: Bar | null) => {
      if (myBar) {
        dispatch(getAllActive(myBar));
      }
    };
    refreshQueue(bar);
  }, [bar, dispatch]);

  const togglePriority = (el: any) => {
    dispatch(
      updateDrinkPriority(
        {
          id: el.id,
          priority: !el.priority,
        },
        bar,
        () => {
          dispatch(showNotification("Priority Updated", "success"));
        },
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Priority Update error!", "error"));
        }
      )
    );
  };

  const handleDeleteDrink = (el: Drink) => {
    setDeleteDialog({
      open: true,
      drink: el,
    });
  };

  const doDeleteDrink = (el: Drink) => {
    dispatch(
      deleteDrink(
        el,
        bar!,
        () =>
          dispatch(showNotification("Drink Deleted Successfully", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Drink Delete error!", "error"));
        }
      )
    );
  };
  const handleRemake = (el: Drink) => {
    const payload = {
      ...el.recipe,
      name: el.name,
    };
    dispatch(
      orderRecipeForBar(
        payload,
        bar?.id,
        el?.appOrderId,
        () =>
          dispatch(showNotification("Drink Ordered Successfully", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Drink Order error!", "error"));
        }
      )
    );
  };
  const handleUnlockConveyor = (el: Drink) => {
    dispatch(
      unlockConveyorBar(
        el.deliveryPosition,
        bar!,
        () =>
          dispatch(showNotification("Conveyor Unlocked Successfully", "success")),
        (e: any) => {
          console.error(e);
          dispatch(showNotification("Conveyor Unlocked error!", "error"));
        }
      )
    );
  };

  return (
    <Box>
      <PageTitle
        pageTitle={bar?.name || "Drink Queue"}
        breadcrumb={[bar?.name || "Bar", "Drink Queue"]}
      />
      <Paper>
        <Typography component="h2" variant="h4" className={classes.padding}>
          Ready Drinks:{" "}
          <span className={classes.number}>
            {queue.filter((el) => el.status === "ready").length}
          </span>
        </Typography>
        <Table>
          {queue.filter((el) => el.status === "ready").length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Recipe
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Drink Details
                  </Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography component="h2" variant="h5">
                    Delivery Position
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Priority
                  </Typography>
                </TableCell>
                {bar?.version === 'ms1' && (<TableCell>
                  <Typography component="h2" variant="h5">
                    Robot
                  </Typography>
                </TableCell>)}
                <TableCell align="right">
                  <Typography component="h2" variant="h5">
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {queue
              .filter((el) => el.status === "ready")
              .map((el, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      {el.recipe.name || "NO RECIPE NAME FOUND"}
                    </TableCell>
                    <TableCell>{el.name || "-"}</TableCell>
                    <TableCell width="20%" align="center">
                      {(
                        <div
                          style={{
                            width: 50,
                            height: 25,
                            background: el.color,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 3,
                          }}
                        >
                          <p style={{ textAlign: "center" }}>
                            {el.deliveryPosition}
                          </p>
                        </div>
                      ) || "-"}
                    </TableCell>
                    <TableCell>{el.priority ? "YES" : "NO"}</TableCell>
                    {bar?.version === 'ms1' && (<TableCell>
                      {(el.robotSelection || {}).displayName || '-'}
                    </TableCell>)}
                    <TableCell align="right">
                      {bar?.version === 'ms1' && (
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => handleUnlockConveyor(el)}
                          style={{ marginRight: "15px" }}
                          endIcon={<LockOpenIcon />}
                        >
                          Unlock Conveyor
                        </Button>
                      )}
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleRemake(el)}
                        endIcon={<RefreshIcon />}
                      >
                        Remake
                      </Button>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <Typography component="h2" variant="h4" className={classes.padding}>
          Delivering Drinks:{" "}
          <span className={classes.number}>
            {queue.filter((el) => el.status === "delivering").length}
          </span>
        </Typography>
        <Table>
          {queue.filter((el) => el.status === "delivering").length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Recipe
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Drink Details
                  </Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography component="h2" variant="h5">
                    Delivery Position
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Priority
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography component="h2" variant="h5">
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {queue
              .filter((el) => el.status === "delivering")
              .map((el, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      {el.recipe.name || "NO RECIPE NAME FOUND"}
                    </TableCell>
                    <TableCell>{el.name || "-"}</TableCell>
                    <TableCell width="20%" align="center">
                      {(
                        <div
                          style={{
                            width: 50,
                            height: 25,
                            background: el.color,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ textAlign: "center" }}>
                            {el.deliveryPosition}
                          </p>
                        </div>
                      ) || "-"}
                    </TableCell>
                    <TableCell>{el.priority ? "YES" : "NO"}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleRemake(el)}
                        endIcon={<RefreshIcon />}
                      >
                        Remake
                      </Button>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <Typography component="h2" variant="h4" className={classes.padding}>
          Producing Drink:{" "}
          <span className={classes.number}>
            {queue.filter((el) => el.status === "producing").length}
          </span>
        </Typography>
        <Table>
          {queue.filter((el) => el.status === "producing").length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Recipe
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Drink Details
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Priority
                  </Typography>
                </TableCell>
                {bar?.version === 'ms1' && (<TableCell>
                  <Typography component="h2" variant="h5">
                    Robot
                  </Typography>
                </TableCell>)}
                <TableCell align="right">
                  <Typography component="h2" variant="h5">
                    Actions
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {queue
              .filter((el) => el.status === "producing")
              .map((el, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      {el.recipe.name || "NO RECIPE NAME FOUND"}
                    </TableCell>
                    <TableCell>{el.name || "-"}</TableCell>
                    <TableCell>{el.priority ? "YES" : "NO"}</TableCell>
                    {bar?.version === 'ms1' && (<TableCell>
                      {(el.robotSelection || {}).displayName || '-'}
                    </TableCell>)}
                    <TableCell align="right">
                      <SquaredIconButton
                        buttonColor="error"
                        onClick={() => handleDeleteDrink(el)}
                        style={{ marginRight: "15px" }}
                        size={"small"}
                      >
                        <DeleteIcon />
                      </SquaredIconButton>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => handleRemake(el)}
                        endIcon={<RefreshIcon />}
                      >
                        Remake
                      </Button>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <Typography component="h2" variant="h4" className={classes.padding}>
          Open Drinks:{" "}
          <span className={classes.number}>
            {queue.filter((el) => el.status === "open").length}
          </span>
        </Typography>
        <Table>
          {queue.filter((el) => el.status === "open").length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Recipe
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Drink Details
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component="h2" variant="h5">
                    Priority
                  </Typography>
                </TableCell>
                {bar?.version === 'ms1' && (<TableCell>
                  <Typography component="h2" variant="h5">
                    Robot
                  </Typography>
                </TableCell>)}
                <TableCell align="right">
                  <Typography component="h2" variant="h5">
                    Actions
                  </Typography>
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {queue
              .filter((el) => el.status === "open")
              .sort((a, b) => Number(b.priority) - Number(a.priority))
              .map((el, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      {el.recipe.name || "NO RECIPE NAME FOUND"}
                    </TableCell>
                    <TableCell>{el.name || "-"}</TableCell>
                    <TableCell>{el.priority ? "YES" : "NO"}</TableCell>
                    {bar?.version === 'ms1' && (<TableCell>
                      {(el.robotSelection || {}).displayName || '-'}
                    </TableCell>)}
                    <TableCell align="right">
                      <SquaredIconButton
                        buttonColor="error"
                        onClick={() => handleDeleteDrink(el)}
                        style={{ marginRight: "15px" }}
                        size={"small"}
                      >
                        <DeleteIcon />
                      </SquaredIconButton>
                      {bar?.version === 'ms1' && (
                        <SquaredIconButton
                          buttonColor="secondary"
                          onClick={() => handleRemake(el)}
                          style={{ marginRight: "15px" }}
                          size={"small"}
                        >
                          <RefreshIcon />
                        </SquaredIconButton>
                      )}
                      <Button
                        color="primary"
                        //color={el.priority ? "inherit" : "primary"}
                        //className={el.priority ? classes.hasPriority : undefined}
                        variant={el.priority ? "contained" : "outlined"}
                        endIcon={
                          el.priority ? <StarIcon /> : <StarBorderIcon />
                        }
                        onClick={() => togglePriority(el)}
                      >
                        Priority
                      </Button>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <Dialog
          title="Delete Recipe"
          open={deleteDialog.open}
          onAbort={() =>
            setDeleteDialog({
              ...deleteDialog,
              open: false,
            })
          }
        >
          <DeleteDialog
            name={`Drink ${deleteDialog.drink?.recipe.name || ""}`}
            onSubmit={() =>
              setDeleteDialog({
                drink: null,
                open: false,
              })
            }
            onConfirm={() => {
              doDeleteDrink(deleteDialog.drink as Drink);
              setDeleteDialog({
                drink: null,
                open: false,
              });
            }}
          />
        </Dialog>
      </Paper>
    </Box>
  );
};

export default DrinkQueueScreen;
