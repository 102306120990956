import {Box, Paper} from "@material-ui/core";
import React, {useCallback} from "react";
import {FunctionComponent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Column} from "react-table";
import EnhancedTable, {
    FetchDataArgs,
} from "../../components/table/EnhancedTable";
import SelectFilter, {
    withOptions,
} from "../../components/table/filters/SelectFilter";
import {selectCurrentInstanceBar} from "../instanceBar/instanceBarSlice";
import {useLoading} from "../loading/hooks";
import {
    getAllByStatus,
    getAllByStatusRaw,
    selectProduction,
    selectProductionCount,
} from "./productionSlice";
import {TableActions} from "./TableActions";
import TableHeaderExport from "../../components/table/TableHeaderExport";
import MainSpacer from "../../components/layout/MainSpacer";
import DateTimeCell from "../../components/table/cells/DateTimeCell";
import { AxiosResponse } from "axios";
import { createExportRequest } from "../exportRequestScreen/exportRequestSlice";
import { showNotification } from "../notification/notificationSlice";
import { useHistory } from "react-router";


const columns: Column<any>[] = [
    {Header: "Recipe", accessor: "recipe.name"},
    {Header: "Order Details", accessor: "name"},
    {Header: "Position", accessor: "deliveryPosition", disableFilters: true},
    {Header: "Robot", accessor: "robotSelection.displayName", disableFilters: true},
    {Header: "Created At", accessor: "createdAt", Cell: DateTimeCell, disableFilters: true},
    {
        Header: "Actions",
        Cell: TableActions,
        disableFilters: true,
        disableSortBy: true,
    },
];

interface ProductionTableProps {
    status: string;
}

const ProductionTable: FunctionComponent<ProductionTableProps> = ({
                                                                      status,
                                                                  }) => {
    const dispatch = useDispatch();
    const bar = useSelector(selectCurrentInstanceBar);
    const isLoading = useLoading();
    const production = useSelector(selectProduction);
    const totalCount = useSelector(selectProductionCount);
    const history = useHistory();

    const handleFetchData = useCallback(
        ({pageIndex, pageSize, sortBy, filters}: FetchDataArgs) => {
            if (bar) {
                dispatch(
                    getAllByStatus(
                        {
                            page: pageIndex,
                            perPage: pageSize,
                            sort: sortBy,
                            search: filters,
                        },
                        status,
                        bar
                    )
                );
            }
        },
        [bar, dispatch, status]
    );

    const handleFetchDataForExport = useCallback(
        (filters, exportType) => {
            dispatch(
                createExportRequest(
                    bar?.id,
                    'Drink',
                    JSON.stringify([...filters, { id: "status", value: status}]),
                    exportType,
                    (payload: any) => {
                      dispatch(showNotification("Export created! It will be available shortly", "success"));
                      history.push("/export-requests");
                    },
                    (payload: any) => {
                      dispatch(showNotification("Export creation error!", "error"));
                    }
                )
            );
        },
        [dispatch, bar?.id, status, history]
    );

    const headers = [
        {label: "Id", key: "id"},
        {label: "Type", key: "type"},
        {label: "App Order Id", key: "appOrderId"},
        {label: "Recipe Name", key: "recipe.name"},
        {label: "Price", key: "recipe.price"},
        {label: "Status", key: "status"},
        {label: "Delivery Position", key: "deliveryPosition"},
        {label: "Created At", key: "createdAt"},
    ];

    return (
            <Box p={3}>
                <TableHeaderExport headers={headers} onExport={handleFetchDataForExport} />
                <MainSpacer/>
                <EnhancedTable
                    columns={columns}
                    data={production}
                    isLoading={isLoading}
                    totalCount={totalCount}
                    onRowClick={() => {
                    }}
                    fetchData={handleFetchData}
                    // fetchDataExport={handleFetchDataForExport}
                />
            </Box>
    );
};

export default ProductionTable;
