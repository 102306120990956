import { Box } from "@material-ui/core";
import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "react-table";
import ColorCell from "../../../components/table/cells/ColorCell";
import EnhancedTable, {
  FetchDataArgs,
} from "../../../components/table/EnhancedTable";
import NumberFilter from "../../../components/table/filters/NumberFilter";
import SelectFilter, {
  withOptions,
} from "../../../components/table/filters/SelectFilter";
import { Bottle } from "../../../services/types/bottle";
import { selectCurrentInstanceBar } from "../../instanceBar/instanceBarSlice";
import { useLoading } from "../../loading/hooks";
import { ingredientCategories } from "../../constants";
import {
  getAllBottles,
  selectBottles,
  selectBottlesCount,
} from "./bottleSlice";

const columns: Column<any>[] = [
  { Header: "Name", accessor: "name" },
  {
    Header: "Category",
    accessor: "category",
    Filter: withOptions(ingredientCategories, SelectFilter),
  },
  { Header: "Sub-Category", accessor: "subCategory" },
  { Header: "Alcohol", accessor: "alcoholPerc", Filter: NumberFilter },
  { Header: "Color", accessor: "color", Cell: ColorCell, disableFilters: true },
  // { Header: 'Actions', Cell: BarRowActions, disableFilters: true, disableSortBy: true },
];

interface BottleTableProps {
  onBottleSelected: (bottle: Bottle) => any;
}

const BottleTable: FunctionComponent<BottleTableProps> = ({
  onBottleSelected,
}) => {
  const dispatch = useDispatch();
  const bottles = useSelector(selectBottles);
  const isLoading = useLoading();
  const bar = useSelector(selectCurrentInstanceBar);

  const totalCount = useSelector(selectBottlesCount);

  const handleFetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, filters }: FetchDataArgs) => {
      if (bar) {
        dispatch(
          getAllBottles(
            {
              page: pageIndex,
              perPage: pageSize,
              sort: sortBy,
              search: filters,
            },
            bar
          )
        );
      }
    },
    [dispatch, bar]
  );

  const handleRowClick = useCallback(
    (bottle: Bottle) => {
      onBottleSelected(bottle);
    },
    [onBottleSelected]
  );

  return (
    <Box p={3}>
      <EnhancedTable
        columns={columns}
        data={bottles}
        totalCount={totalCount}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        fetchData={handleFetchData}
      />
    </Box>
  );
};

export default BottleTable;
