import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Bar } from "../../../services/types/bar";
import { showNotification } from "../../notification/notificationSlice";
import { createGranular, findGlobalGranularByName } from "./granularSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { Granular } from "../../../services/types/granular";
import GlobalIngredientLookup from "../GlobalIngredientLookup";
import { useForm } from "react-hook-form";
import InputButton from "../../../components/form/InputButton";
import InputColor from "../../../components/form/InputColor";
import InputNumber from "../../../components/form/InputNumber";
import InputSelect from "../../../components/form/InputSelect";
import { granularCategories } from "../../constants";
interface CreateGranularFormProps {
  bar: Bar | null;
  onCreateSuccess: (granular: Granular) => any;
}

const CreateGranularForm: FunctionComponent<CreateGranularFormProps> = ({
  bar,
  onCreateSuccess,
}) => {
  const { register, handleSubmit, control, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      color: "",
      price: null,
      category: granularCategories[0],
    },
  });

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data: Granular) => {
    setDisabled(true);
    const granular = {
      name: data.name,
      category: data.category,
      color: data.color,
      price: data.price || 0,
    };
    console.log(granular);
    dispatch(
      createGranular(
        granular,
        bar,
        ({ payload }: any) => {
          dispatch(showNotification("Granular Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Granular
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/*           <InputGlobalLookup
            name="name"
            label="Name"
            control={control}
            inputRef={register}
            fullWidth
          /> */}

          <GlobalIngredientLookup
            label="Name"
            name="name"
            control={control}
            setError={setError}
            onChange={(data: any) => {
              if (data) {
                data.color && setValue("color", data.color);
                setValue("price", 0);
              }
            }}
            findGlobalIngredient={findGlobalGranularByName}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            name="category"
            label="Category"
            control={control}
            required
            fullWidth
          >
            {granularCategories.map((category, idx) => (
              <MenuItem key={idx} value={category}>
                {category}
              </MenuItem>
            ))}
          </InputSelect>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <InputColor
            name="color"
            label="Color"
            control={control}
            setValue={setValue}
            required
          />
        </Grid>
        <Grid item md={6}>
          <InputNumber
            name="price"
            label="Price"
            register={register}
            fullWidth
          />
        </Grid>
      </Grid>
      <MainSpacer />

      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create Granular!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateGranularForm;
