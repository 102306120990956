import React, { FunctionComponent } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound404Screen from "../../components/screens/NotFound404";
import ActivityScreen from "../activityScreen";
import AppOrderListScreen from "../appOrdersScreen/AppOrderListScreen";
import AppOrderShowScreen from "../appOrdersScreen/AppOrderShowScreen";
import BarListScreen from "../barsScreen/BarListScreen";
import BarShowScreen from "../barsScreen/BarShowScreen";
import DrinkQueueScreen from "../drinkQueueScreen/DrinkQueueScreen";
import ExportRequestListScreen from "../exportRequestScreen/ExportRequestListScreen";
import GlobalBeverageShowScreen from "../globalIngredientScreen/beverage/GlobalBeverageShowScreen";
import GlobalBottleShowScreen from "../globalIngredientScreen/bottle/GlobalBottleShowScreen";
import GlobalCoffeeShowScreen from "../globalIngredientScreen/coffee/GlobalCoffeeShowScreen";
import GlobalCoffeeTypeShowScreen from "../globalIngredientScreen/coffee-type/GlobalCoffeeTypeShowScreen";
import GlobalFlavourShowScreen from "../globalIngredientScreen/flavour/GlobalFlavourShowScreen";
import GlobalGarnishShowScreen from "../globalIngredientScreen/garnish/GlobalGarnishShowScreen";
import GlobalIngredientListScreen from "../globalIngredientScreen/GlobalIngredientListScreen";
import GlobalGranularShowScreen from "../globalIngredientScreen/granular/GlobalGranularShowScreen";
import GlobalLeafShowScreen from "../globalIngredientScreen/leaf/GlobalLeafShowScreen";
import BeverageShowScreen from "../ingredientsScreen/beverage/BeverageShowScreen";
import BottleShowScreen from "../ingredientsScreen/bottle/BottleShowScreen";
import CoffeeShowScreen from "../ingredientsScreen/coffee/CoffeeShowScreen";
import FlavourShowScreen from "../ingredientsScreen/flavour/FlavourShowScreen";
import GarnishShowScreen from "../ingredientsScreen/garnish/GarnishShowScreen";
import GranularShowScreen from "../ingredientsScreen/granular/GranularShowScreen";
import IngredientListScreen from "../ingredientsScreen/IngredientListScreen";
import LeafShowScreen from "../ingredientsScreen/leaf/LeafShowScreen";
import LoginScreen from "../loginScreen";
import MyBarScreen from "../myBarScreen";
import PositionScreen from "../positionScreen/PositionScreen";
import ProductionScreen from "../productionScreen/ProductionScreen";
import RecipeCategoryScreen from "../recipeCategoryScreen/RecipeCategoryScreen";
import RecipeScreen from "../recipeScreen/RecipeScreen";
import RobotListScreen from "../robotsScreen/RobotListScreen";
import RobotShowScreen from "../robotsScreen/RobotShowScreen";
import UserManagementListScreen from "../userManagementScreen/UserManagementListScreen";
import UserManagementShowScreen from "../userManagementScreen/UserManagementShowScreen";
import VoucherListScreen from "../vouchersScreen/VoucherListScreen";
import VoucherShowScreen from "../vouchersScreen/VoucherShowScreen";
import BarGuard from "./BarGuard";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import SolubleShowScreen from "../ingredientsScreen/soluble/SolubleShowScreen";
import MilkShowScreen from "../ingredientsScreen/milk/MilkShowScreen";

const Routes: FunctionComponent = () => {
  return (
    <Switch>
      <PublicRoute path="/login">
        <LoginScreen />
      </PublicRoute>

      <PrivateRoute path="/user-management" exact>
        <UserManagementListScreen />
      </PrivateRoute>

      <PrivateRoute path="/user-management/:id">
        <UserManagementShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/export-requests" exact>
        <ExportRequestListScreen />
      </PrivateRoute>

      <PrivateRoute path="/bars" exact>
        <BarListScreen />
      </PrivateRoute>

      <PrivateRoute path="/bars/:id">
        <BarShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/robots" exact>
        <RobotListScreen />
      </PrivateRoute>

      <PrivateRoute path="/robots/:id">
        <RobotShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/ingredients/bottles/:id">
        <BarGuard>
          <BottleShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/garnishes/:id">
        <BarGuard>
          <GarnishShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/granulars/:id">
        <BarGuard>
          <GranularShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/leaves/:id">
        <BarGuard>
          <LeafShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/flavours/:id">
        <BarGuard>
          <FlavourShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/beverages/:id">
        <BarGuard>
          <BeverageShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/coffee/:id">
        <BarGuard>
          <CoffeeShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/solubles/:id">
        <BarGuard>
          <SolubleShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/milk/:id">
        <BarGuard>
          <MilkShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/ingredients/:tabActive" exact>
        <BarGuard>
          <IngredientListScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/dispensers/:tabActive">
        <BarGuard>
          <PositionScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/drinks" exact>
        <BarGuard>
          <RecipeCategoryScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/drinks/:categoryId" exact>
        <BarGuard>
          <RecipeScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/bottles/:id">
        <GlobalBottleShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/beverages/:id">
        <GlobalBeverageShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/flavours/:id">
        <GlobalFlavourShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/garnishes/:id">
        <GlobalGarnishShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/granulars/:id">
        <GlobalGranularShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/leaves/:id">
        <GlobalLeafShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/coffee/:id">
        <GlobalCoffeeShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/coffee-type/:id">
        <GlobalCoffeeTypeShowScreen />
      </PrivateRoute>

      <PrivateRoute path="/global-ingredients/:tabActive" exact>
        <GlobalIngredientListScreen />
      </PrivateRoute>

      <PrivateRoute path="/mybar" exact>
        <BarGuard>
          <MyBarScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/vouchers" exact>
        <BarGuard>
          <VoucherListScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/vouchers/:id">
        <BarGuard>
          <VoucherShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/app-orders" exact>
        <BarGuard>
          <AppOrderListScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/app-orders/:id">
        <BarGuard>
          <AppOrderShowScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/drink-queue" exact>
        <BarGuard>
          <DrinkQueueScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/production/:tabActive" exact>
        <BarGuard>
          <ProductionScreen />
        </BarGuard>
      </PrivateRoute>

      <PrivateRoute path="/" exact>
        <BarGuard>
          <ActivityScreen />
        </BarGuard>
      </PrivateRoute>

      <Route path="*">
        <NotFound404Screen />
      </Route>
    </Switch>
  );
};

export default Routes;
