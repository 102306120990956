import { createReducer, createAction, PrepareAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '../../../store';
import { PaginationResponse } from '../../../services/types/pagination';
import { DispenserBottle } from '../../../services/types/dispenserBottle';
import { Bar } from '../../../services/types/bar';
import { MapLike } from 'typescript';


// STATE INTERFACE
interface DispenserBottleState {
  data: DispenserBottle[];
  map: MapLike<DispenserBottle>;
  count: number;
  // page: number;
  // perPage: number;
}

// INITIAL STATE
const initialState: DispenserBottleState = {
  data: [],
  map: {},
  count: 0,
  // page: 1,
  // perPage: 2,
};



// ACTIONS
export const getAllDispenserBottles = createAction<PrepareAction<{barId: string}>>('dispenserBottle/getAllDispenserBottles', (bar: Bar) => ({
  payload: {
    barId: bar.id,
  },
  meta: {
    fetch: "dispensers",
    resource: "getAllDispenserBottles",
  }
}));
export const getAllDispenserBottlesSuccess = createAction<PaginationResponse<DispenserBottle>>('dispenserBottle/getAllDispenserBottles_SUCCESS');
export const updateDispenserBottle = createAction<PrepareAction<DispenserBottle>>('dispenserBottle/updateDispenserBottle', (dispenser: DispenserBottle, onSuccess, onFailure) => ({
  payload: {
    ...dispenser,
  },
  meta: {
    fetch: "dispensers",
    resource: "updateDispenserBottle",
    onSuccess,
    onFailure,
  }
}));
export const updateDispenserBottleSuccess = createAction<DispenserBottle>('dispenserBottle/updateDispenserBottle_SUCCESS');

export const findBottleByName = createAction<PrepareAction<{name: string, barId: string}>>('dispenserBottle/findBottleByName', (name: string, barId: string, onSuccess, onFailure) => ({
  payload: {
    name,
    barId: barId,
  },
  meta: {
    fetch: "ingredients",
    resource: "findBottleByName",
    onSuccess,
    onFailure,
  }
}));



// REDUCERS
const dispenserBottlesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllDispenserBottlesSuccess, (state: DispenserBottleState, { payload: { data, count, page, perPage } }) => { 
      state.data = data;
      state.map = _.keyBy(data, (el: DispenserBottle) => el.id);
      state.count = count;
      // state.page = page;
      // state.perPage = perPage;
    })
    .addCase(updateDispenserBottleSuccess, (state: DispenserBottleState, { payload }) => { 
      state.map[payload.id] = payload;
      state.data = state.data.map((el) => {
        if (el.id === payload.id) {
          return payload;
        }
        return el;
      });
    })
  ;
});



// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDispenserBottles = (state: RootState) => state.dispensers.bottle.data;

export default dispenserBottlesReducer;
