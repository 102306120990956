import { Chip } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import { CellProps } from 'react-table'
import { UserRole } from '../../services/types/user'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chip: {
    height: "auto",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1) / 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  chipWrap: {
    "& span": {
      display: "block",
      // fontSize: theme.typography.fontSize * 0.8,
    },
  },
  chipTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(0.2),
    fontSize: theme.typography.subtitle1.fontSize,
  }
}));

interface RoleBadgeCellProps  {
  cell: CellProps<any, UserRole[]>;
}

const RoleBadgeCell: FunctionComponent<RoleBadgeCellProps> = ({ cell }) => {
  const classes = useStyles();
  return (
    <div>
      {cell.value.map((role) => <Chip className={classes.chip} component='div' label={(
        <div className={classes.chipWrap}>
          <span className={classes.chipTitle}>{role.role}</span>
          <span>@{role.barName}</span>
        </div>
      )} />)}
    </div>
  )
}

export default RoleBadgeCell;
