import React, { FunctionComponent } from "react";

import { Grid, DialogActions, LinearProgress } from "@material-ui/core";

import MainSpacer from "../../components/layout/MainSpacer";
import InputText from "../../components/form/InputText";
import InputColor from "../../components/form/InputColor";
import InputNumber from "../../components/form/InputNumber";
import InputButton from "../../components/form/InputButton";
import InputSwitch from "../../components/form/InputSwitch";

interface FormValues {
  inputRef: any;
  control: any;
  disabled: boolean;
  hasCoffee?: boolean;
  setValue: (...arg: any) => any;
}
export const InnerForm: FunctionComponent<FormValues> = ({
  inputRef,
  control,
  disabled,
  hasCoffee,
  setValue,
}) => (
  <>
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <InputText
          name="name"
          label="Name"
          register={inputRef}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <InputColor
          name="color"
          label="Color"
          required
          control={control}
          setValue={setValue}
        />

      </Grid>
    </Grid>
    <Grid container spacing={4}>
      <Grid item md={6}>
        <div style={{ paddingTop: "30px" }}>
          <InputSwitch
            name="enabled"
            label="Enabled"
            type="checkbox"
            control={control}
          />
          {hasCoffee ? (
            <InputSwitch
              name="isCoffee"
              label="Coffee Category"
              type="checkbox"
              control={control}
            />
          ) : null}
        </div>
      </Grid>
      <Grid item md={6}>
        <InputNumber
          name="price"
          label="Price"
          register={inputRef}
          fullWidth
          required
        />
      </Grid>
    </Grid>
    <MainSpacer />

    <DialogActions style={{ padding: 0 }}>
      <InputButton disabled={disabled}>Save!</InputButton>
    </DialogActions>
    {disabled && <LinearProgress />}
  </>
);
