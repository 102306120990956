import axios, { AxiosResponse } from "axios";
import { Bottle } from "./types/bottle";
import { Beverage } from "./types/beverage";
import { Garnish } from "./types/garnish";
import { Granular } from "./types/granular";
import { Leaf } from "./types/leaf";
import { PaginationResponse } from "./types/pagination";
import {
  getAccessTokenRequestForAxiosOptions,
  transformID,
  transformIDElement,
} from "./utils";
import { Flavour } from "./types/flavour";
import { Coffee } from "./types/coffee";
import { CoffeeType } from "./types/coffeeType";

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllGlobalBottles = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Bottle>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/bottles/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const getGlobalBottleById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Bottle> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/bottles/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const updateGlobalBottle = async (
  payload: Bottle,
  accessToken: string
): Promise<Bottle> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/bottles/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Bottle
 * @param accessToken
 */
const createGlobalBottle = async (
  payload: Bottle,
  accessToken: string
): Promise<Bottle> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Bottle> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/bottles/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllGlobalBeverages = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Beverage>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/beverages/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const getGlobalBeverageById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/beverages/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const updateGlobalBeverage = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/beverages/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Beverage
 * @param accessToken
 */
const createGlobalBeverage = async (
  payload: Beverage,
  accessToken: string
): Promise<Beverage> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Beverage> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/beverages/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllGlobalFlavours = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Flavour>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/flavours/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Flavour
 * @param accessToken
 */
const getGlobalFlavourById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Flavour> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/flavours/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Flavour
 * @param accessToken
 */
const updateGlobalFlavour = async (
  payload: Flavour,
  accessToken: string
): Promise<Flavour> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/flavours/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Flavour
 * @param accessToken
 */
const createGlobalFlavour = async (
  payload: Flavour,
  accessToken: string
): Promise<Flavour> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Flavour> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/flavours/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllGlobalGarnishes = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Garnish>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/garnishes/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const getGlobalGarnishById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Garnish> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/garnishes/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const updateGlobalGarnish = async (
  payload: Garnish,
  accessToken: string
): Promise<Garnish> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/garnishes/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Garnish
 * @param accessToken
 */
const createGlobalGarnish = async (
  payload: Garnish,
  accessToken: string
): Promise<Garnish> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Garnish> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/garnishes/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllGlobalGranulars = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Granular>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/granulars/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const getGlobalGranularById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Granular> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/granulars/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const updateGlobalGranular = async (
  payload: Granular,
  accessToken: string
): Promise<Granular> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/granulars/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Granular
 * @param accessToken
 */
const createGlobalGranular = async (
  payload: Granular,
  accessToken: string
): Promise<Granular> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Granular> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/granulars/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
const getAllGlobalLeaves = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Leaf>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/leaves/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  const body = response.data;
  body.data = transformID(body.data);
  return body;
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const getGlobalLeafById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Leaf> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/leaves/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const updateGlobalLeaf = async (
  payload: Leaf,
  accessToken: string
): Promise<Leaf> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/leaves/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};

/**
 *
 * @param payload Leaf
 * @param accessToken
 */
const createGlobalLeaf = async (
  payload: Leaf,
  accessToken: string
): Promise<Leaf> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Leaf> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/leaves/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return transformIDElement(response.data);
};


/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
 const getAllGlobalCoffee = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<Coffee>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const getGlobalCoffeeById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<Coffee> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const updateGlobalCoffee = async (
  payload: Coffee,
  accessToken: string
): Promise<Coffee> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee/${payload.id}`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload Coffee
 * @param accessToken
 */
const createGlobalCoffee = async (
  payload: Coffee,
  accessToken: string
): Promise<Coffee> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<Coffee> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee/create`,
    payload,
    optionDecorator({}, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 * Is it right to change the _id in id here?
 * Don't know, but works neat now.
 *
 * @param payload
 * @param accessToken
 */
 const getAllGlobalCoffeeType = async (
  payload: any | undefined,
  accessToken: string
): Promise<PaginationResponse<CoffeeType>> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<PaginationResponse<any>> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee-type/`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload CoffeeType
 * @param accessToken
 */
const getGlobalCoffeeTypeById = async (
  payload: { id: string; barId: string },
  accessToken: string
): Promise<CoffeeType> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<CoffeeType> = await axios.get(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee-type/${payload.id}`,
    optionDecorator({ params: payload }, { "x-bar": payload?.barId })
  );
  return response.data;
};

/**
 *
 * @param payload CoffeeType
 * @param accessToken
 */
const updateGlobalCoffeeType = async (
  payload: CoffeeType,
  accessToken: string
): Promise<CoffeeType> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<CoffeeType> = await axios.patch(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee-type/${payload.id}`,
    payload,
    optionDecorator()
  );
  return response.data;
};

/**
 *
 * @param payload CoffeeType
 * @param accessToken
 */
const createGlobalCoffeeType = async (
  payload: CoffeeType,
  accessToken: string
): Promise<CoffeeType> => {
  const optionDecorator = getAccessTokenRequestForAxiosOptions(accessToken);
  const response: AxiosResponse<CoffeeType> = await axios.post(
    `${process.env.REACT_APP_AUTH_BASE_URL}/uging/coffee-type/create`,
    payload,
    optionDecorator()
  );
  return response.data;
};

const api = {
  getAllGlobalBottles,
  getGlobalBottleById,
  updateGlobalBottle,
  createGlobalBottle,
  getAllGlobalBeverages,
  getGlobalBeverageById,
  updateGlobalBeverage,
  createGlobalBeverage,
  getAllGlobalGarnishes,
  getGlobalGarnishById,
  updateGlobalGarnish,
  createGlobalGarnish,
  getAllGlobalGranulars,
  getGlobalGranularById,
  updateGlobalGranular,
  createGlobalGranular,
  getAllGlobalLeaves,
  getGlobalLeafById,
  updateGlobalLeaf,
  createGlobalLeaf,
  createGlobalFlavour,
  updateGlobalFlavour,
  getAllGlobalFlavours,
  getGlobalFlavourById,
  createGlobalCoffee,
  updateGlobalCoffee,
  getAllGlobalCoffee,
  getGlobalCoffeeById,
  createGlobalCoffeeType,
  updateGlobalCoffeeType,
  getAllGlobalCoffeeType,
  getGlobalCoffeeTypeById,
};

export default api;
