import React, { FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import UserAccount from '../../components/layout/menu/UserAccount'
import { selectUser, selectRole, signOut } from './authSlice';

const ConnectedUserAccount: FunctionComponent = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const role = useSelector(selectRole);
  return (
    <UserAccount 
    user={user} 
    role={role}
    handleLogout={() => dispatch(signOut())}/>
  )
}

export default ConnectedUserAccount
