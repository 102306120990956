import { Box, Paper, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { capitalize } from "lodash";
import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Dialog from "../../components/dialog/Dialog";
import FloatingActionButton from "../../components/layout/FloatingActionButton";
import PageTitle from "../../components/layout/PageTitle";
import { Beverage } from "../../services/types/beverage";
import { Bottle } from "../../services/types/bottle";
import { Flavour } from "../../services/types/flavour";
import { Garnish } from "../../services/types/garnish";
import { Granular } from "../../services/types/granular";
import { Leaf } from "../../services/types/leaf";
import { Coffee } from "../../services/types/coffee";
import CreateGlobalBeverageForm from "./beverage/CreateGlobalBeverageForm";
import GlobalBeverageTable from "./beverage/GlobalBeverageTable";
import CreateGlobalBottleForm from "./bottle/CreateGlobalBottleForm";
import GlobalBottleTable from "./bottle/GlobalBottleTable";
import CreateGlobalFlavourForm from "./flavour/CreateGlobalFlavourForm";
import GlobalFlavourTable from "./flavour/GlobalFlavourTable";
import CreateGlobalGarnishForm from "./garnish/CreateGlobalGarnishForm";
import GlobalGarnishTable from "./garnish/GlobalGarnishTable";
import CreateGlobalGranularForm from "./granular/CreateGlobalGranularForm";
import GlobalGranularTable from "./granular/GlobalGranularTable";
import CreateGlobalLeafForm from "./leaf/CreateGlobalLeafForm";
import GlobalLeafTable from "./leaf/GlobalLeafTable";
import GlobalCoffeeTable from "./coffee/GlobalCoffeeTable";
import CreateGlobalCoffeeForm from "./coffee/CreateGlobalCoffeeForm";
import { CoffeeType } from "../../services/types/coffeeType";
import CreateGlobalCoffeeTypeForm from "./coffee-type/CreateGlobalCoffeeTypeForm";
import GlobalCoffeeTypeTable from "./coffee-type/GlobalCoffeeTypeTable";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

const GlobalIngredientListScreen: FunctionComponent = (props) => {
  //const [tabActive, setTabActive] = useState<string>(ingredientType[0]);
  const { tabActive } = useParams<{ tabActive: string }>();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const setTabActive = (tab: string) => {
    history.push(`/global-ingredients/${tab}`);
  };

  return (
    <Box className={classes.root}>
      <PageTitle
        pageTitle={"Super Admin"}
        breadcrumb={[
          {
            label: "Global Ingredients",
            location: "/global-ingredients/bottles",
          },
          capitalize(tabActive),
        ]}
      />

      <Paper>
        <Tabs
          value={tabActive}
          onChange={(event: ChangeEvent<{}>, value) => setTabActive(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Bottle" value="bottles" />
          <Tab label="Beverage" value="beverages" />
          <Tab label="Flavour" value="flavours" />
          <Tab label="Garnish" value="garnishes" />
          <Tab label="Granular" value="granulars" />
          <Tab label="Leaf" value="leaves" />
          <Tab label="Coffee" value="coffee" />
          <Tab label="Coffee Type" value="coffeeType" />
        </Tabs>
        {tabActive === "bottles" && (
          <>
            <Box p={3}>
              {/* <Typography variant="h5" component="h2" gutterBottom>
                This are Bottles
              </Typography> */}
              <GlobalBottleTable
                onBottleSelected={(bottle: Bottle) => {
                  history.push(`/global-ingredients/bottles/${bottle.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Bottle"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalBottleForm
                onCreateSuccess={(bottle: Bottle) => {
                  history.push(`/global-ingredients/bottles/${bottle.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalBottleForm>
            </Dialog>
          </>
        )}
        {tabActive === "beverages" && (
          <>
            <Box p={3}>
              <GlobalBeverageTable
                onBeverageSelected={(beverage: Beverage) => {
                  history.push(`/global-ingredients/beverages/${beverage.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Beverage"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalBeverageForm
                onCreateSuccess={(beverage: Beverage) => {
                  history.push(`/global-ingredients/beverages/${beverage.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalBeverageForm>
            </Dialog>
          </>
        )}
        {tabActive === "flavours" && (
          <>
            <Box p={3}>
              <GlobalFlavourTable
                onFlavourSelected={(flavour: Flavour) => {
                  history.push(`/global-ingredients/flavours/${flavour.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Flavour"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalFlavourForm
                onCreateSuccess={(flavour: Flavour) => {
                  history.push(`/global-ingredients/flavours/${flavour.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalFlavourForm>
            </Dialog>
          </>
        )}
        {tabActive === "garnishes" && (
          <>
            <Box p={3}>
              <GlobalGarnishTable
                onGarnishSelected={(garnish: Garnish) => {
                  history.push(`/global-ingredients/garnishes/${garnish.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Garnish"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalGarnishForm
                onCreateSuccess={(garnish: Garnish) => {
                  history.push(`/global-ingredients/garnishes/${garnish.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalGarnishForm>
            </Dialog>
          </>
        )}
        {tabActive === "granulars" && (
          <>
            <Box p={3}>
              <GlobalGranularTable
                onGranularSelected={(granular: Granular) => {
                  history.push(`/global-ingredients/granulars/${granular.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Granular"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalGranularForm
                onCreateSuccess={(granular: Granular) => {
                  history.push(`/global-ingredients/granulars/${granular.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalGranularForm>
            </Dialog>
          </>
        )}
        {tabActive === "leaves" && (
          <>
            <Box p={3}>
              <GlobalLeafTable
                onLeafSelected={(leaf: Leaf) => {
                  history.push(`/global-ingredients/leaves/${leaf.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Leaf"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalLeafForm
                onCreateSuccess={(leaf: Leaf) => {
                  history.push(`/global-ingredients/leaves/${leaf.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalLeafForm>
            </Dialog>
          </>
        )}
        {tabActive === "coffee" && (
          <>
            <Box p={3}>
              <GlobalCoffeeTable
                onCoffeeSelected={(coffee: Coffee) => {
                  history.push(`/global-ingredients/coffee/${coffee.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New Coffee"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalCoffeeForm
                onCreateSuccess={(coffee: Coffee) => {
                  history.push(`/global-ingredients/coffee/${coffee.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalCoffeeForm>
            </Dialog>
          </>
        )}
        {tabActive === "coffeeType" && (
          <>
            <Box p={3}>
              <GlobalCoffeeTypeTable
                onCoffeeTypeSelected={(coffeeType: CoffeeType) => {
                  history.push(`/global-ingredients/coffee-type/${coffeeType.id}`);
                }}
              />
            </Box>
            <FloatingActionButton onClick={() => setOpen(true)} />
            <Dialog
              title="Create New CoffeeType"
              open={open}
              onAbort={() => setOpen(false)}
            >
              <CreateGlobalCoffeeTypeForm
                onCreateSuccess={(coffeeType: CoffeeType) => {
                  history.push(`/global-ingredients/coffee-type/${coffeeType.id}`);
                  // setOpen(false);
                }}
              ></CreateGlobalCoffeeTypeForm>
            </Dialog>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default GlobalIngredientListScreen;
