import { createAction, createReducer, PrepareAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { MapLike } from "typescript";
import _ from "lodash";
import {
  PaginationPayloadByBar,
  PaginationResponse,
} from "../../services/types/pagination";

import { ExportRequest } from "../../services/types/exportRequest";
import { Bar } from "../../services/types/bar";

// STATE INTERFACE
interface ExportRequestState {
  data: ExportRequest[];
  map: MapLike<ExportRequest>;
  count: number;
  page: number;
  perPage: number;
}

// INITIAL STATE
const initialState: ExportRequestState = {
  data: [],
  map: {},
  count: 0,
  page: 0,
  perPage: 10,
};

// ACTIONS
export const getAllExportRequests = createAction<PrepareAction<PaginationPayloadByBar>>(
  "exportRequest/getAllExportRequests",
  ({ page, perPage, sort, search }, bar: Bar, onSuccess, onFailure) => ({
    payload: {
      barId: bar.id,
      page,
      perPage,
      sort: (sort || []).reduce(
        (acc: MapLike<number>, sorter: { id: string; desc: boolean }) => {
          acc[sorter.id] = sorter.desc ? -1 : 1;
          return acc;
        },
        {}
      ),
      search: (search || []).reduce(
        (acc: MapLike<string>, searcher: { id: string; value: string }) => {
          acc[searcher.id] = searcher.value;
          return acc;
        },
        {}
      ),
    },
    meta: {
      fetch: "exportRequest",
      resource: "getAllExportRequests",
      onSuccess,
      onFailure,
    },
  })
);
export const createExportRequest = createAction<PrepareAction<Partial<ExportRequest>>>(
  "exportRequest/createExportRequest",
  (barId, collectionName, search, type, onSuccess, onFailure) => ({
    payload: {
      barId,
      collectionName,
      search,
      type,
    },
    meta: {
      fetch: "exportRequest",
      resource: "createExportRequest",
      onSuccess,
      onFailure,
    },
  })
);
export const getExportRequestDownloadById = createAction<PrepareAction<Partial<ExportRequest>>>(
  "exportRequest/getExportRequestDownloadById",
  (id, barId, onSuccess, onFailure) => ({
    payload: {
      barId,
      id,
    },
    meta: {
      fetch: "exportRequest",
      resource: "getExportRequestDownloadById",
      onSuccess,
      onFailure,
    },
  })
);
export const getAllExportRequestsSuccess = createAction<PaginationResponse<ExportRequest>>(
  "exportRequest/getAllExportRequests_SUCCESS"
);

// REDUCERS
export const exportRequestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      getAllExportRequestsSuccess,
      (
        state: ExportRequestState,
        { payload: { data, count, page, perPage } }
      ) => {
        state.data = data;
        state.map = _.keyBy(data, (el: ExportRequest) => el.id);
        state.count = count;
        state.page = page;
        state.perPage = perPage;
      }
    )
    ;
});

// SELECTOR
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectExportRequests = (state: RootState) => state.exportRequests.data;
export const selectExportRequestsCount = (state: RootState) => state.exportRequests.count;
export const selectExportRequestById = (id: string) => (state: RootState) =>
  state.exportRequests.map[id];
export const selectExportRequestsPagination = (state: RootState) => ({
  page: state.exportRequests.page,
  perPage: state.exportRequests.perPage,
});

export default exportRequestReducer;
