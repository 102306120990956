import React, { FunctionComponent, useEffect } from "react";
import { Bar } from "../../../services/types/bar";
import Grid from "@material-ui/core/Grid";
import IceCard from "./IceCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllDispenserIce, selectDispenserIce } from "./dispenserIceSlice";

interface IceDispenserProps {
  bar: Bar | null;
}

const IceDispenserList: FunctionComponent<IceDispenserProps> = ({ bar }) => {
  const select = useSelector(selectDispenserIce);
  const dispatch = useDispatch();

  useEffect(() => {
    if (bar) {
      dispatch(getAllDispenserIce(bar));
    }
  }, [dispatch, bar]);

  return (
    <>
      <Grid container spacing={1}>
        {select.map((el, i) => (
          <Grid item xs={6} key={i}>
            <IceCard bar={bar} position={i} dispenser={el} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default IceDispenserList;
