import { FormControl, InputLabel } from "@material-ui/core";
import * as colors from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Color, ColorPicker } from "material-ui-color";
import React from "react";
import clsx from 'clsx';
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
  colorpickerContainer: {
    paddingTop: theme.spacing(2),
    width: "100%",
  },
}));

type InputColorProps = {
  name: string;
  label: string;
  value?: string | number;
  defaultValue?: any;
  control: any;
  setValue: (...arg: any) => any;
  required?: boolean;
};

const InputColor: FunctionComponent<InputColorProps> = ({
  name,
  label,
  control,
  defaultValue,
  required,
  setValue,
}) => {
  const style = useStyles();

  const onInputChange = (color: Color) => setValue("color", `#${color.hex}`);

  return (
    <Controller
      name={name}
      label={label}
      defaultValue={defaultValue}
      setValue={setValue}
      control={control}
      rules={{ required: required }}
      render={({ value, onChange, onBlur, name }, theState) => (
        <FormControl
          margin="normal"
          error={theState.invalid && theState.isTouched}
          fullWidth
          {...{ value, onChange, onBlur, name }}
        >
          <InputLabel shrink={true} required={required}>
            Color
          </InputLabel>
          <div className={style.colorpickerContainer}>
            <ColorPicker value={value} onChange={onInputChange} disableAlpha/>
          </div>
          
        </FormControl>
      )}
    />
  );
};

export default InputColor;
