import React, { FunctionComponent, useState } from "react";

import {
  Typography,
  Grid,
  DialogActions,
  LinearProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { CoffeeType } from "../../../services/types/coffeeType";
import { showNotification } from "../../notification/notificationSlice";
import { createGlobalCoffeeType } from "./globalCoffeeTypeSlice";
import MainSpacer from "../../../components/layout/MainSpacer";
import { useForm } from "react-hook-form";
import InputText from "../../../components/form/InputText";
import InputButton from "../../../components/form/InputButton";

interface CreateGlobalCoffeeTypeFormProps {
  onCreateSuccess: (coffeeType: CoffeeType) => any;
}

const CreateGlobalCoffeeTypeForm: FunctionComponent<CreateGlobalCoffeeTypeFormProps> = ({
  onCreateSuccess,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const onSubmit = (coffeeType: CoffeeType) => {
    setDisabled(true);
    dispatch(
      createGlobalCoffeeType(
        coffeeType,
        ({ payload }: any) => {
          dispatch(showNotification("Global CoffeeType Saved!", "success"));
          setDisabled(false);
          onCreateSuccess(payload);
        },
        (e: any) => {
          console.error(e);
          setDisabled(false);
        }
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5" component="h2" gutterBottom>
        Create Global CoffeeType
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputText
            name="name"
            label="Name"
            register={register}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <MainSpacer />
      <DialogActions style={{ padding: 0 }}>
        <InputButton disabled={disabled}>Create CoffeeType!</InputButton>
      </DialogActions>
      {disabled && <LinearProgress />}
    </form>
  );
};

export default CreateGlobalCoffeeTypeForm;
